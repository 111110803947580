import React, { useState, useEffect , Fragment } from 'react';
import $ from 'jquery';

import { getinApi } from '../services/api';

import Companies from './landings/renovation2/companies';
import Option from './landings/renovation2/options';
import Rating from 'react-rating';

// Trasnlation
import '../i18n';
import { useTranslation } from 'react-i18next';

export default function Health_quotes({ client_id }) {
	const { t } = useTranslation();

	const [sended, setsended] = useState(false);
	const [showMore, setShowMore] = useState(false);
	const [global_id, setGlobaID] = useState(0);
	const [ready, setReady] = useState(false);
	const [options, setoptions] = useState([]);
	const [options_selected, setoptionSelected] = useState([]);
	const [logos, setLogos] = useState([]);

	useEffect(() => {
		loadOptions(parseInt(client_id));
		setGlobaID(client_id);
		return () => {
			$('body').addClass('view-landing-renovation');
		};
	}, []);

	const loadOptions = async (id) => {
		await getinApi('get', 'clients/' + id + '/quotes').then((res) => {
			// console.log(res);
			if (res.status !== 'Error') {
				setoptions(res.quotes);
				orderOption(res.quotes);
				setReady(true);
			} else {
				return false;
			}
		});
	};

	const orderOption = async (options) => {
		let option_selected = [];

		options.forEach((option, index) => {
			const filtered = option_selected.filter(function (element) {
				return element === option.logo;
			});

			if (filtered.length <= 0) {
				option_selected.push(option.logo);
			}

			if (index + 1 === options.length) {
				setLogos(option_selected);
			}
		});
	};

	const setSubmit = async (id_scrapt) => {
		await getinApi('put', 'clients/' + global_id, {
			other_1: id_scrapt + '',
		}).then((res) => {
			window.analytics.track("Pre-enrolled", {
				Section: 'Health Form',
				attempt_id: Date.now()
			});
			setsended(true);
		});
	};

	const setOptions = (index) => {
		const filtered = options.filter(function (element) {
			return element.logo === index;
		});

		setoptionSelected(filtered);
	};

	const location = () => {
		window.location = 'https://emilan.co';
	};

	return (
		<Fragment>
			{sended ? (
				<div className='health-quotes mess  '>
					<div>
						<div className='chatelo'>
							<h3>{t('land2_title2.label')}</h3>
							<div className='end-renov'>
								<div className='message'>
									<p>{t('land2_parf4.label')}</p>
									<button className='button' onClick={location}>
										{t('land2_out.label')}{' '}
									</button>
								</div>
								<img
									src='assets/Images/landings/renovation2/Registro-renov.png'
									alt='thanks'
								/>
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className='health-quotes '>
					<div className='body-landing2'>
							<h2>{t('land2_title.label')}</h2>
							<p className='left'>{t('refe_bestPrices.label')}</p>
							<div className='options'>
								{ready &&
									options.map(
										(option, index) =>
											index < 5 && (
												<div className='card' key={'option_number' + option.id}>
													<div className='head-card'>
														<div className='img-content'>
															<img src={option.logo} alt='option' />
														</div>
														<div className='stars'>
															<Rating
																className='rating-box'
																emptySymbol={
																	<i className='icon icon_star_void'></i>
																}
																readonly
																initialRating={option.rating}
																fullSymbol={[1, 2, 3, 4, 5].map((n) => (
																	<i className='icon icon_star_full'></i>
																))}
															/>
														</div>
													</div>
													<div className='content-card'>
														<p className='pa1'>
															<b>{option.title}</b>
														</p>
														<p className='pa2'>
															<b>{t('land2_beforehand.label')}</b>
														</p>
														<p className='pa3'>
															<span className='a1'>
																<b>$</b>
															</span>
															<span className='a2'>{option.prima_mes}</span>
															<span className='a3'>{t('mes.label')}</span>
														</p>
														<p className='pa4'>
															<b>
																{t('land2_before.label')} {option.era}
															</b>
														</p>
													</div>
												</div>
											),
									)}
							</div>

						{ !showMore &&
							<Fragment>
								<p>
									{t('refe_preRegister.label')}
								</p>
								<button className='button-plan' onClick={() => setShowMore(true)}>{t('refe_viewPlans.label')}</button>
							</Fragment>
						}
						{ showMore && 
							<Fragment>
									<p>
										{t('refe_chooseCompany.label')}
									</p>
									{logos.length > 0 ? (
										<Companies contenido={logos} setOptions={setOptions} />
									) : (
										<p className='loader-options'>
											<img src='assets/Images/Spinner-2.gif' />
										</p>
									)}
									{ready && options_selected.length > 0 && (
										<p>
											{t('refe_prequalification.label')}
										</p>
									)}
									<div className='options-renov'>
										{ready &&
											options_selected.map((option, index) => (
												<Option
													key={'option_number' + option.id}
													setSended={setSubmit}
													option={option}
												/>
											))}
									</div>
							</Fragment> 
						}
					</div>
				</div>
			)}
		</Fragment>
	);
}
