import React, { Fragment, useState } from 'react'

//Dependencies
import $ from 'jquery'
import Modal from 'react-awesome-modal';
import DatePicker from '@hassanmojab/react-modern-calendar-datepicker';
// import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";

// Trasnlation 
import '../../../i18n'
import { useTranslation } from 'react-i18next';

//API
// import { getinApi } from '../../../services/api';

//Components
import Policies from '../../../components/policies';

//Images
import image_sended from '../../../assets/Images/landings/webinar/Mobile_login-pana.svg'

const LandingWebinarRegister = () => {

  const { t } = useTranslation();

  const [sended, setSended] = useState(false);
  const [visible, setvisible] = useState(false)
  const [dbo, setDbo] = useState('')
  const [terms, setTerms] = useState(false)

    const [inputs, setInputs] = useState({
    name: '',
    lastName: '',
    email: '',
    phone: '',
    zip_code:'',
    dOb:''
  })

  const saveDBOinp = e => {
    $('.DatePicker__input').removeClass('invalide')
    setDbo(e)
    // const d = new Date(`${e.month}/${e.day}/${e.year}`)
    // setInputs({ ...inputs, dOb: d })
    setInputs({ ...inputs, dOb: `${e.month}/${e.day}/${e.year}` })
  }

  const formatInputValue = () => {
    if (!dbo) return '';
    let dat =`${dbo.month}/${dbo.day}/${dbo.year}`
    return dat;
  };

  const setSendede = () => {
		setSended(true);
	};

  const setInput = e => {
    $('#webinar_reg').find('.in-' + e.target.name+'.invalide').removeClass('invalide')
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }

  const openModal = () => {
    $('.terminos .checkmark').removeClass('invalide')
    setvisible(true)
    setTerms(!terms)
  }

  const closeModal = () => {
    setvisible(false)
  }

  const submitInputs = async () => {
    let mailOK = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g

    let validations = 0;
    if ((inputs.name).trim() === '') {
      $('.in-name').addClass('invalide')
      validations = 1;
    }
    if ((inputs.lastName).trim() === '') {
      $('.in-lastName').addClass('invalide')
      validations = 1;
    }
    if ((inputs.email).trim() === '' || inputs.email.match(mailOK) === null ) {
      $('.in-mail').addClass('invalide')
      validations = 1;
    }
    if ((inputs.phone).trim() === '') {
      $('.in-phone').addClass('invalide')
      validations = 1;
    }
    if ((inputs.zip_code).trim() === '') {
      $('.in-zip_code').addClass('invalide')
      validations = 1;
    }
    if ((inputs.dOb).trim() === '') {
      $('.DatePicker__input').addClass('invalide')
      validations = 1;
    }
    if (!terms){
      $('.terminos .checkmark').addClass('invalide')
      validations = 1;
    }

    if (validations) return


  console.log('SUBMIT -->', inputs);
  setSendede()
  }


  return (
    <div className='reg_webinar'>
    { sended ?
      <Fragment>
        <div className="send">
          <figure>
            <img src={image_sended} alt="" />
          </figure>
          <div className="mesagge">
            <h3>{t('webinReg_titSend.label')}</h3>
            <p>{t('webinReg_textSend.label')}</p>
            <a  href='/' className='enviar'>{t('webinEnc_buttSend.label')}</a>
          </div>
        </div> 
      </Fragment>:
      <Fragment>
<div className="contact">
  <div className="content">
    <div className="info_webinar">
        <p className='yell taller'>{t('webinEnc_title2.label')}</p>
        <p className="title">
          {t('textBannWebinar_5.label')}<span >{t('textBannWebinar_6.label')}</span>
        </p>
      <div className="desk">
        <div className="text_agente">
          <p className='name'>{t('textBannWebinar_1.label')}</p>
          <p className='text'>{t('textBannWebinar_2.label')}</p>
        </div>
        <div className="text_banner">
          <p className='text'>{t('webinReg_text1.label')}</p>
          <p className='text'>{t('webinReg_text2.label')}<b>{t('webinReg_text3.label')}</b>{t('webinReg_text4.label')}</p>
          <div className="calendar">
            <div className="dia">
              <i className="icon icon-calend_w"></i>
              <div className="text">
                <span>{t('textBannWebinar_7.label')}</span>
                <span className="grey">{t('textBannWebinar_8.label')}</span>
              </div>
            </div>
            <div className="hora">
              <i className="icon icon-reloj_w"></i>
              <div className="text">
                <span className="grey">8:00 p.m</span>
                <span>{t('textBannWebinar_9.label')}</span>
              </div>
            </div>
          </div>
          <figure className='decision'></figure>
        </div>
      </div>
    </div>
    <div className="cale_mobile">
      <p>{t('webinReg_text5.label')}</p>
          <div className="calendar">
            <div className="dia">
              <i className="icon icon-calend_w"></i>
              <div className="text">
                <span>{t('textBannWebinar_7.label')}</span>
                <span className="grey">{t('textBannWebinar_8.label')}</span>
              </div>
            </div>
            <div className="hora">
              <i className="icon icon-reloj_w"></i>
              <div className="text">
                <span className="grey">8:00 p.m</span>
                <span>{t('textBannWebinar_9.label')}</span>
              </div>
            </div>
          </div>
    </div>

    <section className="form" id='webinar_reg'>
      <h3 className="title">{t('webinReg_titleForm.label')}</h3>
      <div className="form_contact">
        <label className="label" htmlFor="name">{t('iniform1.label')}
          <input className="input in-name" type="text" name="name" id="name" onChange={setInput}/>
        </label>
        <label className="label" htmlFor="lastName">{t('iniform2.label')}
          <input className="input in-lastName" type="text" name="lastName" id="lastName" onChange={setInput}/>
        </label>
        <label className="label" htmlFor="email">{t('land_email.label')}
          <input className="input in-mail" type="email" name="email" id="email" onChange={setInput} required/>
        </label>
        <label className="label" htmlFor="phone">{t('iniform3.label')}
          <input className="input in-phone" type="number" name="phone" id="phone" onChange={setInput}/>
        </label>
        <label className="label" htmlFor="zip_code">{t('iniform4.label')}
          <input className="input in-zip_code" type="text" name="zip_code" id="zip_code" onChange={setInput}/>
        </label>
        <label className="label" htmlFor="dateBird">{t('born.label')}
            <DatePicker
              id='dateBird'
              className='input in-dbo'
              value={dbo}
              onChange={saveDBOinp}
              inputPlaceholder=" "
              formatInputText={formatInputValue}
              shouldHighlightWeekends
              />
          <i className="icon icon-caret-down"></i>
        </label>
        <label className="checkbox terminos" htmlFor="terms">
          <input type='checkbox' id="terms"  defaultValue={terms} onClick={() => openModal()} />
          <span className="checkmark"></span>
          {t('webinReg_pol1.label')} <b>{t('webinReg_pol2.label')}</b>
        </label>
        <button className="enviar"  onClick={submitInputs}>{t('webinReg_buttform.label')}</button>
      </div>
    </section>
    <div className="text_mobile">
          <p className='text'>{t('webinReg_text1.label')}</p>
          <p className='text'>{t('webinReg_text2.label')}<b>{t('webinReg_text3.label')}</b>{t('webinReg_text4.label')}</p>
      <figure className='decision'></figure>
    </div>
  </div>
</div>
      </Fragment>
    }
        <Modal className="modales-n" visible={visible} width="90%" height="80%" effect="fadeInUp" onClickAway={() => closeModal()}>
        <div>
          <Policies />
          <b className="boton-close" onClick={() => closeModal()}>{t('iniform8.label')}</b>
        </div>
      </Modal>
    </div>
  )
}

export default LandingWebinarRegister
