import React, { useState, useContext, useEffect } from 'react'
import $ from 'jquery'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";

export default function Nosotros() {
  useEffect(() => {
      // notify segment of logged in user
      document.addEventListener("mousemove", parallax);
      const elem = document.querySelector("#parallax");
      // Magic happens here
      function parallax(e) {
        let _w = window.innerWidth / 2;
        let _h = window.innerHeight / 2;
        let _mouseX = e.clientX;
        let _mouseY = e.clientY;
        let _depth1 = `${50 - (_mouseX - _w) * 0.01}% ${50 - (_mouseY - _h) * 0.01}%`;
        let _depth2 = `${50 - (_mouseX - _w) * 0.02}% ${50 - (_mouseY - _h) * 0.02}%`;
        let _depth3 = `${50 - (_mouseX - _w) * 0.06}% ${50 - (_mouseY - _h) * 0.06}%`;
        let _depth4 = `${50 - (_mouseX - _w) * 0.09}% ${50 - (_mouseY - _h) * 0.09}%`;
        let x = `${_depth4}, ${_depth3}, ${_depth2}, ${_depth1}`;
        elem.style.backgroundPosition = x;
      }
  }, [])

  // eslint-disable-next-line
  const { t } = useTranslation()

  return (
    <div>
      <Helmet>
        <title>Nosotros</title>
        <meta name="keywords" content="emilan, Bienestar, Vida, Salud, Suplementarios, Innovación, Familia, Servicio." />
        <meta name="description" content="Buscamos promover el bienestar personal y común; a través de nuestros seguros de vida, salud y suplementarios, trabajamos en pro de la innovación, de esta forma todos los días nos esforzamos en ser mejores y prestar un excelente servicio, llegando a nuevas personas e impactando sus vidas y las de toda su familia." />
      </Helmet>
      <div className="us view" data-aos="fade">
        <div className="bkp-3" data-aos="fade"></div>
        <div className="bkp-4" data-aos="fade"></div>
          <div className="banner">
            <div id="parallax"><h1></h1></div>
            <img className="only-mobile" src="assets/Images/banner-us.png" />
          </div>
          <div className="video" data-aos="fade">
            <div className="content-video">
              <div className="box-black">
                <iframe height="100%" width="100%" src={window.localStorage.getItem('lng') == 'en' ? 'https://www.youtube.com/embed/KwfBkBgUtpQ' : 'https://www.youtube.com/embed/AykT6mjz7_o'} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
          <div className="view-content"> 
            <h2 data-aos="fade-right">{t('nos1a.label')}</h2>
            <div className="first-paragraph" data-aos="fade">
              <div className="lateral-text">
                <p data-aos="fade-up">{t('nos2.label')}</p>
                <p data-aos="fade-up">{t('nos3.label')}</p>
              </div>
            </div>
            <div className="vismiesta">
              <div className="Box pri" data-aos="fade">
                <h3 data-aos="fade-right">{t('nos4.label')}</h3>
                <p data-aos="fade-up">{t('nos5.label')}</p>
              </div>
              <div className="Box sec" data-aos="fade">
                <h3 data-aos="fade-right">{t('nos6.label')}</h3>
                <p data-aos="fade-up">{t('nos7.label')}</p>
              </div>
              <div className="Box tre" data-aos="fade">
                <h3 data-aos="fade-right">{t('nos8.label')}</h3>
                <p data-aos="fade-up">{t('nos9.label')}</p>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}