import React, { Fragment, useState } from 'react'

//Dependencies
import $ from 'jquery'

// Trasnlation 
import '../../../i18n'
import { useTranslation } from 'react-i18next';

//API
// import { getinApi } from '../../../services/api';

import image_sended from '../../../assets/Images/landings/webinar/Customer-Survey-pana.svg'

const LandingWebinarEncuesta = () => {


   const { t } = useTranslation();

  const [sended, setSended] = useState(false);

    const [inputs, setInputs] = useState({
    objetividad: '',
    conferencista: '',
    plataforma: '',
    expectativas: '',
    duracion:'',
    utilidad:'',
    temas_extra:'',
    pregunta_extra:'',
    comentario:''
  })

  const setSendede = () => {
		setSended(true);
	};

  const setInput = e => {
    $('#form_enc_webinar').find('.'+e.target.name+'.invalide').removeClass('invalide')
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }


  const submitInputs = async () => {

    let validations = 0;
    if ((inputs.objetividad).trim() === '') {
      $('.objetividad').addClass('invalide')
      validations = 1;
    }
    if ((inputs.conferencista).trim() === '') {
      $('.conferencista').addClass('invalide')
      validations = 1;
    }
    if ((inputs.plataforma).trim() === '') {
      $('.plataforma').addClass('invalide')
      validations = 1;
    }
    if ((inputs.expectativas).trim() === '') {
      $('.expectativas').addClass('invalide')
      validations = 1;
    }
    if ((inputs.duracion).trim() === '') {
      $('.duracion').addClass('invalide')
      validations = 1;
    }
    if ((inputs.utilidad).trim() === '') {
      $('.utilidad').addClass('invalide')
      validations = 1;
    }
    if ((inputs.temas_extra).trim() === '') {
      $('.temas_extra').addClass('invalide')
      validations = 1;
    }
    if ((inputs.pregunta_extra).trim() === '') {
      $('.pregunta_extra').addClass('invalide')
      validations = 1;
    }
    if ((inputs.comentario).trim() === '') {
      $('.comentario').addClass('invalide')
      validations = 1;
    }

    if (validations) return


  console.log('SUBMIT -->', inputs);
  setSendede()
  }
  

  return (
    <div className='enc_webinar'>
    { sended ?
      <Fragment>
        <div className="send">
          <figure>
            <img src={image_sended} alt="" />
          </figure>
          <div className="mesagge">
            <h3>{t('webinEnc_titleSend.label')}</h3>
            <p>{t('webinEnc_textSend.label')}</p>
            <a  href='/' className='enviar'>{t('webinEnc_buttSend.label')}</a>
          </div>
        </div> 
      </Fragment>:
      <Fragment>
      <div className="head">
        <div className="image_desktop"></div>
        <header className="header_enc">
          <h2>{t('webinEnc_title.label')}<span>{t('webinEnc_title2.label')}</span></h2>
          <div className="image_mobile">
            <p className="yell">{t('webinEnc_title2.label')}</p>
            <h4>{t('textBannWebinar_5.label')} <span> {t('textBannWebinar_6.label')}</span></h4>
          </div>
          <p>{t('webinEnc_textHeader.label')}</p>
        </header>
      </div>
        <div className="form" id='form_enc_webinar'>
          <div className="row row4">
            <p className="pregunta"> </p>
            <div className="opciones">
              <label htmlFor="objetividad1" >
                <span>{t('webinEnc_option1.label')}</span>
              </label>
              <label htmlFor="objetividad2">
                <span>{t('webinEnc_option2.label')}</span>
              </label>
              <label htmlFor="objetividad3">
                <span>{t('webinEnc_option3.label')}</span>
              </label>
              <label htmlFor="objetividad4">
                <span>{t('webinEnc_option4.label')}</span>
              </label>
            </div>
          </div>
          <div className="row bg-gray">
            <p className="pregunta">{t('webinEnc_preg1.label')}</p>
            <div className="opciones">
              <label htmlFor="objetividad1" >
                <span>{t('webinEnc_option1.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="objetividad" id="objetividad1" value='Deficiente' onChange={setInput} />
                  <span className='checkmark-r objetividad'></span>
                </div>
              </label>
              <label htmlFor="objetividad2">
                <span>{t('webinEnc_option2.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="objetividad" id="objetividad2" value='Aceptable' onChange={setInput} />
                  <span className='checkmark-r objetividad'></span>
                </div>
              </label>
              <label htmlFor="objetividad3">
                <span>{t('webinEnc_option3.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="objetividad" id="objetividad3" value='Bueno' onChange={setInput} />
                  <span className='checkmark-r objetividad'></span>
                </div>
              </label>
              <label htmlFor="objetividad4">
                <span>{t('webinEnc_option4.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="objetividad" id="objetividad4" value='Excelente' onChange={setInput} />
                  <span className='checkmark-r objetividad'></span>
                </div>
              </label>
            </div>
          </div>
          <div className="row">
            <p className="pregunta">{t('webinEnc_preg2.label')}</p>
            <div className="opciones">
              <label htmlFor="conferencista1" >
                <span>{t('webinEnc_option1.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="conferencista" id="conferencista1" value='Deficiente' onChange={setInput} />
                  <span className='checkmark-r conferencista'></span>
                </div>
              </label>
              <label htmlFor="conferencista2">
                <span>{t('webinEnc_option2.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="conferencista" id="conferencista2" value='Aceptable' onChange={setInput} />
                  <span className='checkmark-r conferencista'></span>
                </div>
              </label>
              <label htmlFor="conferencista3">
                <span>{t('webinEnc_option3.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="conferencista" id="conferencista3" value='Bueno' onChange={setInput} />
                  <span className='checkmark-r conferencista'></span>
                </div>
              </label>
              <label htmlFor="conferencista4">
                <span>{t('webinEnc_option4.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="conferencista" id="conferencista4" value='Excelente' onChange={setInput} />
                  <span className='checkmark-r conferencista'></span>
                </div>
              </label>
            </div>
          </div>
          <div className="row bg-gray">
            <p className="pregunta">{t('webinEnc_preg3.label')}</p>
            <div className="opciones">
              <label htmlFor="plataforma1" >
                <span>{t('webinEnc_option1.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="plataforma" id="plataforma1" value='Deficiente' onChange={setInput} />
                  <span className='checkmark-r plataforma'></span>
                </div>
              </label>
              <label htmlFor="plataforma2">
                <span>{t('webinEnc_option2.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="plataforma" id="plataforma2" value='Aceptable' onChange={setInput} />
                  <span className='checkmark-r plataforma'></span>
                </div>
              </label>
              <label htmlFor="plataforma3">
                <span>{t('webinEnc_option3.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="plataforma" id="plataforma3" value='Bueno' onChange={setInput} />
                  <span className='checkmark-r plataforma'></span>
                </div>
              </label>
              <label htmlFor="plataforma4">
                <span>{t('webinEnc_option4.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="plataforma" id="plataforma4" value='Excelente' onChange={setInput} />
                  <span className='checkmark-r plataforma'></span>
                </div>
              </label>
            </div>
          </div>
          <div className="row">
            <p className="pregunta">{t('webinEnc_preg4.label')}</p>
            <div className="opciones">
              <label htmlFor="expectativas1" >
                <span>{t('webinEnc_option1.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="expectativas" id="expectativas1" value='Deficiente' onChange={setInput} />
                  <span className='checkmark-r expectativas'></span>
                </div>
              </label>
              <label htmlFor="expectativas2">
                <span>{t('webinEnc_option2.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="expectativas" id="expectativas2" value='Aceptable' onChange={setInput} />
                  <span className='checkmark-r expectativas'></span>
                </div>
              </label>
              <label htmlFor="expectativas3">
                <span>{t('webinEnc_option3.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="expectativas" id="expectativas3" value='Bueno' onChange={setInput} />
                  <span className='checkmark-r expectativas'></span>
                </div>
              </label>
              <label htmlFor="expectativas4">
                <span>{t('webinEnc_option4.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="expectativas" id="expectativas4" value='Excelente' onChange={setInput} />
                  <span className='checkmark-r expectativas'></span>
                </div>
              </label>
            </div>
          </div>
          <div className="row bg-gray row5">
            <p className="pregunta">{t('webinEnc_preg5.label')}</p>
            <div className="opciones">
              <label htmlFor="duración1" className='labrow'>
                <span>{t('webinEnc_option5.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="duracion" id="duración1" value='Corta' onChange={setInput} />
                  <span className='checkmark-r duracion'></span>
                </div>
              </label>
              <label htmlFor="duración2" className='labrow'>
                <span>{t('webinEnc_option6.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="duracion" id="duración2" value='Adecuada' onChange={setInput} />
                  <span className='checkmark-r duracion'></span>
                </div>
              </label>
              <label htmlFor="duración3" className='labrow'>
                <span>{t('webinEnc_option7.label')}</span>
                <div className='radio_button'>
                  <input  type="radio" name="duracion" id="duración3" value='Larga' onChange={setInput} />
                  <span className='checkmark-r duracion'></span>
                </div>
              </label>
            </div>
          </div>
          <div className="row row2">
            <p className="pregunta">{t('webinEnc_preg6.label')}</p>
            <div className="opciones">
              <label htmlFor="utilidad1" >
                <div className='radio_button'>
                  <input  type="radio" name="utilidad" id="utilidad1" value='si' onChange={setInput} />
                  <i className="icon icon-heart_w utilidad"></i>
                </div>
              </label>
              <label htmlFor="utilidad2">
                <div className='radio_button'>
                  <input  type="radio" name="utilidad" id="utilidad2" value='no' onChange={setInput} />
                  <i className="icon icon-heart_off utilidad"></i>
                </div>
              </label>
            </div>
          </div>
          <div className="row row3">
            <p className="pregunta">{t('webinEnc_preg7.label')}</p>
            <textarea className='temas_extra' name="temas_extra" id="" onChange={setInput} />
          </div>
          <div className="row row3">
            <p className="pregunta">{t('webinEnc_preg8.label')}</p>
            <textarea className='pregunta_extra' name="pregunta_extra" id="" onChange={setInput} />
          </div>
          <div className="row row3">
            <p className="pregunta">{t('webinEnc_preg9.label')}</p>
            <textarea className='comentario' name="comentario" id="" onChange={setInput} />
          </div>
          <buttton className="enviar"  onClick={submitInputs}>{t('webinEnc_butt.label')}</buttton>
        </div>
      </Fragment>
    }
    </div>
  )
}

export default LandingWebinarEncuesta
