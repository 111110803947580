import React, { useEffect, useState } from 'react'
import $ from 'jquery'

// import FormLandings from '../servicios/form_landings'
// Import Swiper React components
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react'
  
// Trasnlation 
import '../../../i18n'
import { useTranslation } from 'react-i18next'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import "swiper/swiper.min.css";

//logos
import aetna from '../../../assets/Images/landings/agentes/Aetna.jpg' 
import ambetter from '../../../assets/Images/landings/agentes/ambetter.jpeg' 
import ameritas from '../../../assets/Images/landings/agentes/Ameritas.png' 
import blueCross from '../../../assets/Images/landings/agentes/blue-cross-blue-shield-vector-logo.png' 
import brightHealth from '../../../assets/Images/landings/agentes/Bright HealthCare.png' 
import carePlus from '../../../assets/Images/landings/agentes/CarePlus_Logo_.jpg' 
import careSource from '../../../assets/Images/landings/agentes/Caresource.png' 
import cigna from '../../../assets/Images/landings/agentes/Cigna-logo.jpg' 
import forester from '../../../assets/Images/landings/agentes/foresters-insurance-logo-450x127.png' 
import humana  from '../../../assets/Images/landings/agentes/Humana.png' 
import medica from '../../../assets/Images/landings/agentes/medica-logo.jpg' 
import molina from '../../../assets/Images/landings/agentes/Molina-Healthcare.png' 
import mutual from '../../../assets/Images/landings/agentes/Mutual-of-Omaha-logo.png' 
import nationalGeneral from '../../../assets/Images/landings/agentes/national-general-insurance-logo-vector.png' 
import nationalLife from '../../../assets/Images/landings/agentes/national-life-group-logo2.png' 
import obama from '../../../assets/Images/landings/agentes/obama.svg' 
import oscar from '../../../assets/Images/landings/agentes/Oscar_Health_logo.png' 
import sunHealth from '../../../assets/Images/landings/agentes/Sun Health_Dental.jpg' 
import sureBridge from '../../../assets/Images/landings/agentes/SureBridge.png' 
import united from '../../../assets/Images/landings/agentes/UnitedHealthcare.jpg' 
import wellcare from '../../../assets/Images/landings/agentes/wellcare_by_allwell.jpg' 

const salud = [obama, ambetter, oscar, blueCross, aetna, brightHealth, careSource, cigna, medica, molina, united]

const vida = [forester, mutual, nationalLife]
const suplementarios = [ameritas, sureBridge, sunHealth, nationalGeneral]
const medicare = [aetna, carePlus, humana, united, wellcare]

SwiperCore.use([Autoplay,Navigation, Pagination]);

const LandingAgentes = () => {

  const { t } = useTranslation()
  const [perpage, setperpage] = useState(4)
  const [listas, setListas] = useState(1)

  const changeLista = function(op, e){
    setListas(op)
    $('.pest_agentes span').removeClass('active')
    $(e.target).addClass('active')
  }

  // useEffect(() => {
  //   localStorage.setItem('lng', 'es')
  // }, [])

  return (
  <div className="landing_agentes">
    <section className="div_1"> 
      <div className="image_mobile"></div>
      <div className="blue">
      <div className="buble_mobile"></div>
      <div className="buble1"></div>
      <div className="buble2"></div>
      </div>
      <div className="content_div1">
        <h4 className="subtitle">{t('lan_agentstitle.label')} <b>e<span>m</span>ilan</b>!</h4>
        <p className="p1_dv1">{t('lan_agents-prf1.label')}</p>
        <div className="flex puntos">
          <div className="punto">
            <i className="icon icon-2014"></i>
            <p className='p1_p1'>{t('lan_agents-p1.label')}</p>
          </div>
          <div className="punto">
            <i className="icon icon-map_point"></i>
            <p>{t('lan_agents-p2.label')}</p>
          </div>
          <div className="punto">
            <i className="icon icon-equip_star"></i>
            <p>{t('lan_agents-p3.label')}</p>
          </div>
        </div>
        <p className="p2_dv1">{t('lan_agents-prf2_1.label')}<b>e<span>m</span>ilan</b>{t('lan_agents-prf2_2.label')}</p>
        <ul>
        <li><i className="icon icon-checkbox"></i>{t('lan_agents-check1.label')}</li>
        <li><i className="icon icon-checkbox"></i>{t('lan_agents-check2.label')}</li>
        <li><i className="icon icon-checkbox"></i>{t('lan_agents-check3.label')}</li>
        <li><i className="icon icon-checkbox"></i>{t('lan_agents-check4.label')}</li>
        <li><i className="icon icon-checkbox"></i>{t('lan_agents-check5.label')}</li>
        </ul>
        <a href='https://agents.emilan.co/' target='_blank' className='btn_naranja'>{t('lan_agents-btn_orange.label')}</a>
      </div>
    </section>
    <section className='div_2'>
      <h2>{t('lan_agents-div2_prf1.label')}</h2>
      <div className="pest_agentes">
        <span className='active' onClick={(e)=>changeLista(1, e)}>{t('lan_agents-pest1.label')}</span>
        <span onClick={(e)=>changeLista(2, e)}>{t('lan_agents-pest2.label')}</span>
        <span className='pest3' onClick={(e)=>changeLista(3, e)}>
          <span className="pest3_1">{t('lan_agents-pest3_1.label')} </span>
        {t('lan_agents-pest3_2.label')} </span>
        <span onClick={(e)=>changeLista(4, e)}>{t('lan_agents-pest4.label')}</span>
      </div>
      {listas === 1 &&  <div className="slidr s_salud animate__animated animate__fadeIn"> 
        <p className="p">{t('lan_agents-sldsalud.label')}</p>
        <Swiper
          modules={[Autoplay,Navigation, Pagination]}
          // loop={true}
          // rewind={true}
          // loopFillGroupWithBlank={true}
          // spaceBetween={10}
          slidesPerGroup={4}
          slidesPerView={'auto'}
          navigation={true}

        
        freeMode={false}

        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
          
          
          breakpoints={{
            340:{
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: perpage,
              slidesPerGroup: 4,
            },
            // 1450: {
            //   slidesPerView: 5,
            //   slidesPerGroup: 5,
            //   // spaceBetween: 20
            // },
          }}
          
        >
          {salud.map((slide, index) =>
            <SwiperSlide key={'slide-index'+index}  >
              <img src={slide} alt='img_slide'/>
            </SwiperSlide>
          )}
        </Swiper>
      </div>}
      {listas === 2 && <div className="slidr s_vida  animate__animated animate__fadeIn">
        <p className="p">{t('lan_agents-sldvida.label')}</p>
        <Swiper
          modules={[Autoplay,Navigation, Pagination]}
          // loop={true}
          // rewind={true}
          // loopFillGroupWithBlank={true}
          // spaceBetween={10}
          slidesPerGroup={4}
          slidesPerView={'auto'}
          navigation={true}

        
        freeMode={false}

        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
          
          
          breakpoints={{
            340:{
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: perpage,
              slidesPerGroup: 4,
            },
            // 1450: {
            //   slidesPerView: 5,
            //   slidesPerGroup: 5,
            //   // spaceBetween: 20
            // },
          }}
          
        >
          {vida.map((slide, index) =>
            <SwiperSlide key={'slide-index'+index}  >
              <img src={slide} alt='img_slide'/>
            </SwiperSlide>
          )}
        </Swiper>
      </div>}
      {listas === 3 && <div className="slidr s_suplement  animate__animated animate__fadeIn">
        <Swiper
          modules={[Autoplay,Navigation, Pagination]}
          // loop={true}
          // rewind={true}
          // loopFillGroupWithBlank={true}
          // spaceBetween={10}
          slidesPerGroup={4}
          slidesPerView={'auto'}
          navigation={true}

        
        freeMode={false}

        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
          
          
          breakpoints={{
            340:{
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: perpage,
              slidesPerGroup: 4,
            },
            // 1450: {
            //   slidesPerView: 5,
            //   slidesPerGroup: 5,
            //   // spaceBetween: 20
            // },
          }}
          
        >
          {suplementarios.map((slide, index) =>
            <SwiperSlide key={'slide-index'+index}  >
              <img src={slide} alt='img_slide'/>
            </SwiperSlide>
          )}
        </Swiper>
      </div>}
      {listas === 4 && <div className="slidr s_medicare  animate__animated animate__fadeIn">
        <Swiper
          modules={[Autoplay,Navigation, Pagination]}
          // loop={true}
          // rewind={true}
          // loopFillGroupWithBlank={true}
          // spaceBetween={10}
          slidesPerGroup={4}
          slidesPerView={'auto'}
          navigation={true}

        
        freeMode={false}

        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
          
          
          breakpoints={{
            340:{
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: perpage,
              slidesPerGroup: 4,
            },
            // 1450: {
            //   slidesPerView: 5,
            //   slidesPerGroup: 5,
            //   // spaceBetween: 20
            // },
          }}
          
        >
          {medicare.map((slide, index) =>
            <SwiperSlide key={'slide-index'+index}  >
              <img src={slide} alt='img_slide'/>
            </SwiperSlide>
          )}
        </Swiper>
      </div>}
    </section>
  </div>
  )
}

export default LandingAgentes