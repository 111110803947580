import React, { useState, useContext, useEffect } from 'react'
import $ from 'jquery'
import axios from 'axios';
import Rating from 'react-rating'
import Modal from 'react-awesome-modal';
import Policies from '../../../components/policies';
import Select from 'react-select'

// Trasnlation 
import '../../../i18n'
import { useTranslation } from 'react-i18next'

import env from './../../../environtments';

export default function Landing_renovation23({ sended, setSended, lang }) {

  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      $('body').addClass('view-landing-renovation');
    };
  }, []);

  const [inputs, setInputs] = useState({
    fullname: '',
    phone: '',
    zip: '',
    state: '',
    mail: '',
    company: '',
    social: '',
    rate1: 0,
    rate2: 0,
    rate3: 0,
    rate4: 0,
    preg1: null,
    preg2: null,
    preg3: null,
    message: '',
    lang: window.localStorage.getItem('lng')
  })

  const [error, setError] = useState(false);
  const [other, setOther] = useState(false);
  let [selectstate, setSelState] = useState()
  let [selectRedes, setSelRedes] = useState([])

  const setInput = e => {
    $('body').find('.inp-' + e.target.name + '.invalide').removeClass('invalide')
    if (e.target.value !== '') {
      $('body').find('input.inp-' + e.target.name).addClass('fill')
    } else {
      $('body').find('input.inp-' + e.target.name).removeClass('fill')
    }
    $('body').find('.invalide-all').removeClass('invalide-all')
    // setError(false)
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }

  const setSelectState = e => {
    $('body').find('.invalide').removeClass('invalide')
    // setError(false)
    setSelState(e)
    if (e.value === 'Otro') {
      setOther(true);
    } else {
      setInputs({ ...inputs, company: e.value })
    }
  }

  const setSelectRedes = (e) => {
    $('body').find('.invalide-all').removeClass('invalide-all')

    let social = [...selectRedes]
    if ($(e.target).is(':checked')) {
      social.push(e.target.value)
      setSelRedes(social)
    } else {
      let Nsocial = social.filter(red => red !== e.target.value)
      setSelRedes(Nsocial)
    }

  }
  const setSelectOtherRedes = (e) => {
    $('body').find('.invalide').removeClass('invalide')

    let social = [...selectRedes]
    if (e.target.value.trim() !== '') {
      social.push(e.target.value)
    } else {
      let dif = social.filter(red => (red !== 'Instagram' || red !== 'WhatsApp' || red !== 'Twitter' || red !== 'Telegram' || red !== 'TikTok' || red !== 'Facebook'))
      let index = social.indexOf(dif)
      social.splice(index, 1)
    }
    setSelRedes(social)
  }
  useEffect(() => {
    setInputs({ ...inputs, social: JSON.stringify(selectRedes) })
  }, [selectRedes])


  const setRanges = (name, rate) => {
    $('body').find('.inp-' + name + '.invalide').removeClass('invalide')
    //setError(false)
    $('.inp-' + name + ' .faces').addClass('animate__animated animate__bounceOutUp');
    setTimeout(() => {
      $('.inp-' + name + ' .faces').removeClass('animate__bounceOutUp');
    }, 2000);
    setInputs({ ...inputs, [name]: rate })
  }


  const submitInputs = async () => {
    // setSended(true)
    let validations = 0;

    if ((inputs.fullname).trim() === '') {
      $('.inp-fullname').addClass('invalide')
      validations = 1;
    }
    if ((inputs.phone).trim() === '') {
      $('.inp-phone').addClass('invalide')
      validations = 1;
    }
    if ((inputs.zip).trim() === '') {
      $('.inp-zip').addClass('invalide')
      validations = 1;
    }
    if ((inputs.state).trim() === '') {
      $('.inp-state').addClass('invalide')
      validations = 1;
    }
    if ((inputs.mail).trim() === '') {
      $('.inp-mail').addClass('invalide')
      validations = 1;
    }
    if ((inputs.company).trim() === '') {
      $('.inp-company').addClass('invalide')
      validations = 1;
    }
    if ((inputs.rate1) <= 0) {
      $('.inp-rate1').addClass('invalide')
      validations = 1;
    }
    if ((inputs.rate2) <= 0) {
      $('.inp-rate2').addClass('invalide')
      validations = 1;
    }
    if ((inputs.rate3) <= 0) {
      $('.inp-rate3').addClass('invalide')
      validations = 1;
    }
    if ((inputs.rate4) <= 0) {
      $('.inp-rate4').addClass('invalide')
      validations = 1;
    }
    if ((inputs.preg1) === null) {
      $('.inp-preg1 .checkmark').addClass('invalide-all')
      validations = 1;
    }
    if ((inputs.preg2) === null) {
      $('.inp-preg2 .checkmark').addClass('invalide-all')
      validations = 1;
    }
    if ((inputs.preg3) === null) {
      $('.inp-preg3 .checkmark').addClass('invalide-all')
      validations = 1;
    }
    if ((inputs.social) === null || (inputs.social.length) === 0) {
      $('.inp-preg_social .checkmark').addClass('invalide-all')
      $('.inp-preg_social .inp-OthRed').addClass('invalide')
      validations = 1;
    }
    if (!terms) {
      $('.terminos .checkmark').addClass('invalide-all')
      validations = 1;
    }
    if (validations) {
      setError(true)
      return
    }

    console.log(inputs);

    $('.formulario-landign').attr('style', 'opacity:0.5')
    // await axios.post(env.HEALTHS_SCRAPER_L, { loyal: inputs }).then((res) => {
    //   if(res.status === 200){
    setTimeout(() => {
      $('.formulario-landign').attr('style', 'opacity:1')
      setSendede()
    }, 3000);
    //   }else{
    //     console.log(res.status);
    //   }
    // }).catch((err) => console.log(err))
  }

  const setSendede = () => {
    setSended()
  }

  const [visible, setvisible] = useState(false)
  const [terms, setTerms] = useState(false)
  const openModal = () => {
    $('.terminos .checkmark').removeClass('invalide-all')
    setvisible(true)
    setTerms(!terms)
  }

  const closeModal = () => {
    setvisible(false)
  }

  return (
    <div className='landing-renovation23'>
      {sended ?
        <div className="chatelo" >
          <h3 data-aos="fade" >{t('land_thanks.label')}</h3>
          <img src="assets/Images/landings/Gracias.svg" />
        </div> :
        <div className="formulario-landign">
          <p>{t('lan_ren23-pr1.label')}</p>
          <div className="ranking-details">
            <div className="star-div">
              <div className="star">
                <span className="number">1</span>
                <i className="icon icon_star_void"></i>
              </div>
              <span>{t('land_str1.label')}</span>
            </div>
            <div className="star-div">
              <div className="star">
                <span className="number">2</span>
                <i className="icon icon_star_void"></i>
              </div>
              <span>{t('land_str2.label')}</span>
            </div>
            <div className="star-div">
              <div className="star">
                <span className="number">3</span>
                <i className="icon icon_star_void"></i>
              </div>
              <span>{t('land_str3.label')}</span>
            </div>
            <div className="star-div">
              <div className="star">
                <span className="number">4</span>
                <i className="icon icon_star_void"></i>
              </div>
              <span>{t('land_str4.label')}</span>
            </div>
            <div className="star-div">
              <div className="star">
                <span className="number">5</span>
                <i className="icon icon_star_void"></i>
              </div>
              <span>{t('land_str5.label')}</span>
            </div>
          </div>
          <div className="ranking-demo">
            <div className="title">{t('land_example.label')}</div>
            <div className="quest-content">
              <div className="quest">{t('land_rate0.label')}</div>
              <Rating
                className="rating-box"
                emptySymbol={<i className="icon icon_star_full"></i>}
                fullSymbol={[1, 2, 3, 4, 5].map(n => <i className="icon icon_star_full"></i>)}
              />
            </div>
          </div>
          <div className="form-land">
            <div className="two-columns">
              <div className="form-group ">
                <i className="icon icon-user_form"></i>
                <input className="input input-name inp-fullname" placeholder={t('land_name.label')} type="text" name="fullname" id="" onChange={setInput} />
                <div className="underline"></div>
              </div>
              <div className="form-group input-glname ">
                <i className="icon icon-phone_form"></i>
                <input className="input input-name inp-phone" placeholder={t('land_phone.label')} type="text" name="phone" id="" onChange={setInput} />
                <div className="underline"></div>
              </div>
            </div>
            <div className="two-columns">
              <div className="form-group">
                <i className="icon icon-location_form"></i>
                <input className="input input-name inp-zip" placeholder={t('lan_ren23-zip.label')} type="text" name="zip" id="" onChange={setInput} />
                <div className="underline"></div>
              </div>
              <div className="form-group input-glname">
                <i className="icon icon-map_form"></i>
                <input className="input input-name inp-state" placeholder={t('land_state.label')} type="text" name="state" id="" onChange={setInput} />
                <div className="underline"></div>
              </div>
            </div>
            <div className="two-columns">
              <div className="form-group ">
                <i className="icon icon-mail_form"></i>
                <input className="input input-name inp-mail" placeholder={t('land_email.label')} type="text" name="mail" id="" onChange={setInput} />
                <div className="underline"></div>
              </div>
              <div className="form-group input-glname ">
                <i className="icon icon-med_form"></i>
                <Select className='slect-tag inp-company' id="inp-state" classNamePrefix="react-select" options={[
                  { value: "Ambetter", label: "Ambetter" },
                  { value: "AmeriHealth", label: "AmeriHealth" },
                  { value: "BlueCross | BlueShield", label: "BlueCross | BlueShield" },
                  { value: "Bright Health", label: "Bright Health" },
                  { value: "Kaiser", label: "Kaiser" },
                  { value: "Molina", label: "Molina" },
                  { value: "Oscar", label: "Oscar" },
                  { value: "Florida Blue", label: "Florida Blue" },
                  { value: "Medica", label: "Medica" },
                  { value: "Cigna Connect", label: "Cigna Connect" },
                  { value: "Friday", label: "Friday" },
                  { value: "Otro", label: t('land_other.label') }
                ]} value={selectstate} placeholder={t('lan_ren23-company.label')} onChange={setSelectState} />
                <div className="underline"></div>
              </div>
            </div>
            {other &&
              <div className="two-columns">
                <div className="form-group w6">
                  <input className="input input-name inp-company" placeholder={t('land_company.label')} type="text" name="company" id="" onChange={setInput} />
                  <div className="underline"></div>
                </div>
              </div>
            }
            <div className="sino-quest inp-preg_redes">
              <div className="quest">{t('lan_ren23-preg_redes1.label')}<small>{t('lan_ren23-preg_redes2.label')} </small></div>
              <div className="sino-questa">
                <label className='checkbox label' htmlFor="Facebook">
                  <input id='Facebook' type='checkbox' value='Facebook' onChange={setSelectRedes} />
                  <span className="checkmark"></span>Facebook
                </label>
                <label className='checkbox label' htmlFor="Instagram">
                  <input id='Instagram' type="checkbox" value='Instagram' onChange={setSelectRedes} />
                  <span className="checkmark"></span>Instagram
                </label>
                <label className='checkbox label' htmlFor="WhatsApp">
                  <input id='WhatsApp' type="checkbox" value='WhatsApp' onChange={setSelectRedes} />
                  <span className="checkmark"></span>WhatsApp
                </label>
                <label className='checkbox label' htmlFor="Twitter">
                  <input id='Twitter' type="checkbox" value='Twitter' onChange={setSelectRedes} />
                  <span className="checkmark"></span>Twitter
                </label>
                <label className='checkbox label' htmlFor="Telegram">
                  <input id='Telegram' type="checkbox" value='Telegram' onChange={setSelectRedes} />
                  <span className="checkmark"></span>Telegram
                </label>
                <label className='checkbox label' htmlFor="TikTok">
                  <input id='TikTok' type="checkbox" value='TikTok' onChange={setSelectRedes} />
                  <span className="checkmark"></span>TikTok
                </label>
                <div className="other">
                  <span>{t('land_other.label')}:</span>
                  <div className="form-group">
                    <input className="input input-name inp-OthRed" type="text" name="red" id="" onBlur={setSelectOtherRedes} />
                    <div className="underline"></div>
                  </div>
                </div>
              </div>
            </div>
            <p>{t('lan_ren23-pr2.label')}</p>
            <p><b>{t('lan_ren23-pr3.label')}</b></p>
            <div className="ranking-landing">
              <div className="rating-quest inp-rate1">
                <div className="number">1.</div>
                <div className="q">{t('lan_ren23-rate1.label')}<b>{t('lan_ren23-emilan.label')}</b>?</div>
                <Rating
                  className="rating-box"
                  emptySymbol={<i className="icon icon_star_void"></i>}
                  initialRating={inputs.rate1}
                  onChange={(rate) => setRanges('rate1', rate)}
                  fullSymbol={[1, 2, 3, 4, 5].map(n => <i className="icon icon_star_full"></i>)}
                  onHover={(rate) => $('.faces1').html('<div class="animate__animated animate__bounceIn"><i class="icon icon_lvl' + rate + '"></i></div>')}
                />
                <div className="faces faces1"></div>
              </div>
              <div className="rating-quest inp-rate2">
                <div className="number">2.</div>
                <div className="q">{t('lan_ren23-rate2_1.label')} <b>{t('lan_ren23-emilan.label')}</b> {t('lan_ren23-rate2_2.label')}</div>
                <Rating
                  className="rating-box"
                  emptySymbol={<i className="icon icon_star_void"></i>}
                  initialRating={inputs.rate2}
                  onChange={(rate) => setRanges('rate2', rate)}
                  fullSymbol={[1, 2, 3, 4, 5].map(n => <i className="icon icon_star_full"></i>)}
                  onHover={(rate) => $('.faces2').html('<div class="animate__animated animate__bounceIn"><i class="icon icon_lvl' + rate + '"></i></div>')}
                />
                <div className="faces faces2"></div>
              </div>
              <p><b>{t('lan_ren23-pr4.label')}</b></p>
              <div className="rating-quest inp-rate3">
                <div className="number">3.</div>
                <div className="q">{t('lan_ren23-rate3.label')}</div>
                <Rating
                  className="rating-box"
                  emptySymbol={<i className="icon icon_star_void"></i>}
                  initialRating={inputs.rate3}
                  onChange={(rate) => setRanges('rate3', rate)}
                  fullSymbol={[1, 2, 3, 4, 5].map(n => <i className="icon icon_star_full"></i>)}
                  onHover={(rate) => $('.faces3').html('<div class="animate__animated animate__bounceIn"><i class="icon icon_lvl' + rate + '"></i></div>')}
                />
                <div className="faces faces3"></div>
              </div>
              <div className="rating-quest inp-rate4">
                <div className="number">4.</div>
                <div className="q">{t('lan_ren23-rate4.label')}</div>
                <Rating
                  className="rating-box"
                  emptySymbol={<i className="icon icon_star_void"></i>}
                  initialRating={inputs.rate4}
                  onChange={(rate) => setRanges('rate4', rate)}
                  fullSymbol={[1, 2, 3, 4, 5].map(n => <i className="icon icon_star_full"></i>)}
                  onHover={(rate) => $('.faces4').html('<div class="animate__animated animate__bounceIn"><i class="icon icon_lvl' + rate + '"></i></div>')}
                />
                <div className="faces faces4"></div>
              </div>
            </div>
            <p><b>{t('lan_ren23-pr5.label')}</b></p>
            <div className="sino-landing">
              <div className="sino-quest inp-preg1">
                <div className="quest">{t('lan_ren23-preg1.label')}</div>
                <div className="sino-questa">
                  <label className='checkbox label' htmlFor="sipreg1">
                    <input id='sipreg1' type='radio' name='preg1' value='1' onChange={setInput} />
                    <span className="checkmark"></span>{t('yes.label')}
                  </label>
                  <label className='checkbox label' htmlFor="nopreg1">
                    <input id='nopreg1' type="radio" name='preg1' value='0' onChange={setInput} />
                    <span className="checkmark"></span>{t('no.label')}
                  </label>
                </div>
              </div>
              <div className="sino-quest inp-preg2">
                <div className="quest">{t('lan_ren23-preg2.label')}</div>
                <div className="sino-questa">
                  <label className='checkbox label' htmlFor="sipreg2">
                    <input id='sipreg2' type='radio' name='preg2' value='1' onChange={setInput} />
                    <span className="checkmark"></span>{t('yes.label')}
                  </label>
                  <label className='checkbox label' htmlFor="nopreg2">
                    <input id='nopreg2' type="radio" name='preg2' value='0' onChange={setInput} />
                    <span className="checkmark"></span>{t('no.label')}
                  </label>
                </div>
              </div>
              <div className="sino-quest inp-preg3">
                <div className="quest">{t('land_preg3.label')}</div>
                <div className="sino-questa">
                  <label className='checkbox label' htmlFor="sipreg3">
                    <input id='sipreg3' type='radio' name='preg3' value='1' onChange={setInput} />
                    <span className="checkmark"></span>{t('yes.label')}
                  </label>
                  <label className='checkbox label' htmlFor="nopreg3">
                    <input id='nopreg3' type="radio" name='preg3' value='0' onChange={setInput} />
                    <span className="checkmark"></span>{t('no.label')}
                  </label>
                </div>
              </div>
              <div className="bottom-div">
                <p>{t('lan_ren23-message.label')}</p>
                <textarea className="input input-pregs inp-message" placeholder={t('land_placemessage.label')} name="message" id="" onChange={setInput} ></textarea>
              </div>
              <div className="terms-landing">
                <label className='checkbox terminos' htmlFor="acepto">
                  <input id='acepto' type='checkbox' name='terms' defaultValue={terms} onClick={() => openModal()} />
                  <span className="checkmark"></span>
                  <p>
                    {t('land_terms1.label')} <b>{t('land_terms2.label')}</b>
                  </p>
                </label>
              </div>
              <button className="button btn-submit" type="submit" onClick={submitInputs}> {t('land_send.label')} </button>
            </div>
          </div>
          <Modal className="modales-n" visible={visible} width="90%" height="80%" effect="fadeInUp" onClickAway={() => closeModal()}>
            <div>
              <Policies />
              <a className="boton-close" onClick={() => closeModal()}>{t('iniform8.label')}</a>
            </div>
          </Modal>
        </div>
      }
    </div>
  )
}
