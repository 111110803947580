import React, { useEffect, useState } from 'react'
import { Link, useLocation  } from 'react-router-dom';
import $ from 'jquery';
import AOS from 'aos';
// Trasnlation 
import './../../../i18n'
import { useTranslation } from 'react-i18next'

export default function Header() {

  const { t, i18n } = useTranslation()
  
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
    $(".bt").removeClass("active");
    $("." + lang).addClass("active");
    localStorage.setItem('lng', lang)
  }

  return (
    <div className="banner-landing">
      <div className="langs">
        <div className='botones'>
          <button className={localStorage.getItem('lng') === 'es' ? 'bt es active' : 'bt es'} onClick={() => changeLanguage('es')} type='button'><span>ES</span></button>
          <button className={localStorage.getItem('lng') === 'en' ? 'bt en active' : 'bt en'} onClick={() => changeLanguage('en')} type='button'><span>IN</span></button>
        </div>
      </div>
    </div>
  )
}