import React, { useEffect, useState } from 'react';
import $ from 'jquery';

// Trasnlation
import '../../../i18n';
import Rating from 'react-rating';
import { useTranslation } from 'react-i18next';

export default function Option({ setSended, option }) {
	const { t } = useTranslation();

	const setSendede = (index) => {
		setSended(index);
	};

	return (
		<div className='option-renov'>
			<div className='option-header'>
				<img src={option.logo} alt='option' />
				<div className='rate'>
					<Rating
						className='rating-box'
						emptySymbol={<i className='icon icon_star_void'></i>}
						readonly
						initialRating={option.rating}
						fullSymbol={[1, 2, 3, 4, 5].map((n) => (
							<i className='icon icon_star_full'></i>
						))}
					/>
					<p className='category_Name'>{option.category}</p>
				</div>
				<button className='option-button' onClick={() => setSendede(option.id)}>
					{t('land2_pre-reg.label')}
				</button>
			</div>
			<div className='option-body'>
				<h4>{option.title}</h4>
				<div className='option-info'>
					<div className='content d1'>
						<p className='p1'>
							<b>{t('land2_beforehand.label')}</b>
						</p>
						<p className='p2'>
							<span>
								<b>$</b>
							</span>
							<span>{option.prima_mes}</span>
							<span>{t('mes.label')}</span>
						</p>
						<p className='p3'>
							{t('land2_before.label')} {option.era}
						</p>
					</div>
					<div className='content d2'>
						<p className='p1'>
							<b>{t('land2_deductible.label')}</b>
						</p>
						<p className='p2'>
							<span>
								<b>$</b>
							</span>
							<span>{option.deducible}</span>
							<span>{t('año.label')}</span>
						</p>
					</div>
					<div className='content d3'>
						<p className='p4'>
							{t('land2_totalEstim.label')}
							<span>{option.tot_estimated}</span>
						</p>
						<p className='p4'>
							{t('land2_out-of-pocket.label')}
							<span>{option.des_maximo}</span>
						</p>
						<p className='p4'>
							{t('land2_medicalC.label')}
							<span>
								<b>
									{option.consultas === 'Sin cargo después del deducible'
										? 'Sin cargo'
										: option.consultas}
								</b>
							</span>
						</p>
						<p className='p4'>
							{t('land2_drugs.label')}
							<span>
								<b>
									{option.medicamentos === 'Sin cargo después del deducible'
										? 'Sin cargo'
										: option.medicamentos}
								</b>
							</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
