import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
// Trasnlation
import '../i18n';
import { useTranslation } from 'react-i18next';
//components
import Modal from 'react-awesome-modal';
import Policies_GiftLATAM from './policies_giftLATAM';
//images
import title  from "../assets/Images/popReferidos2022/title-desktop-latam.png";
import titleIN  from "../assets/Images/popReferidos2022/title_pop2_eng.png";
import a15  from "../assets/Images/popReferidos2022/price-Latam-popup.png";



export default function ModalRefLatam() {
	const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

// console.log(window.location);
  

  const [visible_gift, setvisible_Gift] = useState(false);

	const openModal_Gift = () => {
		window.analytics.track('Open Terms Gift', {
			Section: 'Health Form',
			attempt_id: Date.now(),
		});
		setvisible_Gift(true);
	};

	const closeModal = () => {
		setvisible_Gift(false);
	};

	useEffect(() => {
		$('.terminos-Card').on('click', '.button-continue', function () {
			$('.terminos-Card').attr('style', 'display: none;');
		});
	}, []);

	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
		$('.bt').removeClass('active');
		$('.' + lang).addClass('active');
		localStorage.setItem('lng', lang);
	};

	return (
		<div className='terminos-Card'>
			<div className='referedLat'>
				<div className='langs'>
					<div className='botones'>
						<button
							className={
								localStorage.getItem('lng') === 'es' ? 'bt es active' : 'bt es'
							}
							onClick={() => changeLanguage('es')}
							type='button'
						>
							<div className='flag flag-es'></div>
							<span>ES</span>
						</button>
						<button
							className={
								localStorage.getItem('lng') === 'en' ? 'bt en active' : 'bt en'
							}
							onClick={() => changeLanguage('en')}
							type='button'
						>
							<div className='flag flag-en'></div>
							<span>IN</span>
						</button>
					</div>
				</div>

				<div className='close button-continue '>
					<i className='icon icon_cross'></i>
				</div>
				<div className='title-text'>
          <div className="tit_image">
              {localStorage.getItem('lng') === 'es'?<img src={title} alt=""/> : <img src={titleIN} alt="" />}
          </div>
          <p className="text_info">
            <p className='p_principal'>
              {t('PupLat_tex1.label')} 
              <span className='price'></span>
             {t('PupLat_tex2.label')} <b>{t('PupLat_tex3.label')}</b>
            </p>
          </p>
           {(pathname !== '/referidos-latinoamerica' && pathname !== '/referidos-emilan') &&
              <a className='boton_ref' href='/referidos-latinoamerica' target='_blank' rel='noopener noreferrer' >{t('PupLat_but.label')}</a>
           }
           {(pathname === '/referidos-latinoamerica' || pathname === '/referidos-emilan') &&
              <button className='boton_ref button-continue' >{t('PupLat_but.label')}</button>
           }
          <p className='small'>
            {t('PupLat_terms1.label')}
            <span onClick={openModal_Gift}>{t('PupLat_terms2.label')}</span>
          </p>
				</div>

				<Modal
					className='modales-n'
					visible={visible_gift}
					width='90%'
					height='80%'
					effect='fadeInUp'
					onClickAway={() => closeModal()}
				>
					<div>
						<Policies_GiftLATAM />
						<a className='boton-close' onClick={() => closeModal()}>
							{t('refe_continue.label')}
						</a>
					</div>
				</Modal>
			</div>
		</div>
	);
}
