import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import env from './../environtments';

// Components 
// import Slider from "rc-slider/lib/Slider";
// import "rc-slider/assets/index.css";

// Trasnlation
import './../i18n';
import { useTranslation } from 'react-i18next';
import Modal from 'react-awesome-modal';
import Policies from '../components/policies';
import Policies_Gift from '../components/policies_gift';

// Services
import { getinApi } from '../services/api';

//librarys
import $ from 'jquery';
import { Helmet } from 'react-helmet';

// Components
import Health_quotes from '../components/healt-quotes';
// import ReferSteps from '../components/referred_steps';
// import ModalSaludForm from '../components/modal_salud-form';
// import ModalRefUSA from '../components/modal_RefUSA';
import ModalRefLatam from '../components/modal_RefLatam';
// import ModalRefRifa from '../components/modal_refRifa';


export default function Healths_Calculator() {

  const loc = useLocation()

  const { t } = useTranslation();

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  const InitialInputs = {
    fullname: '',
    phone: '',
    email: '',
    gender: '',
    zip: '',
    age: 0,
    members: 0,
    incomes: 0,
    secure: 0,
    asesor: null,
    exist: null,
    vip: loc.pathname.includes('VIP') ? true : false
  };

  const initialLister = {
    fullname: '',
    phone: '',
    zone: '',
    date: '',
    hour: '',
  };

  const Initialselects = {
    day: '',
    asesor: '',
  };

  const InitialCompanies = [
    { value: 'Ambetter', label: 'Ambetter' },
    { value: 'AmeriHealth', label: 'AmeriHealth' },
    { value: 'BlueCross | BlueShield', label: 'BlueCross | BlueShield' },
    { value: 'Bright Health', label: 'Bright Health' },
    { value: 'Kaiser', label: 'Kaiser' },
    { value: 'Molina', label: 'Molina' },
    { value: 'Oscar', label: 'Oscar' },
    { value: 'Florida Blue', label: 'Florida Blue' },
    { value: 'Medica', label: 'Medica' },
    { value: 'Cigna Connect', label: 'Cigna Connect' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Otro', label: 'Otro' },
  ];

  const membs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  // const [rang, setRang] = useState(0);
  const [sended, setSended] = useState(false);
  const [opt, setOpt] = useState(loc.pathname.includes('referidos') ? 2 : 1);
  const [isrefer, setIsRef3r] = useState(loc.pathname.includes('referidos') ? 1 : 0);
  const [visible, setvisible] = useState(false);
  const [visible_gift, setvisible_Gift] = useState(false);
  const [terms, setTerms] = useState(false);
  const [generalIds, setIds] = useState(1);
  const [refers, setRefers] = useState([]);
  const [showref, setShowRef1] = useState(false);
  const [isRef, setIsRef] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [loader4, setLoader4] = useState(false);

  const [prefer, setPrefer] = useState(false);
  const [client_id, setClientId] = useState(null);
  const [friends, setFriends] = useState(false);
  const [readyAgents, setreadyAgents] = useState(false);
  const [days, setDays] = useState(null);
  const [agents, setAgents] = useState([]);
  const [members, toggleMembers] = useState(false);
  const [asesors, toggleAsesors] = useState(false);
  const [compa, toggleCompany] = useState(false);
  const [dalys, toggleDaylies] = useState(false);
  const [zones, toggleZones] = useState(false);
  const [hours, toggleHours] = useState(false);
  const [seletop, setSeletop] = useState(false);
  const [formCot, setFormCot] = useState(false);
  const [formReno, setFormReno] = useState(false);
  const [company, setCompany] = useState('');
  const [selects, setSelects] = useState({ ...Initialselects });
  const [inputs, setInputs] = useState({ ...InitialInputs });
  const [lister, setLister] = useState({ ...initialLister });
  const [companies, setCompas] = useState(InitialCompanies);
  const [titleView, setTitleView] = useState('')

  // $('#years').on("input", showSliderValue, false);

  // function showSliderValue() {
  //    $('#valueyears').html($('#years').val());
  //    let bulletPosition = (($('#years').val() - 16)*100)/48;
  //    console.log(bulletPosition);
  //    let lef;
  //    if (bulletPosition >= 50) { lef = `calc( ${bulletPosition}% - 15px)` }else{ lef = `calc( ${bulletPosition}% - 8px)`}
  //   $('#valueyears').css('left', lef)
  // }

  useEffect(() => {

    let new_id = window.location.hash;
    let id = new_id.split('#');
    let loaded_id = parseInt(id[1]);
    let local_next_day = moment(new Date()).add(1, 'days').format('DD');
    let local_day = moment(new Date()).format('DD');

    setDays([local_day, local_next_day]);
    if (loaded_id) {
      setSended(true);
      setClientId(loaded_id);
    }

    getAgents();

    $('body').on('mouseover', '.help-tip', function () {
      if (!$('.help-tip__content.Clone').length) {
        let off = $(this).offset();
        let clone = $(this).find('.help-tip__content').clone();
        clone.addClass('Clone animate__animated animate__bounceIn');
        $('body').append(clone);
        let h = $('body .help-tip__content.Clone').outerHeight() / 2 - 9;
        $('body .help-tip__content.Clone').attr(
          'style',
          'top:' + (off.top - h) + 'px; left:' + (off.left + 29) + 'px',
        );
      }
    });

    $(document).on('click', function () {
      $('body').find('.help-tip__content.Clone').addClass('animate__bounceIn');
      $('body').find('.help-tip__content.Clone').addClass('animate__bounceOut');
      setTimeout(() => {
        $('body').find('.help-tip__content.Clone').remove();
      }, 450);
    });
    //setNewRefer()
    if (loc.pathname.includes('referidos-emilan')) {
      setTitleView('Referidos Emilan')
    }
    if (loc.pathname.includes('referidos-VIP')) {
      setTitleView('Referidos VIP')
    }
    if (loc.pathname.includes('referidos-latinoamerica')) {
      setTitleView('Referidos Latinoamerica')
    }
    if (loc.pathname.includes('cotizador-salud')) {
      setTitleView('Cotizador Salud')
    }

  }, []);
  const clearInvalid = () => {
    $('body').find('.invalide-health').removeClass('invalide-health');
  };
  const setInput = (e) => {
    clearInvalid();
    if (
      e.target.name === 'age' ||
      e.target.name === 'asesor' ||
      e.target.name === 'members' ||
      e.target.name === 'incomes' ||
      e.target.name === 'secure'
    ) {
      setInputs({ ...inputs, [e.target.name]: parseInt(e.target.value) });
    } else {
      setInputs({ ...inputs, [e.target.name]: e.target.value });
    }
    if (loc.pathname.includes('referidos')) {
      if (
        inputs.fullname != '' &&
        inputs.phone != '' &&
        refers.length === 0
      ) {
        showRefers();
      }
    } else {
      if (
        inputs.fullname != '' &&
        inputs.phone != '' &&
        inputs.asesor !== null &&
        refers.length === 0
      ) {
        showRefers();
      }
    }
    // console.log(inputs);
  };
  const getAgents = async () => {
    await getinApi('get', 'asessors_list').then((res) => {
      setreadyAgents(true);
      setAgents(res.agents);
    });
  };
  const setInputLister = (e) => {
    $('body').find('.invalide').removeClass('invalide');
    setLister({ ...lister, [e.target.name]: e.target.value });
    // console.log(lister);
  };
  const setRemoveRefer = (refer) => {
    if (refers.length > 1) {
      let local_refer = [...refers];
      let newRefer = local_refer.filter(function (data, i) {
        return data.local_id !== refer;
      });
      setRefers(newRefer);
    }
  };
  const setMember = (member) => {
    setInputs({ ...inputs, members: member });
    toggleMembers(false);
    //console.log(inputs);
  };
  const setAsessor = (asesor, text, action) => {
    setInputs({ ...inputs, asesor: asesor });
    setInputs({ ...inputs, asesor: asesor });
    setSelects({ ...selects, asesor: text });
    toggleAsesors(false);
    //console.log(inputs);
    //console.log(selects);
    if (action != 'no-action') {
      if (
        inputs.fullname != '' &&
        inputs.phone != '' &&
        asesor !== null &&
        refers.length === 0
      ) {
        showRefers();
      }
    }
  };
  const setComa = (asesor, text) => {
    setCompany(asesor);
    toggleCompany(false);
  };
  const setDay = (day, text) => {
    setLister({ ...lister, date: day });
    setLister({ ...lister, date: day });
    setSelects({ ...selects, day: text });
    toggleDaylies(false);
    //console.log(lister);
  };
  const setZone = (zone) => {
    setLister({ ...lister, zone: zone });
    toggleZones(false);
    //console.log(lister);
  };
  const setHour = (hour) => {
    setLister({ ...lister, hour: hour });
    toggleHours(false);
    //console.log(lister);
  };
  const setNewRefer = (t = 0) => {
    //console.log(refers);
    if (refers.length < 4) {
      clearInvalid();
      let newRefer = {
        local_id: generalIds,
        fullname: '',
        phone: '',
        email: '',
      };
      if (t === 1) {
        setRefers([newRefer]);
      } else {
        setRefers([...refers, newRefer]);
      }

      setIds(generalIds + 1);
    }
  };
  const setInputRefer = (em, refer) => {
    clearInvalid();
    let local_refer = [...refers];
    local_refer.filter(function (data, i) {
      if (data.local_id === refer) {
        data[em.target.name] = em.target.value;
      }
      return false;
    });
    setRefers(local_refer);
  };
  const showRefers = (turn = 1) => {
    if (turn === 2) {
      setNewRefer(1);
      setIsRef(1);
      setShowRef1(true);
    } else {
      setNewRefer();
      setIsRef(1);
      setShowRef1(true);
    }
  };
  const showFormCot = () => {
    setFormCot(true);
    setFormReno(false);
    setSeletop(true);
    clearInvalid();
    window.analytics.track('Select Quoter', {
      Section: 'Health Form',
      attempt_id: Date.now(),
    });
  };
  const showFormReno = () => {
    setFormCot(false);
    setFormReno(true);
    setSeletop(true);
    clearInvalid();
    window.analytics.track('Select Renovation', {
      Section: 'Health Form',
      attempt_id: Date.now(),
    });
  };
  const SetFriends = () => {
    setFriends(true);
    setInputs({ ...inputs, exist: true });
  };
  const hideRefers = () => {
    setRefers([]);
    setIds(1);
    setIsRef(0);
    setShowRef1(false);
  };
  const ChangeLoader = () => {
    $('.load').attr('style', 'flex-direction:row-reverse');
  };
  const ChangeLoader2 = () => {
    $('.load').attr('style', 'flex-direction:row');
  };
  const submitInputs = async () => {
    window.analytics.track('Submint Button Click', {
      Section: 'Health Form',
      attempt_id: Date.now(),
    });
    let validations = 0;
    let opt_class = '.opt' + opt;

    if (opt === 1 || opt === 2 || opt === 3) {
      clearInvalid();

      if (inputs.fullname.trim() === '') {
        $(opt_class + ' #fullname').addClass('invalide-health');
        validations = 1;
      }
      if (inputs.phone.trim() === '') {
        $(opt_class + ' #phone').addClass('invalide-health');
        validations = 1;
      }
      if (inputs.email.trim() === '') {
        $(opt_class + ' #email').addClass('invalide-health');
        validations = 1;
      }

      if (inputs.zip.trim() === '' && opt !== 2) {
        $(opt_class + ' #zip').addClass('invalide-health');
        validations = 1;
      }

      if (refers.length > 0) {
        refers.forEach((refer) => {
          if (refer.fullname === null || refer.fullname.trim() === '') {
            $(opt_class + ' .inp-fullname-refer' + refer.local_id).addClass(
              'invalide-health',
            );
            validations = 1;
          }
          if (refer.phone === null || refer.phone.trim() === '') {
            $(opt_class + ' .inp-phone-refer' + refer.local_id).addClass(
              'invalide-health',
            );
            validations = 1;
          }
        });
      }

      if (opt === 1) {
        let nIntervId = setInterval(ChangeLoader, 2000);
        let nIntervId2 = setInterval(ChangeLoader2, 4100);

        if (inputs.gender.trim() === '') {
          $(opt_class + ' .data-gender .checkmark').addClass('invalide-health');
          validations = 1;
        }
        if (inputs.age === 0) {
          $(opt_class + ' #age').addClass('invalide-health');
          validations = 1;
        }
        if (inputs.members === 0) {
          $(opt_class + ' #members').addClass('invalide-health');
          validations = 1;
        }
        if (inputs.incomes === 0) {
          $(opt_class + ' #incomes').addClass('invalide-health');
          validations = 1;
        }
        if (inputs.secure === null) {
          $(opt_class + ' .secure .checkmark').addClass('invalide-health');
          validations = 1;
        }
        if (!terms) {
          $('.terminos .checkmark').addClass('invalide-health');
          validations = 1;
        }

        if (!loc.pathname.includes('cotizador-salud') && isRef === null) {
          $(opt_class + ' .options-response .checkmark').addClass(
            'invalide-health',
          );
          validations = 1;
        }

        if (validations) {
          console.log('error');
          return;
        }

        setLoader(true);
        delete inputs.vip
        // await axios
        // 	.post(env.HEALTHS_SCRAPER, { client: inputs, refers: refers})
        // 	.then((res) => {
        // clearInterval(nIntervId);
        // clearInterval(nIntervId2);
        // let message;
        // switch (res.data.msj) {
        // 			case 'low_income':
        // 				message = 'Ingresos Bajos';
        // 				break;
        // 			case 'no_plans':
        // 				message = 'No hay planes disponibles para esta configuración';
        // 				break;

        // 			default:
        // 				message = 'Ingrensando los datos ';
        // 				break;
        // 		}

        // 		if (res.data.status == 'Error') {
        // 			$('.load p').html(
        // 				`<span>¡Error!</span> <br /> Ocurrio un Error: <br /> ${message}`,
        // 			);
        // 			window.analytics.track('Error in Form', {
        // 				Message: message,
        // 				attempt_id: Date.now(),
        // 			});
        // 		} else {
        setTimeout(() => {

          setLoader(false);
          // setClientId(res.data.client_id);
          setSended(true);
          window.analytics.track('New Lead and Quote', {
            attempt_id: Date.now(),
          });

        }, 4000);
        // 		}
        // 		return res.data;
        // 	})
        // 	.catch((err) => {
        // 		console.log(err);
        // 		return { status: 'Error' };
        // 	});
      }

      if (opt === 2) {
        // prexist  ?
        if (inputs.email.trim() === '') {
          $(opt_class + ' #email').addClass('invalide-health');
          validations = 1;
        }

        if (inputs.exist === null) {
          setInputs({ ...inputs, exist: true });
        }

        if (validations) {
          return;
        }

        // await axios
        // 	.post(env.HEALTHS_SCRAPER_P, { client: inputs, refers: refers  })
        // 	.then((res) => {
        setTimeout(() => {
          locationg && ((locationg.data.continent_code == 'SA' || locationg.data.continent_code == 'CA')) && setLoader2(true);

          (locationg && locationg.data.continent_code == 'NA' && locationg.data.country_code == 'US') && setLoader4(true);

          window.analytics.track('New Refer', {
            Section: 'Health Form',
            attempt_id: Date.now(),
          });
        }, 4000);
        // 		return res.data;
        // 	})
        // 	.catch((err) => {
        // 		console.log(err);
        // 		return { status: 'Error' };
        // 	});
      }

      if (opt === 3 || (opt === 1 && formReno)) {
        // prexist  ?
        if (inputs.email.trim() === '') {
          $(opt_class + ' #email').addClass('invalide-health');
          validations = 1;
        }
        if (company.trim() === '') {
          $(opt_class + ' #compani').addClass('invalide-health');
          validations = 1;
        }

        if (isRef === null) {
          $(opt_class + ' .options-response .checkmark').addClass(
            'invalide-health',
          );
          validations = 1;
        }

        if (validations) {
          return;
        }

        //console.log(parseInt(inputs.asesor));
        // let client = await getinApi("post", "loyals", {
        //   fullname: inputs.fullname,
        //   phone: inputs.phone,
        //   email: inputs.email,
        //   zip: inputs.zip,
        //   user_id: inputs.asesor ? inputs.asesor : 43,
        //   prexist: inputs.exist,
        // })
        //   .then((res) => {
        //     return res.client.id;
        //   })
        //   .catch((err) => console.log(err));

        let client = await getinApi('post', 'loyals', {
          fullname: inputs.fullname,
          phone: inputs.phone,
          zip: inputs.zip,
          state: 'N/N',
          mail: inputs.email,
          company: company,
          rate1: 5,
          rate2: 5,
          rate3: 5,
          rate4: 5,
          rate5: 5,
          preg1: 1,
          preg2: 1,
          preg3: 1,
          agent: inputs.asesor ? inputs.asesor : 43,
          message: '',
          lang: window.localStorage.getItem('lng'),
        })
          .then((res) => {
            if (res.status === 'Success') {
              // setLoader4(true);
              window.analytics.track('New Loyal', {
                Section: 'Health Form',
                attempt_id: Date.now(),
              });
              return res.user.id;
            } else {
              console.log(res.status);
            }
          })
          .catch((err) => console.log(err));

        //console.log(client);
        if (client !== null) {
          asyncForEach(refers, async (refer, index) => {
            await getinApi('post', 'refers', {
              fullname: refer.fullname,
              phone: refer.phone,
              email: refer.email != '' ? refer.email : '',
              client_id: null,
              user_id: inputs.asesor ? inputs.asesor : 43,
              other_2: client,
            })
              .then((res) => {
                // setLoader4(true);
                window.analytics.track('New Refer', {
                  Section: 'Health Form',
                  attempt_id: Date.now(),
                });
              })
              .catch((err) => console.log(err));
          });
        }
      }
    }
  };

  const CalendarSubmit = async () => {
    window.analytics.track('Submint Button Lister', {
      Section: 'Health Form',
      attempt_id: Date.now(),
    });
    let validations = 0;

    clearInvalid();

    if (lister.fullname.trim() === '') {
      $('#fullname-lister').addClass('invalide-health');
      validations = 1;
    }
    if (lister.phone.trim() === '') {
      $('#phone-lister').addClass('invalide-health');
      validations = 1;
    }
    if (lister.zone.trim() === '') {
      $('#zone').addClass('invalide-health');
      validations = 1;
    }

    if (lister.date.trim() === '') {
      $('#date').addClass('invalide-health');
      validations = 1;
    }
    if (lister.hour.trim() === '') {
      $('#hour').addClass('invalide-health');
      validations = 1;
    }

    if (validations) {
      return;
    }

    await getinApi('post', 'listers', {
      fullname: lister.fullname,
      phone: lister.phone,
      zone: lister.zone,
    })
      .then(async (res) => {
        await getinApi('post', 'hours', {
          hour: lister.hour,
          day: lister.date,
          month: moment(new Date()).format('MM'),
          year: moment(new Date()).format('YYYY'),
          lister_id: res.lister.id,
        })
          .then((rest) => {
            window.analytics.track('Lead Agended', {
              Section: 'Health Form',
              attempt_id: Date.now(),
            });
            setLoader3(true);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };
  const location = () => {
    window.location = 'https://emilan.co';
  };
  const SetOPt1 = () => {
    setInputs({ ...InitialInputs });
    setTerms(false);
    setRefers([]);
    setShowRef1(false);
    setOpt(1);
    setPrefer(false);
    setFormCot(false);
    setFormReno(false);
    setSeletop(false);
    showFormCot()
    window.analytics.track('Inside Option', {
      Section: 'Health Form',
      Option: 'Option - ' + 1,
      attempt_id: Date.now(),
    });
  };
  const SetOPt1_cot = () => {
    setInputs({ ...InitialInputs });
    setTerms(false);
    setRefers([]);
    setShowRef1(false);
    setOpt(1);
    setFormCot(true);
    setPrefer(false);
  };
  const SetOPt2 = () => {
    setInputs({ ...InitialInputs });
    setFriends(false);
    setRefers([]);
    setShowRef1(false);
    setOpt(2);
    window.analytics.track('Inside Option', {
      Section: 'Health Form',
      Option: 'Option - ' + 2,
      attempt_id: Date.now(),
    });
  };
  const SetOPt3 = () => {
    setPrefer(false);
    setLister({ ...initialLister });
    setOpt(3);
    window.analytics.track('Inside Option', {
      Section: 'Health Form',
      Option: 'Option - ' + 3,
      attempt_id: Date.now(),
    });
    setPrefer(false);
  };
  const showFormOpt3 = () => {
    setPrefer(true);
  };
  const hideFormOpt3 = () => {
    setPrefer(false);
  };
  const openModal = () => {
    window.analytics.track('Open Terms', {
      Section: 'Health Form',
      attempt_id: Date.now(),
    });
    setvisible(true);
    setTerms(!terms);
  };
  const openModal_Gift = () => {
    window.analytics.track('Open Terms Gift', {
      Section: 'Health Form',
      attempt_id: Date.now(),
    });
    setvisible_Gift(true);
  };
  const closeModal = () => {
    setvisible(false);
    setvisible_Gift(false);
  };
  const check = () => {
    //console.log(sended);
    //console.log(client_id);
    //console.log(inputs);
    //console.log(lister);
  };

  const openOPtions = () => {
    console.log('banner clicked ');
    SetOPt2();
    window.analytics.track('Inside Option by Banner', {
      Section: 'Health Form',
      Option: 'Option - Clicked Banner ' + 2,
      attempt_id: Date.now(),
    });
    SetFriends();
    setTimeout(() => {
      $('#noted-yes').trigger('click');
    }, 200);
  };

  //obtener ip <-en locationg se guarda toda la data de localización obtenida por la api para ser usada
  const [locationg, setLocationg] = useState()

  const getGeoInfo = () => {
    axios.get(env.IP_URL).then((response) => {
      let data = response.data
      setLocationg({ data })
    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    getGeoInfo()
  }, [])
  //obtener ip

  return (
    <div className='content_box health-calculator view'>
      <Helmet>
        <title>{titleView}</title>
        <meta
          name='keywords'
          content='Cotizador, Salud, Bajo costo, Planes, Aseguranza, Seguros, Estados Unidos, emilan.'
        />
        <meta
          name='description'
          content='Sabemos lo valioso que es tu tiempo, por ello, hemos diseñado una serie de preguntas que en cuestión de minutos te ayudarán a elegir el plan de vida que más se adapte a tus necesidades. Contamos con los mejores precios en aseguranza, bajos costos, prevención en todo momento. '
        />
      </Helmet>
      {/*
			// <ReferSteps />
			<ModalSaludForm />
      <ModalRefLatam/>
    */}
      {locationg && ((locationg.data.continent_code == 'SA' || locationg.data.continent_code == 'CA')) && <ModalRefLatam />
      }
      <div className='health-form-bkp'>
        <div className='bkp-2'></div>
        <div className='bkp-3'></div>
        {loader && (
          <div className='modal1'>
            <div className='load animate__animated animate__fadeIn'>
              <img
                className='cargando'
                src='assets/Images/Cargando.svg'
                alt='loaded'
              />
              <p>
                <span>{t('refe_waiting.label')}</span> <br />
                {t('refe_waiting2.label')}
              </p>
            </div>
          </div>
        )}
        {loader2 && (
          <div className='modal2'>
            <div className='gift-card animate__animated animate__fadeIn'>
              <h3>{t('refe_thanks.label')}</h3>
              <div>
                <div className='flex'>
                  <p>{t('refe_rememberCard.label')}</p>
                  <button className='button' onClick={location}>
                    {t('land2_out.label')}
                  </button>
                </div>
                <img src='assets/Images/Bono_Latino_15.png' alt='card' />
              </div>
            </div>
          </div>
        )}

        {loader3 && (
          <div className='modal3'>
            <div className='scheduled animate__animated animate__fadeIn'>
              <h3>{t('refe_savedCAll.label')}</h3>
              <div>
                <div className='flex'>
                  <p>{t('refe_dataThanks.label')}</p>
                  <button className='button' onClick={location}>
                    {t('land2_out.label')}
                  </button>
                </div>
                <img
                  src='assets/Images/landings/renovation2/Registro-renov.png'
                  alt='thanks'
                />
              </div>
            </div>
          </div>
        )}
        {loader4 && (
          <div className='modal4'>
            <div className={localStorage.getItem('lng') === 'es' ? "scheduled animate__animated animate__fadeIn" : "scheduled scheduledIN animate__animated animate__fadeIn"}>

              <div>
                <div className='flex'>
                  <p>{t('refe_dataThanks2.label')}</p>
                  <button className='button' onClick={location}>
                    {t('land2_out.label')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {sended ? (
          <Health_quotes client_id={client_id} />
        ) : (
          <Fragment>
            <div className='h-calculator-form'>
              <div className='form-calculator' id='formCalculator'>
                <div className='bubble1'></div>
                <div className='bubble2'></div>
                <div className='title'>
                  {isrefer == 1 && <h2>
                    <i className='icon icon_recomendar'></i>
                    {t('refe_secondButton2.label')}
                  </h2>}
                  {isrefer == 0 && <h2>
                    <i className='icon icon_calificar'></i>
                    {t('refe_firstButton1.label')}
                  </h2>}

                </div>
                <div className='div_bigButons'>

                  {opt === 1 && (
                    <div
                      className={
                        'form-quote animate__animated animate__fadeIn opt' + opt
                      }
                    >


                      <div>
                        <div className='ps fz17 p-helptip'>
                          {t('refe_but1P1_p1.label')}
                          <div
                            className='help-tip'
                            role='tooltip'
                            id='name-tooltip'
                          >
                            <div className='help-tip__content p-helptip-cont'>
                              <ul>
                                <li>
                                  <i>&#9679;</i>
                                  {t('refe_but1P1_op1.label')}
                                </li>
                                <li>
                                  <i>&#9679;</i>
                                  {t('refe_but1P1_op2.label')}
                                </li>
                                <li>
                                  <i>&#9679;</i>
                                  {t('refe_but1P1_op3.label')}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <ul className='tool-pre-hide'>
                          <li>{t('refe_but1P1_op1.label')}</li>
                          <li>{t('refe_but1P1_op2.label')}</li>
                          <li>{t('refe_but1P1_op3.label')}</li>
                        </ul>
                        <p className='ps fz17'>{t('refe_but1P1_p2.label')}</p>
                        <div className='initial-data flex'>
                          <label
                            htmlFor='name'
                            className='input name'
                            id='fullname'
                          >
                            <i className='icon icon-comm-user' />
                            <input
                              id='name'
                              type='text'
                              name='fullname'
                              placeholder={t('refe_fullName.label')}
                              onChange={setInput}
                            />
                          </label>
                          <label
                            htmlFor='phone-label'
                            className='input phone'
                            id='phone'
                          >
                            <div
                              className='help-tip'
                              role='tooltip'
                              id='name-tooltip'
                            >
                              <div className='help-tip__content'>
                                <p>{t('telinput.label')}</p>
                              </div>
                            </div>
                            <i className='icon icon-comm-phone' />
                            <input
                              type='tel'
                              name='phone'
                              id='phone-label'
                              placeholder={t('iniform3.label')}
                              onChange={setInput}
                            />
                          </label>
                          <label
                            htmlFor='mail'
                            className='input email'
                            id='email'
                          >
                            <i className=' icon icon-comm-env' />
                            <input
                              type='email'
                              name='email'
                              id='mail'
                              placeholder={t('land_email.label')}
                              onChange={setInput}
                            />
                          </label>

                          <label
                            className='checkbox data-gender'
                            htmlFor='gender'
                            id='data-gender'
                          >
                            <input
                              id='gender'
                              type='radio'
                              name='gender'
                              value='female'
                              onChange={setInput}
                            />
                            <span className='checkmark'></span>
                            <p>F</p>
                          </label>
                          <label
                            className='checkbox data-gender'
                            htmlFor='gender2'
                            id='data-gender2'
                          >
                            <input
                              id='gender2'
                              type='radio'
                              name='gender'
                              value='male'
                              onChange={setInput}
                            />
                            <span className='checkmark'></span>
                            <p>M</p>
                          </label>

                          <label
                            htmlFor='postal'
                            className='input postal'
                            id='zip'
                          >
                            <i className='icon icon-home' />
                            <input
                              type='text'
                              name='zip'
                              id='postal'
                              placeholder={t('iniform4.label')}
                              onChange={setInput}
                            />
                          </label>
                          <label
                            htmlFor='years'
                            className='input years'
                            id='age'
                          >
                            <i className='icon icon-calendar' />
                            <input
                              type='number'
                              name='age'
                              id='years'
                              placeholder={t('refe_age.label')}
                              onChange={setInput}
                            />
                          </label>

                          <div className='members-group'>
                            <label
                              htmlFor='family-members'
                              className='input family select'
                              id='members'
                              onClick={() => toggleMembers(!members)}
                            >
                              <i className='icon icon-family' />
                              <i className='icon icon-caret-up' />
                              <i className='icon icon-caret-down' />

                              {inputs.members !== 0 ? (
                                <span>{inputs.members}</span>
                              ) : (
                                <input
                                  type='text'
                                  name='members'
                                  disabled
                                  id='family-members'
                                  placeholder={t('refe_memFamily.label')}
                                  onChange={setInput}
                                />
                              )}
                            </label>
                            {members && (
                              <div className='opciones members'>
                                <ul>
                                  <li onClick={() => setMember(1)}>1</li>
                                  <li onClick={() => setMember(2)}>2</li>
                                  <li onClick={() => setMember(3)}>3</li>
                                  <li onClick={() => setMember(4)}>4</li>
                                  <li onClick={() => setMember(5)}>5</li>
                                  <li onClick={() => setMember(6)}>6</li>
                                  <li onClick={() => setMember(7)}>7</li>
                                  <li onClick={() => setMember(8)}>8</li>
                                  <li onClick={() => setMember(9)}>9</li>
                                  <li onClick={() => setMember(10)}>10</li>
                                </ul>
                              </div>
                            )}
                          </div>
                          <label
                            htmlFor='fam-income'
                            className='input income'
                            id='incomes'
                          >
                            <div
                              className='help-tip'
                              role='tooltip'
                              id='name-tooltip'
                            >
                              <div className='help-tip__content'>
                                <p>{t('popIncomes.label')}</p>
                              </div>
                            </div>
                            <i className='icon icon-income' />
                            <input
                              type='number'
                              name='incomes'
                              id='fam-income'
                              placeholder={t('refe_incomeFamily.label')}
                              onChange={setInput}
                            />
                          </label>
                          {/*
                              <span className='botom-icomes '>
                              * {t('popIncomes.label')}
                              </span>
                            */}
                        </div>
                        <div className='current-insurance flex '>
                          <i className=' icon icon-shield' />
                          <p className='in-blk '>
                            {t('refe_secureActualy.label')}
                          </p>
                          <div className='response options-response'>
                            <label
                              className='secure checkbox2'
                              htmlFor='current-yes'
                            >
                              <p>{t('yes.label')}</p>
                              <input
                                id='current-yes'
                                type='radio'
                                name='secure'
                                value='1'
                                onChange={setInput}
                              />
                              <span className='checkmark'></span>
                            </label>
                            <label
                              className='secure checkbox2'
                              htmlFor='current-not'
                            >
                              <p className='no'>{t('no.label')}</p>
                              <input
                                id='current-not'
                                type='radio'
                                name='secure'
                                value='0'
                                onChange={setInput}
                              />
                              <span className='checkmark'></span>
                            </label>
                          </div>
                        </div>
                      </div>


                      <Fragment>
                        <div className='terms-health-calculator'>
                          <label className='checkbox terminos' htmlFor='acepto'>
                            <input
                              id='acepto'
                              type='checkbox'
                              name='terms'
                              defaultValue={terms}
                              onClick={() => openModal()}
                            />
                            <span className='checkmark'></span>
                            <p className='ps'>
                              {t('refe_politic.label')}
                              <b>{t('refe_politic2.label')}</b>
                            </p>
                          </label>
                        </div>

                        <div className='referred'>
                          {/*
                            <p className='ps fz17'>
                              {t('refe_Recomended.label')}
                            </p>
                            <div className='recomended-response options-response '>
                              <label
                                className='checkbox2 '
                                htmlFor='referred-yes'
                              >
                                <input
                                  id='referred-yes'
                                  type='radio'
                                  name='referred1'
                                  value='1'
                                  onChange={showRefers}
                                />
                                <span className='checkmark'></span>
                                <p>{t('yes.label')}</p>
                              </label>
                              <label
                                className=' checkbox2'
                                htmlFor='referred-not'
                              >
                                <input
                                  id='referred-not'
                                  type='radio'
                                  name='referred1'
                                  value='0'
                                  onChange={hideRefers}
                                />
                                <span className='checkmark '></span>
                                <p className='no'>{t('no.label')}</p>
                              </label>
                            </div>
                            {showref &&
                              refers.length > 0 &&
                              refers.map((ref, index) => (
                                <div
                                  className='animate__animated animate__fadeIn flex data-referred referrs1'
                                  key={index + 'refer' + ref.local_id}
                                >
                                  <label
                                    htmlFor='name-ref'
                                    className={
                                      'input name-ref inp-fullname-refer' +
                                      ref.local_id
                                    }
                                    id='data-name-ref'
                                  >
                                    <i className='icon icon-comm-user' />
                                    <input
                                      id='name-ref'
                                      placeholder={t('refe_fullName.label')}
                                      type='text'
                                      name='fullname'
                                      onChange={(e) =>
                                        setInputRefer(e, ref.local_id)
                                      }
                                      defaultValue={ref.fullname}
                                    />
                                  </label>
                                  <label
                                    htmlFor='phone-ref'
                                    className={
                                      'input phone-ref inp-phone-refer' +
                                      ref.local_id
                                    }
                                    id='data-phone-ref'
                                  >
                                    <i className='icon icon-comm-phone' />
                                    <input
                                      type='tel'
                                      name='phone'
                                      id='phone-ref'
                                      placeholder={t('iniform3.label')}
                                      onChange={(e) =>
                                        setInputRefer(e, ref.local_id)
                                      }
                                      defaultValue={ref.phone}
                                    />
                                  </label>
                                  <label
                                    htmlFor='email-ref'
                                    className={
                                      'input email-ref email-ref' + ref.local_id
                                    }
                                    id='data-mail-ref'
                                  >
                                    <i className='icon icon-comm-env' />
                                    <input
                                      type='email'
                                      name='email'
                                      id='email-ref'
                                      placeholder={t('land_email.label')}
                                      onChange={(e) =>
                                        setInputRefer(e, ref.local_id)
                                      }
                                      defaultValue={ref.email}
                                    />
                                  </label>

                                  {(index !== refers.length - 1 ||
                                    refers.length === 4) && (
                                    <label
                                      className='button-blue plus qsbold'
                                      onClick={(e) =>
                                        setRemoveRefer(ref.local_id)
                                      }
                                    >
                                      <span className='button-blue'>
                                        {t('refe_eliminate.label')}{' '}
                                        <i className='icon icon-add' />
                                      </span>
                                    </label>
                                  )}
                                  {index === refers.length - 1 &&
                                    refers.length < 4 && (
                                      <label
                                        className='button-blue plus qsbold'
                                        onClick={setNewRefer}
                                      >
                                        <span className='button-blue'>
                                          {t('refe_addIn.label')}{' '}
                                          <i className='icon icon-add' />
                                        </span>
                                      </label>
                                    )}
                                </div>
                              ))} */ }
                          <input
                            className='button-blue center go'
                            type='button'
                            value={t('cotizar.label')}
                            onClick={submitInputs}
                          />
                        </div>

                        {prefer && (
                          <div className='animate__fadeIn animate__animated mbt-2'>
                            <div className='flex data-help'>
                              <label
                                htmlFor='name'
                                className='input name3'
                                id='fullname-lister'
                              >
                                <i className='icon icon-comm-user' />
                                <input
                                  id='name'
                                  type='text'
                                  name='fullname'
                                  placeholder={t('refe_fullName.label')}
                                  onChange={setInputLister}
                                />
                              </label>
                              <label
                                htmlFor='phone'
                                className='input phone3'
                                id='phone-lister'
                              >
                                <i className='icon icon-comm-phone' />
                                <input
                                  type='tel'
                                  name='phone'
                                  id='phone'
                                  placeholder={t('iniform3.label')}
                                  onChange={setInputLister}
                                />
                              </label>
                              <div className='dia-contact'>
                                <label
                                  htmlFor='day-contact'
                                  className='input day select'
                                  id='date'
                                  onClick={() => toggleDaylies(!dalys)}
                                >
                                  <i className=' icon icon-caret-up' />
                                  <i className=' icon icon-caret-down' />
                                  <i className='icon icon-calendar' />
                                  {lister.date !== '' ? (
                                    <span>{selects.day}</span>
                                  ) : (
                                    <input
                                      type='text'
                                      name='date'
                                      id='day-contact'
                                      placeholder={t('dia.label')}
                                      disabled
                                      onChange={setInputLister}
                                    />
                                  )}
                                </label>
                                {dalys && (
                                  <div className='opciones dia'>
                                    <ul>
                                      {days && (
                                        <Fragment>
                                          <li
                                            onClick={() =>
                                              setDay(days[0], 'Hoy')
                                            }
                                          >
                                            {t('refe_today.label')}
                                          </li>
                                          <li
                                            onClick={() =>
                                              setDay(days[1], 'Mañana')
                                            }
                                          >
                                            {t('refe_tomorrow.label')}
                                          </li>
                                        </Fragment>
                                      )}
                                    </ul>
                                  </div>
                                )}
                              </div>
                              <div className='horario-contact'>
                                <label
                                  htmlFor='horary-contact'
                                  className='input horary select'
                                  id='zone'
                                  onClick={() => toggleZones(!zones)}
                                >
                                  <i className=' icon icon-caret-up' />
                                  <i className=' icon icon-caret-down' />
                                  <i className='icon icon-hour' />
                                  {lister.zone !== '' ? (
                                    <span>{lister.zone}</span>
                                  ) : (
                                    <input
                                      id='horary-contact'
                                      type='text'
                                      name='zone'
                                      placeholder={t('refe_timeZone.label')}
                                      disabled
                                      onChange={setInputLister}
                                    />
                                  )}
                                </label>
                                {zones && (
                                  <div className='opciones zona'>
                                    <ul>
                                      <li onClick={() => setZone('Este')}>
                                        {t('refe_east.label')}
                                      </li>
                                      <li onClick={() => setZone('Central')}>
                                        {t('refe_center.label')}
                                      </li>
                                      <li onClick={() => setZone('Montaña')}>
                                        {t('refe_mountain.label')}
                                      </li>
                                      <li onClick={() => setZone('Pacifico')}>
                                        {t('refe_pacific.label')}
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                              <div className='hora-contact'>
                                <label
                                  htmlFor='hour-contact'
                                  className='input hour select'
                                  id='hour'
                                  onClick={() => toggleHours(!hours)}
                                >
                                  <i className=' icon icon-caret-up' />
                                  <i className=' icon icon-caret-down' />
                                  <i className='icon icon-alarm' />
                                  {lister.hour !== '' ? (
                                    <span>{lister.hour}</span>
                                  ) : (
                                    <input
                                      type='text'
                                      name='hour'
                                      id='hour-contact'
                                      placeholder={t('refe_hour.label')}
                                      disabled
                                      onChange={setInputLister}
                                    />
                                  )}
                                </label>
                                {hours && (
                                  <div className='opciones hour'>
                                    <ul>
                                      <li
                                        onClick={() => setHour('09:00 a 12:00')}
                                      >
                                        09:00 a 12:00
                                      </li>
                                      <li
                                        onClick={() => setHour('12:00 a 15:00')}
                                      >
                                        12:00 a 15:00
                                      </li>
                                      <li
                                        onClick={() => setHour('15:00 a 18:00')}
                                      >
                                        15:00 a 18:00
                                      </li>
                                      <li
                                        onClick={() => setHour('18:00 a 21:00')}
                                      >
                                        18:00 a 21:00
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </div>
                            <input
                              className='button-blue center end'
                              type='button'
                              value={t('iniform7.label')}
                              onClick={CalendarSubmit}
                            />
                          </div>
                        )}
                      </Fragment>

                    </div>
                  )}
                  {opt === 2 && locationg && (
                    <div
                      className={'animate__animated animate__fadeIn opt' + opt}
                    >
                      <div className='recommend-friends'>
                        <p className='ps fz17'>{loc.pathname.includes('referidos-latinoamerica') || ((locationg.data.continent_code == 'SA' || locationg.data.continent_code == 'CA')) ? t('refe_dataPlease.label-latam') : t('refe_dataPlease.label')}</p>
                        <div className='initial-data2 flex'>
                          <label
                            htmlFor='name'
                            className='input name'
                            id='fullname'
                          >
                            <i className='icon icon-comm-user' />
                            <input
                              id='name'
                              type='text'
                              name='fullname'
                              placeholder={t('refe_fullName.label')}
                              onChange={setInput}
                            />
                          </label>
                          <label
                            htmlFor='phone-label'
                            className='input phone'
                            id='phone'
                          >
                            <i className='icon icon-comm-phone' />
                            <input
                              type='tel'
                              name='phone'
                              id='phone-label'
                              placeholder={t('phone.label')}
                              onChange={setInput}
                            />
                          </label>
                          <label
                            htmlFor='mail'
                            className='input email'
                            id='email'
                          >
                            <i className=' icon icon-comm-env' />
                            <input
                              type='email'
                              name='email'
                              id='mail'
                              placeholder={t('land_email.label')}
                              onChange={setInput}
                            />
                          </label>

                          {((loc.pathname.includes('latinoamerica') || ((locationg.data.continent_code == 'SA' || locationg.data.continent_code == 'CA')))) && <label
                            htmlFor='country'
                            className='input name'
                            id='countryy'
                          >
                            <i className='icon icon-location_form' />
                            <input
                              id='country'
                              type='text'
                              name='country'
                              placeholder={t('refe_country.label')}
                              onChange={setInput}
                            />
                          </label>}

                          {!loc.pathname.includes('referidos') && <div className='asesores'>
                            <label
                              htmlFor='name-asess'
                              className='input name select'
                              id='data-name-asess'
                              onClick={() => toggleAsesors(!asesors)}
                            >
                              <i className=' icon icon-comm-user' />
                              <i className=' icon icon-caret-up' />
                              <i className=' icon icon-caret-down' />
                              {inputs.asesor !== null ? (
                                <span>{selects.asesor}</span>
                              ) : (
                                <input
                                  id='name-asess'
                                  type='text'
                                  name='asesor'
                                  placeholder={t('refe_assesorName.label')}
                                  onChange={setInput}
                                  disabled
                                />
                              )}
                            </label>

                            {asesors && (
                              <div className='opciones'>
                                <ul>
                                  {readyAgents &&
                                    agents.map((agent, index) => (
                                      <li
                                        key={'agetns_' + agent.id}
                                        onClick={() =>
                                          setAsessor(
                                            agent.id,
                                            agent.name,
                                            'action',
                                          )
                                        }
                                      >
                                        {agent.name}
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            )}
                          </div>}
                        </div>
                        <div className='referred2'>
                          {showref && (
                            <p className='ps fz17'>
                              {t('refe_yesNow.label')}
                              {
                                // Quieres recomendar los planes de <span>emilan </span>a un conocido para ganarte una <span>GIFT CARD</span> virtual?
                              }
                            </p>
                          )}
                          {showref &&
                            refers.length > 0 &&
                            refers.map((ref, index) => (
                              <div
                                className='animate__animated animate__fadeIn flex data-referred referrs1'
                                key={index + 'refer' + ref.local_id}
                              >
                                <label
                                  htmlFor='name-ref'
                                  className={
                                    'input name-ref inp-fullname-refer' +
                                    ref.local_id
                                  }
                                  id='data-name-ref'
                                >
                                  <i className='icon icon-comm-user' />
                                  <input
                                    id='name-ref'
                                    placeholder={t('refe_fullName.label')}
                                    type='text'
                                    name='fullname'
                                    onChange={(e) =>
                                      setInputRefer(e, ref.local_id)
                                    }
                                    defaultValue={ref.fullname}
                                  />
                                </label>
                                <label
                                  htmlFor='phone-ref'
                                  className={
                                    'input phone-ref inp-phone-refer' +
                                    ref.local_id
                                  }
                                  id='data-phone-ref'
                                >
                                  <i className='icon icon-comm-phone' />
                                  <input
                                    type='tel'
                                    name='phone'
                                    id='phone-ref'
                                    placeholder={t('iniform3.label')}
                                    onChange={(e) =>
                                      setInputRefer(e, ref.local_id)
                                    }
                                    defaultValue={ref.phone}
                                  />
                                </label>
                                <label
                                  htmlFor='email-ref'
                                  className={
                                    'input email-ref email-ref' + ref.local_id
                                  }
                                  id='data-mail-ref'
                                >
                                  <i className='icon icon-comm-env' />
                                  <input
                                    type='email'
                                    name='email'
                                    id='email-ref'
                                    placeholder={t('land_email.label')}
                                    onChange={(e) =>
                                      setInputRefer(e, ref.local_id)
                                    }
                                    defaultValue={ref.email}
                                  />
                                </label>

                                {(index !== refers.length - 1 ||
                                  refers.length === 4) && (
                                    <label
                                      className='button-blue plus qsbold'
                                      onClick={(e) => setRemoveRefer(ref.local_id)}
                                    >
                                      <span className='button-blue'>
                                        {t('refe_eliminate.label')}{' '}
                                        <i className='icon icon-add' />
                                      </span>
                                    </label>
                                  )}
                                {index === refers.length - 1 &&
                                  refers.length < 4 && (
                                    <label
                                      className='button-blue plus qsbold'
                                      onClick={setNewRefer}
                                    >
                                      <span className='button-blue'>
                                        {t('refe_addIn.label')}{' '}
                                        <i className='icon icon-add' />
                                      </span>
                                    </label>
                                  )}
                              </div>
                            ))}

                          {showref && (
                            <div>
                              <div className='terms-health-calculator'>
                                <label
                                  className='checkbox terminos'
                                  htmlFor='acepto'
                                >
                                  <input
                                    id='acepto'
                                    type='checkbox'
                                    name='terms'
                                    defaultValue={terms}
                                    onClick={() => openModal()}
                                  />
                                  <span className='checkmark'></span>
                                  <p className='ps'>
                                    {t('refe_politic.label')}
                                    <b>{t('refe_politic2.label')}</b>
                                  </p>
                                </label>
                              </div>
                              <input
                                className='button-blue center '
                                type='button'
                                value={t('iniform7.label')}
                                onClick={submitInputs}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

          </Fragment>
        )}
      </div>
      <Modal
        className='modales-n'
        visible={visible}
        width='90%'
        height='80%'
        effect='fadeInUp'
        onClickAway={() => closeModal()}
      >
        <div>
          <Policies />
          <a className='boton-close' onClick={() => closeModal()}>
            {t('refe_continue.label')}
          </a>
        </div>
      </Modal>
      <Modal
        className='modales-n'
        visible={visible_gift}
        width='90%'
        height='80%'
        effect='fadeInUp'
        onClickAway={() => closeModal()}
      >
        <div>
          <Policies_Gift />
          <a className='boton-close' onClick={() => closeModal()}>
            {t('refe_continue.label')}
          </a>
        </div>
      </Modal>
    </div>
  );
}
