import React from 'react'
import { useLocation } from 'react-router-dom';
import taxteanLogo from '../../../assets/Images/landings/servicios/logo_tax.png'

const HeaderServiciosEn = () => {
  const { pathname } = useLocation();
  return (
    <div className='header-servicios'>
      <a className="logo" href='https://emilan.co/'> </a>

      {pathname === '/landings_en/credit_repair' &&
        <div className="title_healt">
          <img className='logo_tax' src={taxteanLogo} alt="taxteam-logo" />
        </div>
      }
      {pathname === '/landings_en_health' &&
        <div className="title_healt">
          <p className='p_title'>Health <br /> Insurances</p>
          <i className="icon icon-shield_heart" />
        </div>
      }



      {pathname === '/landings_en/credit_repair' &&
        <>
          <a className="healt-comunicate" href="tel:+188822641801">
            <i className="icon icon-headset" />
            <p className='p_comunicate'>Talk to an Expert<span>(888) 226-4180</span></p>
          </a>
          <a className="healt-comunicate_mobile" href="tel:+18882264180">
            <div className='div_icon'>
              <i className="icon icon-headset" />
            </div>
            <p className='p_comunicate_mobile'>Talk to <br />an expert</p>
          </a>
        </>
      }
      {pathname !== '/landings_en/credit_repair' &&
        <>
          <a className="healt-comunicate" href="tel:+19546982058">
            <i className="icon icon-headset" />
            <p className='p_comunicate'>Talk to a certified agent<span>(954) 698-2058</span></p>
          </a>
          <a className="healt-comunicate_mobile" href="tel:+19546982058">
            <div className='div_icon'>
              <i className="icon icon-headset" />
            </div>
            <p className='p_comunicate_mobile'>Talk to <br />a agent</p>
          </a>
        </>
      }

    </div>
  )
}

export default HeaderServiciosEn