// Trasnlation
import './../i18n';
import { useTranslation } from 'react-i18next';

/*Modal de carga en Healt-calc_mobile */
export default function ModalLoader3(){

  const { t } = useTranslation();

  const location = () => {
		window.location = 'https://emilan.co';
	};

  return(
    <div className='modal3'>
      <div className='scheduled animate__animated animate__fadeIn'>
        <h3>{t('refe_savedCAll.label')}</h3>
        <div>
          <div className='flex'>
            <p>{t('refe_dataThanks.label')}</p>
            <button className='button' onClick={location}>
              {t('land2_out.label')}
            </button>
          </div>
          <img
            src='assets/Images/landings/renovation2/Registro-renov.png'
            alt='thanks'
          />
        </div>
      </div>
    </div>
  )
}