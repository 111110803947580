

//Componente de animacion de puntos (escribiendo en chat)
export default function DotsMove(){

  return(
    <div className='dots'>
      <div className='dot'></div>
      <div className='dot'></div>
      <div className='dot'></div>
    </div>
  )
}