//React
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

//Components
import DotsMove from './dotsMove';

// Trasnlation
import './../i18n';
import { useTranslation } from 'react-i18next';

export default function OptionTwoFormHealt({opt,errors,setInput,refers,setInputRefer,submitInputs,openModal,terms,showref
  ,readyAgents,agents,setAsessor,setNewRefer,setTerms}){

  const loc = useLocation() 

  const { t } = useTranslation(); 

  const [animDots,setAnimDots] = useState(false)

  const [refNextQue,setRefNextQue] = useState({ans05:false,ans1:false,ans2:false,ans3:false,ans4:false,ans5:false,ans6:false})
  
  const activeAnimation = (fun,dat=null) => {
    setAnimDots(true)
    setTimeout(()=>{
      setAnimDots(false)
      fun(dat)
    },1500)
  }

  //Perimitir paso sgte referidos
  const verifyInputRefer = (e,sec) => {
    if(e == 0 && !errors.fullname){
      activeAnimation(setRefNextQue,{...refNextQue,ans05:loc.pathname.includes('latinoamerica')?true:false , ans1:loc.pathname.includes('latinoamerica')?false:true})
    }
    if(e == 'country' && !errors.country){
      activeAnimation(setRefNextQue,{...refNextQue, ans1:true})
    }
    if(e == 1 && !errors.phone){
      activeAnimation(setRefNextQue,{...refNextQue,ans2:true})
    }
    if(e == 2 && !errors.email){
      activeAnimation(setRefNextQue,{...refNextQue,ans3:true})
    }
    if(e == 3){
      if(!errors['inp-email-refer'+(sec+1)] && !errors['inp-phone-refer'+(sec+1)] && !errors['inp-fullname-refer'+(sec+1)]){
        activeAnimation(setRefNextQue,{...refNextQue,ans4:true})
      }
    }
    if(e == 4){
      if(sec){
        setRefNextQue({...refNextQue,ans4:false})
        activeAnimation(setNewRefer())
      }else{
        activeAnimation(setRefNextQue,{...refNextQue,ans5:true})
      }
    }
    if(e == 5){
      if(!terms){
        activeAnimation(setRefNextQue,{...refNextQue,ans6:true})
      }else{
        setRefNextQue({...refNextQue,ans6:false})
      }
      setTerms(!terms)
    }
  }

  useEffect(()=>{
    if(terms){
      activeAnimation(setRefNextQue,{...refNextQue,ans6:true})
    }
  },[terms])

  return(
    <div
      className={'animate__animated animate__fadeIn opt' + opt}
    >
      <div className='recommend-friends'>
            <div className="group_q">
              <div className="question">
                <div className="emi"><i className="icon icon-m_emilan"></i></div>
                <div className="div">
                  <p className='question_p'>
                    {t('saludF4b_p2a.label')}
                  </p>
                  <p className='question_p'>
                    {t('saludF4_p2b.label')}
                  </p>
                </div>
              </div>
              <label
                htmlFor='name'
                className={errors.fullname?'input name error':'input name'}
                id='fullname'
              >
                <i className='icon icon-comm-user' />
                <input
                  id='name'
                  type='text'
                  name='fullname'
                  placeholder={t('refe_fullName.label')}
                  onChange={setInput}
                />
                <button className="send_inp" onClick={() => verifyInputRefer(0)}><i className="icon icon-send_input"></i></button>
              </label>
            </div>

            {/*referQuestion country*/}
            {loc.pathname.includes('latinoamerica') && refNextQue.ans05 && <div className="group_q">
              <div className="question">
                <div className="emi"><i className="icon icon-m_emilan"></i></div>
                <div className="div">
                  <p className='question_p'>
                    {t('saludF4b_pcountry.label')}
                  </p>
                </div>
              </div>
              <label
                htmlFor='country-label'
                className={errors.country?'input name error':'input name'}
                id='countryl'
              >
                <i className='icon icon-location_form' />
                <input
                  type='text'
                  name='country'
                  id='country-label'
                  placeholder={t('refe_country.label')}
                  onChange={setInput}
                />
                <button className="send_inp" onClick={() => verifyInputRefer('country')}><i className="icon icon-send_input"></i></button>
              </label>
            </div>}
            {/*referQuestion country*/}


            {/*referQuestion1*/}
            {refNextQue.ans1 && <div className="group_q">
              <div className="question">
                <div className="emi"><i className="icon icon-m_emilan"></i></div>
                <div className="div">
                  <p className='question_p'>
                    {t('saludF4b_p3.label')}
                  </p>
                </div>
              </div>
              <label
                htmlFor='phone-label'
                className={errors.phone?'input phone error':'input phone'}
                id='phone'
              >
                <i className='icon icon-mobile_phone' />
                <input
                  type='tel'
                  name='phone'
                  id='phone-label'
                  placeholder='(000) 000-0000'
                  onChange={setInput}
                />
                <button className="send_inp" onClick={() => verifyInputRefer(1)}><i className="icon icon-send_input"></i></button>
              </label>
            </div>}
            {/*referQuestion1*/}

            {/*referQuestion2*/}
            {refNextQue.ans2 && <div className="group_q">
              <div className="question">
                <div className="emi"><i className="icon icon-m_emilan"></i></div>
                <div className="div">
                  <p className='question_p'>
                    {t('saludF4b_p4.label')}
                  </p>
                </div>
              </div>
              <label
                htmlFor='mail'
                className={errors.email?'input email error':'input email'}
                id='email'
              >
                <i className=' icon icon-comm-env' />
                <input
                  type='email'
                  name='email'
                  id='mail'
                  placeholder='oooooo@oooo.com'
                  onChange={setInput}
                />
                <button className="send_inp" onClick={() => verifyInputRefer(2)}><i className="icon icon-send_input"></i></button>
              </label>
            </div>}
            {/*referQuestion2*/}

            {/*referQuestion3*/}
            {refNextQue.ans3 && <div className="group_q">
              <div className="question">
                <div className="emi"><i className="icon icon-m_emilan"></i></div>
                <div className="div">
                  <p className='question_p'>
                    {t('saludF4b_p5.label')}
                  </p>
                </div>
              </div>
              <div className='opciones'>
                {readyAgents &&
                  agents.map((agent, index) => (
                    <button className='btn_assesor'
                      key={'agetns_' + agent.id}
                      onClick={() =>
                        setAsessor(
                          agent.id,
                          agent.name,
                          'action',
                        )
                      }
                    >
                    {(agent.name == 'Christian') ?
                    <lord-icon
                      src="https://cdn.lordicon.com/imamsnbq.json"
                      trigger="loop"
                      colors="primary:#000540,secondary:#00e5ba"
                      style={{width:'40px', height:'40px'}}>
                    </lord-icon>:
                    <lord-icon
                      src="https://cdn.lordicon.com/sodlceyy.json"
                      trigger="loop"
                      colors="primary:#000540,secondary:#00e5ba"
                      style={{width:'40px', height:'40px'}}>
                    </lord-icon>}

                      {agent.name}
                    </button>
                  ))}
              </div>
            </div>}
            {/*referQuestion3*/}

        {/*referQuestion4*/}
        <div className='referred2'>
          {showref &&
            refers.length > 0 &&
            refers.map((ref, index) => (
              <div
                className='animate__animated animate__fadeIn data-referred referrs1'
                key={index + 'refer' + ref.local_id}
              >
                {/*referQuestion5*/}
                {<div className="group_q">
                  <div className="question">
                    <div className="emi"><i className="icon icon-m_emilan"></i></div>
                    <div className="div">
                      <p className='question_p'>
                        {t('saludF4b_p6a.label')}
                      </p>
                      <p className='question_p'>
                        {t('saludF4b_p6b.label')}
                      </p>
                    </div>
                  </div>

                  <label
                    htmlFor='name-ref'
                    className={
                      errors['inp-fullname-refer'+ref.local_id]? 'input name-ref inp-fullname-refer'+ref.local_id+' error'
                      :'input name-ref inp-fullname-refer'+ref.local_id
                    }
                    id='data-name-ref'
                  >
                    <i className='icon icon-comm-user' />
                    <input
                      id='name-ref'
                      placeholder={t('refe_fullName.label')}
                      type='text'
                      name='fullname'
                      onChange={(e) =>
                        setInputRefer(e, ref.local_id)
                      }
                      defaultValue={ref.fullname}
                    />
                  </label>

                  <label
                    htmlFor='phone-ref'
                    className={
                      errors['inp-phone-refer'+ref.local_id]? 'input phone-ref inp-phone-refer'+ref.local_id+' error'
                      :'input phone-ref inp-phone-refer'+ref.local_id
                    }
                    id='data-phone-ref'
                  >
                    <i className='icon icon-mobile_phone' />
                    <input
                      type='tel'
                      name='phone'
                      id='phone-ref'
                      placeholder='(000) 000-0000'
                      onChange={(e) =>
                        setInputRefer(e, ref.local_id)
                      }
                      defaultValue={ref.phone}
                    />
                  </label>


                  <label
                    htmlFor='email-ref'
                    className={
                      errors['inp-email-refer'+ref.local_id]?'input email-ref email-ref'+ref.local_id+' error'
                      :'input email-ref email-ref'+ref.local_id
                    }
                    id='data-mail-ref'
                  >
                    <i className='icon icon-comm-env' />
                    <input
                      type='email'
                      name='email'
                      id='email-ref'
                      placeholder='oooooo@oooo.com'
                      onChange={(e) =>
                        setInputRefer(e, ref.local_id)
                      }
                      defaultValue={ref.email}
                    />
                  </label>


                  <button className="btn_enviar" onClick={() => verifyInputRefer(3,index)}>{t('iniform7.label')}</button>
                </div>}
                  {/*referQuestion5*/}

                {/*referQuestion6*/}
                {refNextQue.ans4 && <div className="group_q">
                  <div className="question">
                    <div className="emi"><i className="icon icon-m_emilan"></i></div>
                    <div className="div">
                      <p className='question_p'>
                        {t('saludF4b_p7.label')}
                      </p>
                    </div>
                  </div>
                  <div className="butons">
                    <button className="btn_option" onClick={() => verifyInputRefer(4,true)}>{t('yes.label')}</button>
                    <button className="btn_option" onClick={() => verifyInputRefer(4,false)}>{t('no.label')}</button>
                  </div>
                </div>}
                  {/*referQuestion6*/}
              </div>
            ))}

            {/*referQuestion7*/}
          {showref && (
            <div>
              {/*referQuestion8*/}
              {refNextQue.ans5 && <div className="group_q">
                <div className="question">
                  <div className="emi"><i className="icon icon-m_emilan"></i></div>
                  <div className="div">
                      <p className='question_p'>
                      {t('saludF4b_p10.label')}
                      </p>
                  </div>
                </div>
                <div className="options">
                  <lord-icon
                    src="https://cdn.lordicon.com/wxnxiano.json"
                    trigger="loop"
                    colors="primary:#000540,secondary:#00e5ba"
                    style={{width:'65px', height:'65px'}}>
                  </lord-icon>
                  <button className="btn_enviar leer" onClick={() => openModal()}>{t('leer.label')}</button>
                </div>
                <label className='checkbox2 terminos' htmlFor='acepto2'>
                  <input
                    id='acepto2'
                    type='checkbox'
                    name='terms'
                    defaultValue={terms}
                    checked={terms}
                  />
                  <p className='ps'>
                    {t('saludF4_r12.label')}
                  </p>
                  <span className='checkmark' onClick={() => verifyInputRefer(5,true)}></span>
                </label>
            
              </div>}
              {/*referQuestion8*/}

              {/*referQuestion9*/}
              {refNextQue.ans6 && 
              <div className="group_q">
                <div className="question">
                  <div className="emi"><i className="icon icon-m_emilan"></i></div>
                  <div className="div">
                    <p className='question_p'>
                      {t('saludF4b_p11.label')}
                    </p>
                  </div>
                </div>
              </div>}
              {/*referQuestion9*/}

              {/*referQuestion10*/}
              {refNextQue.ans6 && <input
                className='btn_enviar go '
                type='button'
                value= {t('saludF4_end.label')}
                onClick={submitInputs}
              />}
              {/*referQuestion10*/}
            </div>
          )}
          {/*referQuestion7*/}
        </div>
        {/*referQuestion4*/}

      </div>
      {animDots && <DotsMove/>}
    </div>
  )
}