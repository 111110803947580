import React, { useEffect, useState } from 'react';
// Trasnlation
import '../i18n';
import { useTranslation } from 'react-i18next';

export default function Policies_Gift() {
	let es = `
            <div class="col-terms"><h3>1.</h3><p> Actividad válida del 14 de febrero de 2022 hasta agotar existencias.
            </p> </div>
            <div class="col-terms"><h3>2.</h3><p> La persona que recomiende deberá efectivamente aplicar a un plan de salud y aún no ser clientes de <b>e <span>m</span>ilan</b> o haber tenido un plan de salud con nosotros.</p> </div>
            <div class="col-terms"><h3>3.</h3><p> El cliente y la persona que sea referida deberán tener una permanencia mínima con el plan de un mes a partir de la aceptación del plan de salud por parte del mercado de salud.</p> </div>
            <div class="col-terms"><h3>4.</h3><p> No hay un límite de referidos por persona, es decir, podrás referir el número de personas que desees y podrás acceder a una gift card virtual.</p> </div>
            <div class="col-terms"><h3>5.</h3><p>Entre más personas recomiendes, más gift card virtual podrás ganar.</p> </div>
            <div class="col-terms"><h3>6.</h3><p>  Se deben registrar las personas referidas en <a href="https://emilan.co/salud-form"> https://emilan.co/salud-form</a> para acceder al beneficio de la gift card. Si refieres a alguien aunque aplique y no lo has registrado, no podremos entregarte el beneficio.</p></div>
            <div class="col-terms"><h3>7.</h3><p>  En emilan Insurance nos reservamos el derecho de suspender el beneficio en caso que el referenciador no cumpla con la aplicación al plan de salud o su referenciado tampoco lo haga.</p></div>`;
	let en = ` 
            <div class="col-terms"><h3>1.</h3> <p>Activity valid from February 14th, 2022 while supplies last.</p></div>
            <div class="col-terms"><h3>2.</h3> <p>The person you refer must actually apply to a health plan with us and not yet be an emilan client or have had a health plan with us.</p> </div>
            <div class="col-terms"><h3>3.</h3> <p>The client and the person who is referred must have a minimum stay with the plan of one month from the acceptance of the Marketplace health plan.</p> </div>
            <div class="col-terms"><h3>4.</h3> <p> There is no limit of referrals per person, it means, you can refer to the number of people you want and you can access a virtual gift card.</p> </div>
            <div class="col-terms"><h3>5.</h3> <p> More people you refer, more Gift Cards you can earn.</p> </div>
            <div class="col-terms"><h3>6.</h3> <p>The people referred must be registered at https://emilan.co/salud-form to access the benefit of the gift card. If you refer someone even if they apply and you have not registered them in this link, we will not be able to give you the benefit.</p></div>
            <div class="col-terms"><h3>7.</h3> <p> At emilan Insurance we reserve the right to suspend the benefit in case that neither the one who refers or the referenced doesn't apply to a health plan .</p></div>`;

	// eslint-disable-next-line
	const { t } = useTranslation();

	return (
		<div className='terminos-IN'>
			{t('lang.label') === 'es' && (
				<div className='tERMINOS'>
					{' '}
					<h2>{t('terminos.label')}</h2>
					<div dangerouslySetInnerHTML={{ __html: es }}></div>
				</div>
			)}
			{t('lang.label') === 'en' && (
				<div className='tERMINOS'>
					{' '}
					<h2>{t('terminos.label')}</h2>
					<div dangerouslySetInnerHTML={{ __html: en }}></div>
				</div>
			)}
		</div>
	);
}
