import React, { useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import Home from './views/home';
import HomeNew from './views/homeNew';
import Services from './views/services';
import Salud from './views/salud';
import Vida from './views/vida';
import Suplementario from './views/suplementario';
import Nosotros from './views/nosotros';
import Blog from './views/blog';
import Blog1 from './views/blog1';
import Calculator from './views/calculator';
import Healths_Calculator from './views/healths-calculator';
import NotFound from './views/404';
import Healths_Calc_Mobile from './components/healt-calc_mobile';
import Contact from './views/formContact';
import PoliciesView from './views/policiesView';

//Jquery
import $ from 'jquery'

function usePageViews() {
  let location = useLocation();
  React.useEffect(() => {
    console.log(location);
  }, [location]);
}

export default function Routes() {
  usePageViews();

  const [currentWindow, setCurrentWindow] = useState($(window).width())

  $(window).resize(() => {
    setCurrentWindow($(window).width())
  })

  return (
    <Switch>
      {/* <Route exact path='/' component={Services} /> */}
      <Route exact path='/' component={HomeNew} />
      <Route exact path='/services' component={Services} />
      <Route exact path='/salud' component={Salud} />
      <Route exact path='/vida' component={Vida} />
      <Route exact path='/suplementario' component={Suplementario} />
      <Route exact path='/nosotros' component={Nosotros} />
      <Route exact path='/blog' component={Blog} />
      <Route exact path='/:id=:slug' component={Blog1} />
      <Route exact path='/calculator' component={Calculator} />
      <Route exact path='/salud-form' component={Healths_Calc_Mobile} />
      <Route exact path='/referidos-emilan' component={Healths_Calculator} />
      <Route exact path='/referidos-VIP' component={Healths_Calculator} />
      <Route exact path='/referidos-latinoamerica' component={Healths_Calculator} />
      {/* <Route exact path='/cotizador-salud' component={Healths_Calculator}/> */}
      <Route exact path='/contact' component={Contact} />
      <Route exact path='/policies' component={PoliciesView} />

      <Route component={NotFound} />
    </Switch>
  );
}