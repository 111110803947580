import React, { useEffect, useState } from 'react';
// Trasnlation
import '../i18n';
import { useTranslation } from 'react-i18next';

export default function Policies_GiftLATAM() {
	let es = `
            <div class="col-terms"><h3>1.</h3><p> Actividad válida hasta agotar existencias.
            </p> </div>
            <div class="col-terms"><h3>2.</h3><p>La persona que recomiende deberá efectivamente aplicar a un plan de salud y aún no ser clientes de <b>e<span>m</span>ilan</b> o haber tenido un plan de salud con nosotros.</p> </div>
            <div class="col-terms"><h3>3.</h3><p> El cliente y la persona que sea referida deberán tener una permanencia mínima con el plan de un mes a partir de la aceptación del plan de salud por parte del mercadeo de salud.</p> </div>
            <div class="col-terms"><h3>4.</h3><p> No hay un límite de referidos por persona, es decir, podrás referir el número de personas que desees y podrás acceder a $15 por cada persona que aplique con <b>e<span>m</span>ilan</b>.</p> </div>
            <div class="col-terms"><h3>5.</h3><p> Entre más personas recomiendes, más dinero podrás ganar.</p> </div>
            <div class="col-terms"><h3>6.</h3><p> Se deben registrar las personas referidas en <a href="https://emilan.co/referidos-latinoamerica">https://emilan.co/referidos-latinoamerica</a> para acceder al beneficio. Si refieres a alguien aunque aplique y no lo has registrado, no podremos entregarte el beneficio.</p></div>
            <div class="col-terms"><h3>7.</h3><p> En emilan Insurance nos reservamos el derecho de suspender el beneficio en caso que el referenciador no cumpla con la aplicación al plan de salud o su referenciado tampoco lo haga.</p></div>
            <div class="col-terms"><h3 class='start'>8.</h3>
            <div class="flex-c">
            <p> El referido, es decir la persona que se encuentra en Estados Unidos, debe cumplir con los siguientes requisitos: </p><p><ol type='a'>
             <li>Vivir en Estados Unidos</li>
             <li>Tener ingresos anuales mayores a $14,000 </li>
             <li>Tener estatus migratorio definido (permiso de trabajo, residencia, ciudadanía, TPS u otros)</li>
             <li>Tener entre 18 y 64 años de edad</li>
             <li>Declarar taxes </li></ol></p></div></div>
            <div class="col-terms"><h3>9.</h3><p> Una vez se confirme la aplicación de tu referido, nos pondremos en contacto vía correo electrónico para solicitarte la información bancaria y depositar el dinero que te corresponda.</p></div>`;
	let en = ` 
            <div class="col-terms"><h3>1.</h3><p> Activity valid while supplies last.</p></div>
            <div class="col-terms"><h3>2.</h3> <p>The person you refer must actually apply to a health plan with us and not yet be an <b>e<span>m</span>ilan</b> client or have had a health plan with us.</p> </div>
            <div class="col-terms"><h3>3.</h3> <p> The client and the person who is referred must have a minimum stay with the plan of one month from the acceptance of the Marketplace health plan.</p> </div>
            <div class="col-terms"><h3>4.</h3> <p> There is no limit of referrals per person, it means, you can refer to the number of people you want and you can access a virtual gift card.</p> </div>
            <div class="col-terms"><h3>5.</h3> <p> The more people you refer to, the more virtual gift cards you can earn.</p> </div>
            <div class="col-terms"><h3>6.</h3> <p>The people referred must be registered at <a href="https://emilan.co/referidos-latinoamerica">https://emilan.co/referidos-latinoamerica</a> to access the benefit of the gift card. If you refer someone even if they apply and you have not registered them in this link, we will not be able to give you the benefit.</p></div>
            <div class="col-terms"><h3>7.</h3> <p> At emilan Insurance we reserve the right to suspend the benefit in case that neither the one who refers or the referenced doesn't apply to a health plan.</p></div>
             <div class="col-terms"><h3 class='start'>8.</h3>
            <div class="flex-c">
            <p>The referral, as the person who is in the United States, must meet the following requirements: </p><p><ol type='a'>
             <li>Live in the United States</li>
             <li>Have an annual income over $14,000 </li>
             <li>Have defined immigration status (work permit, residency, citizenship, TPS, or other)</li>
             <li>Be between 18 and 64 years old</li>
             <li>Declare taxes</li></ol></p></div></div>
            <div class="col-terms"><h3>9.</h3> <p> Once your referral application is confirmed, we will contact you via email to request your bank information and deposit the money you are entitled to.</p></div>
            `;

	// eslint-disable-next-line
	const { t } = useTranslation();

	return (
		<div className='terminos-IN'>
			{t('lang.label') === 'es' && (
				<div className='tERMINOS'>
					{' '}
					<h2>{t('terminos.label')}</h2>
					<div dangerouslySetInnerHTML={{ __html: es }}></div>
				</div>
			)}
			{t('lang.label') === 'en' && (
				<div className='tERMINOS'>
					{' '}
					<h2>{t('terminos.label')}</h2>
					<div dangerouslySetInnerHTML={{ __html: en }}></div>
				</div>
			)}
		</div>
	);
}
