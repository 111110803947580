// Trasnlation
import './../i18n';
import { useTranslation } from 'react-i18next';

/*Modal de carga en Healt-calc_mobile */
export default function ModalLoader1(){

  const { t } = useTranslation();

  return(
    <div className='modal1'>
      <div className='load animate__animated animate__fadeIn'>
        <img
          className='cargando'
          src='assets/Images/Cargando.svg'
          alt='loaded'
        />
        <p>
          <span>{t('refe_waiting.label')}</span> <br />
          {t('refe_waiting2.label')}
        </p>
      </div>
    </div>
  )
}