import React, { useState, useContext } from 'react'
import $ from 'jquery'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'
import Aliates from '../components/aliates'
import { Helmet } from "react-helmet";

export default function Suplementario() {

  const [aliados, setAliados] = useState([
    'assets/Images/aliates/Suplementarios/AMERITAS.jpg',
    'assets/Images/aliates/Suplementarios/ASSURE-FOR-LIFE.jpg',
    'assets/Images/aliates/Suplementarios/NATIONAL-CARE.jpg',
    'assets/Images/aliates/Suplementarios/NATIONAL-GENERAL.jpg',
    'assets/Images/aliates/Suplementarios/SUREBRIDGE.jpg'
  ])

  // eslint-disable-next-line
  const { t } = useTranslation()

  return (
    <div>
      <Helmet>
        <title>Protección Suplementaria</title>
        <meta name="keywords" content="Suplementarios, Protección, Dental, Visión, Audición, Funeraria, Familia." />
        <meta name="description" content="No es necesario tener un seguro médico para contratar uno suplementario. Puedes obtenerlos como planes independientes y lo mejor de todo es que si deseas también puedes contratar varios según tus necesidades. Visión, Dental, Audición y Previsión funeraria." />
      </Helmet>
      <div className="suplementario view2" data-aos="fade">
        <div className="banner" data-aos="fade">
            <img 
              srcSet="assets/Images/suplementaria-bkp.png 769w, assets/Images/suplementaria-bkp-desk.png 2000w"
              title="Planes Suplementarios"
              alt="No es necesario tener un seguro médico para contratar uno suplementario. Puedes obtener planes dentales, visión, audición y previsión funeraria como piezas independientes y lo mejor de todo es que si deseas también puedes contratar varios según tus necesidades. "
            />
            <span className="Title animate__animated animate__fade">
              {t('suple1.label')}
            </span>
          </div>
          <div className="view-content"> 
          <h2 data-aos="fade-down">{t('suple2.label')}</h2>
            <div className="first-paragraph">
              <div className="lateral-text">
                <p data-aos="fade-up">
                  {t('suple3.label')}
                </p>
              </div>
              <img data-aos="fade-left" className="lateral-img" 
                srcSet="assets/Images/suple1.png 769w, assets/Images/suple1-desk.png 2000w"
                title="Suplementarios a bajo costo en USA"
                alt="Tu mejor aliado en la protección tuya y de toda tu familia, precios incomparables, no hay tiempos de espera, cuidamos tus ojos, oídos, dientes y prevemos todos los gastos de tu muerte o de algún familiar."
              />
            </div>
              <div className="section-suple section1">
                <div className="img-section">
                  <img data-aos="fade" className="lateral-img"
                    srcSet="assets/Images/dentis.png 769w, assets/Images/dentis-desk.png 2000w"
                    title="Plan Dental para toda la familia en Estados Unidos"
                    alt="Cuidar de ti y tu familia es nuestra prioridad, deja en manos de un experto tu salud oral y luce una sonrisa digna de mostrar. Contamos con planes que se adaptan a cada una de tus necesidades: Servicios Preventivos, Servicios Básicos y Servicios Especializados"
                  />
                  <div className="Title" data-aos="fade-right">
                    {t('dental.label')}
                  </div>
                </div>
                <div className="float-section">
                  <p data-aos="fade-up">
                    {t('dental1.label')}
                  </p>
                  <p data-aos="fade-up">
                    {t('dental2.label')}
                  </p>
                  <div className="icons-div">
                    <div className="Box-icon" data-aos="fade-right">
                      <div className="Box-icon-icon">
                        <i className="icon icon_dental1"></i>
                      </div>
                      <span>{t('dental3.label')}</span>
                    </div>
                    <div className="Box-icon" data-aos="fade-up">
                      <div className="Box-icon-icon">
                        <i className="icon icon_dental2"></i>
                      </div>
                      <span>{t('dental4.label')}</span>
                    </div>
                    <div className="Box-icon" data-aos="fade-left">
                      <div className="Box-icon-icon">
                        <i className="icon icon_dental3"></i>
                      </div>
                      <span>{t('dental5.label')}</span>
                    </div>
                </div>
              </div>
            </div>
              <div className="section-suple section2">
                <div className="img-section">
                  <img className="lateral-img" data-aos="fade"  
                    srcSet="assets/Images/vision.png 769w, assets/Images/vision-desk.png 2000w"
                    title="Plan de Visión económico"
                    alt="Los planes de visión se tornan cada vez más necesarios, y con nosotros puedes tener un plan que no solo cuida de ti, sino también de toda tu familia: Plan Básico y Plan Premier."
                  />
                  <div className="Title" data-aos="fade-left">
                    {t('vision.label')}
                  </div>
                </div>
                <div className="float-section">
                  <p data-aos="fade-up">
                    {t('vision1.label')}                
                  </p>
                  <div className="icons-div">
                    <div className="Box-icon" data-aos="fade-right">
                      <div className="Box-icon-icon">
                        <i className="icon icon_vision1"></i>
                      </div>
                      <span>{t('vision2.label')}</span>
                    </div>
                    <div className="Box-icon" data-aos="fade-left">
                      <div className="Box-icon-icon">
                        <i className="icon icon_vision2"></i>
                      </div>
                      <span>{t('vision3.label')}</span>
                    </div>
                  </div>
                </div>
            </div>
              <div className="section-suple section3">
                <div className="img-section">
                  <img className="lateral-img" data-aos="fade"
                    srcSet="assets/Images/audi.png 769w, assets/Images/audi-desk.png 2000w"
                    title="Plan de Audición, exámenes y audífonos"
                    alt="Velamos por tu bienestar, contamos con diferentes opciones para que goces de una excelente salud auditiva: Beneficio máximo, Examen de audición y Adquisición y reparación de audífonos. "
                  />
                  <div className="Title" data-aos="fade-right">
                    {t('audio.label')}
                  </div>
                </div>
              <div className="float-section">
                <p data-aos="fade-up">
                  {t('audio1.label')}
                </p>
                <div className="icons-div">
                  <div className="Box-icon" data-aos="fade-right">
                    <div className="Box-icon-icon">
                      <i className="icon icon_audi1"></i>
                    </div>
                    <span>{t('audio2.label')}</span>
                  </div>
                  <div className="Box-icon" data-aos="fade-up">
                    <div className="Box-icon-icon">
                      <i className="icon icon_audi2"></i>
                    </div>
                    <span>{t('audio3.label')}</span>
                  </div>
                  <div className="Box-icon" data-aos="fade-left">
                    <div className="Box-icon-icon">
                      <i className="icon icon_audi3"></i>
                    </div>
                    <span>{t('audio4.label')}</span>
                  </div>
                </div>
              </div>
            </div>
              <div className="section-suple section4">
                <div className="img-section">
                  <img className="lateral-img" data-aos="fade"
                    srcSet="assets/Images/fune.png 769w, assets/Images/fune-desk.png 2000w"
                    title="Previsión Funeraria, cremación"
                    alt="Contamos con 3 opciones para que logres estar donde tu y tu familia siempre lo quiso: opción nacional, retorno y raíces. A su vez contamos con planes que se adaptan a cada una de tus necesidades: Plan Familiar hasta 7 protegidos, Plan Individual, Plan Pareja, Plan silver para mayores de 65 años."
                  />
                  <div className="Title" data-aos="fade-right">
                    {t('fune.label')}
                  </div>
                </div>
              <div className="float-section">
                <div className="icons-div">
                  <div className="Box-icon" data-aos="fade-right">
                    <div className="Box-icon-icon">
                      <i className="icon icon_fune1"></i>
                    </div>
                    <span>{t('fune1.label')}</span>
                  </div>
                  <div className="Box-icon" data-aos="fade-up">
                    <div className="Box-icon-icon">
                      <i className="icon icon_fune2"></i>
                    </div>
                    <span>{t('fune2.label')}</span>
                  </div>
                  <div className="Box-icon" data-aos="fade-up">
                    <div className="Box-icon-icon">
                      <i className="icon icon_fune3"></i>
                    </div>
                    <span>{t('fune3.label')}</span>
                  </div>
                  <div className="Box-icon" data-aos="fade-left">
                    <div className="Box-icon-icon">
                      <i className="icon icon_fune4"></i>
                    </div>
                    <span>{t('fune4.label')}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="box-campania">
              <div className="Column-desk">
                <h3 data-aos="fade-right">{t('op1.label')}</h3>
                <ul className="top-campania">
                  <li data-aos="fade-right">{t('op1a.label')}</li>
                  <li data-aos="fade-right">{t('op1b.label')}</li>
                  <li data-aos="fade-right">{t('op1c.label')}</li>
                  <li data-aos="fade-right">{t('op1d.label')}</li>
                </ul>
              </div>
              <div className="Column-desk">
                <h3 data-aos="fade-right">{t('op2.label')}</h3>
                <ul className="top-campania">
                  <p data-aos="fade-right">{t('op2a.label')}</p>
                  <li data-aos="fade-right">{t('op2b.label')}</li>
                  <li data-aos="fade-right">{t('op2c.label')}</li>
                  <li data-aos="fade-right">{t('op2d.label')}</li>
                  <li data-aos="fade-right">{t('op2e.label')}</li>
                </ul>
              </div>
              <div className="Column-desk">
                <h3 data-aos="fade-right">{t('op3.label')}</h3>
                <ul className="top-campania">
                  <li data-aos="fade-right">{t('op3a.label')}</li>
                  <li data-aos="fade-right">{t('op3b.label')}</li>
                  <li data-aos="fade-right">{t('op3c.label')}</li>
                </ul>
              </div>
            </div>
            

        </div>            
        <Aliates contenido={aliados} suplementario={true} />
      </div>
    </div>
  )
}