import React, { useEffect } from 'react'
import imagen_lady from '../../../assets/Images/landings/servicios/she-carrer.png'
import FormLandings from './form_landings'


const ServicioTaxTeamEn= () => {
  
  useEffect(() => {
    localStorage.setItem('lng', 'es')
  }, [])

  return (
  <div className="servicios servicio_taxteam">
    <section className="div_1"> 
    <div className="image_mobile"></div>
      <div className="busca_plan">
      <p>Don´t let your financial past affect your future, <i className="icon icon-ingresos"/>. <br />
      repair your credit and make your dreams come true.<i className="icon icon-man"/> .</p>
      </div>
      <div className="options_contact">
        <a className='btn_orange' href="tel:+18882264180" >
          <i className="icon icon-headset"></i>
          <p>Call an expert <br /> <span>(888) 226-4180</span></p>
        </a>
        <p className='p_btns'>Chat with us</p>
        <div className="bnts_chat">
          <a className='btn_lightBlue' href='https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2F100907558856439%2F%3Fref%3Dw17077647%26messaging_source%3Dsource%253Apages%253Amessage_shortlink%26source_id%3D1441792' target='_blank'>
            <i className="icon icon-messenger"></i>
            <p>Chat on <span>Facebook</span></p>
          </a>
          <a className='btn_lightBlue' href='https://bit.ly/E_Credit-Repair' target='_blank'>
            <i className="icon icon_what-share"></i>
            <p>Chat on <span>Whatsapp</span></p>
          </a>
        </div>
      </div>
    </section>
    <section className='div_2'>
     <div className="buble1"></div>
     <div className="contenido_div2">
      <h2>What Is Credit?</h2>
      <p className='p_dv2'>Credit involves receiving something of value now with the promise to pay it back later.</p>
      <p className="p2_dv2">Your credit score is your entire <span>financial reputation</span> consolidated in a number between 300 and 850.</p>
     </div>
    </section>
    <section className='div_3'>
      <div className="contenido_div3">
        <h2>This are the benefits of repair your credit with us</h2>
        <ul>
          <li className='li_en'>
            <i className="icon icon-manos_abiertas "></i>
            <p>We help you get a clean credit history.</p>
          </li>
          <li className='li_en'>
            <i className="icon icon-lupa_emoji "></i>
            <p>We make requests to validate the information on the credit report and send disputes for erroneous negative items</p>
          </li>
          <li className='li_en'>
            <i className="icon icon-experto "></i>
            <p>We offer you the personalized help of an expert.</p>
          </li>
          <li className='li_en'>
            <i className="icon icon-grafica_emoji"></i>
            <p>We advise you on your credit and financial life.</p>
          </li>
          <li className='li_en'>
            <i className="icon icon-docs_emoji"></i>
            <p>We break down your credit score.</p>
          </li>
          <li className='li_en'>
            <i className="icon icon-mano_ok"></i>
            <p> We make your credit applications and credit reports reflect the financial investment that you are.</p>
          </li>
        </ul>
      </div>
    </section>
    <section className='div_5'>
      <h2>3 steps to repair your credit</h2>
      <div className="contenido_div5">
        <div className="paso paso1">
          <lord-icon
            src="https://cdn.lordicon.com/mgmiqlge.json"
            trigger="loop-on-hover"
            colors="primary:#00e5ba,secondary:#000540,tertiary:#000540,quaternary:#ebe6ef"
            style={{width:'250px', height:'220px'}}>
          </lord-icon>
          <div className="">
            <div className="num_paso"><span>1</span></div>
            <p className='p_paso'>Schedule A Fast & Free Credit Analysis with one of our team members</p>
          </div>
        </div>
        <div className="paso paso2">
          <lord-icon
            src="https://cdn.lordicon.com/pxecqsgb.json"
            trigger="hover"
            colors="primary:#00e5ba,secondary:#ebe6ef"
            style={{width:'250px', height:'220px'}}>
          </lord-icon>
          <div className="">
            <div className="num_paso"><span>2</span></div>
            <p className='p_paso'>We will go over your current credit reports from all 3 bureaus</p>
          </div>
        </div>
        <div className="paso paso3">
          <lord-icon
            src="https://cdn.lordicon.com/surjmvno.json"
            trigger="hover"
            delay="1500"
            colors="primary:#00e5ba,secondary:#ebe6ef"
            style={{width:'250px', height:'220px'}}>
          </lord-icon>
          <div className="">
            <div className="num_paso"><span>3</span></div>
            <p className='p_paso'>We fight for your financial future by helping you fix your credit</p>
          </div>
        </div>
      </div>
    </section>
    <section className='div_4'>
      <div className="buble2"></div>
      <h2>Repair your financial future right now.</h2>
      <div className="llamar_healt">
        <div className="llamar_info">
          <h4>Let´s make your dreams come true! <span>Give us a call!</span></h4>
          <a className='btn_orange2' href="tel:+18882264180">
          (888) 226-4180
          </a>
        </div>
        <div className="image">
          <img src={imagen_lady} alt="imagen prefiere llamar" />
        </div>
      </div>
    </section>

  </div>
  )
}

export default ServicioTaxTeamEn