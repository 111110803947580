import { useEffect, useState } from 'react';
import $ from 'jquery';

import AOS from 'aos';
import 'aos/dist/aos.css';

// Trasnlation
import './i18n';
import { useTranslation } from 'react-i18next';

// Store
import Store from './services/store';
//componentes
import HeaderServiciosEn from './components/landings/servicios/header_servicios_en';
import FooterServiciosEn from './components/landings/servicios/footer_servicios_en';
import RoutesLandingsEn from './routerLandingEn';

function AppLServiciosEn() {
	const [sended, setSended] = useState(false);

	const { i18n } = useTranslation();

	const initLang = () => {
		if (localStorage.getItem('lng')) {
			i18n.changeLanguage(localStorage.getItem('lng'));
		} else {
			window.localStorage.setItem('lng', 'en');
			i18n.changeLanguage('en');
		}
	};
	const setSendede = () => {
		setSended(true);
	};

	return (
		<Store>
			<div className='App' onLoad={initLang}>
        <HeaderServiciosEn/>

        <RoutesLandingsEn sended={sended} setSended={setSendede}/>
        
        <FooterServiciosEn/>
			</div>
		</Store>
	);
}

export default AppLServiciosEn;
