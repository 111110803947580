const env = {
  // API_URL:'https://request.emilan.co/v1/api/', // Prod
  //API_URL: "http://localhost/dashboard.emilan.co/api/", // Dev

  API_URL_BLOG: "https://blog.emilan.co/wp-json/wp/v2/posts", // Prod
  API_URL_BLOG_EN: "https://blog.emilan.co/en/wp-json/wp/v2/posts", // Prod
  API_URL_BLOG_CAT: "https://blog.emilan.co/wp-json/wp/v2/posts?categories=", // Prod
  API_URL_BLOG_MEDIA: "https://blog.emilan.co/wp-json/wp/v2/media/", // Prod
  API_URL_BLOG_TOKEN: "https://blog.emilan.co/wp-json/jwt-auth/v1/token", // Prod
  API_URL_BLOG_COMMENTS: "https://blog.emilan.co/wp-json/wp/v2/comments", // Prod
  API_URL_BLOG_COMMENTS_EN: "https://blog.emilan.co/en/wp-json/wp/v2/comments", // Prod
  API_URL_FORM1: "https://emilan.co/salud-form2/insert-records-web.php", // Prod
  API_URL_FORM2: "https://emilan.co/salud-form2/insert-records-web2.php", // Prod

  // HEALTHS_SCRAPER: 'https://scraper.emilan.co:7001/get-quotes', // Prod
  // HEALTHS_SCRAPER_P: 'https://scraper.emilan.co:7001/prorefers', // Prod
  // HEALTHS_SCRAPER_L: 'https://scraper.emilan.co:7001/proloyals', // Prod
  // HEALTHS_SCRAPER: "http://localhost:7001/get-quotes", // Dev
  // HEALTHS_SCRAPER_P: "http://localhost:7001/prorefers", // Dev
  // HEALTHS_SCRAPER_L: "http://localhost:7001/proloyals" // Dev

  IP_URL: 'https://ipapi.co/json/', // Verificar IP
};

export default env;
