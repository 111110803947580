import { useEffect, useState } from 'react';

import Header from './components/landings/renovation2/header';
import Footer from './components/landings/renovation2/footer';
import Landing_renovation2 from './views/landings/renovacion2/landing-renovation';
import $ from 'jquery';

import AOS from 'aos';
import 'aos/dist/aos.css';

// Trasnlation
import './i18n';
import { useTranslation } from 'react-i18next';

// Store
import Store from './services/store';

function AppRenovation2() {
	const [sended, setSended] = useState(false);

	const { i18n } = useTranslation();

	const initLang = () => {
		if (localStorage.getItem('lng')) {
			i18n.changeLanguage(localStorage.getItem('lng'));
		} else {
			window.localStorage.setItem('lng', 'es');
			i18n.changeLanguage('es');
		}
	};
	const setSendede = () => {
		setSended(true);
	};

	return (
		<Store>
			<div className='App' onLoad={initLang}>

        <img
          className='logo'
          src='assets/Images/landings/renovation2/logo-emilan-renov2.png'
          alt='logo'
        />
				<div className='courtain'>
					<img
						className='left'
						src='assets/Images/landings/renovation2/burb-left.svg'
						alt='left'
					/>
					<img
						className='right'
						src='assets/Images/landings/renovation2/burb-right.svg'
						alt='right'
					/>
				</div>
				<Landing_renovation2 sended={sended} setSended={setSendede} />
				<Footer />
			</div>
		</Store>
	);
}

export default AppRenovation2;
