import React, { useState, useContext, useEffect } from 'react';
import $ from 'jquery';

import { getinApi } from '../../../services/api';

import Companies from '../../../components/landings/renovation2/companies';
import Option from '../../../components/landings/renovation2/options';
import Header from '../../../components/landings/renovation2/header';

// Trasnlation
import '../../../i18n';
import { useTranslation } from 'react-i18next';

export default function Landing_renovation2({ lang }) {
	const { t } = useTranslation();

	const [sended, setsended] = useState(false);
	const [global_id, setGlobaID] = useState(0);
	const [ready, setReady] = useState(false);
	const [options, setoptions] = useState([]);
	const [options_selected, setoptionSelected] = useState([]);
	const [logos, setLogos] = useState([]);

	useEffect(() => {
		loadOptions(window.location.hash);
		return () => {
			$('body').addClass('view-landing-renovation');
		};
	}, []);



  const loadOptions = async (new_id) => {
    let id = new_id.split('#');
    setGlobaID(id[1])
    await getinApi('get', 'loyals/'+id[1]+'/scrapts' ).then( (res) => { 
      if (res.status !== 'Error') {
        setoptions(res.scrapts)
        orderOption(res.scrapts);
        setReady(true)
      }else{
        return false
      }

    } );
  };




  const orderOption = async(options) => {
     let option_selected = [];

     options.forEach(
       (option , index) => {
          const filtered = option_selected.filter(function (element) {
            return element === option.logo;
          });

         if (filtered.length <= 0 ){
           option_selected.push(option.logo);
         }

         if ((index + 1 )=== options.length){
           setLogos(option_selected);
         }
       }
     )

    
  };

  const setSubmit = async (id_scrapt) => {
    await getinApi('put', "loyals/" + global_id, { "other1": id_scrapt+"" }  ).then( (res) => {
      setsended(true);
    })
    
  };

  const setOptions = (index) => {
    const filtered = options.filter(function (element) {
      return element.logo === index;
    });

    setoptionSelected(filtered);
  };

  const location = () => {
    window.location = "https://emilan.co";
  };

	return (
		<div className='landing-renovation2'>
			<Header />
			{sended ? (
				<div className='chatelo'>
					<h3>{t('land2_title2.label')}</h3>
					<div className='end-renov'>
						<div className='message'>
							<p>{t('land2_parf4.label')}</p>
							<button className='button' onClick={location}>
								{t('land2_out.label')}{' '}
							</button>
						</div>
						<img
							src='assets/Images/landings/renovation2/Registro-renov.png'
							alt='thanks'
						/>
					</div>
				</div>
			) : (
				<div className='body-landing2'>
					<h2>{t('land2_title.label')}</h2>
					<p>{t('land2_parf1.label')}</p>
					<p>{t('land2_parf2.label')}</p>
					{logos.length > 0 ? (
						<Companies contenido={logos} setOptions={setOptions} />
            ) : <p className="loader-options"><img src="assets/Images/Spinner-2.gif" /></p> }
            {(ready) && options_selected.length > 0 && <p>{t('land2_parf3.label')}</p>  }
					<div className='options-renov'>
						{ready &&
							options_selected.map((option, index) => (
								<Option
									key={'option_number' + option.id}
									setSended={setSubmit}
									option={option}
								/>
							))}
					</div>
				</div>
			)}
		</div>
	);
}
