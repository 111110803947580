import React, { useState, useContext, useEffect, Fragment } from 'react'
import $ from 'jquery'

// Services 
import { getinApiBlog } from '../services/wp-api'
import Html2React from 'html2react'
import { useParams } from 'react-router-dom';
import moment from 'moment'
import tz from 'moment-timezone'
import mojs from '@mojs/core';
import { Helmet } from "react-helmet";

import { useHistory } from "react-router-dom";

// Components 
import Comment from '../components/comment';

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'


export default function Blog1() {

  let history = useHistory();
  let languege = window.localStorage.getItem('lng')

  const { id } = useParams();

  let [post, setPost] = useState([])
  let [go, setGo] = useState(false)
  let [mensaje, setMesage] = useState({ messahe: '' })
  let [comments, setComment] = useState([])
  let [ready, setReady] = useState(false)
  let [readyCom, setReadyCom] = useState(false)


  useEffect(() => {
    getPost(id);
    moment.locale('es')
    window.scrollTo(0, 0);
  }, [go]);

  useEffect(() => {
    if (post) {
      console.log('post ', post.content)
    }
  }, [post])

  const Toggle = () => {
    $('#cn-wrapper').toggleClass('opened-nav');
  }

  const gotoPost = (post, slug) => {
    window.location.href = window.location.origin + '/' + post + '=' + slug;
  }

  const getPost = async (id) => {
    var pst;
    await getinApiBlog('getAll', '/' + id).then(async (res) => {
      var dateString = res.date;
      var dateObj = new Date(dateString);
      var momentObj = moment(dateObj);
      var momentString = momentObj.format('DD/MM/YYYY');
      pst = {
        id: res.id,
        date: momentString,
        likes: res.meta_box.likes,
        dislikes: res.meta_box.dislikes,
        title: res.title.rendered,
        slug: res.slug,
        content: ((res.content.rendered).replace(/\&nbsp;/g, '')).replace(/<strong> <\/strong>/g, ''),
        excerpt: res.excerpt.rendered,
        featured_media: res.featured_media,
        media: [],
        liked: false,
        disliked: false
      }
      console.log(res.wpml_media_processed, res.slug);
      $('body').on('click', '.bt', function () {
        console.log(languege);
        if (!$(this).hasClass("" + languege)) {
          gotoPost(res.wpml_media_processed, res.slug)
        }
      });
      if (res.featured_media !== 0) {
        await getinApiBlog('getMedia', res.featured_media).then(async (res) => {
          pst.media.push(res.source_url);
          await getCommentsPost()
          setReady(true)
          return false
        }).catch((err) => console.log(err));
      } else {
        setReady(true)
        return false
      }
    }).catch((err) => console.log(err))
    setPost(pst);
  }

  const getCommentsPost = async () => {
    var commented = [];
    var Base = 'getAllComents'
    if (window.localStorage.getItem('lng') === 'en') {
      Base = 'getAllComentseN'
    }
    await getinApiBlog(Base, '?post=' + id).then(async (res) => {
      res.forEach(async (comment) => {
        var dateString = comment.date;
        var dateObj = new Date(dateString);
        var momentObj = moment(dateObj).tz('America/Bogota');
        var momentString = moment(momentObj, "YYYYMMDD").fromNow();
        let pst = {
          id: comment.id,
          date: momentString,
          content: comment.content.rendered,
          parent: comment.parent,
        }
        commented.push(pst);
      });
      setComment(commented);
      setReadyCom(true)
    }).catch((err) => console.log(err))
  }

  const goComentPush = async (e) => {
    if (e.target.value != '') {
      if (e.key === 'Enter') {
        $('.mesajse').val('Cargando.')
        setTimeout(() => {
          $('.mesajse').val('Cargando..')
        }, 100);
        setTimeout(() => {
          $('.mesajse').val('Cargando...')
        }, 250);
        setTimeout(() => {
          $('.mesajse').val('Cargando....')
        }, 350);
        $('.mesajse').attr('disabled', true);
        $('.b-coment').addClass('sending');
        // setReadyCom(false)
        await getinApiBlog('postCommentBlog', '?post=' + id + '&content=' + mensaje.messahe).then(async (res) => {
          // console.log(res);
          await getCommentsPost()
          $('.b-coment').removeClass('sending');
          setMesage({ messahe: '' })
          $('.mesajse').val('')
          $('.mesajse').attr('disabled', false);
        }).catch((err) => console.log(err))
      } else {
        setMesage({ ...mensaje, [e.target.name]: e.target.value })
      }
    }
  }


  const aniamteLike = (post) => {

    function extend(a, b) {
      for (var key in b) {
        if (b.hasOwnProperty(key)) {
          a[key] = b[key];
        }
      }
      return a;
    }

    function Animocon(el, options) {
      this.el = el;
      this.options = extend({}, this.options);
      extend(this.options, options);

      this.checked = false;

      this.timeline = new mojs.Timeline();

      for (var i = 0, len = this.options.tweens.length; i < len; ++i) {
        this.timeline.add(this.options.tweens[i]);
      }

      var self = this;
      if (!post.liked) {
        self.options.onCheck();
        self.timeline.replay();
      } else {
        self.options.onUnCheck();
      }
      self.checked = !self.checked;

    }

    Animocon.prototype.options = {
      tweens: [
        new mojs.Burst({})
      ],
      onCheck: function () { return false; },
      onUnCheck: function () { return false; }
    };

    /* Icon 14 */
    var el14 = document.querySelector('button.icobutton.icon14.heart-like-' + post.id),
      el14span = el14.querySelector('span'),
      el14counter = el14.querySelector('span.icobutton__text');

    new Animocon(el14, {
      tweens: [
        // ring animation
        new mojs.Shape({
          parent: el14,
          duration: 750,
          type: 'circle',
          radius: { 0: 20 },
          fill: 'transparent',
          stroke: '#FB6300',
          strokeWidth: { 35: 0 },
          opacity: 0.2,
          x: -10,
          top: '45%',
          easing: mojs.easing.bezier(0, 1, 0.5, 1)
        }),
        new mojs.Shape({
          parent: el14,
          duration: 500,
          delay: 100,
          type: 'circle',
          radius: { 0: 10 },
          fill: 'transparent',
          stroke: '#FB6300',
          strokeWidth: { 5: 0 },
          opacity: 0.2,
          x: 11,
          y: -13,
          easing: mojs.easing.sin.out
        }),
        new mojs.Shape({
          parent: el14,
          duration: 500,
          delay: 180,
          type: 'circle',
          radius: { 0: 5 },
          fill: 'transparent',
          stroke: '#FB6300',
          strokeWidth: { 5: 0 },
          opacity: 0.5,
          x: -17,
          y: -27,
          isRunLess: true,
          easing: mojs.easing.sin.out
        }),
        new mojs.Shape({
          parent: el14,
          duration: 800,
          delay: 240,
          type: 'circle',
          radius: { 0: 10 },
          fill: 'transparent',
          stroke: '#FB6300',
          strokeWidth: { 5: 0 },
          opacity: 0.3,
          x: -40,
          y: 10,
          easing: mojs.easing.sin.out
        }),
        new mojs.Shape({
          parent: el14,
          duration: 800,
          delay: 240,
          type: 'circle',
          radius: { 0: 10 },
          fill: 'transparent',
          stroke: '#FB6300',
          strokeWidth: { 5: 0 },
          opacity: 0.4,
          x: 29,
          y: -12,
          easing: mojs.easing.sin.out
        }),
        new mojs.Shape({
          parent: el14,
          duration: 1000,
          delay: 300,
          type: 'circle',
          radius: { 0: 8 },
          fill: 'transparent',
          stroke: '#FB6300',
          strokeWidth: { 5: 0 },
          opacity: 0.2,
          x: -3,
          y: -41,
          easing: mojs.easing.sin.out
        }),
        new mojs.Shape({
          parent: el14,
          duration: 600,
          delay: 330,
          type: 'circle',
          radius: { 0: 12 },
          fill: 'transparent',
          stroke: '#FB6300',
          strokeWidth: { 5: 0 },
          opacity: 0.4,
          x: -31,
          y: -32,
          easing: mojs.easing.sin.out
        }),
        // icon scale animation
        new mojs.Tween({
          duration: 1200,
          easing: mojs.easing.ease.out,
          onUpdate: function (progress) {
            if (progress > 0.3) {
              var elasticOutProgress = mojs.easing.elastic.out(1.43 * progress - 0.43);
              el14span.style.WebkitMaskImage = '/assets/Images/like.svg';
              el14span.style.maskImage = '/assets/Images/like.svg';
              el14span.style.WebkitTransform = el14span.style.transform = 'scale3d(' + elasticOutProgress + ',' + elasticOutProgress + ',1)';
            }
            else {
              el14span.style.WebkitTransform = el14span.style.transform = 'scale3d(0,0,1)';
            }
          }
        })
      ],
      onCheck: function () {
        el14span.style.backgroundColor = '#FB6300';
        el14counter.innerHTML = Number(el14counter.innerHTML) + 1;
      },
      onUnCheck: function () {
        el14span.style.backgroundColor = '#C0C1C3';
        var current = Number(el14counter.innerHTML);
        el14counter.innerHTML = current > 1 ? Number(el14counter.innerHTML) - 1 : '';
      }
    });
  }

  const aniamteDisLike = (post) => {

    function extend(a, b) {
      for (var key in b) {
        if (b.hasOwnProperty(key)) {
          a[key] = b[key];
        }
      }
      return a;
    }

    function Animocon(el, options) {
      this.el = el;
      this.options = extend({}, this.options);
      extend(this.options, options);

      this.checked = false;

      this.timeline = new mojs.Timeline();

      for (var i = 0, len = this.options.tweens.length; i < len; ++i) {
        this.timeline.add(this.options.tweens[i]);
      }

      var self = this;
      if (!post.disliked) {
        self.options.onCheck();
        self.timeline.replay();
      } else {
        self.options.onUnCheck();
      }
      self.checked = !self.checked;

    }

    Animocon.prototype.options = {
      tweens: [
        new mojs.Burst({})
      ],
      onCheck: function () { return false; },
      onUnCheck: function () { return false; }
    };

    /* Icon 12 */
    var el12 = document.querySelector('button.icobutton.icon12.heart-dislike-' + post.id),
      el12span = el12.querySelector('span'),
      el12counter = el12.querySelector('span.icobutton__text');
    var opacityCurve12 = mojs.easing.path('M0,100 L20,100 L20,1 L100,1');
    var translationCurve12 = mojs.easing.path('M0,100h20V0c0,0,0.2,101,80,101');
    new Animocon(el12, {
      tweens: [
        // burst animation
        new mojs.Burst({
          parent: el12,
          count: 2,
          radius: { 10: 90 },
          angle: 92,
          top: '90%',
          children: {
            shape: 'line',
            fill: '#C0C1C3',
            scale: 1,
            radius: { 40: 0 },
            stroke: '#C0C1C3',
            strokeWidth: { 4: 1 },
            strokeLinecap: 'round',
            opacity: 0.5,
            duration: 500,
            delay: 200,
            easing: mojs.easing.bezier(0.1, 1, 0.3, 1)
          }
        }),
        // burst animation
        new mojs.Burst({
          parent: el12,
          count: 3,
          radius: { 10: 40 },
          angle: 182,
          top: '90%',
          children: {
            shape: 'line',
            fill: '#C0C1C3',
            opacity: 0.5,
            scale: 1,
            radius: { 10: 0 },
            stroke: '#C0C1C3',
            strokeWidth: { 4: 1 },
            strokeLinecap: 'round',
            duration: 600,
            delay: 200,
            easing: mojs.easing.bezier(0.1, 1, 0.3, 1)
          }
        }),
        // ring animation
        new mojs.Shape({
          parent: el12,
          radius: { 40: 0 },
          radiusY: { 20: 0 },
          fill: '#C0C1C3',
          stroke: '#C0C1C3',
          strokeWidth: 1,
          opacity: 0.3,
          top: '90%',
          duration: 400,
          delay: 100,
          easing: 'bounce.out'
        }),
        // icon scale animation
        new mojs.Tween({
          duration: 500,
          easing: mojs.easing.bounce.out,
          onUpdate: function (progress) {
            var translationProgress = translationCurve12(progress);
            el12span.style.WebkitTransform = el12span.style.transform = 'translate3d(0,' + -350 * translationProgress + '%,0)';

            var colorProgress = opacityCurve12(progress);
            el12span.style.backgroundColor = colorProgress ? '#FB6300' : '#C0C1C3';
          }
        })
      ],
      onCheck: function () {
        el12span.style.backgroundColor = '#FB6300';
        el12counter.innerHTML = Number(el12counter.innerHTML) + 1;
      },
      onUnCheck: function () {
        el12counter.innerHTML = Number(el12counter.innerHTML) - 1;
        el12span.style.backgroundColor = '#C0C1C3';
      }
    });
  }

  const likedChange = async (poste) => {
    aniamteLike(poste)
    var likes
    var liked
    if (poste.liked) {
      poste.likes != '' ? likes = parseInt(poste.likes) - 1 : likes = 0;
      liked = false;
    } else {
      poste.likes != '' ? likes = parseInt(poste.likes) + 1 : likes = 1;
      liked = true;
    }
    await getinApiBlog('postBlog', '/' + poste.id + `?meta_box={"likes":${likes}}`).then((res) => {
      let local_posts = post;
      local_posts.liked = liked
      local_posts.likes = likes
      setPost(local_posts)
    }).catch((err) => console.log(err));
  }

  const dislikedChange = async (poste) => {
    aniamteDisLike(poste)
    var dislikes
    var disliked
    if (poste.disliked) {
      poste.dislikes != '' ? dislikes = parseInt(poste.dislikes) - 1 : dislikes = 0;
      disliked = false;
    } else {
      poste.dislikes != '' ? dislikes = parseInt(poste.dislikes) + 1 : dislikes = 1;
      disliked = true;
    }
    await getinApiBlog('postBlog', '/' + poste.id + `?meta_box={"dislikes":${dislikes}}`).then((res) => {
      let local_posts = post;
      local_posts.disliked = disliked
      local_posts.dislikes = dislikes
      setPost(local_posts)
    }).catch((err) => console.log(err));
  }

  // eslint-disable-next-line
  const { t } = useTranslation()

  return (
    <div>
      <Helmet>
        <title>Blog</title>
        <meta name="keywords" content="Novedades, Noticias, Artículos, Vida, Salud, Suplementarios, emilan, Familia." />
        <meta name="description" content="Entérate de noticias y temas de relevancia personal y común. Un espacio para instruirte y cuidar no solo de ti sino de toda tu familia. Artículos de interés para todo tipo de público con relación a los seguros de Vida, Salud, Suplementarios. " />
      </Helmet>
      {ready &&
        <div className="blog_web_1 view" key={'blog-' + post.id}>
          <div className="banner">
            <img src={post.media} />
          </div>
          <div className="view-content">
            <h2>{post.title}</h2>
            <div className="first-paragraph">
              <div className="date-public">
                <div className="date">  {t('publicado.label')}: {post.date}</div>
                <div className="butons-likes">
                  <div className="like">
                    <button className={`icobutton icobutton--heart icon14 heart-like-${post.id}`} onClick={() => likedChange(post)}>
                      <span className="icon icon_like"></span>
                      <span className="icobutton__text icobutton__text--side">{(post.likes != '') ? post.likes : '0'}</span>
                    </button>
                  </div>
                  <span className="dislike">
                    <button className={`icobutton icobutton--heart icon12 heart-dislike-${post.id}`} onClick={() => dislikedChange(post)}>
                      <span className="icon icon_dislike"></span>
                      <span className="icobutton__text icobutton__text--side">{(post.dislikes != '') ? post.dislikes : '0'}</span>
                    </button>
                  </span>
                </div>
              </div>
              <div className="lateral-text" dangerouslySetInnerHTML={{ __html: post.content }} />
              <div className="shareBox">
                <div className="div-share-box js flexbox canvas canvastext webgl no-touch geolocation postmessage websqldatabase indexeddb hashchange history draganddrop websockets rgba hsla multiplebgs backgroundsize borderimage borderradius boxshadow textshadow opacity cssanimations csscolumns cssgradients cssreflections csstransforms csstransforms3d csstransitions fontface generatedcontent video audio localstorage sessionstorage webworkers no-applicationcache svg inlinesvg smil svgclippaths">
                  <button className="cn-button" id="cn-button" onClick={Toggle}>
                    <div className="shrbot">
                      <i className="icon icon_share"></i>
                      <div className="text">{t('share.label')}</div>
                    </div>
                  </button>
                  <div className="cn-wrapper" id="cn-wrapper">
                    <ul>
                      <li><a href={'whatsapp://send?text=' + window.location} data-action="share/whatsapp/share"><span className="icon icon_what-share"></span></a></li>
                      <li><a href={'https://www.facebook.com/sharer/sharer.php?u=' + window.location} target="_blank"><span className="icon icon_face-share"></span></a></li>
                      <li><a href="https://www.instagram.com/emilan.insurance" target="_blank"><span className="icon icon_insta-share"></span></a></li>
                      <li><a target="_blank" href={'http://www.linkedin.com/shareArticle?mini=true&url=' + window.location + '&title=' + post.title + '&source=https://' + window.location.hostname}><span className="icon icon_in-share"></span></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="comentbox">
                <h3>{t('comen.label')}</h3>
                <div className="coment-b b-coment" >
                  <i className="icon icon_user-com"></i>
                  <form className="content-com" >
                    <textarea placeholder={t('comenadd.label')} className="mesajse" name="messahe" onKeyUp={goComentPush} ></textarea>
                  </form>
                </div>
                {readyCom && comments.map((comment, index) =>
                  <Comment comment={comment} key={comment.id} />
                )}
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}