import React from 'react'
import { useTranslation } from 'react-i18next'

const CopyR = () => {
    const { t } = useTranslation()
  return (
      <div className="copy-rigth">
        © Copyright 2022 {t('derechos.label')}  <span>emilan</span>
      </div>
  )
}

export default CopyR