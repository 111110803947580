import React from 'react'

export default function Comment({comment}) {
  return (
    <div className="coment-b animate__animated animate__slideInDown" key={'key-coment' + comment.id}>
      <i className="icon icon_user-com"></i>
      <div className="content-com">
        <span className="date-com">{comment.date}</span>
        <div className="username">Username</div>
        <div dangerouslySetInnerHTML={{ __html: comment.content }} />
      </div>
    </div>
  )
}

