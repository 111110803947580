
import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import NotFound from './views/404';

import ServicioSaludEn from './views/landings/servicios/servicio_salud_en';
import ServicioTaxTeamEn from './views/landings/servicios/servicio_taxteam_en';

function usePageViews() {
	let location = useLocation();
	React.useEffect(() => {
		console.log(location.pathname);
	}, [location]);
}

export default function RoutesLandingsEn() {
	usePageViews();
	return (
		<Switch>
			<Route exact path='/landings_en_health'component={ServicioSaludEn} />
			<Route exact path='/landings_en/credit_repair' component={ServicioTaxTeamEn} />
			<Route component={NotFound} />
		</Switch>
	);
}
