// @ts-nocheck
import axios from 'axios';

import env from '../environtments.js';

const URL_API_BLOG = env.API_URL_BLOG
const URL_API_BLOG_EN = env.API_URL_BLOG_EN
const URL_API_BLOG_CAT = env.API_URL_BLOG_CAT
const URL_API_BLOG_MEDIA = env.API_URL_BLOG_MEDIA
const URL_API_BLOG_TOKEN = env.API_URL_BLOG_TOKEN
const URL_API_BLOG_COMMENTS = env.API_URL_BLOG_COMMENTS
const URL_API_BLOG_COMMENTS_EN = env.API_URL_BLOG_COMMENTS_EN
const URL_API_FORM1= env.API_URL_FORM1
const URL_API_FORM2 = env.API_URL_FORM2

// METHOD , TABLE NAME / * , DATA 

export const getinApiBlog = async (method,data = '' ) => {
  
  switch (method) {
    case 'getAll': 
      return await axios.get(URL_API_BLOG+data).then(res => {
        return res.data;
      }).catch(err => {
        return {status:'Error'};
      }); 
    case 'getAllEn': 
      return await axios.get(URL_API_BLOG_EN+data).then(res => {
        return res.data;
      }).catch(err => {
        return {status:'Error'};
      }); 
    case 'getMedia': 
      return await axios.get(URL_API_BLOG_MEDIA+data).then(res => {
        return res.data;
      }).catch(err => {
        return {status:'Error'};
      }); 
    case 'getAllCat': 
      return await axios.get(URL_API_BLOG_CAT , data ).then(res => {
        return res.data;
      }).catch(err => {
        return {status:'Error'};
      }); 
    case 'getAllComents': 
      return await axios.get(URL_API_BLOG_COMMENTS+data ).then(res => {
        return res.data;
      }).catch(err => {
        return {status:'Error'};
      }); 
    case 'getAllComentseN':
      return await axios.get(URL_API_BLOG_COMMENTS_EN+data ).then(res => {
        return res.data;
      }).catch(err => {
        return {status:'Error'};
      }); 
    case 'postCommentBlog':
      return await axios.post(URL_API_BLOG_TOKEN, {
          "username": "Editor",
          "password": "SqjHU2gMlg201v$#$*84teXe"
        }).then( async (res) => {
          return await axios.post(URL_API_BLOG_COMMENTS + data, null ,{
            headers: {
              Authorization: 'Bearer '+res.data.token,
            }
          }).then(res => {
            return res.data;
          }).catch(err => {
            return { status: 'Error' };
          });
        }).catch(err => {
          return { status: 'Error' };
        });
    case 'postBlog': 
      return await axios.post(URL_API_BLOG_TOKEN, {
          "username": "Editor",
          "password": "SqjHU2gMlg201v$#$*84teXe"
        }).then( async (res) => {
          return await axios.post(URL_API_BLOG+data, null ,{
            headers: {
              Authorization: 'Bearer '+res.data.token,
            }
          }).then(res => {
            return res.data;
          }).catch(err => {
            return { status: 'Error' };
          });
        }).catch(err => {
          return { status: 'Error' };
        });
    case 'postHomeForm': 
      const params = new URLSearchParams();
      params.append('data', data);
      return await axios.post(URL_API_FORM1, params ,{
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
        }).then(res => {
          return res.status;
        }).catch(err => {
          return { status: 'Error' };
        });
    case 'postHomeForm2': 
      const params2 = new URLSearchParams();
      params2.append('data', data);
      return await axios.post(URL_API_FORM2, params2 ,{
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
        }).then(res => {
          return res.status;
        }).catch(err => {
          return { status: 'Error' };
        });
     

    default: break;
  }
}