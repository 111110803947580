import React, { Fragment, useState } from 'react'
import $ from 'jquery'

import { useTranslation } from 'react-i18next'
import Modal from 'react-awesome-modal';
import Policies from '../../../components/policies';

import emilan from '../../../assets/Images/home/emilan-mixto.svg'

export default function FootWebinar() {

  const [terms, setTerms] = useState(false)
  const [visible, setvisible] = useState(false)
  const openModal = () => {
    $('.terminos .checkmark').removeClass('invalide-all')
    setvisible(true)
    setTerms(!terms)
  }

  const closeModal = () => {
    setvisible(false)
  }

  // eslint-disable-next-line
  const { t } = useTranslation()

  return (
    <Fragment>
      <div className="footer footer2">

        <div className="infos">
          <h5>{t('moreServices.label')}</h5>

        </div>

        <div className="more_services">
          <ul>
            <li>
              <span>{t('healtProt.label')}</span>
            </li>
            <li>
              <span>{t('lifeProt.label')}</span>
            </li>
            <li>
              <span>{t('supleProt.label')}</span>
            </li>
            <li>
              <span>Blog</span>
            </li>
            <li>
              <span>{t('aboutus.label')}</span>
            </li>
            <li>
              <span onClick={() => openModal()} >{t('PrivPoli.label')}</span>
            </li>
          </ul>
        </div>
        <div className="message">
          <label htmlFor="" className="message_wa">
            <input type="text" placeholder={t('leaveMess.label')} />
            <i className="icon icon-mail_form "></i>
            <i className="icon icon-send"></i>
          </label>
        </div>

        <div className="contact_us">
          <div className="footer-icons">
            <a href="https://web.facebook.com/emilan.insurance" target='_blank' rel='noreferrer'>
              <i className="icon icon-facebo"></i></a>
            <a href="https://www.instagram.com/emilan.insurance/" target='_blank' rel='noreferrer'>
              <i className="icon icon-instagr"></i></a>
            <a href="https://youtube.com/channel/UCJGZWE_XB7BhZrfnGWZcGHw" target='_blank' rel='noreferrer'>
              <i className="icon icon-youtu"></i></a>
            <a href="https://api.whatsapp.com/send/?phone=1(561)6131007&text=Hola,%20estoy%20interesado%20en%20sus%20servicios&app_absent=0" target='_blank' rel='noreferrer'>
              <i className="icon icon-wasa"></i></a>
          </div>
        </div>



      </div>
      <Modal className="modales-n" visible={visible} width="90%" height="80%" effect="fadeInUp" onClickAway={() => closeModal()}>
        <div>
          <Policies />
          <a className="boton-close" onClick={() => closeModal()}>Continuar</a>
        </div>
      </Modal>
    </Fragment>

  )
}