import React, { Fragment, useEffect, useState } from 'react'
import $ from 'jquery'

import { useTranslation } from 'react-i18next';
import Modal from 'react-awesome-modal';
import Policies from '../../../components/policies';
import { getinApi } from '../../../services/api';

const stepsForm = window.stepsForm

const FormLandings = () => {

	const { t } = useTranslation();
  const [terms, setTerms] = useState(false);
	const [visible, setvisible] = useState(false);
	const [current, setCurrent] = useState(1);

	const openModal = () => {
		$('.terminos .checkmark').removeClass('invalide-all')
		setvisible(true);
		setTerms(!terms);
	};

	
  //info
  const [inform, setInform] = useState({
		fullname:'',
    phone:0,
    zip: '',
    gender:'',
    entry:0,
    birth:'',
    numfamy:0,
    mail:'',
		source: window.location.pathname
  })

	const closeModal = () => {
		setvisible(false);
	};
	
	useEffect(() => {

		let el = document.getElementById('theForm');
		let questions = [].slice.call(el.querySelectorAll('ol.questions > li'));
		let questionsCount = questions.length;
		$('.questions').find('li:nth-child(1)').addClass('current')

		$('span.number-current').html(current);
		$('span.number-total').html(questionsCount);

	}, [])


	const changeStep = (nextstep) => {
		$('.questions li').removeClass('current')
		$('.questions').find('li:nth-child(' + nextstep + ')').addClass('current')
		$('.questions').find('li:nth-child(' + nextstep + ')').find('input').focus()
	}

	const NextStep = async (ev, button = false) => {

		var keyCode = ev.keyCode || ev.which;
		let el = document.getElementById('theForm');
		let questions = [].slice.call(el.querySelectorAll('ol.questions > li'));

		if (keyCode === 13 || button) {
			ev.preventDefault();
			if (button && !terms) {
				$('.terminos .checkmark').addClass('invalide-all')
				return 
			}	
			if (current < questions.length) {
				if (!validations(current)) {
					setCurrent(current + 1)
					changeStep(current + 1)
					$('span.number-current').html(Number(current + 1));
					$('div.progress').css('width', (current + 1) * (100 / questions.length) + '%');  
				}else{
					return 
				}
			}

			if (current == questions.length - 1) {
				$('button.next').addClass('show')
			}

			if (current == questions.length) {
				if (!validations(current)) {
					$('.simform-inner').addClass('hide');
					$('.final-message').html('¡Gracias! Estaremos en contacto.');
					$('.final-message').addClass('show');
					await SendNewLander()
				} else {
					return
				}
			}
	
		}
	}

	const SendNewLander = async () => {
		await getinApi('post', 'landers', inform ).then(
			(data) => { 
				// console.log(data)
			}
		);
	}


  useEffect(() => {
		if(inform){
			//console.log(inform);
		}
  }, [inform])




  const takeInput =(e)=>{
    // validations()
    $('.simform').find('.inp').removeClass('invalide')
    setInform({...inform, [e.target.name]:e.target.value })
  }

 const validations = (step) =>{
    let members = /^[1-9]{1,2}$/g
    let mailOK = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    let nacimDate = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[0-1])\/(19\d{2}|20[0-9]{2})$/g
    let zipCode = /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/g

    let validations = 0;

			if(step == 1) {
				if (inform.fullname.trim() == '') {
					$('.inp-fullname').addClass('invalide')
					return true
				} else {
					return false
				}
			};
			if(step == 2) {
				if (inform.phone <= 0 || inform.phone.length != 10 )  {
					$('.inp-phone').addClass('invalide')
					return true
				} else {
					return false
				}
			}
			if(step == 3) {
				if (inform.zip.trim() == '' ) {
					$('.inp-zip').addClass('invalide')
					return true
				} else {
					return false
				}
			}
			if(step == 4) {
				if (inform.gender == '') {
					$('.inp-gender').addClass('invalide')
					return true
				} else {
					return false
				}
			}
			if(step == 5) {
				if (inform.birth.trim() == '') {
					$('.inp-birth').addClass('invalide')
					return true
				} else {
					return false
				}
			}
			if (step == 6) {
				if (inform.entry <= 0) {
					$('.inp-entry').addClass('invalide')
					return true
				} else {
					return false
				}
			}
			if (step == 7) {
				if (inform.numfamy.trim() == '' || inform.numfamy.match(members) === null) {
					$('.inp-numfamy').addClass('invalide')
					return true
				} else {
					return false
				}
			}
			if (step == 8) {
				if (inform.mail.trim() == '' || inform.mail.match(mailOK) === null) {
					$('.inp-mail').addClass('invalide')
					return true
				} else {
					return false
				}
			}


				// if(validations) {
				// 	return;
				// }

 }


  return (
    <Fragment>
        <div id="theForm" className="simform" autoComplete="off" >
					<div className="simform-inner">
						<ol className="questions" >
							<li>
								<span><label htmlFor="q1"><i className="icon icon-name"></i> Cuál es tu nombre y apellido?</label></span>
							<input id="q1" className='inp inp-fullname' name="fullname" type="text" placeholder='Nombre' required onChange={(e) => takeInput(e)} onKeyPress={NextStep}/>
							</li>
							<li>
								<span><label htmlFor="q2"><i className="icon icon-telefono"></i>Cuál es tu teléfono?</label></span>
							<input id="q2" className='inp inp-phone' name="phone" type="number" placeholder='Telefono' required onChange={(e)=>takeInput(e)} onKeyPress={NextStep} />
							</li>
							<li>
								<span><label htmlFor="q3"><i className="icon icon-zip"></i>Cuál es tu código postal?</label></span>
							<input id="q3" className='inp inp-zip' name="zip" type="text" placeholder='Codigo Postal' required onChange={(e)=>takeInput(e)} onKeyPress={NextStep} />
							</li>
							<li>
								<span><label htmlFor="q4"><i className="icon icon-genero"></i>Cuál es tu género?</label></span>
								<input id="q4" className='inp inp-gender' name="gender" type="text" placeholder='Genero' required onChange={(e)=>takeInput(e)} onKeyPress={NextStep} />
							</li>
							<li>
								<span><label htmlFor="q5"><i className="icon icon-nacimiento"></i>Cuál es tu fecha de nacimiento?</label></span>
							<input id="q5" className='inp inp-birth' name="birth" type="date" placeholder='MM/DD/AAAA' required onChange={(e)=>takeInput(e)} onKeyPress={NextStep} />
							</li>
							<li>
								<span><label htmlFor="q6"><i className="icon icon-ingresos"></i>Cuáles son los ingresos anuales familiares?</label></span>
							<input id="q6" className='inp inp-entry' name="entry" type="number" placeholder='Ingresos $$' required onChange={(e)=>takeInput(e)} onKeyPress={NextStep} />
							</li>
							<li>
								<span><label htmlFor="q7"><i className="icon icon-family_emoji"></i>Cuántos son los integrantes de tu familia?</label></span>
							<input id="q7" className='inp inp-numfamy' name="numfamy" type="number" placeholder='# Integrantes' min='1' max='2' required onChange={(e) => takeInput(e)} onKeyPress={NextStep}/>
							</li>
							<li>
								<span><label htmlFor="q8"><i className="icon icon-email"></i>Cuál es tu correo electrónico?</label></span>
								<input id="q8" className='inp-mail' name="mail" type="mail" placeholder='E-mail' onChange={(e) => takeInput(e)} onKeyPress={NextStep}/>
							</li>
						</ol>
						<div className="controls">
							<button className="next btn-postal" onClick={(e) => NextStep(e, true)}>Busca tu plan</button>
							<div className="progress"></div>
							<span className="number">
								<span className="number-current"></span>
								<span className="number-total"></span>
							</span>
							<span className="error-message"></span>
						</div>
						<label className='checkbox terminos' htmlFor="acepto">
							<input id='acepto' type='checkbox' name='terms' defaultValue={terms} onClick={() => openModal()} />
							<span className="checkmark"></span>
							<p className='p_politic'>
								Acepto terminos y politicas de privacidad.
							</p>
						</label>
					</div>
					<span className="final-message"></span>
				</div>	
 
      <Modal
				className='modales-n'
				visible={visible}
				width='90%'
				height='80%'
				effect='fadeInUp'
				onClickAway={() => closeModal()}
			>
				<div>
					<Policies />
					<a className='boton-close' onClick={() => closeModal()}>
						{t('iniform8.label')}
					</a>
				</div>
			</Modal>
    </Fragment>
  )
}

export default FormLandings