import React, { useEffect } from 'react'
import imagen_lady from '../../../assets/Images/landings/servicios/Lang_salud-lady-working.png'
import FormLandingsEn from './form_landings_en'


const ServicioSaludEn = () => {


  useEffect(() => {
    localStorage.setItem('lng', 'en')
  }, [])

  return (
    <div className="servicios servicio_salud">
      <section className="div_1">
        <div className="image_mobile"></div>
        <div className="busca_plan busca_plan_en">
          <p>It´s time to get protected <i className="icon icon-candado" />, get your health insurance plan paying <span>$0*</span> per month.</p>
          <	FormLandingsEn />
        </div>
        <div className="options_contact">
          <a className='btn_orange' href="tel:+19546982058" >
            <i className="icon icon-headset"></i>
            <p>Call a certified agent <br /> <span>(954) 698-2058</span></p>
          </a>
          <a className='btn_lightBlue' href='https://m.me/emilan.co.fb?ref=w16822831' target='_blank'>
            <i className="icon icon-messenger"></i>
            <p>Chat on <span>Facebook</span></p>
          </a>
          <a className='btn_lightBlue' href='https://bit.ly/emilan_SSalud2' target='_blank'>
            <i className="icon icon_what-share"></i>
            <p>Chat on <span>Whatsapp</span></p>
          </a>
        </div>
      </section>
      <section className='div_2'>
        <h4>Some of your health plan benefits are</h4>
        <ul>
          <li>
            <i className="icon icon-award "></i>
            <p>Health plans with monthly payments and deductibles from $0*.</p>
          </li>
          <li>
            <i className="icon icon-headset "></i>
            <p>Telemedicine 24/7**.</p>
          </li>
          <li>
            <i className="icon icon_salud-box "></i>
            <p>74 free preventive health check-ups in all our plans.</p>
          </li>
          <li>
            <i className="icon icon-medkit"></i>
            <p>Visits to the primary doctor and generic medications from $0*.</p>
          </li>
          <li>
            <i className="icon icon-heart-plus"></i>
            <p>Specialists, hospitalization and emergencies at low costs.</p>
          </li>
        </ul>
      </section>
      <section className='div_3'>
        <div className="buble1"></div>
        <div className="contenido_div3">
          <h4>This is what you need to apply:</h4>
          <div className="requisitos_health">
            <p><i className="icon icon-location"></i>Must live in the U.S</p>
            <p><i className="icon icon-bank"></i>Have a lawfull immigration status that allows you to work legally (work permit, residence, citizenship, among others).</p>
            <p><i className="icon icon-paper"></i>Do your taxes.</p>
            <p><i className="icon icon-medkit"></i>Not have another health plan from the state like Medicare or Medicaid or from your employer 💉.</p>
          </div>
          <p className='parra'><b>Don't you meet some of these requirements?</b> Don't worry, we´ll help you!.</p>
          <a className='btn_div3' href="https://bit.ly/emilan_SSalud2" target='_blank'>Chat with us here</a>
        </div>
      </section>
      <section className='div_4'>
        <div className="buble2"></div>
        <div className="llamar_healt">
          <div className="llamar_info">
            <h4>If you prefer contact us <br />directly at </h4>
            <a className='btn_orange2' href="tel:+19546982058">
              (954) 698-2058
            </a>
          </div>
          <div className="image">
            <img src={imagen_lady} alt="imagen prefiere llamar" />
          </div>
        </div>
        <p className="condiciones">
          *The cost of your health plan depends on your zip code, age, average income and how many people declare on your taxes
          **To use your free 24/7 Telemedicine service,you must register with your insurance company first.
        </p>
      </section>

    </div>
  )
}

export default ServicioSaludEn