import React, { useState } from 'react'
import $ from 'jquery'

import { useTranslation } from 'react-i18next'
import Modal from 'react-awesome-modal';
import Policies from '../../policies';

export default function Footer() {

  const [terms, setTerms] = useState(false)
  const [visible, setvisible] = useState(false)
  const openModal = () => {
    $('.terminos .checkmark').removeClass('invalide-all')
    setvisible(true)
    setTerms(!terms)
  }

  const closeModal = () => {
    setvisible(false)
  }

  // eslint-disable-next-line
  const { t } = useTranslation()

  return (
    <div className="footer-landing23">
      <div className="footer-icons">
        <a href="https://www.facebook.com/emilan.co.fb/"><i className="icon icon-face_line"></i></a>
        <a href="https://www.instagram.com/emilan.insurance"><i className="icon icon_insta-share"></i></a>
        <a href="https://youtube.com/channel/UCJGZWE_XB7BhZrfnGWZcGHw"><i className="icon icon-you_line"></i></a>
        <a href="https://api.whatsapp.com/send/?phone=1(561)6131007&text=Hola,%20estoy%20interesado%20en%20sus%20servicios&app_absent=0"><i className="icon icon_what-share"></i></a>
      </div>

      <div className="infos">
        <span><b />(954)<b />698-2058</span>
      </div>
      <Modal className="modales-n" visible={visible} width="90%" height="80%" effect="fadeInUp" onClickAway={() => closeModal()}>
        <div>
          <Policies />
          <a className="boton-close" onClick={() => closeModal()}>Continuar</a>
        </div>
      </Modal>
    </div>
  )
}