import React, { useState } from 'react';
import $ from 'jquery';

import { useTranslation } from 'react-i18next';
import Modal from 'react-awesome-modal';
import Policies from '../../policies';

export default function FooterServiciosEn() {
  const [terms, setTerms] = useState(false);
  const [visible, setvisible] = useState(false);
  const openModal = () => {
    $('.terminos .checkmark').removeClass('invalide-all');
    setvisible(true);
    setTerms(!terms);
  };

  const closeModal = () => {
    setvisible(false);
  };

  // eslint-disable-next-line
  const { t } = useTranslation();

  return (
    <>
      <div className='footer-servicios'>
        <div className="flex">
          <div className='more_info_serv'>
            <h6>More services:</h6>
            <div className="opciones_serv">
              <a href='https://emilan.co/salud'>Health protection</a>
              <a href='https://emilan.co/vida'>Life protection</a>
              <a href='https://emilan.co/suplementario'>Suplemental protection</a>
              <a href='https://emilan.co/blog'>Blog</a>
              <a href='https://emilan.co/nosotros'>About Us</a>
              <a onClick={() => openModal()}>Privacy policy</a>
            </div>
          </div>
          <div className='div_input'>
            <input type="email" name="email" id="email-healt" placeholder='Send email' title='enviar email' required />
            <a className="icon icon-send" href='https://emilan.co/home'></a>
          </div>
          <div className='footer-icons'>
            <a href='https://www.facebook.com/emilan.co.fb/'>
              <i className='icon icon-face_white'></i>
            </a>
            <a href='https://www.instagram.com/emilan.insurance'>
              <i className='icon icon-insta_white'></i>
            </a>
            <a href='https://youtube.com/channel/UCJGZWE_XB7BhZrfnGWZcGHw'>
              <i className='icon icon-you_white'></i>
            </a>
            <a href='https://api.whatsapp.com/send/?phone=1(561)6131007&text=Hola,%20estoy%20interesado%20en%20sus%20servicios&app_absent=0'>
              <i className='icon icon-what_white'></i>
            </a>


          </div>
        </div>
        <p>&copy; Copyright 2022 All rights reserved <b>emilan</b></p>
      </div>
      <Modal
        className='modales-n'
        visible={visible}
        width='90%'
        height='80%'
        effect='fadeInUp'
        onClickAway={() => closeModal()}
      >
        <div>
          <Policies />
          <a className='boton-close' onClick={() => closeModal()}>
            {t('iniform8.label')}
          </a>
        </div>
      </Modal>
    </>
  );
}
