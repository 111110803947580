import React, { useState, useContext, useEffect } from 'react'
import $ from 'jquery'

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'

//apis
import { getinApiBlog } from '../services/wp-api'
import { Helmet } from "react-helmet";

//componnts
import BannerSlider from '../components/BannerSlider';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper core and required modules
import { Pagination, Navigation, Mousewheel, Keyboard, Autoplay } from 'swiper';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

//Images
import states from '../assets/Images/home/MapaUSAstates.png'
import states2 from '../assets/Images/home/MapaUSActualizado02.png'
import states3 from '../assets/Images/home/MapaUSActualizado03.png'

import img_step1 from '../assets/Images/home/customer-survey-animate.svg'
import img_step2 from '../assets/Images/home/choose-animate.svg'
import img_step3 from '../assets/Images/home/celebration-animate.svg'
import help_you from '../assets/Images/home/girlPhone.png'
import arrow1 from '../assets/Images/home/flechaCurva1.svg'
import arrow2 from '../assets/Images/home/flechaCurva2.svg'

//logos
import aetna from '../assets/Images/landings/agentes/Aetna.jpg'
import ambetter from '../assets/Images/landings/agentes/ambetter.jpeg'
import ameritas from '../assets/Images/landings/agentes/Ameritas.png'
import blueCross from '../assets/Images/landings/agentes/blue-cross-blue-shield-vector-logo.png'
import brightHealth from '../assets/Images/landings/agentes/Bright HealthCare.png'
import carePlus from '../assets/Images/landings/agentes/CarePlus_Logo_.jpg'
import careSource from '../assets/Images/landings/agentes/Caresource.png'
import cigna from '../assets/Images/landings/agentes/Cigna-logo.jpg'
import forester from '../assets/Images/landings/agentes/foresters-insurance-logo-450x127.png'
import humana from '../assets/Images/landings/agentes/Humana.png'
import medica from '../assets/Images/landings/agentes/medica-logo.jpg'
import molina from '../assets/Images/landings/agentes/Molina-Healthcare.png'
import mutual from '../assets/Images/landings/agentes/Mutual-of-Omaha-logo.png'
import nationalGeneral from '../assets/Images/landings/agentes/national-general-insurance-logo-vector.png'
import nationalLife from '../assets/Images/landings/agentes/national-life-group-logo2.png'
import obama from '../assets/Images/landings/agentes/obama.svg'
import oscar from '../assets/Images/landings/agentes/Oscar_Health_logo.png'
import sunHealth from '../assets/Images/landings/agentes/Sun Health_Dental.jpg'
import sureBridge from '../assets/Images/landings/agentes/SureBridge.png'
import united from '../assets/Images/landings/agentes/UnitedHealthcare.jpg'
import wellcare from '../assets/Images/landings/agentes/wellcare_by_allwell.jpg'


//footer
import Footer2 from '../components/footer2'

const LOGOS = [obama, ambetter, oscar, blueCross, aetna, brightHealth, careSource, cigna, medica, molina, united, ameritas, carePlus, forester, humana, mutual, nationalGeneral, nationalLife, sunHealth, sureBridge, wellcare]


export default function HomeNew() {

  // eslint-disable-next-line
  const { t } = useTranslation()
  const [companies, setCompanies] = useState(LOGOS)

  setTimeout(() => {
    if (!!window.IntersectionObserver) {
      let options = {
        root: null,
        rootMargin: "0px 0px 0px 0px",
        threshold: 1.0
      }
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            console.log(entry);
            contador(5000, 'cant_why1', 1)
            contador(4000, 'cant_why2', 1)
            contador(80, 'cant_why3', 80)
            contador(48, 'cant_why4', 80)
            observer.unobserve(entry.target);
          }
        });
      }, options);

      let see = document.querySelector('#div_pp')
      observer.observe(see);
    }
  }, 100);

  useEffect(() => {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://tag.simpli.fi/sifitag/529c90b0-732f-013b-5a35-0cc47a1f72a4";
    document.getElementsByTagName('head')[0].appendChild(s);
  }, [])
  const contador = (limite, item, time) => {
    let cantidad = 0

    let valor = setInterval(() => {
      cantidad += 1
      $('#' + item).html(cantidad + ' +')
      if (cantidad === limite) {
        clearInterval(valor)
      }
    }, time);
  }

  // const [visible, setvisible] = useState(false)
  // const [terms, setTerms] = useState(false)
  // const openModal = () => {
  //   $('.terminos .checkmark').removeClass('invalide-all')
  //   setvisible(true)
  //   setTerms(!terms)
  // }

  // const closeModal = () => {
  //   setvisible(false)
  // }

  return (
    <div>
      <Helmet>
        <title>Inicio</title>
        <meta name="keywords" content="emilan, Seguros, Vida, Salud, Suplementarios, Familia, Confiabilidad, Hogar, Respaldo, Pólizas." />
        <meta name="description" content="Soportados en los mejores aliados de seguros de los Estados Unidos buscamos llegar a todos los hogares prevención en temas relacionados con vida, salud y suplementarios. Contamos con la experiencia y el respaldo de grandes empresas que garantizan la total confiabilidad en nuestros servicios. " />
      </Helmet>
      <div className="home_web2 view">
        <BannerSlider />
        <div className="div_elige">
          <h2>{t('titleDiv1.label')}</h2>
          <div className="options_seguros">
            <Swiper
              className="mySwiper"
              mousewheel={true}
              keyboard={true}
              slidesPerView={1}
              // spaceBetween={50}
              freeMode={false}
              loop={false}
              pagination={{ dynamicBullets: true }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
              breakpoints={{
                480: {
                  slidesPerView: 2,
                  loop: true,
                  autoplay:
                  {
                    delay: 3000,
                    disableOnInteraction: false
                  }

                },
                768: {
                  slidesPerView: 2,
                },
                1240: {
                  slidesPerView: 3,

                  autoplay: false,
                },
              }}
            >
              <SwiperSlide>
                <div className="optiion_seg_card">
                  <div className="wave"></div>
                  <div className="cont">
                    <div className="div_icon">
                      <i className="icon icon_salud-box"></i>
                    </div>
                    <div className="tit_seguros">{t('healIss.label')}</div>
                    <a className="botton_withe" href='https://wa.link/9nhcxh' target="_blank" rel='noreferrer noopener'>{t('quote.label')}</a>
                    <span className='price_prox'>{t('startFrom.label')} $0*</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="optiion_seg_card">
                  <div className="wave"></div>
                  <div className="cont">
                    <div className="div_icon">
                      <i className="icon icon-heart"></i>
                    </div>
                    <div className="tit_seguros">{t('lifeains.label')}</div>
                    <a className="botton_withe" href='https://wa.link/rwxair' target="_blank" rel='noreferrer noopener'>{t('quote.label')}</a>
                    <span className='price_prox'>{t('startFrom.label')} $13</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="optiion_seg_card">
                  <div className="wave"></div>
                  <div className="cont">
                    <div className="div_icon">
                      <i className="icon icon-umbrella"></i>
                    </div>
                    <div className="tit_seguros suple">{t('supleINs.label')}</div>
                    <a className="botton_withe" href='https://wa.link/zjfmc2' target="_blank" rel='noreferrer noopener'>{t('quote.label')}</a>
                    <span className='price_prox'>{t('startFrom.label')} $9</span>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            {/*               <div className="optiion_seg_card">
                <div className="div_icon">
                  <i className="icon icon_salud-box"></i>
                </div>
                <div className="tit_seguros">Seguros de Salud</div>
                <div className="botton_withe">Cotizar</div>
                <span className='price_prox'>desde $0*</span>
              </div>
              <div className="optiion_seg_card">
                <div className="div_icon">
                  <i className="icon icon-heart"></i>
                </div>
                <div className="tit_seguros">Seguros de Vida</div>
                <div className="botton_withe">Cotizar</div>
                <span className='price_prox'>desde $13</span>
              </div>
              <div className="optiion_seg_card">
                <div className="div_icon">
                  <i className="icon icon-umbrella"></i>
                </div>
                <div className="tit_seguros">Seguros Suplementarios</div>
                <div className="botton_withe">Cotizar</div>
                <span className='price_prox'>desde $9</span>
              </div> */}
          </div>
          <p className="comment">{t('*depend.label')} </p>
        </div>
        <div className="div_why" id='div_pp'>
          <h2>{t('whyCho.label')}</h2>
          <div className="options_why">
            <Swiper
              className="mySwiper"
              mousewheel={true}
              keyboard={true}
              slidesPerView={1}
              // spaceBetween={0}
              freeMode={false}
              // loop={true}
              pagination={{ dynamicBullets: true }}
              navigation={{
                prevEl: '.prev',
                nextEl: '.next',
              }}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
              breakpoints={{
                480: {
                  slidesPerView: 1,
                  // spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  // spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  // spaceBetween: 40,
                },
                1240: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                  autoplay: false
                },
              }}
            >
              <SwiperSlide>
                <div className="optiion_why">
                  <div className="div_icon">
                    <i className="icon icon-money"></i>
                  </div>
                  <div className="cant_why" id="cant_why1">15.000M + </div>
                  <p className="text">{t('premDue.label')}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="optiion_why">
                  <div className="div_icon">
                    <i className="icon icon-starTeam"></i>
                  </div>
                  <div className="cant_why" id="cant_why2">14.000 + </div>
                  <p className="text">{t('activeMem.label')}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="optiion_why">
                  <div className="div_icon">
                    <i className="icon icon-cusService"></i>
                  </div>
                  <div className="cant_why" id="cant_why3">80 + </div>
                  <p className="text">{t('licenAgen.label')}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="optiion_why">
                  <div className="div_icon">
                    <i className="icon icon-mapa"></i>
                  </div>
                  <div className="cant_why" id="cant_why4">48 + </div>
                  <p className="text">{t('statesWhi.label')}</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="div_states">
          <h2>{t('weAre.label')}</h2>
          <div className="image_states">
            <img src={states} alt="map e.u.states active" className="states_map" data-aos='fade-right' />
            <img src={states2} alt="list to estates active own service" className="stateslist_1" data-aos='fade-left' />
            <img src={states3} alt="list to estates active own service" className="stateslist_2" data-aos='fade' />
          </div>
        </div>
        <div className="div_how">
          <h2>{t('3steps.label')}</h2>
          <div className="steps desktop">
            <div className="step step1" data-aos='fade' data-aos-duration='600'>
              <div className="number_step">1</div>
              <div className="div_imge">
                <img className='image_step' src={img_step1} alt='' />
              </div>
              <div className="tit_step">{t('fillOut.label')}</div>
              <p className="tex_step">{t('tellUs.label')}</p>
            </div>
            <div className="arrow ar1" data-aos='fade' data-aos-delay='500' data-aos-duration='90'>
              <img src={arrow1} alt="primera flecha" />
              <div className="cortina"></div>
            </div>
            <div className="step step2" data-aos='fade' data-aos-delay='900' data-aos-duration='600'>
              <div className="number_step">2</div>
              <div className="div_imge">
                <img className='image_step' src={img_step2} alt='' />
              </div>
              <div className="tit_step">{t('selectYour.label')}</div>
              <p className="tex_step">{t('weContact.label')}</p>
            </div>
            <div className="arrow  ar2" data-aos='fade' data-aos-delay='1400' data-aos-duration='90'>
              <img src={arrow2} alt="segunda flecha" />
              <div className="cortina"></div>
            </div>
            <div className="step step3" data-aos='fade' data-aos-delay='1900' data-aos-duration='600'>
              <div className="number_step">3</div>
              <div className="div_imge">
                <img className='image_step' src={img_step3} alt='' />
              </div>
              <div className="tit_step">{t('yourePart.label')}</div>
              <p className="tex_step">{t('wereWith.label')}</p>
            </div>
          </div>
          <div className="steps mobile">
            <Swiper
              className="mySwiper"
              mousewheel={true}
              keyboard={true}
              slidesPerView={1}
              // spaceBetween={0}
              freeMode={false}
              // loop={true}
              pagination={{ dynamicBullets: true }}
              navigation={{
                prevEl: '.prev',
                nextEl: '.next',
              }}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
              breakpoints={{
                480: {
                  slidesPerView: 1,
                  // spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  // spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  // spaceBetween: 40,
                },
                1240: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
            >
              <SwiperSlide>
                <div className="step step1" data-aos='fade' data-aos-duration='3000' >
                  <div className="number_step">1</div>
                  <div className="div_imge">
                    <img className='image_step' src={img_step1} alt='' />
                  </div>
                  <div className="tit_step">{t('fillOut.label')}</div>
                  <p className="tex_step">{t('tellUs.label')}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="step step2" data-aos='fade ' data-aos-duration='3000'>
                  <div className="number_step">2</div>
                  <div className="div_imge">
                    <img className='image_step' src={img_step2} alt='' />
                  </div>
                  <div className="tit_step">{t('selectYour.label')}</div>
                  <p className="tex_step">{t('weContact.label')}</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="step step3" data-aos='fade' data-aos-duration='3000'>
                  <div className="number_step">3</div>
                  <div className="div_imge">
                    <img className='image_step' src={img_step3} alt='' />
                  </div>
                  <div className="tit_step">{t('yourePart.label')}</div>
                  <p className="tex_step">{t('wereWith.label')}</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <a className="button_orange" href='tel:844463951' rel='noopener'>
            <i className="icon icon_phone"></i>
            <span>{t('callNow.label')}</span>
          </a>

        </div>
        <div className="div_companies">
          <h2>{t('weWork.label')}</h2>
          <Swiper
            className="mySwiper"
            mousewheel={true}
            keyboard={true}
            slidesPerView={2}
            spaceBetween={40}
            freeMode={false}
            loop={true}
            pagination={{ dynamicBullets: true }}
            navigation={{
              prevEl: '.prev',
              nextEl: '.next',
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              480: {
                slidesPerView: 3,
                // spaceBetween: 10,
              },
              768: {
                slidesPerView: 4,
                // spaceBetween: 20,
              },
              1024: {
                slidesPerView: 5,
                // spaceBetween: 40,
              },
              1240: {
                slidesPerView: 6,
                // spaceBetween: 50,
              },
            }}
            modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
          >
            {companies.map((slide, index) => (
              <SwiperSlide key={'slide-index' + index}>
                <div className="div_img_compay">
                  <img src={slide} alt='company' />
                </div>
              </SwiperSlide>

            ))}
            <div className='prev'>
              <i className='icon icon-prev2'></i>
            </div>
            <div className='next'>
              <i className='icon icon-next2'></i>
            </div>
          </Swiper>
        </div>
        <div className="div_help">
          <div className="img_help">
            <img src={help_you} alt="imge help assesor" />
          </div>
          <div className="info_help">
            <h3>{t('needHelp.label')}</h3>
            <p>{t('leaveUs.label')}</p>
            <a className="button_orange" href='/contact' target='_blank' rel='noopener'>
              <span>{t('iWant.label')}</span>
            </a>
          </div>

        </div>
      </div>
      <Footer2 />
    </div>
  )
} 