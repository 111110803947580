import React, { useEffect, useState } from 'react'
import { Link, useLocation  } from 'react-router-dom';
import $ from 'jquery';
import AOS from 'aos';
// Trasnlation 
import '../../../i18n'
import { useTranslation } from 'react-i18next'

export default function Header23({sended}) {

  const { t, i18n } = useTranslation()
  
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
    $(".bt").removeClass("active");
    $("." + lang).addClass("active");
    localStorage.setItem('lng', lang)
  }

  return (
    <div className="banner-landing23">
      <img className="banner" src="assets/Images/landings/renovation2023.png" />
      {sended ?
        <div className="title-sended">
          {t('land_banner3.label')}
        </div>
        :
        <div className="title">
          <img src="assets/Images/landings/renovation/obama.svg" />
          {t('lan_ren23-textBanner.label')}
        </div>
      }

      <img src="assets/Images/landings/renovation/logo.svg" className="logoemi"/>

      <div className="langs">
        <div className='botones'>
          <button className={localStorage.getItem('lng') === 'es' ? 'bt es active' : 'bt es'} onClick={() => changeLanguage('es')} type='button'><div className='flag flag-es'></div><span>ES</span></button>
          <button className={localStorage.getItem('lng') === 'en' ? 'bt en active' : 'bt en'} onClick={() => changeLanguage('en')} type='button'><div className='flag flag-en'></div><span>IN</span></button>
        </div>
      </div>
    </div>
  )
}