

import React, { useEffect, useState }from 'react'

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTranslation } from 'react-i18next'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'


export default function Aliates({ contenido, suplementario }) {

  // eslint-disable-next-line
  const { t } = useTranslation()

  const [perpage, setperpage] = useState(6)

  useEffect(() => {
    if (suplementario) {
      setperpage(5)
    }
  }, [suplementario])


  return (
    <div className="aliates" data-aos="fade">
      <div className="aliates-title">
        {t('aliates.label')}
      </div>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        freeMode={false}
        breakpoints={{
          320: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 4,
          },
          1024: {
            slidesPerView: perpage,
            spaceBetween:10
          },
          1450: {
            slidesPerView: perpage,
            spaceBetween:20
          },
        }}
        
      >
        {contenido.map((slide, index) =>
          <SwiperSlide key={'slide-index'+index}  >
            <img src={slide} />
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  )
}
