import React, { useState, useContext, useEffect } from 'react'
import $ from 'jquery'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'
import { Helmet } from "react-helmet";


export default function Services() {

  // eslint-disable-next-line
  const { t } = useTranslation()

  useEffect(() => {
    $('.hover01 span').mouseover(function() {
      $('.hover01 div').removeClass('active');
      $(this).parent().addClass('active');
      $(this).mouseout(function () {
        $('.hover01 div').removeClass('active');
      });
    });
  });

  return (
    <div>
      <Helmet>
        <title>Servicios</title>
        <meta name="keywords" content="Servicios emilan, vida, salud, suplementarios" />
        <meta name="description" content="En emilan, trabajamos día a día para generar en ti experiencias memorables, llegando a nuevos lugares, velando por el bienestar tuyo y de toda tu familia. Ofreciendo servicios sólidos y confiables: Vida, Salud, Suplementarios. Promoviendo a través de estos, el respaldo que garantiza el bienestar de todos." />
      </Helmet>
      <div className="services view" >
        <div className="hover01 column">
          <div data-aos="fade-down">
            <figure>
              <img 
                data-aos="zoom-in"
                src="assets/Images/serv1.png" 
                srcSet="assets/Images/salud-bkp.png 415w, assets/Images/serv1.png 2000w"
              />
            </figure>
            <span className="hash animate__animated animate__fadeIn" >
              {t('salud1.label')}
              <a className="btn-more" href="/salud">{t('ver.label')}</a>
            </span>
          </div>
          <div data-aos="fade-down">
            <figure>
              <img 
                data-aos="zoom-in"
                src="assets/Images/serv2.png"
                srcSet="assets/Images/vida-bkp.png 415w, assets/Images/serv2.png 2000w"
              />
            </figure>
            <span className="hash animate__animated animate__fadeIn">
              {t('vida1.label')}
              <a className="btn-more" href="/vida">{t('ver.label')}</a>
            </span>
          </div>
          <div data-aos="fade-down">
            <figure>
              <img 
                data-aos="zoom-in"
                src="assets/Images/serv3.png"
                srcSet={`assets/Images/suplementaria-bkp.png 415w, assets/Images/serv3.png 2000w`}
                title="Protección Suplementaria económica"
                alt="Cuidamos de ti latino y toda tu familia con planes para cada necesidad: Visión, Dental, Audición y Previsión funeraria, todos estos a un bajo costo y aplicación inmediata. No existen los periodos de espera."
              />
            </figure>
            <span className="hash animate__animated animate__fadeIn">
              {t('suple1.label')}
              <a className="btn-more" href="/suplementario">{t('ver.label')}</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}