

import React, { useEffect, useState } from 'react'
// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

export default function Policies() {

  let es = `<h3>1. Introducción.</h3>

            <p>emilan.co ("Compañía") respeta su
              privacidad y se compromete a protegerla a través de nuestro cumplimiento de la
              siguiente política.</p>

            <p>Esta política describe los tipos de
              información que podemos recopilar de usted o que usted puede proporcionar
              cuando visita el sitio web https://www.emilan.co (nuestro "Sitio web")
              y nuestras prácticas para recopilar, usar, mantener, proteger y divulgar esa
              información.</p>

            <p>Esta política se aplica a la información que
              recopilamos a través de:  </p>

            <p>
              Sitio Web.</p>

            <p>
              Correo electrónico, texto y otros mensajes
              electrónicos entre usted y este sitio web.</p>

            <p>
              A través de aplicaciones móviles y de escritorio
              que usted descarga de este sitio web, que proporcionan una interacción en el
              navegador entre usted y este sitio web.</p>

            <p>
              Cuando interactúa con nuestra publicidad y
              aplicaciones en sitios web y servicios de terceros, si dichas aplicaciones o
              publicidad incluyen enlaces a esta política.</p>

            <p>
              Cualquier otra fuente.</p>

            <p>No se aplica a la información recopilada por:</p>

            <p >
              Nosotros fuera de línea o a través de cualquier
              otro medio, incluyendo cualquier otro sitio web operado por la compañía.</p>

            <p>
              Cualquier tercero (incluyendo nuestros afiliados y
              subsidiarios); o cualquier tercero
              (incluidas nuestras filiales y subsidiarios), incluso a través de cualquier aplicación
              o contenido (incluida la publicidad) que pueda vincularse o ser accesible desde
              o en el Sitio web.</p>

            <p>Por favor, lea esta política cuidadosamente
              para entender nuestras políticas y prácticas con respecto a su información y
              cómo la trataremos.  Si no está de acuerdo con nuestras políticas y prácticas,
              su elección es no utilizar nuestro sitio web. Al acceder o utilizar este sitio
              web, usted acepta esta política de privacidad.  Esta política puede cambiar de
              vez en cuando (consulte cambios en nuestra Política de privacidad). Su uso
              continuado de este sitio web después de que realicemos cambios se considera
              como la aceptación de dichos cambios, por lo que le rogamos que consulte la
              política periódicamente para ver si hay actualizaciones.</p>

            <br></br>

            <h3>2. Niños menores de 18 años.</h3>

            <p>Nuestro sitio web no está destinado a niños
              menores de 18 años de edad. Ninguna persona menor de 18 años puede proporcionar
              información personal a o en el sitio web. No recopilamos información personal
              de niños menores de 18 años. Si  usted es   menor de  18 años,  no  utilice
              ni  proporcione  ninguna  información  en  este sitio web  o  a través de
              cualquiera  de  sus    funciones,  registrarse  en  el  sitio web,  no podrá realizar
              ninguna cotización a través  del sitio web, ni utilizar ninguna  de  las
              funciones  interactivas  o de comentarios  públicos  de  este sitio web,  ni  proporcionarnos
              información  sobre  usted,   incluido  su  nombre, dirección,  número de
              teléfono, dirección de correo electrónico  o  cualquier  nombre  de pantalla
              o  nombre  de usuario que pueda utilizar. Si nos enteramos de que hemos
              recopilado o recibido información personal de un niño menor de 18 años sin la
              verificación o el consentimiento de los padres, eliminaremos esa información.
              Si cree que podríamos tener alguna información de o sobre un niño menor de 18
              años, póngase en contacto con nosotros en nuestro sitio web.</p>

            <p>Los residentes de California menores de 16
              años de edad pueden tener derechos adicionales con respecto a la recopilación y
              venta de su información personal.  Consulte Sus derechos de privacidad de
              California para obtener más información.</p>

            <br></br>

            <h3>3. Información que recopilamos sobre usted
              y cómo la recopilamos.</h3>

            <p>Recopilamos varios tipos de información de y
              sobre los usuarios en nuestro sitio web, incluida la información:  </p>

            <p>
              Por el cual usted puede ser identificado
              personalmente, como nombre, dirección, código postal, correo electrónico,
              número de teléfono, número de seguro social u otra información similar
              (información personal). </p>

            <p>
              Sobre usted, pero individualmente no lo identifica.</p>

            <p>
              Acerca de su conexión a Internet, el equipo que
              utiliza para acceder a nuestro sitio web, y los detalles de uso. </p>

            <p>Recopilamos esta información:</p>

            <p>
              Directamente de usted cuando nos lo proporciona. </p>

            <p>
              Automáticamente a medida que navega por el sitio.
              La información recopilada automáticamente puede incluir detalles de uso,
              direcciones IP e información recopilada a través de cookies, beacom web y otras
              tecnologías de seguimiento. </p>

            <p>
              De terceros, por ejemplo, de nuestros socios
              comerciales. </p>

            <br></br>

            <h3>4. Información que usted nos proporciona.</h3>

            <p>La información que recopilamos en o a través
              de nuestro sitio web puede incluir:</p>

            <p>
              Información que usted proporciona rellenando
              formularios en nuestro Sitio Web. Esto incluye la información proporcionada en
              el momento de registrarse para utilizar nuestro sitio web, suscribirse a
              nuestro servicio, publicar material o solicitar servicios adicionales. También podemos
              pedirle información cuando participa en un concurso o promoción patrocinada por
              nosotros, y cuando informa de un problema con nuestro sitio web.  </p>

            <p>
              Registros y copias de su correspondencia (incluidas
              las direcciones de correo electrónico), si se comunica con nosotros.</p>

            <p>
              Sus respuestas a encuestas que podríamos pedirle
              que complete con fines de investigación. </p>

            <p>
              Detalles de las transacciones que realiza a través
              de nuestro Sitio Web y del cumplimiento de sus pedidos. Es posible que se le
              solicite que proporcione información financiera antes de realizar un pedido a
              través de nuestro sitio web.</p>

            <p>
              Sus consultas de búsqueda en el sitio web.</p>

            <p>También puede proporcionar información para
              ser publicada o mostrada (más adelante publicada) en áreas públicas del Sitio
              Web, o transmitida a otros usuarios del Sitio web o a terceros (colectivamente,
              Contribuciones del Usuario).  Sus Contribuciones de Usuario se publican y se
              transmiten a otros bajo su propio riesgo. Aunque limitamos el acceso a ciertas
              páginas, tenga en cuenta que ninguna medida de seguridad es perfecta o impenetrable.
              Además, no podemos controlar las acciones de otros usuarios del Sitio web con
              los que puede optar por compartir sus Contribuciones de usuario. Por lo tanto,
              no podemos y no garantizamos que sus Contribuciones de Usuario no serán vistas
              por personas no autorizadas.    </p>

            <br></br>

            <h3>5. Información que recopilamos a través de
              tecnologías de recopilación automática de datos.</h3>

            <p>A medida que navega e interactúa con nuestro
              sitio web, podemos utilizar tecnologías de recopilación automática de datos
              para recopilar cierta  información sobre  su  equipo, acciones de  navegación y
              patrones, que incluyen:</p>

            <p>
              Detalles de sus visitas a nuestro sitio web,
              incluidos datos de  tráfico, datos de ubicación,  registros y  otros datos de
              comunicación  y los recursos a los  que  accede  y utiliza  en  el sitio web.</p>

            <p>
              Información sobre su ordenador y conexión a
              Internet, incluyendo su dirección IP, sistema operativo y tipo de navegador. </p>

            <p>También, podemos utilizar estas tecnologías
              para recopilar información sobre  sus actividades en línea  a lo largo del
              tiempo y a través de sitios web de terceros  u  otros servicios en línea
              (seguimiento del comportamiento).   Consulte nuestro sitio web para obtener más
              información  sobre  cómo  puede  optar por no participar en el seguimiento
              del  comportamiento en  este  sitio web  y cómo respondemos a las señales  del
              navegador web  y  otros  mecanismos  que  permiten  a los consumidores  elegir
              sobre el seguimiento del comportamiento.  </p>

            <p>La información que recopilamos automáticamente
              incluye información personal que podemos    mantenerla o asociarla con
              información personal que recopilamos   de otras  maneras  o  recibimos  de
              terceros. Nos ayuda a mejorar nuestro sitio web y  a  ofrecer un  servicio
              mejor y más personalizado, incluso  al  permitirnos: </p>

            <p>
              Estimar el tamaño de nuestra audiencia y los
              patrones de uso. </p>

            <p>
              Almacenar información sobre sus preferencias, lo
              que nos permite personalizar nuestro sitio web de acuerdo con sus intereses
              individuales. </p>

            <p>
              Acelerar búsquedas.</p>

            <p>
              Reconocerle cuando regrese a nuestro sitio web.</p>

            <p>Las tecnologías que utilizamos para esta
              recopilación automática de datos pueden incluir:</p>

            <p>
              Cookies (o cookies del navegador). Una cookie es un
              pequeño archivo que se coloca en  el  disco  duro de  su  ordenador. Puede
              negarse a aceptar las cookies del navegador  activando la  configuración
              apropiada  en  su  navegador. Sin embargo, si selecciona esta  configuración,
              es  posible que no  pueda  acceder  a  ciertas  partes  de  nuestro sitio web.
              A menos  que  haya  ajustado la configuración  de su  navegador para que
              rechace las  cookies, nuestro sistema emitirá  cookies  cuando  dirija  su
              navegador  a  nuestro sitio web.</p>

            <br></br>

            <p>
              Cookies flash. Ciertas funciones  de  nuestro sitio
              web  pueden utilizar objetos almacenados  locales  (o  cookies flash)  para
              recopilar y almacenar  información  sobre  sus  preferencias  y  navegación
              hacia,  desde y  en  nuestro sitio web. Las cookies flash no se gestionan
              mediante la misma configuración  del  navegador que se utiliza para  las
              cookies del navegador. Para obtener  información  sobre  cómo administrar  su
              configuración de  privacidad  y  seguridad  para las cookies flash. Consulte opciones
              sobre  cómo  usamos y  divulgamos  su  información. </p>

            <p>
              Beacons web. Las páginas de nuestro  sitio web
              pueden  contener  pequeños  archivos  electrónicos conocidos como Beacons
              web    (también  conocidos  como  gifs transparentes, etiquetas de píxeles y
              gifs de un solo píxel) que  permiten a  la Compañía, por  ejemplo,  contar  los
              usuarios  que  han  visitado  esas  páginas  o  han abierto un correo
              electrónico  y  para  otras  estadísticas  relacionadas con el sitio web (por
              ejemplo,  registrar  la  popularidad de cierto contenido del sitio web  y
              verificar  integridad del sistema y del servidor ).</p>

            <p>
              Google Analytics. Nuestro sitio web utiliza una
              herramienta  llamada  Google Analytics,  que  recopila  información del usuario
              con respecto a  las  actividades  que los usuarios realizan mientras están  en
              el sitio web. Google  Analytics  recopila  información  como  la frecuencia
              con la que los usuarios  visitan  este sitio,  qué  páginas  visitan  cuando lo
              hacen  y  qué  otros  sitios  utilizaron    antes  de llegar a    este  sitio.
              Utilizamos  la  información  que obtenemos  de  Google  Analytics  para
              mejorar  este  sitio  y  nuestros  esfuerzos de divulgación. Google Analytics
              recopila  solo  la dirección  IP  asignada  a  usted en la  fecha  en que
              visita  este  sitio,  en lugar  de  su  nombre  u  otra  información de
              identificación.  Aunque  Google  Analytics  planta  una  cookie  permanente en
              su navegador web  para  identificarlo    como un  usuario único  la  próxima
              vez  que  visite  este  sitio,  la  cookie no puede  ser  utilizada  por  nadie
              más que  Google. La  capacidad de Google para utilizar y compartir la
              información  recopilada  por  Google  Analytics  sobre  sus  visitas  a  este
              sitio  está  restringida  por  las  Condiciones de uso de Google  Analytics  y
              la  Política de privacidad de  Google. Puede  evitar que  Google  Analytics
              lo  reconozca  en  las visitas  que regresan  a  este  sitio  deshabilitando
              las  cookies  en  su  navegador.</p>

            <p>
              Google Ads y Remarketing. Utilizamos  una
              herramienta  llamada  "Google  Ads"  para  recopilar  información
              sobre  el  uso  de  este  sitio y utilizamos  esta  información  para
              ofrecerle  anuncios  más  relevantes ("Retargeting").  Google y
              otros  terceros    pueden  mostrar  estos  anuncios  en  otros  sitios web que
              visite o  en los servicios  que utilice. Estos anuncios que se muestran en
              otros sitios  web  se  basan  en  sus  visitas  anteriores  a  este  sitio
              mediante  el uso de  cookies.   Puede optar por no participar en   el uso de
              cookies  por  parte  de   Google  visitando la página configuración de
              anuncios  de Google.  </p>

            <p>
              Píxel de seguimiento de Facebook. Podemos
              utilizar  el  "píxel de seguimiento de conversiones"  proporcionado
              por  Facebook  para  registrar la eficacia de los  anuncios  de  Facebook  y
              para  fines  de investigación de  mercado  adicionales. Esta herramienta  nos
              permite    recopilar  información  sobre  su  actividad  después de que se  le
              redirige  al    sitio web después de hacer clic  en  un  anuncio  en  Facebook.
              Facebook o  sus  socios  pueden utilizar una cookie  para  facilitar  la
              visualización  de  anuncios. Facebook  puede  guardar  y  procesar  estos datos
              y conectarlos a  su  cuenta de acuerdo con la Política de datos de  Facebook. </p>

            <p>
              Scripts incrustados. Un script  incrustado es un
              código de programación  que  está  diseñado  para  recopilar  información
              sobre  sus  interacciones  con  el sitio   web,  como los enlaces  en los que
              hace  clic.  El  código  se  descarga  temporalmente  en  su  dispositivo desde
              nuestro servidor web  o  un proveedor de   servicios   externo,   está  activo
              solo  mientras  está conectado a nuestro  sitio y  se  desactiva  o  elimina
              a  partir de entonces.</p>

            <p>No recopilamos información  personal
              automáticamente,  pero  podemos vincular esta a la información personal sobre
              usted que recopilamos de  otras fuentes o que usted  nos proporcionó. </p>

            <br></br>

            <h3>6. Uso de cookies y otras tecnologías de
              seguimiento por parte de terceros.</h3>

            <p>Algunos contenidos o aplicaciones, incluidos
              los anuncios, en el Sitio Web son  servidos   por  terceros,  incluidos
              anunciantes,  redes  publicitarias y servidores, proveedores de contenido y
              proveedores de aplicaciones. Estos terceros pueden utilizar cookies solas o en
              conjunto  con beacons  web  u  otras tecnologías de seguimiento  para
              recopilar  información  sobre    usted  cuando  utiliza  nuestro sitio web. La
              información que recopilan puede estar asociada  con  su información  personal
              o pueden recopilar información, incluida información personal, sobre sus
              actividades  en línea  a lo largo del tiempo y  en  diferentes  sitios web  y
              otros servicios en   línea.    Pueden utilizar esta información para
              proporcionarle   publicidad basada en intereses (conductual) u  otro
              contenido   dirigido.</p>

            <p>No controlamos  las  tecnologías de seguimiento
              de  estos  terceros ni  cómo  se  pueden   utilizar. Si    tiene alguna
              pregunta    sobre  un  anuncio  u  otro  contenido dirigido,  debe  ponerse en
              contacto directamente con  el  proveedor responsable. Para obtener información
              sobre  cómo    puede  optar por no  recibir  publicidad  dirigida de muchos
              proveedores,  consulte  opciones  sobre  cómo  usamos y  divulgamos  su
              información. </p>

            <br></br>

            <h3>7. Cómo utilizamos su información.</h3>

            <p>Utilizamos la información  que  recopilamos
              sobre  usted o que  usted nos proporciona, incluida  cualquier información
              personal: </p>

            <p>
              Para presentarle nuestro sitio web  y  sus
              contenidos. </p>

            <p>
              Para proporcionarle información, productos o
              servicios que nos solicite. </p>

            <p>
              Para cumplir con cualquier otro propósito para el
              cual  usted  lo proporciona. </p>

            <p>
              Para comunicarnos con  usted  sobre  su  pedido,
              compra,  cuenta  o  suscripción.</p>

            <p>
              Para llevar a cabo nuestras  obligaciones  y  hacer
              cumplir  nuestros  derechos  derivados  de  cualquier  contrato  celebrado
              entre  usted  y  nosotros,  incluyendo  para  la facturación  y el cobro.</p>

            <p>
              Para notificarle sobre cambios importantes  en
              nuestro  sitio web  o  cualquier  producto  o  servicio que ofrecemos  o
              proporcionamos a través de él.</p>

            <p>
              Para operar,  mantener,  mejorar,  personalizar y
              analizar  los  productos  o  servicios que ofrecemos.</p>

            <p>
              Para monitorear y  analizar  tendencias,  uso y
              actividades con fines  de marketing  o    publicidad.</p>

            <p>
              Para  detectar,  prevenir  o  investigar
              violaciones de seguridad, fraude y  otras actividades  no autorizadas  o
              ilegales.</p>

            <p>
              Para permitirle participar en funciones
              interactivas  en  nuestro  sitio web.  </p>

            <p>
              Enviar comunicaciones promocionales, tales  como
              información  sobre  características,  boletines informativos,  ofertas,
              promociones,  concursos y  eventos.</p>

            <p>
              Compartir información entre los  productos  y
              dispositivos de la  compañía  para  proporcionar una experiencia  más
              personalizada  y  consistente  en todos los productos de la compañía  que
              utiliza.</p>

            <p>
              Desarrollar, probar y  mejorar  nuevos  productos
              o  servicios,  incluyendo  la  realización de  encuestas  e  investigación, la
              prueba y resolución de problemas de  nuevos  productos  y  características.</p>

            <p>
              De cualquier  otra  manera,    podemos  describir
              cuándo  usted  proporciona  la  información.</p>

            <p>
              Para cualquier  otro  fin  con  su  consentimiento.</p>

            <p>También podemos utilizar su información  para
              ponernos en contacto con  usted acerca de nuestros propios bienes  y
              servicios  y de  terceros que  puedan  ser  de su interés.  Para obtener más
              información,  consulte  opciones  sobre  cómo  usamos  y  divulgamos  su
              información.</p>

            <p>Podemos  utilizar la  información  que  hemos
              recopilado  de  usted    para  permitirnos  mostrar  anuncios  a las audiencias
              objetivo de  nuestros anunciantes.   A pesar de que  no divulgamos su
              información  personal  para  estos  fines  sin  su  consentimiento,  si  hace
              clic  o   interactúa con  un  anuncio,  el  anunciante  puede  asumir  que
              cumple  con  sus criterios de destino. </p>

            <p>No vendemos,  alquilamos  o  arrendamos  nuestras
              listas de clientes a terceros.</p>

            <br></br>

            <h3>8. Divulgación de su información.</h3>

            <p>Podemos  divulgar  información  agregada  o
              anonimizada  sobre  nuestros  usuarios  sin  ninguna  restricción.  No
              compartiremos  su información personal que recopilemos o  que  usted  proporcione
              como  se describe  en  esta  Política,  excepto  en  las  siguientes
              circunstancias:</p>

            <p>
              Con subsidiarios y afiliados para  fines
              comerciales.  A nuestras subsidiarias  y compañías afiliadas para   fines  de
              gestión  y  análisis,  toma de decisiones y  otros  fines comerciales. </p>

            <p>
              Cuando trabajamos con proveedores de servicios. A
              proveedores de servicios,  contratistas y  otros  terceros que  nos
              proporcionan  servicios de soporte, como procesamiento de    tarjetas de
              crédito,  alojamiento de  sitios web, correo electrónico y entrega postal,
              mapeo de ubicación,  entrega de productos  y   servicios o  servicios de
              análisis, y  que  están obligados    por obligaciones  contractuales a mantener
              la confidencialidad  de la información personal y usarla solo para  el  fines
              para los que  se  lo  revelamos.    </p>

            <p>
              Cuando nos  involucramos    en una venta  o
              transferencia  de  la  Compañía. Si  nos involucramos  con  una  fusión,
              desinversión,  reestructuración,  reorganización,  disolución  u  otra  venta
              o  transferencia  de  algunos  o todos los  activos  de  la  Compañía  (ya sea
              como una empresa en marcha  o  como  parte  de  la  quiebra,  liquidación  o
              procedimiento similar), a  entidades  comerciales  o  personas  involucradas
              en  la  negociación  o  transferencia.</p>

            <p>
              Cuando estamos  obligados  por  ley. Para cumplir
              con  cualquier  orden judicial,   ley o proceso   legal,   incluyendo  para
              responder  a  cualquier  solicitud gubernamental  o    regulatoria. </p>

            <p>
              Cuando hacemos  valer  nuestros  derechos. Para
              hacer cumplir  o  aplicar  esta  Política,  nuestros  Términos  de uso y otros
              acuerdos, incluso para fines de facturación  y cobro.</p>

            <p>
              Para ayudar a  proteger  los intereses legítimos.
              Si  creemos  que la divulgación  nos  ayudará  a  proteger los derechos,  la
              propiedad  o la seguridad  de  la  Compañía,  nuestros   usuarios,  socios,
              agentes y  otros. Esto  incluye el intercambio de  información  con  otras
              empresas  y  organizaciones  para la protección contra el fraude, y la
              prevención de spam y malware. </p>

            <p>
              Para cumplir con  la  finalidad  de  dicha
              información  o  con  su  consentimiento. Para  cumplir con  la  finalidad  para
              la que  usted  proporciona  la  información,  para  cualquier  otro  propósito
              divulgado  por  nosotros  cuando  usted  proporciona  la  información,  o  con
              su  consentimiento.</p>

            <p>
              Cuando trabajamos con proveedores de servicios de
              marketing.  A los proveedores de  servicios de marketing  para  evaluar,
              desarrollar y proporcionarle  promociones  y  ofertas especiales  que  puedan
              interesarle,   administrar  concursos,  sorteos y  eventos,  o  para  otros
              fines promocionales. </p>

            <p>
              Cuando trabajamos  con  socios comerciales.  A
              terceros  con los  que tenemos alianzas o  asociaciones  estratégicas de
              marketing  con el  fin de proporcionarle información  sobre  productos  y
              servicios que  creemos  que  serán  de su interés. </p>

            <p>
              Cualquier otro tipo de divulgaciones de terceros. </p>

            <p>La información que usted publica en o a través
              de las áreas públicas de los Servicios  (por ejemplo, salas de  chat,  tablones
              de anuncios y  grupos de discusión) es  generalmente  accesible  para y  puede
              ser  recopilada  y  utilizada  por  otros  y  puede  resultar  en  mensajes no
              solicitados  u  otro  contacto.   Se recomienda a los usuarios de los
              Servicios  que  tengan  cuidado  al  proporcionar información  personal  sobre
              sí mismos  en  áreas públicas  o  interactivas. </p>

            <p>"No Rastrear" es una  preferencia de
              privacidad  que puede  establecer en la mayoría de  los navegadores web. Cuando
              activa esta  preferencia,  envía    una  señal  o  mensaje  a  los sitios web
              que visita  indicando  que no  desea  ser  rastreado. Tenga en cuenta  que
              respetamos la configuración del navegador  “No rastrear” . Para obtener más
              información  sobre “ No rastrear”,  visite www.allaboutdnt.org.</p>

            <br></br>

            <h3>9. Opciones sobre cómo usamos y divulgamos
              su información.</h3>

            <p>Nos esforzamos  por  ofrecerle  opciones  con
              respecto a  la información  personal  que    nos proporciona.  Hemos creado
              mecanismos  para   proporcionarle el  siguiente  control  sobre  su
              información:</p>

            <p>
              Cookies y otras tecnologías  de seguimiento. Es
              posible  que  pueda    configurar  su  navegador para que  rechace las cookies
              y otras  tecnologías  ajustando  la configuración  apropiada  en  su
              navegador. Cada  navegador  es diferente,  pero  muchos  navegadores  comunes
              tienen  preferencias  que  pueden  ajustarse para  permitirle aceptar o
              rechazar las cookies y  ciertas  otras  tecnologías  antes de que se
              establezcan  o  instalen, o  permitirle  eliminar  o  rechazar el uso  o  la
              instalación  de  ciertas  tecnologías por completo.  Le recomendamos que consulte
              el menú Ayuda de su navegador para saber cómo modificar la configuración de su
              navegador. Tenga en cuenta que no puede  eliminar  las cookies flash
              simplemente  cambiando la configuración de su  navegador.  Para obtener
              información sobre  cómo  puede   administrar  la configuración de cookies de
              Flash,   visite  la  página  de configuración de Flash  Player en el sitio web de
              Adobe.  Si  desactiva o rechaza las  cookies,  tenga  en cuenta  que  algunas
              partes  de  los  Servicios  pueden  volverse  inaccesibles  o  no funcionar
              correctamente.</p>

            <p>
              Comunicaciones promocionales de la Compañía. Si
              no  desea que su información de  contacto sea utilizada  por  la  Compañía
              para  promocionar productos o servicios propios  o  de terceros,   puede  optar
              por no participar  <b>(i)</b>  informándonos  de  sus  preferencias  en  el
              momento  en que se  registra  para obtener una  cuenta, completar un formulario
              de pedido,   suscribirse  a    nuestro  boletín informativo o  completar
              cualquier  otro  formulario  en  o a través  de los  Servicios  que
              recopilamos sus  datos; <b>(ii)</b> modificar sus  preferencias   de  usuario
              en el perfil de su  cuenta  marcando o desmarcando  las casillas
              correspondientes;  <b>(iii)</b>  siguiendo  las  instrucciones de exclusión
              voluntaria en  los correos electrónicos  promocionales que le enviamos;  o <b>(iv)</b>
              enviándonos  un correo electrónico  indicando  su  solicitud. Tenga en cuenta
              que si  opta  por no recibir  comunicaciones  promocionales  de  nuestra
              parte, aún  podemos  enviarle  comunicaciones transaccionales, incluidos
              correos electrónicos  sobre  su  cuenta  o  compras.</p>

            <p>
              Divulgación de su información  para  publicidad  de
              terceros. Si no  desea que       compartamos  su información  personal con
              terceros no afiliados  o no agentes,con  fines promocionales,  puede optar
              por no participar  <b>(i)</b>  informándonos de sus  preferencias  en  el
              momento en que  se  registra  para obtener una  cuenta, completar un formulario
              de  pedido,   suscribirse  a    nuestro  boletín informativo o  completar
              cualquier  otro  formulario  en  o a través de los  Servicios  que  recopilamos
              sus datos; <b>(ii)</b> modificar sus  preferencias   de  usuario  en el perfil
              de su  cuenta  marcando o  desmarcando  las casillas correspondientes;  <b>(iii)</b>
              siguiendo  las  instrucciones de exclusión voluntaria en  los correos
              electrónicos  promocionales que le enviamos; o <b>(iv)</b>  enviándonos un
              correo electrónico  indicando  su solicitud.</p>

            <p>
              Publicidad dirigida. Si no  desea que utilicemos
              la información  que recopilamos  o  que nos proporciona para ofrecer anuncios
              de acuerdo con las preferencias de público objetivo de nuestros  anunciantes,
              puede optar por no hacerlo modificando sus preferencias  de  usuario en su
              perfil  de cuenta  marcando o desmarcando  las  casillas  correspondientes   o
              enviándonos  un correo electrónico  indicando su solicitud. No controlamos la
              recopilación o el uso  de su  información por parte de terceros para servir
              publicidad basada en  intereses.  Sin embargo, estos  terceros  pueden
              proporcionarle  formas de  elegir que su  información  no sea recopilada  o
              utilizada   de  esta  manera. Muchas  compañías   de  publicidad  que
              recopilan  información  para  publicidad basada en intereses  son  miembros
              de  la   Digital  Advertising Alliance o  la  Network  Advertising
              Initiative,  las cuales  mantienen  sitios web  donde  las personas  pueden
              optar por no recibir publicidad basada en  intereses  de    sus  miembros.
              Para  darse de baja en  estas  páginas,  visite www.AboutAds.info y
              www.networkadvertising.org.</p>

            <p>
              Información de ubicación. Es  posible que  pueda
              ajustar la configuración  de  su  dispositivo   para que  la  información
              sobre  su  ubicación  física  no  se  envíe a nosotros  o a terceros  mediante <b>(a)</b>
              la  desactivación de  los servicios de ubicación  dentro de la configuración
              del  dispositivo;  o  <b>(b)  </b>denegar a ciertos sitios web  o
              aplicaciones  móviles el permiso para acceder  a la información  de  ubicación
              cambiando las preferencias  y  permisos  pertinentes  en  la configuración de
              su  dispositivo  móvil  o  navegador. Tenga  en cuenta  que  su  ubicación
              puede  derivarse  de  su  WiFi, Bluetooth y  otros  ajustes del dispositivo.
              Si  tienes  preguntas  sobre cómo desactivar los servicios de ubicación de tu
              dispositivo, te recomendamos que te pongas en contacto con tu proveedor de
              servicios  móviles  o con el fabricante de tu dispositivo. </p>

            <p>
              Transmisiones de voz. Es  posible  que  pueda
              ajustar  la  configuración de su  dispositivo   para que  sus  transmisiones
              de  voz  no puedan enviarse a nosotros o a terceros  mediante <b>(a)</b> la
              desactivación de  los servicios de reconocimiento de  voz  y micrófono dentro
              de la configuración del  dispositivo;  o  <b>(b)</b>  denegar a ciertos sitios
              web  o  aplicaciones  móviles el permiso para acceder  a  los servicios   de
              reconocimiento de micrófono  y  voz cambiando las preferencias  y  permisos
              pertinentes  en  la configuración de su  dispositivo  móvil  o  navegador. </p>

            <p>No controlamos  la  recopilación  o el uso de
              su  información por parte de terceros  para  servir publicidad basada en
              intereses. Sin embargo, estos terceros pueden  proporcionarle  formas  de
              elegir que su  información  no sea recopilada  o utilizada   de  esta  manera.
              Puede optar por no  recibir  anuncios  dirigidos  de  miembros  de  la
              Network  Advertising  Initiative  ("NAI")  en el sitio web de la
              NAI. </p>

            <p>Los residentes de California pueden  tener
              derechos  y  opciones de información  personal  adicionales. Consulte  sus
              derechos de privacidad de California  para obtener  más  información.</p>

            <br></br>

            <h3>10. Sus derechos de privacidad de
              California.</h3>

            <p>Bajo las secciones 1798.83-1798.84 del Código
              Civil de California, los residentes  de California tienen derecho a
              solicitarnos un  aviso que identifique  las  categorías de información
              personal  del cliente  que  compartimos  con  nuestros  afiliados  y  terceros
              con fines de marketing, y  proporcionar  información  de  contacto para dichos
              afiliados  y  terceros.  Si usted es residente de  California  y  desea  una  copia
              de  este  aviso,  envíe  una  solicitud por escrito  a  la  siguiente
              dirección: emilan.co, 1057 NW 107th  St,  Apt  L315, Miami, Florida 33168,
              Estados Unidos de  América.</p>

            <br></br>

            <h3>11. Transferencia de información a los
              Estados Unidos.</h3>

            <p>Nuestro sitio es operado  en  los  Estados
              Unidos  y  destinado a los usuarios ubicados en  los  Estados Unidos.  Si se
              encuentra  fuera  de  los Estados Unidos,  tenga en cuenta que la
              información  que  recopilamos,  incluida la Información personal,   se
              transferirá,    procesará,  almacenará  y  utilizará  en  los  Estados Unidos.
              Las  leyes de protección  de datos en  los Estados  Unidos  pueden  diferir de
              las del  país en el que  se  encuentra, y  su Información  personal  puede
              compartirse   con terceros   con fines de marketing  directo  y  puede  estar
              sujeta  a  solicitudes de acceso  de  gobiernos, tribunales  o fuerzas del
              orden  en  los  Estados  Unidos  de acuerdo con  las leyes  de  los  Estados
              Unidos . Al  utilizar  nuestro  sitio  o proporcionarnos cualquier
              información,  usted  acepta la transferencia y el procesamiento,  el uso,  el
              intercambio  y  el almacenamiento  de  su  información,  incluida la
              Información personal, en  los  Estados  Unidos como se  establece  en  esta
              Política de privacidad. </p>

            <br></br>

            <h3>12. Seguridad de los datos.</h3>

            <p>Tomamos  precauciones  razonables  para
              proteger  su información personal.  Hemos    implementado  medidas  diseñadas
              para  proteger  su información  personal  de la pérdida  accidental  y  del
              acceso no autorizado, uso,  alteración y  divulgación. Toda la información
              que    nos  proporciona  se  almacena  en  nuestros  servidores  seguros detrás
              de  firewalls. Cualquier  transacción de  pago  será.  </p>

            <p>La seguridad  de su  información  también
              depende  de  usted. Cuando  le hayamos dado  (o  donde  haya  elegido) una
              contraseña  para  acceder  a  ciertas  partes de nuestro sitio web,  usted  es
              responsable  de  mantener  esta  contraseña  confidencial. Le pedimos  que  no
              comparta  su  contraseña  con  nadie. Le invitamos a que  tenga  cuidado  al
              dar información  en  áreas públicas  del  sitio web  como  tableros de
              mensajes. La  información  que  comparta en las áreas públicas  puede  ser
              vista  por  cualquier  usuario del  Sitio Web.</p>

            <br></br>

            <p>Desafortunadamente, la  transmisión  de
              información a través  de  Internet  no es  completamente  segura. Aunque
              hacemos  todo lo posible  para  proteger  su información personal, no podemos
              garantizar  la  seguridad  de  su información  personal  transmitida  a
              nuestro sitio web. Cualquier transmisión de información personal es  bajo  su
              propio  riesgo. No  somos  responsables  de la elusión de cualquier
              configuración  de  privacidad  o  medidas de  seguridad  contenidas  en  el
              sitio web.</p>

            <br></br>

            <h3>13. Cambios en nuestra política de
              privacidad.</h3>

            <p>Es nuestra  responsabilidad publicar
              cualquier  cambio que hagamos a  nuestra  política de privacidad  en  esta
              página  con  un  aviso de que la política  de  privacidad se  ha  actualizado  en
              la página de inicio del sitio web. Si realizamos cambios materiales en la forma
              en que tratamos   la información personal de  nuestros  usuarios, se lo
              notificaremos  por  correo electrónico  a  la dirección de correo electrónico
              principal  especificada  en  su  cuenta  o a través de  un  aviso  en la página
              de inicio del sitio web.  La fecha en que se revisó por última vez la política
              de privacidad  se  identifica  en  la  parte superior  de  la  página. Usted
              es  responsable  de asegurarse de  que tenemos una dirección de correo
              electrónico activa y entregable actualizada  para  usted, y  de  visitar
              periódicamente  nuestro  sitio web  y  esta  política de  privacidad  para
              verificar    cualquier  cambio.</p>

            <br></br>

            <h3>14. Condiciones de uso.</h3>

            <p>Si decide visitar los Servicios, su visita  y
              cualquier  disputa  sobre  privacidad  está  sujeta  a  esta  Política  y
              nuestros  Términos de uso, incluidas las limitaciones  de daños,  la
              resolución  de  disputas y la aplicación  de  la  ley aplicable. </p>

            <br></br>

            <h3>15. Información de contacto.</h3>

            <p>Para hacer preguntas o comentarios sobre esta
              política de privacidad  y nuestras  prácticas de privacidad, contáctenos en:</p>

            <h3>Correo electrónico: </h3>
            <p>info@emilan.co</p>

            <h3>Dirección: emilan.co</h3>
            <p>8300 NW 53 ST, Ste 102, Miami, FL 33166, United States of America.</p>

            <h3>Teléfono: (954)</h3>
            <p>698-2058</p>`;
  let en = ` Effective Date: March 26, 2021

          Last Reviewed: March 26, 2021

          <h3>1. Introduction.</h3>
          <p>
            Emilan. Co (“Company” or “We”) respects your privacy and are committed to protecting it through our compliance with this policy.
          </p><p>
            This policy describes the types of information we may collect from you or that you may provide when you visit the website https://www.emilan.co (our “Website”) and our practices for collecting, using, maintaining, protecting, and disclosing that information.
          </p><p>
            This policy applies to information we collect:
          </p><p>
            On this Website.
          </p><p>
            In email, text, and other electronic messages between you and this Website.
          </p><p>
            Through mobile and desktop applications you download from this Website, which provide dedicated non-browser-based interaction between you and this Website.
          </p><p>
            When you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this policy.
          </p><p>
            Any other sources.
          </p><p>
            It does not apply to information collected by:
          </p><p>
            Us offline or through any other means, including on any other website operated by Company or any third party (including our affiliates and subsidiaries); or
          </p><p>
            Any third party (including our affiliates and subsidiaries), including through any application or content (including advertising) that may link to or be accessible from or on the Website.
          </p><p>
            Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website. By accessing or using this Website, you agree to this privacy policy. This policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of this Website after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.
          </p><p>
            <h3>2. Children Under the Age of 18.</h3>
          </p><p>
            Our Website is not intended for children under 18 years of age. No one under age 18 may provide any personal information to or on the Website. We do not knowingly collect personal information from children under 18. If you are under 18, do not use or provide any information on this Website or through any of its features, register on the Website, make any purchases through the Website, use any of the interactive or public comment features of this Website, or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use. If we learn we have collected or received personal information from a child under 18 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 18, please contact us at our website.
          </p><p>
            California residents under 16 years of age may have additional rights regarding the collection and sale of their personal information. Please see Your California Privacy Rights for more information.
          </p><p>
            <h3>3. Information We Collect About You and How We Collect It.</h3>
          </p><p>
            We collect several types of information from and about users of our Website, including information:
          </p><p>
            By which you may be personally identified, such as name, postal address, e-mail address, telephone number, social security number or other similar information (“personal information”);
          </p><p>
            That is about you but individually does not identify you; and
          </p><p>
            About your internet connection, the equipment you use to access our Website, and usage details.
          </p><p>
            We collect this information:
          </p><p>
            Directly from you when you provide it to us.
          </p><p>
            Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.
          </p><p>
            From third parties, for example, our business partners.
          </p><p>
            <h3>4. Information You Provide to Us.</h3>
          </p><p>
            The information we collect on or through our Website may include:
          </p><p>
            Information that you provide by filling in forms on our Website. This includes information provided at the time of registering to use our Website, subscribing to our service, posting material, or requesting further services. We may also ask you for information when you enter a contest or promotion sponsored by us, and when you report a problem with our Website.
          </p><p>
            Records and copies of your correspondence (including email addresses), if you contact us.
          </p><p>
            Your responses to surveys that we might ask you to complete for research purposes.
          </p><p>
            Details of transactions you carry out through our Website and of the fulfillment of your orders. You may be required to provide financial information before placing an order through our Website.
          </p><p>
            Your search queries on the Website.
          </p><p>
            You also may provide information to be published or displayed (hereinafter, “posted”) on public areas of the Website, or transmitted to other users of the Website or third parties (collectively, “User Contributions”). Your User Contributions are posted on and transmitted to others at your own risk. Although we limit access to certain pages, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users of the Website with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.
          </p><p>
            <h3>5. Information We Collect Through Automatic Data Collection Technologies.</h3>
          </p><p>
            As you navigate through and interact with our Website, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:
          </p><p>
            Details of your visits to our Website, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Website.
          </p><p>
            Information about your computer and internet connection, including your IP address, operating system, and browser type.
          </p><p>
            We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking). Please see our Website for information on how you can opt-out of behavioral tracking on this website and how we respond to web browser signals and other mechanisms that enable consumers to exercise choice about behavioral tracking.
          </p><p>
            The information we collect automatically includes personal information we may maintain it or associate it with personal information we collect in other ways or receive from third parties. It helps us to improve our Website and to deliver better and more personalized service, including by enabling us to:
          </p><p>
            Estimate our audience size and usage patterns.
          </p><p>
            Store information about your preferences, allowing us to customize our Website according to your individual interests.
          </p><p>
            Speed up your searches.
          </p><p>
            Recognize you when you return to our Website.
          </p><p>
            The technologies we use for this automatic data collection may include:
          </p><p>
            Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.
          </p><p>
            Flash Cookies. Certain features of our Website may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, see Choices About How We Use and Disclose Your Information.
          </p><p>
            Web Beacons. Pages of our Website may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).
          </p><p>
            Google Analytics. Our website uses a tool called Google Analytics, which collects user information regarding the activities that users engage in while on the website. Google Analytics collects information such as how often users visit this site, what pages they visit when they do so, and what other sites they used prior to coming to this site. We use the information we get from Google Analytics to improve this site and our outreach efforts. Google Analytics collects only the IP address assigned to you on the date you visit this site, rather than your name or other identifying information. Although Google Analytics plants a permanent cookie on your web browser to identify you as a unique user the next time you visit this site, the cookie cannot be used by anyone but Google. Google’s ability to use and share information collected by Google Analytics about your visits to this site is restricted by the Google Analytics Terms of Use and the Google Privacy Policy. You can prevent Google Analytics from recognizing you on return visits to this site by disabling cookies on your browser.
          </p><p>
            Google Ads & Remarketing. We use a tool called “Google Ads” to collect information about the use of this site and use this information to serve you more relevant advertisements (“Retargeting”). Google and other third parties may show these advertisements on other websites that you visit or services you use. These ads shown on other web sites are based on your past visits to this site by using cookies. You may opt-out of Google’s use of cookies by visiting Google’s Ads Settings page.
          </p><p>
            Facebook Tracking Pixel. We may use the “conversion tracking pixel” provided by Facebook to record the efficacy of Facebook’s advertisements and for additional market research purposes. This tool allows us to gather information about your activity after you are redirected to the website after clicking on an advertisement on Facebook. Facebook or its partners may use a cookie to facilitate the display of advertisements. Facebook may save and process this data and connect it to your account in accordance with Facebook’s Data Policy.
          </p><p>
            Embedded Scripts. An embedded script is programming code that is designed to collect information about your interactions with the website, such as the links you click on. The code is temporarily downloaded onto your Device from our web server or a third-party service provider, is active only while you are connected to our site, and is deactivated or deleted thereafter.
          </p><p>
            We do not collect personal information automatically, but we may tie this information to personal information about you that we collect from other sources or you provide to us.
          </p><p>
            <h3>6. Third-Party Use of Cookies and Other Tracking Technologies.</h3>
          </p><p>
            Some content or applications, including advertisements, on the Website are served by third-parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our website. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.
          </p><p>
            We do not control these third parties’ tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt-out of receiving targeted advertising from many providers, see Choices About How We Use and Disclose Your Information.
          </p><p>
            <h3>7. How We Use Your Information.</h3>
          </p><p>
            We use information that we collect about you or that you provide to us, including any personal information:
          </p><p>
            To present our Website and its contents to you.
          </p><p>
            To provide you with information, products, or services that you request from us.
          </p><p>
            To fulfill any other purpose for which you provide it.
          </p><p>
            To communicate with you about your order, purchase, account, or subscription.
          </p><p>
            To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.
          </p><p>
            To notify you about important changes to our Website or any products or services we offer or provide though it.
          </p><p>
            To operate, maintain, improve, personalize, and analyze the products or services we offer or provide;
          </p><p>
            To monitor and analyze trends, usage, and activities for marketing or advertising purposes;
          </p><p>
            To detect, prevent, or investigate security breaches, fraud, and other unauthorized or illegal activity;
          </p><p>
            To allow you to participate in interactive features on our Website.
          </p><p>
            Send promotional communications, such as information about features, newsletters, offers, promotions, contests, and events;
          </p><p>
            Share information across the Company’s products and devices to provide a more tailored and consistent experience on all Company products you use;
          </p><p>
            Develop, test and improve new products or services, including by conducting surveys and research, and testing and troubleshooting new products and features;
          </p><p>
            In any other way, we may describe when you provide the information.
          </p><p>
            For any other purpose with your consent.
          </p><p>
            We may also use your information to contact you about our own and third-parties’ goods and services that may be of interest to you. For more information, see Choices About How We Use and Disclose Your Information.
          </p><p>
            We may use the information we have collected from you to enable us to display advertisements to our advertisers’ target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.
          </p><p>
            We do not sell, rent or lease our customer lists to the third party.
          </p><p>
            <h3>8. Disclosure of Your Information.</h3>
          </p><p>
            We may disclose aggregated or anonymized information about our users without any restrictions. We will not share your personal information that we collect or you provide as described in this Policy except in the following circumstances:
          </p><p>
            With subsidiaries and affiliates for business purposes. To our subsidiaries and affiliated companies for purposes of management and analysis, decision-making, and other business purposes.
          </p><p>
            When we work with service providers. To service providers, contractors, and other third parties that provide us with support services, such as credit card processing, website hosting, email and postal delivery, location mapping, product and service delivery, or analytics services, and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.
          </p><p>
            When we become involved in a sale or transfer of the Company. If we become involved with a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of the Company’s assets (whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding), to business entities or people involved in the negotiation or transfer.
          </p><p>
            When we are required by law. To comply with any court order, law, or legal process, including to respond to any government or regulatory request.
          </p><p>
            When we enforce our rights. To enforce or apply this Policy, our Terms of Use, and other agreements, including for billing and collection purposes.
          </p><p>
            To help protect lawful interests. If we believe disclosure will help us protect the rights, property, or safety of the Company, our users, partners, agents, and others. This includes exchanging information with other companies and organizations for fraud protection, and spam and malware prevention.
          </p><p>
            To fulfill the purpose for that information or with your consent. To fulfill the purpose for which you provide the information, for any other purpose disclosed by us when you provide the information, or with your consent.
          </p><p>
            When we work with marketing service providers. To marketing service providers to assess, develop, and provide you with promotions and special offers that may interest you, administer contests, sweepstakes, and events, or for other promotional purposes.
          </p><p>
            When we work with business partners. To third parties whom we have strategic marketing alliances or partnerships with for the purpose of providing you information regarding products and services that we think will be of interest to you.
          </p><p>
            Any other types of third-party disclosures.
          </p><p>
            Information that you post on or through the public areas of the Services (e.g., chat rooms, bulletin boards, and discussion groups) are generally accessible to, and may be collected and used by, others and may result in unsolicited messages or other contact from others. Users of the Services are encouraged to exercise caution when providing personal information about themselves in public or interactive areas.
          </p><p>
          </p><p>
          </p><p>
            “Do Not Track” is a privacy preference you can set in most web browsers. When you turn on this preference, it sends a signal or message to the websites you visit indicating that you do not wish to be tracked. Please note that we honor Do Not Track browser settings. For more information about Do Not Track, please visit www.allaboutdnt.org.
          </p><p>
            <h3>9. Choices About How We Use and Disclose Your Information.</h3>
          </p><p>
            We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:
          </p><p>
            Cookies & Other Tracking Technologies. You may be able to set your browser to reject cookies and certain other technologies by adjusting the appropriate settings in your browser. Each browser is different, but many common browsers have preferences that may be adjusted to allow you to either accept or reject cookies and certain other technologies before they are set or installed, or allow you to remove or reject the use or installation of certain technologies altogether. We recommend that you refer to the Help menu in your browser to learn how to modify your browser settings. Please note that you cannot remove Flash cookies simply by changing your browser settings. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe’s website. If you disable or refuse cookies, please note that some parts of the Services may become inaccessible or may not function properly.
          </p><p>
            Promotional Communications from the Company. If you do not wish to have your contact information used by the Company to promote our own or third-party products or services, you can opt-out by (i) informing us of your preference at the time you register for an account, complete an order form, sign up for our newsletter, or complete any other form on or through the Services which we collect your data; (ii) modifying your user preferences in your account profile by checking or unchecking the relevant boxes; (iii) following the opt-out instructions in the promotional emails we send you; or (iv) sending us an email stating your request. Please note that if you opt-out of receiving promotional communications from us, we may still send you transactional communications, including emails about your account or purchases.
          </p><p>
            Disclosure of Your Information for Third-Party Advertising. If you do not want us to share your personal information with unaffiliated or non-agent third parties for promotional purposes, you can opt-out by (i) informing us of your preference at the time you register for an account, complete an order form, sign up for our newsletter, or complete any other form on or through the Services which we collect your data; (ii) modifying your user preferences in your account profile by checking or unchecking the relevant boxes; (iii) following the opt-out instructions in the promotional emails we send you; or (iv) sending us an email stating your request.
          </p><p>
            Targeted Advertising. If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers’ target-audience preferences, you can opt-out by modifying your user preferences in your account profile by checking or unchecking the relevant boxes or sending us an email stating your request. We do not control third parties’ collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. Many advertising companies that collect information for interest-based advertising are members of the Digital Advertising Alliance or the Network Advertising Initiative, both of which maintain websites where people can opt-out of interest-based advertising from their members. To opt-out on these pages, visit www.AboutAds.info and www.networkadvertising.org.
          </p><p>
            Location Information. You may be able to adjust the settings of your device so that information about your physical location is not sent to us or third parties by (a) disabling location services within the device settings; or (b) denying certain websites or mobile applications permission to access location information by changing the relevant preferences and permissions in your mobile device or browser settings. Please note that your location may be derived from your WiFi, Bluetooth, and other device settings. If you have questions about how to disable your device’s location services, we recommend you contact your mobile service carrier or your device manufacturer.
          </p><p>
            Voice Transmissions. You may be able to adjust the settings of your device so that your voice transmissions cannot be sent to us or third parties by (a) disabling microphone and speech recognition services within the device settings; or (b) denying certain websites or mobile applications permission to access microphone and speech recognition services by changing the relevant preferences and permissions in your mobile device or browser settings.
          </p><p>
            We do not control third parties’ collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt-out of receiving targeted ads from members of the Network Advertising Initiative (“NAI”) on the NAI’s website.
          </p><p>
            California residents may have additional personal information rights and choices. Please see Your California Privacy Rights for more information.
          </p><p>
            <h3>10. Your California Privacy Rights.</h3>
          </p><p>
            Under California Civil Code sections 1798.83-1798.84, California residents are entitled to ask us for a notice identifying the categories of personal customer information which we share with our affiliates and third parties for marketing purposes, and providing contact information for such affiliates and third parties. If you are a California resident and would like a copy of this notice, please submit a written request to the following address: Emilan. Co, 1057 NW 107th St, Apt L315, Miami, Florida 33168, United States of America.
          </p><p>
            <h3>11. Transfer of Information to the United States.</h3>
          </p><p>
            Our site is operated in the United States and intended for users located in the United States. If you are located outside of the United States, please be aware that information we collect, including Personal Information, will be transferred to, processed, stored and used in the United States. The data protection laws in the United States may differ from those of the country in which you are located, and your Personal Information may be shared with third parties for direct marketing purposes and may be subject to access requests from governments, courts, or law enforcement in the United States according to laws of the United States. By using our site or providing us with any information, you consent to the transfer to, and processing, usage, sharing and storage of your information, including Personal Information, in the United States as set forth in this Privacy Policy.
          </p><p>
            <h3>12. Data Security.</h3>
          </p><p>
            We take reasonable precautions to secure your personal information. We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls. Any payment transactions will be.
          </p><p>
            The safety and security of your information also depend on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. We urge you to be careful about giving out information in public areas of the Website like message boards. The information you share in public areas may be viewed by any user of the Website.
          </p><p>
            Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.
          </p><p>
            <h3>13. Changes to Our Privacy Policy.</h3>
          </p><p>
            It is our policy to post any changes we make to our privacy policy on this page with a notice that the privacy policy has been updated on the Website home page. If we make material changes to how we treat our users’ personal information, we will notify you by email to the primary email address specified in your account or through a notice on the Website home page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Website and this privacy policy to check for any changes.
          </p><p>
            <h3>14. Terms of Use.</h3>
          </p><p>
            If you choose to visit the Services, your visit and any dispute over privacy is subject to this Policy and our Terms of Use, including limitations on damages, resolution of disputes, and application of the governing law.
          </p><p>
            <h3>15. Contact Information.</h3>
          </p><p>
            To ask questions or comment about this privacy policy and our privacy practices, contact us at
          </p><p>
            Email: info@emilan.co.
          </p><p>
            Address: 8300 NW 53 ST, Ste 102, Miami, FL 33166, United States of America.
          </p>
          Phone: (954) 698-2058`

  // eslint-disable-next-line
  const { t } = useTranslation()

  return (
    <div className="terminos-IN">
      {t('lang.label') === 'es' &&
        <div className="tERMINOS" > <h2>{t('terminos.label')}</h2>
          <div dangerouslySetInnerHTML={{ __html: es }}></div>

        </div>
      }
      {(t('lang.label') === 'en') &&
        <div className="tERMINOS" > <h2>{t('terminos.label')}</h2>
          <div dangerouslySetInnerHTML={{ __html: en }}></div>
        </div>
      }
    </div>
  )
}
