import { useEffect , useState } from 'react';

import Header from './components/landings/renovation/header'
import Header23 from './components/landings/renovation/header23'
import Footer from './components/landings/renovation/footer'
import Footer23 from './components/landings/renovation/footer23'
import Landing_renovation from './views/landings/renovacion/landing-renovation';
import Landing_renovation23 from './views/landings/renovacion/landing-renovation2023';
import $ from 'jquery'

import AOS from 'aos';
import 'aos/dist/aos.css';

// Trasnlation 
import './i18n'
import { useTranslation } from 'react-i18next'

// Store
import Store from './services/store' 

function AppRenovation() {

  const [sended, setSended] = useState(false);

  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
      startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
      initClassName: 'aos-init', // class applied after initialization
      animatedClassName: 'aos-animate', // class applied on animation
      useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
      disableMutationObserver: false, // disables automatic mutations' detections (advanced)
      debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
      throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 120, // offset (in px) from the original trigger point
      delay: 0, // values from 0 to 3000, with step 50ms
      duration: 400, // values from 0 to 3000, with step 50ms
      easing: 'ease', // default easing for AOS animations
      once: false, // whether animation should happen only once - while scrolling down
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

    });
    
    window.addEventListener('load', AOS.refresh);

  }, [])

  const { i18n } = useTranslation()

  const initLang = () => {
    if (localStorage.getItem('lng')) {
      i18n.changeLanguage(localStorage.getItem('lng'))
    }else{
      window.localStorage.setItem('lng' ,'es')
      i18n.changeLanguage('es')
    }
  }
  
  const setSendede = () => {
    setSended(true)
  }

  return (
    <Store>
      <div className="App" onLoad={initLang}>
        <Header23 sended={sended}/>
        <Landing_renovation23 sended={sended} setSended={setSendede} />
        <Footer23 />
      </div>
    </Store>
  );
}

export default AppRenovation;
