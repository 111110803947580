import React from 'react'
import $ from 'jquery'
import { useLocation } from 'react-router-dom';
import taxteanLogo from '../../../assets/Images/landings/servicios/logo_tax.png'

import '../../../i18n';
import { useTranslation } from 'react-i18next';

const HeaderServicios = () => {
  const { pathname } = useLocation();

  const { t, i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    $('.bt').removeClass('active');
    $('.' + lang).addClass('active');
    localStorage.setItem('lng', lang);
  };

  if (pathname === '/landings_agentes') {
    $('.header-servicios').addClass('center')
  }
  return (
    <div className='header-servicios'>
      <a className="logo" href="https://emilan.co/" > </a>

      {(pathname === '/landings_es/medicare' || pathname === '/landings_es/salud') &&
        <div className="title_healt">
          <p className='p_title'>Seguros <br /> Médicos</p>
          <i className="icon icon-shield_heart" />
        </div>
      }
      {pathname === '/landings_es/vida' &&
        <div className="title_healt">
          <p className='p_title'>Seguros <br /> de Vida</p>
          <i className="icon icon-heart_shield" />
        </div>
      }
      {pathname === '/landings_es/reparacion_credito' &&
        <div className="title_healt">
          <img className='logo_tax' src={taxteanLogo} alt="taxteam-logo" />
        </div>
      }
      {pathname === '/landings_es/suplementarios' &&
        <div className="title_healt">
          <i className="icon icon-umbrella" />
          <p className='p_title'>Seguros <br />Suplementarios</p>
        </div>
      }
      {pathname === '/landings_agentes' &&
        <div className='langs'>
          <div className='botones'>
            <button
              className={
                localStorage.getItem('lng') === 'es' ? 'bt es active' : 'bt es'
              }
              onClick={() => changeLanguage('es')}
              type='button'
            >
              <div className='flag flag-es'></div>
              <span>ES</span>
            </button>
            <button
              className={
                localStorage.getItem('lng') === 'en' ? 'bt en active' : 'bt en'
              }
              onClick={() => changeLanguage('en')}
              type='button'
            >
              <div className='flag flag-en'></div>
              <span>IN</span>
            </button>
          </div>
        </div>
      }

      {pathname === '/landings_es/reparacion_credito' &&
        <>
          <a className="healt-comunicate" href="tel:+1888226418">
            <i className="icon icon-headset" />
            <p className='p_comunicate'>Comunícate con un experto<span>(888) 226-4180</span></p>
          </a>
          <a className="healt-comunicate_mobile" href="tel:+18882264180">
            <div className='div_icon'>
              <i className="icon icon-headset" />
            </div>
            <p className='p_comunicate_mobile'>Habla con <br />un experto</p>
          </a>
        </>
      }
      {(pathname === '/landings_es/medicare' || pathname === '/landings_es/salud' || pathname === '/landings_es/suplementarios' || pathname === '/landings_es/vida') &&
        <>
          <a className="healt-comunicate" href="tel:+19546982058">
            <i className="icon icon-headset" />
            <p className='p_comunicate'>Comunícate con un agente certificado <span>(954) 698-2058</span></p>
          </a>
          <a className="healt-comunicate_mobile" href="tel:+19546982058">
            <div className='div_icon'>
              <i className="icon icon-headset" />
            </div>
            <p className='p_comunicate_mobile'>Habla con <br />un agente</p>
          </a>
        </>
      }

    </div>
  )
}

export default HeaderServicios