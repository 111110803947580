import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import $ from 'jquery';
import AOS from 'aos';
// Trasnlation
import './../i18n';
import { useTranslation } from 'react-i18next';

import robot from '../assets/Images/home/roboEmi.png'

// import Promo1 from './promo1';
// import Policies_Gift from './policies_gift';
// import Modal from 'react-awesome-modal';
// import BannerSlider from './BannerSlider';

export default function Header() {
  const { t, i18n } = useTranslation();
  const [url, setUrl] = useState(null);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    $('.bt').removeClass('active');
    $('.' + lang).addClass('active');
    localStorage.setItem('lng', lang);
  };

  let location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');

  const ToogleSearch = () => {
    $('.search-box').toggleClass('active');
  };
  const ToogleMenu = () => {
    $('.nav-links').toggleClass('active');
  };
  const ToogleMenuServ = () => {
    $('.nav-links-serv').toggleClass('active');
  };

  const renderHeader = async () => {
    $('body').removeClass();
    $('body').addClass('vista-' + splitLocation[1]);
    $(function () {
      window.addEventListener('load', AOS.refresh);
    });

    setTimeout(() => {
      if (
        window.location.pathname === '/' ||
        window.location.pathname === '/salud-form'
      ) {
        if (window.location.pathname === '/') {
          var x = $('.header-desk').offset();
          // console.log('entra aqui home');
          // console.log(x);
        }
        if (window.location.pathname === '/salud-form') {
          var x = $('.header-desk .pbx').offset();
          // console.log('entra aqui salud');
          // console.log(x);
        }
        $('.header-desk').addClass('small');
        window.onscroll = function (e) {
          var scrollTop =
            window.pageYOffset !== undefined
              ? window.pageYOffset
              : (
                document.documentElement ||
                document.body.parentNode ||
                document.body
              ).scrollTop;

          if (scrollTop >= x.top) {
            console.log(splitLocation[1]);
            $('.header-desk').addClass('stiky');
            if (window.location.pathname === '/salud-form') {
              $('body').addClass('stiky');
            }
          } else {
            if (window.location.pathname === '/salud-form') {
              $('body').removeClass('stiky');
            }
            $('.header-desk').removeClass('stiky');
          }
        };
      } else {
        if (
          window.location.pathname != '/'
          && window.location.pathname != '/salud-form'
        ) {
          console.log('afirmativo');
          $('.header-desk').removeClass('small');
          $('.header-desk').attr('style', 'position:fixed;');

          $(document).scroll(function () {
            // navbarScroll();
          });
        }

        function navbarScroll() {
          var y = $(window).scrollTop();
          // console.log(y);
          if (y > 20 && window.location.pathname != '/') {
            $('.header-desk').addClass('small');
            $('.offset').addClass('active');
            // $('.BannerSlider').addClass('active');
          } else if (y <= 100 && window.location.pathname != '/') {
            $('.header-desk').removeClass('small');
            $('.offset').removeClass('active');
            // $('.BannerSlider').removeClass('active');
          }
        }
      }
    }, 100);
  };
  const renderHeader2 = async () => {
    $('body').removeClass();
    $('body').addClass('vista-' + splitLocation[1]);
    // $('.header-desk').removeClass('small');
    // $('.header-desk').removeClass('stiky');
    // $('.offset').removeClass('active');
    $(function () {
      window.addEventListener('load', AOS.refresh);
    });

    setTimeout(() => {

      $('.header-desk').addClass('small');
      $('.header-desk').addClass('stiky');
      $('.offset').addClass('active');

    }, 800);
  };

  // useEffect(() => {
  // 	setUrl(window.location.pathname);
  // 	renderHeader2();
  // }, [location]);
  useEffect(() => {
    renderHeader2();
  }, [pathname]);


  useEffect(() => {
    $('.terminos-Card').on('click', '.button-continue', function () {
      $('.terminos-Card').attr('style', 'display: none;');
    });
  }, []);
  return (
    <div>
      <div className='header'>
        <input type='checkbox' id='hamburger1' />
        <label htmlFor='hamburger1' onClick={ToogleMenu}></label>
        <ul className='nav-links'>
          <li>
            <Link
              to='/'
              className={splitLocation[1] === '' ? 'active' : ''}
            >
              <i className='icon icon_home'></i>
              {t('inicio.label')}
            </Link>
          </li>
          <li>
            <Link
              to='/services'
              className={
                splitLocation[1] === 'services' ||
                  splitLocation[1] === 'vida' ||
                  splitLocation[1] === 'salud' ||
                  splitLocation[1] === 'suplementario'
                  ? 'active'
                  : ''
              }
            >
              <i className='icon icon_serv'></i>
              {t('servicios.label')}
            </Link>
          </li>
          <li>
            <Link
              to='/blog'
              className={
                splitLocation[1] !== 'services' &&
                  splitLocation[1] !== 'salud' &&
                  splitLocation[1] !== 'vida' &&
                  splitLocation[1] !== 'suplementario' &&
                  splitLocation[1] !== 'nosotros' &&
                  splitLocation[1] !== ''
                  ? 'active'
                  : ''
              }
            >
              <i className='icon icon_blog'></i>
              {t('blog.label')}
            </Link>
          </li>
          <li>
            <Link
              to='/nosotros'
              className={splitLocation[1] === 'nosotros' ? 'active' : ''}
            >
              <i className='icon icon_us'></i>
              {t('nosotros.label')}
            </Link>
          </li>
        </ul>
        <Link to='/' className='source-logo header_logo' href='/'>
          <img src='assets/Images/logo.svg' alt='Logo Emilan' />
        </Link>
        <div className='div-phones'>
          <i className='icon icon_phones'></i>
        </div>
        <div className='langs'>
          <div className='botones'>
            <button
              className={
                localStorage.getItem('lng') === 'es' ? 'bt es active' : 'bt es'
              }
              onClick={() => changeLanguage('es')}
              type='button'
            >
              <div className='flag flag-es'></div>
              <span>ES</span>
            </button>
            <button
              className={
                localStorage.getItem('lng') === 'en' ? 'bt en active' : 'bt en'
              }
              onClick={() => changeLanguage('en')}
              type='button'
            >
              <div className='flag flag-en'></div>
              <span>IN</span>
            </button>
          </div>
        </div>
        <a
          className='bubble_what'
          href='https://api.whatsapp.com/send?phone=17868090282&text=Hola Emi, te hablo desde la pagina web para asesoria gratis'
        >
          <i className='icon icon_what-share'></i>
        </a>
        <div className='search' onClick={ToogleSearch}>
          <i className='icon icon_search'></i>
        </div>
        <div className='search-box'>
          <input
            type='text'
            placeholder='¿Que necesitas?'
            name='search-input'
          />
        </div>
        {/* url !== '/salud-form' && <Promo1 openModal_Gift={openModal_Gift} /> */}
      </div>
      {/*
 			<div className='home_banner-header'>
				<span className='Title'>
					<img
						src='assets/Images/big-logo.svg'
						className='hash animate__animated animate__flipInX'
					/>
				</span>
			</div>
*/}
      <div className='header-desk'>
        <div className='div-phones'>
          <i className='icon icon_phones'></i>
        </div>
        {/* 
        <Promo1 openModal_Gift={openModal_Gift} /> 
      */}
        <ul className='nav-links'>
          <li>
            <Link
              to='/'
              className={
                splitLocation[1] === ''
                  ? 'animate__animated animate__bounce active'
                  : 'animate__animated animate__bounce'
              }
            >
              {t('inicio.label')}
            </Link>
          </li>
          <li>
            <Link
              to='/services'
              className={
                splitLocation[1] === 'services' ||
                  splitLocation[1] === 'vida' ||
                  splitLocation[1] === 'salud' ||
                  splitLocation[1] === 'suplementario'
                  ? 'animate__animated animate__bounce active'
                  : 'animate__animated animate__bounce '
              }
            >
              {t('servicios.label')}
            </Link>
            <input
              type='checkbox'
              id='hamburger2'
              className={splitLocation[1] === '' ? 'hidens' : ''}
            />
            <label
              htmlFor='hamburger2'
              onClick={ToogleMenuServ}
              className={splitLocation[1] === '' ? 'hidens' : ''}
            ></label>
            <ul className='nav-links-serv'>
              <li>
                <a
                  href='/salud'
                  className={splitLocation[1] === 'salud' ? 'active' : ''}
                >
                  <i className='icon icon_salud-box'></i>
                  {t('salud1.label')}
                </a>
              </li>
              <li>
                <a
                  href='/vida'
                  className={splitLocation[1] === 'vida' ? 'active' : ''}
                >
                  <i className='icon icon_vida-box'></i>
                  {t('vida1.label')}
                </a>
              </li>
              <li>
                <a
                  href='/suplementario'
                  className={
                    splitLocation[1] === 'suplementario' ? 'active' : ''
                  }
                >
                  <i className='icon icon_suple-box'></i>
                  {t('suple1.label')}
                </a>
              </li>
            </ul>
          </li>
          <Link to='/' className='' href='/'>
            <div className='source-logo header_logo'>
              <img
                src='assets/Images/logo-desk1.svg'
                className='logo_A animate__animated animate__pulse'
                alt='Logo Emilan'
              />
              <img
                src='assets/Images/logo-over.svg'
                alt='Logo Emilan'
                className='Over'
              />
            </div>
          </Link>

          <li>
            <Link
              to='/blog'
              className={
                splitLocation[1] !== 'services' &&
                  splitLocation[1] !== 'salud' &&
                  splitLocation[1] !== 'vida' &&
                  splitLocation[1] !== 'suplementario' &&
                  splitLocation[1] !== 'nosotros' &&
                  splitLocation[1] !== ''
                  ? 'animate__animated animate__bounce active'
                  : 'animate__animated animate__bounce'
              }
            >
              {t('blog.label')}
            </Link>
          </li>
          <li>
            <Link
              to='/nosotros'
              className={
                splitLocation[1] === 'nosotros'
                  ? 'animate__animated animate__bounce active'
                  : 'animate__animated animate__bounce'
              }
            >
              {t('nosotros.label')}
            </Link>
          </li>
        </ul>
        <a className='boton-promo2' href='/salud-form'>
          {t('promo1btn2.label')}
        </a>
        <a
          className='bubble_what'
          href='https://api.whatsapp.com/send?phone=17868090282&text=Hola Emi, te hablo desde la pagina web para asesoria gratis'
        >
          <i className='icon icon_what-share'></i>
        </a>
        <a
          className='robo_chat' target='_blank' rel='noreferrer'
          href='https://emilan.co/salud-form'
        >
          <div className='div_robot'>
            <div className="globo">
              <p className="text_robot">{t('botchat.label')}</p>
            </div>
            <div className="robo">
              <img src={robot} alt='imagen de robot de chat de ayuda' className="img_robot"></img>
            </div>
          </div>
        </a>

        <div className='langs '>
          <div className='botones'>
            <button
              className={
                localStorage.getItem('lng') === 'es' ? 'bt es active' : 'bt es'
              }
              onClick={() => changeLanguage('es')}
              type='button'
            >
              <div className='flag flag-es'></div>
              <span>{t('es.label')}</span>
            </button>
            <button
              className={
                localStorage.getItem('lng') === 'en' ? 'bt en active' : 'bt en'
              }
              onClick={() => changeLanguage('en')}
              type='button'
            >
              <div className='flag flag-en'></div>
              <span>{t('en.label')}</span>
            </button>
          </div>
        </div>
        <a className='pbx' rel='noreferrer' href='tel:(954) 698-2058' >
          <i className='icon icon_phone'></i>
          <div>
            <p className='text'>{t('CallFree.label')}</p>
            <p className='number'>(954) 698-2058</p>
          </div>
        </a>
        <div id='sb-search' className='sb-search'>
          <form>
            <input
              className='sb-search-input'
              placeholder='¿Qué necesitas?'
              type='text'
              name='search'
              id='search'
            />
            <input className='sb-search-submit' type='submit' />
            <span className='sb-icon-search icon icon_search'></span>
          </form>
        </div>
      </div>
    </div>
  );
}
