//React
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//Components
import DotsMove from './dotsMove';
import Slider from "rc-slider/lib/Slider";
import "rc-slider/assets/index.css";

// Trasnlation
import './../i18n';
import { useTranslation } from 'react-i18next';


//Formulario uno en vista Healt-calc_mobile
export default function OptionOneFormHealt({setNewRefer,setTerms,opt,formCot,errors,setInput,inputs,setInputs,rang2,setInputsRange,marks2,membs,setMember,rang,
  marks,setInputsRange2,seletop,showRefers,hideRefers,refers,setInputRefer,submitInputs,openModal,terms,showref}){

  const { t } = useTranslation();

  let history = useHistory()

  const Linkto = () => {
    history.push('./')
  }

  const [animDots,setAnimDots] = useState(false)

  const [nextQue,setNextQue] = useState({ans1:false,ans2:false,ans3:false,ans3dot5:false,ans4:false,ans5:false,ans6:false,ans7:false,ans8:false,ans9:false,ans10:false,
    ans11:false,ans12:false,ans13:false,ans14:false})

  const activeAnimation = (fun,dat=null) => {
    setAnimDots(true)
    setTimeout(()=>{
      setAnimDots(false)
      fun(dat)
    },1500)
  }

  //Perimitir paso sgte
  const verifyInputForNext = (e,sec) => {
    if(e == 0 && !errors.fullname){
      activeAnimation(setNextQue,{...nextQue,ans1:true})
    }
    if(e == 1 && !errors.zip){
      activeAnimation(setNextQue,{...nextQue,ans2:true})
    }
    if(e == 2 && rang2){
      setInputs({ ...inputs, age: rang2});
      activeAnimation(setNextQue,{...nextQue,ans3:true})
    }
    if(e == '3-5' && sec){
      activeAnimation(setNextQue,{...nextQue,ans3dot5:true,ans4:false})
    }
    if(e == '3-5' && !sec){
      activeAnimation(setNextQue,{...nextQue,ans3dot5:false,ans4:true})
    }
    if(e == 3 && !errors.email){
      activeAnimation(setNextQue,{...nextQue,ans4:true})
    }
    if(e == 3 && errors.email){
      alert('Invalid email')
    }
    if(e == 4 && !errors.phone){
      activeAnimation(setNextQue,{...nextQue,ans5:true})
    }
    if(e == 5){
      activeAnimation(setNextQue,{...nextQue,ans6:true})
    }
    if(e == 6){
      activeAnimation(setNextQue,{...nextQue,ans7:true})
    }
    if(e == 7 && rang){
      setInputs({ ...inputs, incomes: rang*700});
      activeAnimation(setNextQue,{...nextQue,ans8:true})
    }
    if(e == 8){
      activeAnimation(setNextQue,{...nextQue,ans9:true,ans11:false})
    }
    if(e == 9 && sec){
      activeAnimation(setNextQue,{...nextQue,ans10:true})
    }
    if(e == 9 && !sec){
      Linkto()
    }
    if(e == 10 && sec){
      if(terms){
        setTerms(false)
        activeAnimation(setNextQue,{...nextQue,ans11:false})
      }else{
        setTerms(true)
        activeAnimation(setNextQue,{...nextQue,ans11:true})
      }
    }
    if(e == 11 && sec){
      activeAnimation(setNextQue,{...nextQue,ans14:true})
    }else if(e == 11 && !sec){
      activeAnimation(setNextQue,{...nextQue,ans12:true})
    }
    if(e == 12){
      if(!errors['inp-email-refer'+(sec+1)] && !errors['inp-phone-refer'+(sec+1)] && !errors['inp-fullname-refer'+(sec+1)]){
        activeAnimation(setNextQue,{...nextQue,ans13:true})
      }
    }
    if(e == 13 && sec){
      activeAnimation(setNextQue,{...nextQue,ans14:true})
    }else if(e == 13 && !sec){
      setNextQue({...nextQue,ans13:false})
      activeAnimation(setNewRefer())
    }
  }

  useEffect(()=>{
    if(terms){
      setNextQue({...nextQue,ans11:true})
    }
  },[terms])

  return(
    <div
    className={
      'form-quote animate__animated animate__fadeIn opt' + opt
    }
  >
    {formCot && (
      <div className='questions'>
        <div className="group_q">
          <div className="question">
            <div className="emi"><i className="icon icon-m_emilan"></i></div>
            <div className="div">
                <p className='question_p'>{t('saludF4_p2a.label')}</p>
                <p className='question_p'>{t('saludF4_p2b.label')}</p>
            </div>
          </div>
          <label
            htmlFor='name'
            className={errors.fullname ? 'input name error' : 'input name'}
            id='fullname'
          >
            <i className='icon icon-comm-user' />
            <input
              id='name'
              type='text'
              name='fullname'
              placeholder={t('refe_fullName.label')}
              onChange={setInput}
            />
            <button className="send_inp" onClick={() => verifyInputForNext(0)}><i className="icon icon-send_input"></i></button>
          </label>
        </div>

        {/*--question1*/}
        {nextQue.ans1 && <div className="group_q">
          <div className="question">
            <div className="emi"><i className="icon icon-m_emilan"></i></div>
            <div className="div">
                <p className='question_p'>
                {t('saludF4_p3.label')}
                </p>
            </div>
          </div>

          <label
            htmlFor='postal'
            className={errors.zip ?'input postal error' :'input postal'}
            id='zip'
          >
            <i className='icon icon-location_form' />
            <input
              type='number'
              name='zip'
              id='postal'
              placeholder='ZIP'
              onChange={setInput}
            />
            <button className="send_inp" onClick={() => verifyInputForNext(1)}><i className="icon icon-send_input"></i></button>
          </label>
        </div>}
        {/*--question1*/}

        {/*--question2*/}
        {nextQue.ans2 && <div className="group_q">
          <div className="question">
            <div className="emi"><i className="icon icon-m_emilan"></i></div>
            <div className="div">
                <p className='question_p'>
                {t('saludF4_p4.label')}
                </p>
            </div>
          </div>
            <div className="slider-wrapper">
            <span className='val_rang'>{rang2}</span>
            <Slider min={16} max={64} defaultValue={rang2} value={rang2} step={1}  name='age' dots= {true} onChange={setInputsRange} marks={marks2} tipFormatter={value => "$"+ value} />
          </div>
          <button className="btn_enviar" onClick={() => verifyInputForNext(2)}>{t('iniform7.label')}</button>
        </div>}
        {/*--question2*/}

        {/*--question3*/}
        {nextQue.ans3 && <div className="group_q">
          <div className="question">
            <div className="emi"><i className="icon icon-m_emilan"></i></div>
            <div className="div">
                <p className='question_p'>
                {t('saludF4_p5.label')}
                </p>
            </div>
          </div>
          <div className="butons">
            <button className="btn_option" onClick={() => verifyInputForNext('3-5',true)}>{t('yes.label')}</button>
            <button className="btn_option" onClick={() => verifyInputForNext('3-5',false)}>{t('no.label')}</button>
          </div>
          {nextQue.ans3dot5 && <label
            htmlFor='mail'
            className={errors.email ? 'input email error' : 'input email'}
            id='email'
          >
            <i className=' icon icon-mail_form ' />
            <input
              type='email'
              name='email'
              id='mail'
              placeholder='oooooo@oooo.com'
              onChange={setInput}
            />
            <button className="send_inp" onClick={() => verifyInputForNext(3)}><i className="icon icon-send_input"></i></button>
          </label>}
        </div>}
        {/*--question3*/}

        {/*--question4*/}
        {nextQue.ans4 && <div className="group_q">
          <div className="question">
            <div className="emi"><i className="icon icon-m_emilan"></i></div>
            <div className="div">
                <p className='question_p'>
                  {t('saludF4_p6.label')}
                </p>
            </div>
          </div>
          <label
            htmlFor='phone-label'
            className={errors.phone?'input phone error':'input phone'}
            id='phone'
          >
            <i className=' icon icon-mobile_phone' />
            <input
              type='tel'
              name='phone'
              id='phone-label'
              placeholder='(000) 000-0000'
              onChange={setInput}
            />
            <button className="send_inp" onClick={() => verifyInputForNext(4)}><i className="icon icon-send_input"></i></button>
          </label>
        </div>}
        {/*--question4*/}

        {/*--question5*/}
        {nextQue.ans5 && <div className="group_q">
          <div className="question">
            <div className="emi"><i className="icon icon-m_emilan"></i></div>
            <div className="div">
                <p className='question_p'>
                  {t('saludF4_p7.label')}
                </p>
            </div>
          </div>
          <div className="options">
            <label
              className='checkbox data-gender'
              htmlFor='gender4'
              id='data-gender'
              onClick={() => verifyInputForNext(5)}
            >
              <input
                id='gender4'
                type='radio'
                name='gender'
                value='female'
                onChange={setInput}
              />
              <p  className='p_check'>
              <lord-icon 
                src="https://cdn.lordicon.com/sodlceyy.json"
                trigger="loop"
                colors="primary:#000540,secondary:#00e5ba"
                style={{width:'65px', height:'65px'}}>
              </lord-icon><br />
              {t('woman.label')}
            </p>
            </label>
            <label
              className='checkbox data-gender'
              htmlFor='gender5'
              id='data-gender2'
              onClick={() => verifyInputForNext(5)}
            >
              <input
                id='gender5'
                type='radio'
                name='gender'
                value='male'
                onChange={setInput}
              />
              <p className='p_check'>
                <lord-icon
                    src="https://cdn.lordicon.com/imamsnbq.json"
                    trigger="loop"
                    colors="primary:#000540,secondary:#00e5ba"
                    style={{width:'65px', height:'65px'}}>
                </lord-icon><br />
                {t('man.label')}
              </p>
            </label>
            <label
              className='checkbox data-gender'
              htmlFor='gender3'
              id='data-gender3'
              onClick={() => verifyInputForNext(5)}
            >
              <input
                id='gender3'
                type='radio'
                name='gender'
                value='other'
                onChange={setInput}
              />
              <p className='p_check'>
                <lord-icon
                    src="https://cdn.lordicon.com/dxjqoygy.json"
                    trigger="loop"
                    colors="primary:#000540,secondary:#00e5ba"
                    style={{width:'65px', height:'65px'}}>
                </lord-icon><br />
                {t('land_other.label')}
              </p>
            </label>
          </div>
        </div>}
        {/*--question5*/}

        {/*--question6*/}
        {nextQue.ans6 && <div className="group_q">
          <div className="question">
            <div className="emi"><i className="icon icon-m_emilan"></i></div>
            <div className="div">
                <p className='question_p'>
                  {t('saludF4_p8.label')}
                </p>
            </div>
          </div>
          <div className="options opt_fam">
          {
            membs.map( val => (
              <label
                className='checkbox3 data-members'
                htmlFor={'member'+ val}
                id='data-members'
                key={val + 100}
              >
                <input
                  id={'member'+ val}
                  type='radio'
                  name='members'
                  value={val}
                  onChange={() => setMember(val)}
                />
                <div className="b_check" onClick={() => verifyInputForNext(6)}>{val}</div>
              </label>
            ))
          }
          </div>
        </div>}
        {/*--question6*/}

        {/*--question7*/}
        {nextQue.ans7 && <div className="group_q">
          <div className="question">
            <div className="emi"><i className="icon icon-m_emilan"></i></div>
            <div className="div">
                <p className='question_p'>
                {t('saludF4_p9.label')}
                </p>
            </div>
          </div>
          <div className="slider-wrapper">
            <span>${rang*700}</span>
            <Slider min={0} max={99} defaultValue={rang} value={rang} step={1}  name='incomes' dots= {true} marks={marks} onChange={setInputsRange2} 
            /> 
          </div>
          <button className="btn_enviar" onClick={() => verifyInputForNext(7)}>{t('iniform7.label')}</button>
        </div>}
        {/*--question7*/}

        {/*--question8*/}
        {nextQue.ans8 && <div className="group_q">
          <div className="question">
            <div className="emi"><i className="icon icon-m_emilan"></i></div>
            <div className="div">
                <p className='question_p'>
                {t('saludF4_p10.label')}
                </p>
            </div>
          </div>
          <div className="options">
            <label
              className='checkbox3'
              htmlFor='current-yes'
            >
              <input
                id='current-yes'
                type='radio'
                name='secure'
                value={Number(1)}
                onChange={setInput}
              />
              <div className="btn_option" onClick={() => verifyInputForNext(8,true)}>{t('yes.label')}</div>
            </label>
            <label
              className='checkbox3'
              htmlFor='current-not'
            >
              <input
                id='current-not'
                type='radio'
                name='secure'
                value={Number(0)}
                onChange={setInput}
              />
              <div className="btn_option" onClick={() => verifyInputForNext(8,false)}>{t('no.label')}</div>
            </label>
          </div>
        </div>}
        {/*--question8*/}

        {/*--question9*/}
        {nextQue.ans9 && <div className="group_q">
          <div className="question">
            <div className="emi"><i className="icon icon-m_emilan"></i></div>
            <div className="div">
              <div className='question_p'>
                {t('saludF4_p11a.label')}
                <ul>
                <li>{t('saludF4_p11a1.label')}</li>
                <li>{t('saludF4_p11a2.label')}</li>
                <li>{t('saludF4_p11a3.label')}</li>
                </ul>
              </div>
              <p className='question_p'>
                {t('saludF4_p11b.label')}
              </p>
            </div>
          </div>
          <div className="options">
            <label
              className='checkbox3'
              htmlFor='requirements-yes'
            >
              <input
                id='requirements-yes'
                type='radio'
                name='requirements'
                value='si'
                
              />
              <div className="btn_option" onClick={() => verifyInputForNext(9,true)}>{t('yes.label')}</div>
            </label>
            <label
              className='checkbox3'
              htmlFor='requirements-not'
            >
              <input
                id='requirements-not'
                type='radio'
                name='requirements'
                value='no'
              />
              <div className="btn_option" onClick={() => verifyInputForNext(9,false)}>{t('no.label')}</div>
            </label>
          </div>
        </div>}
        {/*--question9*/}

        {/*--question10*/}
        {nextQue.ans10 && <div className="group_q">
          <div className="question">
            <div className="emi"><i className="icon icon-m_emilan"></i></div>
            <div className="div">
                <p className='question_p'>
                {t('saludF4_p12.label')}
                </p>
            </div>
          </div>
          <div className="options">
            <lord-icon
              src="https://cdn.lordicon.com/wxnxiano.json"
              trigger="loop"
              colors="primary:#000540,secondary:#00e5ba"
              style={{width:'65px', height:'65px'}}>
            </lord-icon>
            <button className="btn_enviar leer" onClick={() => openModal()}>{t('leer.label')}</button>
          </div>
          <label className='checkbox2 terminos' htmlFor='acepto3'>
            <input
              id='acepto3'
              type='checkbox'
              name='terms'
              defaultValue={terms}
              checked={terms}
            />
            <p className='ps'>
              {t('saludF4_r12.label')}
            </p>
            <span className='checkmark' onClick={() => verifyInputForNext(10,true)}></span>
          </label>
      
        </div>}
        {/*--question10*/}
      </div>
    )}

    {/*-question 11 */}
    {seletop && nextQue.ans11 && (
      <Fragment>
        <div className='referred'>
          <div className="group_q">
            <div className="question">
              <div className="emi"><i className="icon icon-m_emilan"></i></div>
              <div className="div">
                <p className='question_p'>
                  {t('saludF4_p13.labelpart1')}
                  <b>{t('saludF4_p13.labelpart2')}</b>
                  {t('saludF4_p13.labelpart3')}
                </p>
              </div>
            </div>
            <div className="options">
              <label
                className='checkbox3'
                htmlFor='referred-yes'
              >
                <input
                  id='referred-yes'
                  type='radio'
                  name='referred'
                  value='1'
                  onChange={showRefers}
                />
                <div className="btn_option" onClick={() => verifyInputForNext(11,false)}>{t('yes.label')}</div>
              </label>
              <label
                className='checkbox3'
                htmlFor='referred-not'
              >
                <input
                  id='referred-not'
                  type='radio'
                  name='referred'
                  value='0'
                  onChange={hideRefers}
                />
                <div className="btn_option" onClick={() => verifyInputForNext(11,true)}>{t('no.label')}</div>
              </label>
            </div>
          </div>
          

          {showref &&
            refers.length > 0 &&
            refers.map((ref, index) => (
              <div
                className='animate__animated animate__fadeIn data-referred referrs1'
                key={index + 'refer' + ref.local_id}
              >
                {/*question 12 */}
                {nextQue.ans12 && <div className="group_q">
                  <div className="question">
                    <div className="emi"><i className="icon icon-m_emilan"></i></div>
                    <div className="div">
                      <p className='question_p'>
                        {t('saludF4_p14.label')}.
                      </p>
                    </div>
                  </div>
                    <label
                      htmlFor='name-ref'
                      className={
                        errors['inp-fullname-refer'+ref.local_id]? 'input name-ref inp-fullname-refer'+ref.local_id+' error'
                        :'input name-ref inp-fullname-refer'+ref.local_id
                      }
                      id='data-name-ref'
                    >
                      <i className='icon icon-comm-user' />
                      <input
                        id='name-ref'
                        placeholder={t('refe_fullName.label')}
                        type='text'
                        name='fullname'
                        onChange={(e) =>
                          setInputRefer(e, ref.local_id)
                        }
                        defaultValue={ref.fullname}
                      />
                    </label>
                    <label
                      htmlFor='phone-ref'
                      className={
                        errors['inp-phone-refer'+ref.local_id]? 'input phone-ref inp-phone-refer'+ref.local_id+' error'
                        :'input phone-ref inp-phone-refer'+ref.local_id
                      }
                      id='data-phone-ref'
                    >
                      <i className='icon icon-mobile_phone' />
                      <input
                        type='tel'
                        name='phone'
                        id='phone-ref'
                        placeholder='(000) 000-0000'
                        onChange={(e) =>
                          setInputRefer(e, ref.local_id)
                        }
                        defaultValue={ref.phone}
                      />
                    </label>
                    <label
                      htmlFor='email-ref'
                      className={
                        errors['inp-email-refer'+ref.local_id]?'input email-ref email-ref'+ref.local_id+' error'
                        :'input email-ref email-ref'+ref.local_id
                      }
                      id='data-mail-ref'
                    >
                      <i className='icon icon-comm-env' />
                      <input
                        type='email'
                        name='email'
                        id='email-ref'
                        placeholder='oooooo@oooo.com'
                        onChange={(e) =>
                          setInputRefer(e, ref.local_id)
                        }
                        defaultValue={ref.email}
                      />
                    </label>
                    <button className="btn_enviar" onClick={() => verifyInputForNext(12,index)}>{t('iniform7.label')}</button>
                </div>}
                {/*question 12 */}

                {/*question 13 */}
                {nextQue.ans13 && <div className="group_q">
                  <div className="question">
                    <div className="emi"><i className="icon icon-m_emilan"></i></div>
                    <div className="div">
                      <p className='question_p'>
                        {t('saludF4_p15.label')}
                      </p>
                    </div>
                  </div>
                  <div className="butons">
                    <button className="btn_option" onClick={() => verifyInputForNext(13,false)}>{t('yes.label')}</button>
                    <button className="btn_option" onClick={() => verifyInputForNext(13,true)}>{t('no.label')}</button>
                  </div>
                </div>}
                {/*question 13 */}
              </div>
            ))}

          {/*question 14 */}
          {nextQue.ans14  && <div className="group_q">
            <div className="question">
              <div className="emi"><i className="icon icon-m_emilan"></i></div>
              <div className="div">
                <p className='question_p'>
                  {t('saludF4_p16a.label')}   
                </p>
                <div className='question_p'>
                  {t('saludF4_p16b.label')}<a href="https://www.emilan.co/linker">emilan.co/linker</a>
                </div>
                <p className='question_p'>
                  {t('saludF4_p16c.label')}
                </p>
              </div>
            </div>
          </div>}

          {nextQue.ans14  && <input
            className='btn_enviar go '
            type='button'
            value= {t('saludF4_end.label')}
            onClick={submitInputs}
          />}
          {/*question 14 */}
        </div>
      </Fragment>
    )}
    {/*-question 11 */}
    {animDots && <DotsMove/>}
  </div>
  )
}