import { useState } from 'react';
// import $ from 'jquery';

// import LandingWebinarEncuesta from './views/landings/webinar/webinar-encuesta';
import RoutesWebinar from './routerWebinar'
import Header from './views/landings/webinar/webinar-header';
import Footer from './views/landings/webinar/webinar-footer'
import CopyR from './components/copyR';
// import AOS from 'aos';
// import 'aos/dist/aos.css';

// Trasnlation
import './i18n';
import { useTranslation } from 'react-i18next';

// Store
import Store from './services/store';

function AppWebinar() {

	const [sended, setSended] = useState(false);

	const { i18n } = useTranslation();

	const initLang = () => {
		if (localStorage.getItem('lng')) {
			i18n.changeLanguage(localStorage.getItem('lng'));
		} else {
			window.localStorage.setItem('lng', 'es');
			i18n.changeLanguage('es');
		}
	};
	const setSendede = () => {
		setSended(true);
	};

	return (
		<Store>
			<div className='App' onLoad={initLang}>
        <Header />
				<RoutesWebinar sended={sended} setSended={setSendede} />
				<Footer />
        <CopyR />
			</div>
		</Store>
	);
}

export default AppWebinar;
