import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

import env from './../environtments';

// Components 
// import Slider from "rc-slider/lib/Slider";
// import "rc-slider/assets/index.css";
// import DotsMove from './dotsMove';
import ModalLoader1 from './modalLoader1';
import ModalLoader2 from './modalLoader2';
import ModalLoader3 from './modalLoader3';
import OptionOneFormHealt from './optionOneFormHealt';
import OptionTwoFormHealt from './optionTwoFormHealt';

// Trasnlation
import './../i18n';
import { useTranslation } from 'react-i18next';
import Modal from 'react-awesome-modal';
import Policies from '../components/policies';
import Policies_Gift from '../components/policies_gift';

// Services
import { getinApi } from '../services/api';

//librarys
import $ from 'jquery';
import { Helmet } from 'react-helmet';

// Components
import Health_quotes from '../components/healt-quotes';
// import ReferSteps from '../components/referred_steps';
// import ModalSaludForm from '../components/modal_salud-form';
// import ModalRefUSA from './modal_RefUSA';
import ModalRefLatam from './modal_RefLatam';


export default function Healths_Calc_Mobile() {

  const loc = useLocation()

  const { t } = useTranslation();

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  const InitialInputs = {
    fullname: '',
    phone: '',
    email: '',
    gender: '',
    zip: '',
    age: 0,
    members: 0,
    incomes: 0,
    secure: 0,
    asesor: null,
    exist: null,
  };

  const initialLister = {
    fullname: '',
    phone: '',
    zone: '',
    date: '',
    hour: '',
  };

  const Initialselects = {
    day: '',
    asesor: '',
  };

  const InitialCompanies = [
    { value: 'Ambetter', label: 'Ambetter' },
    { value: 'AmeriHealth', label: 'AmeriHealth' },
    { value: 'BlueCross | BlueShield', label: 'BlueCross | BlueShield' },
    { value: 'Bright Health', label: 'Bright Health' },
    { value: 'Kaiser', label: 'Kaiser' },
    { value: 'Molina', label: 'Molina' },
    { value: 'Oscar', label: 'Oscar' },
    { value: 'Florida Blue', label: 'Florida Blue' },
    { value: 'Medica', label: 'Medica' },
    { value: 'Cigna Connect', label: 'Cigna Connect' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Otro', label: 'Otro' },
  ];

  const membs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]


  const [sended, setSended] = useState(false);
  const [opt, setOpt] = useState(0);
  const [visible, setvisible] = useState(false);
  const [visible_gift, setvisible_Gift] = useState(false);
  const [terms, setTerms] = useState(false);
  const [generalIds, setIds] = useState(1);
  const [refers, setRefers] = useState([]);
  const [showref, setShowRef1] = useState(false);
  const [isRef, setIsRef] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [prefer, setPrefer] = useState(false);
  const [client_id, setClientId] = useState(null);
  const [friends, setFriends] = useState(false);
  const [readyAgents, setreadyAgents] = useState(false);
  const [days, setDays] = useState(null);
  const [agents, setAgents] = useState([]);
  const [members, toggleMembers] = useState(false);
  const [asesors, toggleAsesors] = useState(false);
  const [compa, toggleCompany] = useState(false);
  const [dalys, toggleDaylies] = useState(false);
  const [zones, toggleZones] = useState(false);
  const [hours, toggleHours] = useState(false);
  const [seletop, setSeletop] = useState(false);
  const [formCot, setFormCot] = useState(false);
  const [formReno, setFormReno] = useState(false);
  const [company, setCompany] = useState('');
  const [selects, setSelects] = useState({ ...Initialselects });
  const [inputs, setInputs] = useState({ ...InitialInputs });
  const [lister, setLister] = useState({ ...initialLister });
  const [companies, setCompas] = useState(InitialCompanies);

  //ranges
  const [rang, setRang] = useState(0);
  const [rang2, setRang2] = useState(0);
  const marks = { 0: "$0", 101: "$'70.000" }
  const marks2 = { 16: "16", 65: "64" }
  const setInputsRange = e => {
    setRang2(e)
    let lef = (e - 16) * 100 / 48
    $('.val_rang').css('left', lef + '%')
  }
  const setInputsRange2 = e => {
    setRang(e)
  }

  // window.addEventListener('touchEvent', setInputs2, {passive: false})

  useEffect(() => {
    let new_id = window.location.hash;
    let id = new_id.split('#');
    let loaded_id = parseInt(id[1]);

    let local_next_day = moment(new Date()).add(1, 'days').format('DD');
    let local_day = moment(new Date()).format('DD');

    setDays([local_day, local_next_day]);
    if (loaded_id) {
      setSended(true);
      setClientId(loaded_id);
    }

    getAgents();

    $('body').on('mouseover', '.help-tip', function () {
      if (!$('.help-tip__content.Clone').length) {
        let off = $(this).offset();
        let clone = $(this).find('.help-tip__content').clone();
        clone.addClass('Clone animate__animated animate__bounceIn');
        $('body').append(clone);
        let h = $('body .help-tip__content.Clone').outerHeight() / 2 - 9;
        $('body .help-tip__content.Clone').attr(
          'style',
          'top:' + (off.top - h) + 'px; left:' + (off.left + 29) + 'px',
        );
      }
    });

    $(document).on('click', function () {
      $('body').find('.help-tip__content.Clone').addClass('animate__bounceIn');
      $('body').find('.help-tip__content.Clone').addClass('animate__bounceOut');
      setTimeout(() => {
        $('body').find('.help-tip__content.Clone').remove();
      }, 450);
    });
    //setNewRefer()
  }, []);
  const clearInvalid = () => {
    $('body').find('.invalide-health').removeClass('invalide-health');
  };
  const setInput = (e) => {
    let validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
    let letters = /([A-z])/

    clearInvalid();
    if (e.target.name == 'email') {
      if (validEmail.test(e.target.value)) {
        setInputs({ ...inputs, [e.target.name]: e.target.value });
        setErrors({ ...errors, email: false })
      } else {
        setErrors({ ...errors, email: true })
      }
      return
    }
    if (e.target.name == 'phone') {
      if (letters.test(e.target.value)) {
        alert('Wrong phone')
        setErrors({ ...errors, phone: true })
        return
      } else {
        setErrors({ ...errors, phone: false })
      }
    }
    if (
      e.target.name === 'age' ||
      e.target.name === 'asesor' ||
      e.target.name === 'members' ||
      e.target.name === 'incomes' ||
      e.target.name === 'secure'
    ) {
      setInputs({ ...inputs, [e.target.name]: parseInt(e.target.value) });
      setErrors({ ...errors, [e.target.name]: e.target.value.length > 0 ? false : true })
    } else {
      setInputs({ ...inputs, [e.target.name]: e.target.value });
      setErrors({ ...errors, [e.target.name]: e.target.value.length > 0 ? false : true })
    }

    if (
      inputs.fullname != '' &&
      inputs.phone != '' &&
      inputs.asesor !== null &&
      refers.length === 0
    ) {
      showRefers();
    }
  };
  const getAgents = async () => {
    await getinApi('get', 'asessors_list').then((res) => {
      setreadyAgents(true);
      setAgents(res.agents);
    });
  };
  const setInputLister = (e) => {
    $('body').find('.invalide').removeClass('invalide');
    setLister({ ...lister, [e.target.name]: e.target.value });
    // console.log(lister);
  };
  const setRemoveRefer = (refer) => {
    if (refers.length > 1) {
      let local_refer = [...refers];
      let newRefer = local_refer.filter(function (data, i) {
        return data.local_id !== refer;
      });
      setRefers(newRefer);
    }
  };
  const setMember = (member) => {
    setInputs({ ...inputs, members: member });
    toggleMembers(false);
    //console.log(inputs);
  };
  const setAsessor = (asesor, text, action) => {
    setInputs({ ...inputs, asesor: asesor });
    setInputs({ ...inputs, asesor: asesor });
    setSelects({ ...selects, asesor: text });
    toggleAsesors(false);
    //console.log(inputs);
    //console.log(selects);
    if (action != 'no-action') {
      if (
        inputs.fullname != '' &&
        inputs.phone != '' &&
        asesor !== null &&
        refers.length === 0
      ) {
        showRefers();
      }
    }
  };
  const setComa = (asesor, text) => {
    setCompany(asesor);
    toggleCompany(false);
  };
  const setDay = (day, text) => {
    setLister({ ...lister, date: day });
    setLister({ ...lister, date: day });
    setSelects({ ...selects, day: text });
    toggleDaylies(false);
    //console.log(lister);
  };
  const setZone = (zone) => {
    setLister({ ...lister, zone: zone });
    toggleZones(false);
    //console.log(lister);
  };
  const setHour = (hour) => {
    setLister({ ...lister, hour: hour });
    toggleHours(false);
    //console.log(lister);
  };
  const setNewRefer = (t = 0) => {
    //console.log(refers);
    if (refers.length < 4) {
      clearInvalid();
      let newRefer = {
        local_id: generalIds,
        fullname: '',
        phone: '',
        email: '',
      };
      if (t === 1) {
        setRefers([newRefer]);
      } else {
        setRefers([...refers, newRefer]);
        setErrors({ ...errors, ['inp-fullname-refer' + (refers.length + 1)]: true, ['inp-phone-refer' + (refers.length + 1)]: true, ['inp-email-refer' + (refers.length + 1)]: true })
      }

      setIds(generalIds + 1);
    }
  };
  const setInputRefer = (em, refer) => {
    clearInvalid();

    let validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
    let letters = /([A-z])/

    if (em.target.name == 'fullname') {
      setErrors({ ...errors, ['inp-' + em.target.name + '-refer' + refer]: em.target.value.length > 0 ? false : true })
    }
    if (em.target.name == 'email') {
      setErrors({ ...errors, ['inp-' + em.target.name + '-refer' + refer]: em.target.value.length > 0 && validEmail.test(em.target.value) ? false : true })
    }
    if (em.target.name == 'phone') {
      setErrors({ ...errors, ['inp-' + em.target.name + '-refer' + refer]: em.target.value.length > 0 && !letters.test(em.target.value) ? false : true })
    }
    let local_refer = [...refers];
    local_refer.filter(function (data, i) {
      if (data.local_id === refer) {
        data[em.target.name] = em.target.value;
      }
      return false;
    });
    setRefers(local_refer);

  };
  const showRefers = (turn = 1) => {
    if (turn === 2) {
      setNewRefer(1);
      setIsRef(1);
      setShowRef1(true);
    } else {
      setNewRefer();
      setIsRef(1);
      setShowRef1(true);
    }
  };
  const showFormCot = () => {
    setFormCot(true);
    setFormReno(false);
    setSeletop(true);
    clearInvalid();
    window.analytics.track('Select Quoter', {
      Section: 'Health Form',
      attempt_id: Date.now(),
    });
  };
  const showFormReno = () => {
    setFormCot(false);
    setFormReno(true);
    setSeletop(true);
    clearInvalid();
    window.analytics.track('Select Renovation', {
      Section: 'Health Form',
      attempt_id: Date.now(),
    });
  };
  const SetFriends = () => {
    setFriends(true);
    setInputs({ ...inputs, exist: true });
  };
  const hideRefers = () => {
    setRefers([]);
    setIds(1);
    setIsRef(0);
    setShowRef1(false);
  };
  const ChangeLoader = () => {
    $('.load').attr('style', 'flex-direction:row-reverse');
  };
  const ChangeLoader2 = () => {
    $('.load').attr('style', 'flex-direction:row');
  };
  const submitInputs = async () => {
    // console.log('inputs -> ',inputs)
    window.analytics.track('Submint Button Click', {
      Section: 'Health Form',
      attempt_id: Date.now(),
    });
    let validations = 0;
    let opt_class = '.opt' + opt;

    if (opt === 1 || opt === 2 || opt === 3) {
      clearInvalid();

      if (inputs.fullname.trim() === '') {
        $(opt_class + ' #fullname').addClass('invalide-health');
        validations = 1;
      }
      if (inputs.phone.trim() === '') {
        $(opt_class + ' #phone').addClass('invalide-health');
        validations = 1;
      }
      if (inputs.email.trim() === '') {
        $(opt_class + ' #email').addClass('invalide-health');
        validations = 1;
      }

      if (inputs.zip.trim() === '' && opt !== 2) {
        $(opt_class + ' #zip').addClass('invalide-health');
        validations = 1;
      }

      if (refers.length > 0) {
        refers.forEach((refer) => {
          if (refer.fullname === null || refer.fullname.trim() === '') {
            $(opt_class + ' .inp-fullname-refer' + refer.local_id).addClass(
              'invalide-health',
            );
            validations = 1;
          }
          if (refer.phone === null || refer.phone.trim() === '') {
            $(opt_class + ' .inp-phone-refer' + refer.local_id).addClass(
              'invalide-health',
            );
            validations = 1;
          }
        });
      }

      if (opt === 1 && formCot) {
        let nIntervId = setInterval(ChangeLoader, 2000);
        let nIntervId2 = setInterval(ChangeLoader2, 4100);

        if (inputs.gender.trim() === '') {
          $(opt_class + ' .data-gender .checkmark').addClass('invalide-health');
          validations = 1;
        }
        if (inputs.age === 0) {
          $(opt_class + ' #age').addClass('invalide-health');
          validations = 1;
        }
        if (inputs.members === 0) {
          $(opt_class + ' #members').addClass('invalide-health');
          validations = 1;
        }
        if (inputs.incomes === 0) {
          $(opt_class + ' #incomes').addClass('invalide-health');
          validations = 1;
        }
        if (inputs.secure === null) {
          $(opt_class + ' .secure .checkmark').addClass('invalide-health');
          validations = 1;
        }
        if (!terms) {
          $('.terminos .checkmark').addClass('invalide-health');
          validations = 1;
        }

        if (isRef === null) {
          $(opt_class + ' .options-response .checkmark').addClass(
            'invalide-health',
          );
          validations = 1;
        }

        if (validations) {
          return;
        }

        setLoader(true);

        // await axios
        //   .post(env.HEALTHS_SCRAPER, { client: inputs, refers: refers, vip: loc.pathname.includes('VIP') ? true : false })
        //   .then((res) => {
        //     clearInterval(nIntervId);
        //     clearInterval(nIntervId2);
        //     let message;
        //     switch (res.data.msj) {
        //       case 'low_income':
        //         message = 'Ingresos Bajos';
        //         break;
        //       case 'no_plans':
        //         message = 'No hay planes disponibles para esta configuración';
        //         break;

        //       default:
        //         message = 'Ingrensando los datos ';
        //         break;
        //     }

        //     if (res.data.status == 'Error') {
        //       $('.load p').html(
        //         `<span>¡Error!</span> <br /> Ocurrio un Error: <br /> ${message}`,
        //       );
        //       window.analytics.track('Error in Form', {
        //         Message: message,
        //         attempt_id: Date.now(),
        //       });
        //     } else {
        setTimeout(() => {

          setLoader(false);
          // setClientId(res.data.client_id);
          setSended(true);
          window.analytics.track('New Lead and Quote', {
            attempt_id: Date.now(),
          });

        }, 4000);
        // }
        //   return res.data;
        // })
        // .catch((err) => {
        //   console.log(err);
        //   return { status: 'Error' };
        // });
      }

      if (opt === 2) {
        // prexist  ?
        if (inputs.email.trim() === '') {
          $(opt_class + ' #email').addClass('invalide-health');
          validations = 1;
        }

        if (inputs.exist === null) {
          setInputs({ ...inputs, exist: true });
        }

        if (validations) {
          return;
        }
        // console.log('inputs: ',inputs,' | refers: ',refers)
        // await axios
        // 	.post(env.HEALTHS_SCRAPER_P, { client: inputs, refers: refers,vip: loc.pathname.includes('VIP') ? true : false})
        // 	.then((res) => {
        setTimeout(() => {

          setLoader2(true);
          window.analytics.track('New Refer', {
            Section: 'Health Form',
            attempt_id: Date.now(),
          });

        }, 3000);
        // 	return res.data;
        // })
        // .catch((err) => {
        // 	console.log(err);
        // 	return { status: 'Error' };
        // });
      }

      if (opt === 3 || (opt === 1 && formReno)) {
        // prexist  ?
        if (inputs.email.trim() === '') {
          $(opt_class + ' #email').addClass('invalide-health');
          validations = 1;
        }
        if (company.trim() === '') {
          $(opt_class + ' #compani').addClass('invalide-health');
          validations = 1;
        }

        if (isRef === null) {
          $(opt_class + ' .options-response .checkmark').addClass(
            'invalide-health',
          );
          validations = 1;
        }

        if (validations) {
          return;
        }

        //console.log(parseInt(inputs.asesor));
        // let client = await getinApi("post", "loyals", {
        //   fullname: inputs.fullname,
        //   phone: inputs.phone,
        //   email: inputs.email,
        //   zip: inputs.zip,
        //   user_id: inputs.asesor ? inputs.asesor : 43,
        //   prexist: inputs.exist,
        // })
        //   .then((res) => {
        //     return res.client.id;
        //   })
        //   .catch((err) => console.log(err));

        let client = await getinApi('post', 'loyals', {
          fullname: inputs.fullname,
          phone: inputs.phone,
          zip: inputs.zip,
          state: 'N/N',
          mail: inputs.email,
          company: company,
          rate1: 5,
          rate2: 5,
          rate3: 5,
          rate4: 5,
          rate5: 5,
          preg1: 1,
          preg2: 1,
          preg3: 1,
          agent: inputs.asesor ? inputs.asesor : 43,
          message: '',
          lang: window.localStorage.getItem('lng'),
        })
          .then((res) => {
            if (res.status === 'Success') {
              setLoader2(true);
              window.analytics.track('New Loyal', {
                Section: 'Health Form',
                attempt_id: Date.now(),
              });
              return res.user.id;
            } else {
              console.log(res.status);
            }
          })
          .catch((err) => console.log(err));

        //console.log(client);
        if (client !== null) {
          asyncForEach(refers, async (refer, index) => {
            await getinApi('post', 'refers', {
              fullname: refer.fullname,
              phone: refer.phone,
              email: refer.email != '' ? refer.email : '',
              client_id: null,
              user_id: inputs.asesor ? inputs.asesor : 43,
              other_2: client,
            })
              .then((res) => {
                setLoader2(true);
                window.analytics.track('New Refer', {
                  Section: 'Health Form',
                  attempt_id: Date.now(),
                });
              })
              .catch((err) => console.log(err));
          });
        }
      }
    }
  };
  const CalendarSubmit = async () => {
    window.analytics.track('Submint Button Lister', {
      Section: 'Health Form',
      attempt_id: Date.now(),
    });
    let validations = 0;

    clearInvalid();

    if (lister.fullname.trim() === '') {
      $('#fullname-lister').addClass('invalide-health');
      validations = 1;
    }
    if (lister.phone.trim() === '') {
      $('#phone-lister').addClass('invalide-health');
      validations = 1;
    }
    if (lister.zone.trim() === '') {
      $('#zone').addClass('invalide-health');
      validations = 1;
    }

    if (lister.date.trim() === '') {
      $('#date').addClass('invalide-health');
      validations = 1;
    }
    if (lister.hour.trim() === '') {
      $('#hour').addClass('invalide-health');
      validations = 1;
    }

    if (validations) {
      return;
    }

    await getinApi('post', 'listers', {
      fullname: lister.fullname,
      phone: lister.phone,
      zone: lister.zone,
    })
      .then(async (res) => {
        await getinApi('post', 'hours', {
          hour: lister.hour,
          day: lister.date,
          month: moment(new Date()).format('MM'),
          year: moment(new Date()).format('YYYY'),
          lister_id: res.lister.id,
        })
          .then((rest) => {
            window.analytics.track('Lead Agended', {
              Section: 'Health Form',
              attempt_id: Date.now(),
            });
            setLoader3(true);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };
  const location = () => {
    window.location = 'https://emilan.co';
  };
  const SetOPt1 = () => {
    setInputs({ ...InitialInputs });
    setTerms(false);
    setRefers([]);
    setShowRef1(false);
    setOpt(1);
    setPrefer(false);
    setFormCot(false);
    setFormReno(false);
    setSeletop(false);
    showFormCot()
    window.analytics.track('Inside Option', {
      Section: 'Health Form',
      Option: 'Option - ' + 1,
      attempt_id: Date.now(),
    });
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://tag.simpli.fi/sifitag/37c990c0-7f19-013b-ac66-0cc47abd0334";
    document.getElementsByTagName('head')[0].appendChild(s);
  };
  const SetOPt1_cot = () => {
    setInputs({ ...InitialInputs });
    setTerms(false);
    setRefers([]);
    setShowRef1(false);
    setOpt(1);
    setFormCot(true);
    setPrefer(false);
  };
  const SetOPt2 = () => {
    setInputs({ ...InitialInputs });
    setFriends(false);
    setRefers([]);
    setShowRef1(false);
    setOpt(2);
    window.analytics.track('Inside Option', {
      Section: 'Health Form',
      Option: 'Option - ' + 2,
      attempt_id: Date.now(),
    });
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://tag.simpli.fi/sifitag/3fe43070-7f19-013b-6163-0cc47a8ffaac";
    document.getElementsByTagName('head')[0].appendChild(s);
  };
  const SetOPt3 = () => {
    setPrefer(false);
    setLister({ ...initialLister });
    setOpt(3);
    window.analytics.track('Inside Option', {
      Section: 'Health Form',
      Option: 'Option - ' + 3,
      attempt_id: Date.now(),
    });
    setPrefer(false);
  };
  const showFormOpt3 = () => {
    setPrefer(true);
  };
  const hideFormOpt3 = () => {
    setPrefer(false);
  };
  const openModal = () => {
    window.analytics.track('Open Terms', {
      Section: 'Health Form',
      attempt_id: Date.now(),
    });
    setvisible(true);
    setTerms(true);
  };
  const openModal_Gift = () => {
    window.analytics.track('Open Terms Gift', {
      Section: 'Health Form',
      attempt_id: Date.now(),
    });
    setvisible_Gift(true);
  };
  const closeModal = () => {
    setvisible(false);
    setvisible_Gift(false);
    setTerms(true)
  };
  const check = () => {
    //console.log(sended);
    //console.log(client_id);
    //console.log(inputs);
    //console.log(lister);
  };

  const openOPtions = () => {
    console.log('banner clicked ');
    SetOPt2();
    window.analytics.track('Inside Option by Banner', {
      Section: 'Health Form',
      Option: 'Option - Clicked Banner ' + 2,
      attempt_id: Date.now(),
    });
    SetFriends();
    setTimeout(() => {
      $('#noted-yes').trigger('click');
    }, 200);
  };

  const [errors, setErrors] = useState(
    {
      fullname: true,
      zip: true,
      age: true,
      email: true,
      phone: true,
      incomes: true,
      country: true
    })

  //obtener ip <-en locationg se guarda toda la data de localización obtenida por la api para ser usada
  const [locationg, setLocationg] = useState()

  const getGeoInfo = () => {
    axios.get(env.IP_URL).then((response) => {
      let data = response.data
      setLocationg({ data })
    }).catch((error) => {
      console.log(error)
    })
  }

  useEffect(() => {
    getGeoInfo()
  }, [])
  //obtener ip

  return (
    <div className='content_box health-calculator view'>
      <Helmet>
        <title>Cotizador Salud</title>
        <meta
          name='keywords'
          content='Cotizador, Salud, Bajo costo, Planes, Aseguranza, Seguros, Estados Unidos, emilan.'
        />
        <meta
          name='description'
          content='Sabemos lo valioso que es tu tiempo, por ello, hemos diseñado una serie de preguntas que en cuestión de minutos te ayudarán a elegir el plan de vida que más se adapte a tus necesidades. Contamos con los mejores precios en aseguranza, bajos costos, prevención en todo momento. '
        />
      </Helmet>
      {/* 
      <ModalSaludForm />
      <ModalRefLatam/>

    */}
      {locationg && ((locationg.data.continent_code == 'SA' || locationg.data.continent_code == 'CA')) && <ModalRefLatam />}

      {/* {locationg && locationg.data.continent_code == 'NA' && locationg.data.country_code == 'US' && <ModalRefUSA/>} */}
      <div className='health-form-bkp'>
        <div className='bkp-2'></div>
        <div className='bkp-3'></div>
        {loader && (
          <ModalLoader1 />
        )}
        {loader2 && (
          <ModalLoader2 />
        )}
        {loader3 && (
          <ModalLoader3 />
        )}
        {sended ? (
          <Health_quotes client_id={client_id} />
        ) : (
          <Fragment>
            <div className='h-calculator-form_mobile'>
              <div className='form-calculator' id='formCalculator'>
                <div className='bubble1'></div>
                <div className='bubble2'></div>
                <div className='title'>
                  <div className="back animate__animated animate__fadeIn"></div>
                  <div className="plants animate__animated animate__zoomIn"></div>
                  <div className="doll animate__animated animate__slideInDown"></div>
                </div>
                <div className="question">
                  <div className="emi"><i className="icon icon-m_emilan"></i></div>
                  <p className='question_p'>{t('saludF4_p1.label')}</p>
                </div>
                <div className='div_bigButons'>
                  {!loc.pathname.includes('latinoamerica') && <div
                    className={
                      opt === 1
                        ? 'active big-button psbold big1'
                        : 'big-button psbold big1'
                    }
                    onClick={SetOPt1}
                  >
                    <lord-icon
                      src="https://cdn.lordicon.com/tyounuzx.json"
                      trigger="loop"
                      colors="primary:#000540,secondary:#00e5ba"
                      style={{ width: '70px', height: '70px' }}>
                    </lord-icon>
                    {t('saludF4_r1.label')}
                  </div>}
                  <div
                    className={
                      opt === 2
                        ? 'active big-button psbold big2'
                        : 'big-button psbold big2'
                    }
                    onClick={SetOPt2}
                  >
                    <lord-icon
                      src="https://cdn.lordicon.com/zpxybbhl.json"
                      trigger="loop"
                      colors="primary:#000540,secondary:#00e5ba"
                      style={{ width: '70px', height: '70px' }}>
                    </lord-icon>
                    {t('saludF4_r2.label')}
                  </div>
                </div>
                {/*---opciones---*/}
                <div>
                  {opt === 1 && (
                    <OptionOneFormHealt
                      opt={opt}
                      formCot={formCot}
                      errors={errors}
                      setInput={setInput}
                      inputs={inputs}
                      setInputs={setInputs}
                      rang2={rang2}
                      setInputsRange={setInputsRange}
                      marks2={marks2}
                      membs={membs}
                      setMember={setMember}
                      rang={rang}
                      marks={marks}
                      setInputsRange2={setInputsRange2}
                      seletop={seletop}
                      showRefers={showRefers}
                      hideRefers={hideRefers}
                      refers={refers}
                      setInputRefer={setInputRefer}
                      submitInputs={submitInputs}
                      openModal={openModal}
                      terms={terms}
                      showref={showref}
                      setNewRefer={setNewRefer}
                      setTerms={setTerms}
                    />
                  )}

                  {opt === 2 && (
                    <OptionTwoFormHealt
                      opt={opt}
                      errors={errors}
                      setInput={setInput}
                      refers={refers}
                      setInputRefer={setInputRefer}
                      submitInputs={submitInputs}
                      openModal={openModal}
                      terms={terms}
                      showref={showref}
                      readyAgents={readyAgents}
                      agents={agents}
                      setAsessor={setAsessor}
                      setNewRefer={setNewRefer}
                      setTerms={setTerms}
                    />
                  )}
                </div>
              </div>
            </div>

          </Fragment>
        )}
      </div>
      <Modal
        className='modales-n'
        visible={visible}
        width='90%'
        height='80%'
        effect='fadeInUp'
        onClickAway={() => closeModal()}
      >
        <div>
          <Policies />
          <a className='boton-close' onClick={() => closeModal()}>
            {t('refe_continue.label')}
          </a>
        </div>
      </Modal>
      <Modal
        className='modales-n'
        visible={visible_gift}
        width='90%'
        height='80%'
        effect='fadeInUp'
        onClickAway={() => closeModal()}
      >
        <div>
          <Policies_Gift />
          <a className='boton-close' onClick={() => closeModal()}>
            {t('refe_continue.label')}
          </a>
        </div>
      </Modal>
    </div>
  );
}
