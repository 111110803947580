import React, { useEffect } from 'react'
import imagen_lady from '../../../assets/Images/landings/servicios/Lang_salud-lady-working.png'
import FormLandings from './form_landings'


const ServicioSalud = () => {

  useEffect(() => {
    localStorage.setItem('lng', 'es')
  }, [])

  return (
    <div className="servicios servicio_salud">
      <section className="div_1">
        <div className="image_mobile"></div>
        <div className="busca_plan">
          <p>Asegura tu protección <i className="icon icon-candado" />, obtén tu seguro médico pagando desde <span>$0*</span> al mes.</p>
          <	FormLandings />
        </div>
        <div className="options_contact">
          <a className='btn_orange' href="tel:+19546982058" >
            <i className="icon icon-headset"></i>
            <p>Habla con un agente certificado <span>(954) 698-2058</span></p>
          </a>
          <a className='btn_lightBlue' href='https://m.me/emilan.co.fb?ref=w16822831' target='_blank'>
            <i className="icon icon-messenger"></i>
            <p>Chatea en <span>Facebook</span></p>
          </a>
          <a className='btn_lightBlue' href='https://bit.ly/emilan_SSalud2' target='_blank'>
            <i className="icon icon_what-share"></i>
            <p>Chatea en <span>Whatsapp</span></p>
          </a>
        </div>
      </section>
      <section className='div_2'>
        <h4>Estos serían los beneficios de tu seguro médico</h4>
        <ul>
          <li>
            <i className="icon icon-award "></i>
            <p>Planes médicos con mensualidad y deducibles desde $0*.</p>
          </li>
          <li>
            <i className="icon icon-headset "></i>
            <p>Telemedicina 24/7</p>
          </li>
          <li>
            <i className="icon icon_salud-box "></i>
            <p>74 chequeos médicos preventivos gratuitos en todos nuestros planes.</p>
          </li>
          <li>
            <i className="icon icon-medkit"></i>
            <p>Consultas al médico primario y medicamentos genéricos desde$0*</p>
          </li>
          <li>
            <i className="icon icon-heart-plus"></i>
            <p>Especialistas, hospitalización y emergencias a bajo costo.</p>
          </li>
        </ul>
      </section>
      <section className='div_3'>
        <div className="buble1"></div>
        <div className="contenido_div3">
          <h4>Ten en cuenta estos requisitos para aplicar</h4>
          <div className="requisitos_health">
            <p><i className="icon icon-location"></i>Debes vivir en Estados Unidos.</p>
            <p><i className="icon icon-bank"></i>Tener un estatus migratorio legal en el país que te permita trabajar legalmente (permiso de trabajo, residencia, ciudadanía, entre otros).</p>
            <p><i className="icon icon-paper"></i>Declarar en tus taxes unos ingresos mínimos según tu estado.</p>
            <p><i className="icon icon-medkit"></i>No tener otro plan de salud del estado (Medicare o Medicaid) o de tu empleador 💉.</p>
          </div>
          <p className='parra'><b>Te hace falta alguno de estos requisitos?</b> No te preocupes, nosotros te ayudamos.</p>
          <a className='btn_div3' href="https://bit.ly/emilan_SSalud2" target='_blank'>Escribenos Aquí</a>
        </div>
      </section>
      <section className='div_4'>
        <div className="buble2"></div>
        <div className="llamar_healt">
          <div className="llamar_info">
            <h4>¿Prefieres hablar directamente con alguien? Llámanos al</h4>
            <a className='btn_orange2' href="tel:+19546982058">
              (954) 698-2058
            </a>
          </div>
          <div className="image">
            <img src={imagen_lady} alt="imagen prefiere llamar" />
          </div>
        </div>
        <p className="condiciones">
          *El costo de tu plan médico depende de tu código postal, edad, ingresos aproximados y número de personas en tu declaración de taxes. <br /> **Para hacer uso del servicio gratuito de Telemedicina 24/7 debes registrarte antes con tu compañía de seguros.
        </p>
      </section>

    </div>
  )
}

export default ServicioSalud