import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import AppRenovation from './AppRenovation';
import AppRenovation2 from './AppRenovation2';

import { BrowserRouter } from 'react-router-dom';
import { debugContextDevtool } from 'react-context-devtool';
import AppLServicios from './AppLServicios';
import AppLServiciosEn from './AppLServicios_en';
import AppWebinar from './AppWebinar';


(async () => {
  const container = document.getElementById('root');
  const RouteBase = window.location.pathname

  switch (RouteBase) {
    case '/landings_loyalty':
      await import("./assets/scss/global/index-renovation2.scss");
      ReactDOM.render(<BrowserRouter> <AppRenovation2 /> </BrowserRouter>, container);
      break;
    case '/preinscripcion':
      await import("./assets/scss/global/index-renovation2.scss");
      ReactDOM.render(<BrowserRouter> <AppRenovation2 /> </BrowserRouter>, container);
      break;
    case '/landings':
      await import("./assets/scss/global/index-renovation.scss");
      ReactDOM.render(<BrowserRouter> <AppRenovation /> </BrowserRouter>, container);
      break;
    case '/landings_es/salud':
      await import("./assets/scss/global/servicios.scss");
      ReactDOM.render(<BrowserRouter> <AppLServicios /> </BrowserRouter>, container);
      break;
    case '/landings_es/vida':
      await import("./assets/scss/global/servicios.scss");
      ReactDOM.render(<BrowserRouter> <AppLServicios /> </BrowserRouter>, container);
      break;
    case '/landings_es/suplementarios':
      await import("./assets/scss/global/servicios.scss");
      ReactDOM.render(<BrowserRouter> <AppLServicios /> </BrowserRouter>, container);
      break;
    case '/landings_es/medicare':
      await import("./assets/scss/global/servicios.scss");
      ReactDOM.render(<BrowserRouter> <AppLServicios /> </BrowserRouter>, container);
      break;
    case '/landings_es/reparacion_credito':
      await import("./assets/scss/global/servicios.scss");
      ReactDOM.render(<BrowserRouter> <AppLServicios /> </BrowserRouter>, container);
      break;
    case '/landings_en/credit_repair':
      await import("./assets/scss/global/servicios.scss");
      ReactDOM.render(<BrowserRouter> <AppLServiciosEn /> </BrowserRouter>, container);
      break;
    case '/landings_en_health':
      await import("./assets/scss/global/servicios.scss");
      ReactDOM.render(<BrowserRouter> <AppLServiciosEn/> </BrowserRouter>, container);
      break;
    case '/landings_agentes':
      await import("./assets/scss/global/servicios.scss");
      ReactDOM.render(<BrowserRouter> <AppLServicios/> </BrowserRouter>, container);
      break;
    case '/webinar-encuesta':
      await import("./assets/scss/global/webinar.scss");
      ReactDOM.render(<BrowserRouter> <AppWebinar /> </BrowserRouter>, container);
      break;
    case '/webinar-registro':
      await import("./assets/scss/global/webinar.scss");
      ReactDOM.render(<BrowserRouter> <AppWebinar /> </BrowserRouter>, container);
      break;
    default:
      await import("./assets/scss/global/index.scss");
      ReactDOM.render(<BrowserRouter> <App /> </BrowserRouter>, container);
      break;
  }
  
  debugContextDevtool(container);
})();
