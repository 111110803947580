import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper core and required modules
import { Pagination, Navigation, Mousewheel, Keyboard, Autoplay } from 'swiper';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

// components
// import Policies_Gift from './policies_gift';
import Modal from 'react-awesome-modal';
import Policies_GiftLATAM from './policies_giftLATAM';
import Policies_GiftUSA from './policies_giftUSA';
import Policies_GiftRIFA from './policies_giftRifa';

const BannerSlider = () => {

  const { t } = useTranslation();

  const [visible_giftRIFA, setvisible_GiftRIFA] = useState(false);
  const [visible_giftLatam, setvisible_GiftLatam] = useState(false);

  // const [visible_giftUsa, setvisible_GiftUsa] = useState(false);
  // const openModal_GiftUSA = () => {
  // 	window.analytics.track('Open Terms Gift', {
  // 		Section: 'Health Form',
  // 		attempt_id: Date.now(),
  // 	});
  // 	setvisible_GiftUsa(true);
  // };
  const openModal_GiftRIFA = () => {
    window.analytics.track('Open Terms Gift', {
      Section: 'Health Form',
      attempt_id: Date.now(),
    });
    setvisible_GiftRIFA(true);
  };
  const openModal_GiftLATAM = () => {
    window.analytics.track('Open Terms Gift', {
      Section: 'Health Form',
      attempt_id: Date.now(),
    });
    setvisible_GiftLatam(true);
  };

  const closeModal = () => {
    // setvisible_GiftUsa(false);
    setvisible_GiftRIFA(false);
    setvisible_GiftLatam(false);
  };
  return (
    <Fragment>
      <div className='BannerSlider'>
        <Swiper
          className="mySwiper"
          mousewheel={true}
          keyboard={true}
          slidesPerView={1}
          // spaceBetween={0}
          freeMode={false}
          loop={true}
          pagination={{ dynamicBullets: true }}
          navigation={{
            prevEl: '.prev',
            nextEl: '.next',
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
        >
          {/* 	
          */}

          {/* <SwiperSlide>
                <div className={localStorage.getItem('lng') === 'es' ? "banner banner1":"banner banner1 banner1IN" }>
                  <a className="btnRefPurple" href='https://emilan.co/referidos-emilan' target='_blank' rel='noopener noreferrer' >{t('butonBanner1.label')}</a>
                  <div className="termCondition" onClick={()=>openModal_GiftUSA()}></div>
                </div>
              </SwiperSlide>   */}
          <SwiperSlide>
            <div className="banner banner2">
              {
                localStorage.getItem('lng') === 'es' ? <div className="title_img"></div> : <div className="title_img title_imgIN"></div>
              }
              <div className="text_banner">
                {t('textBanner2_1.label')}
                <span className='price'></span>
                {t('textBanner2_2.label')}
              </div>
              <a className="btnRefPurple" href='https://emilan.co/referidos-latinoamerica' rel='noopener noreferrer'>{t('butonBanner2.label')}</a>
              <div className="termCondition" onClick={() => openModal_GiftLATAM()}>{t('termCondition.label')}</div>
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
                <div className="banner banner3">
                <div className="text_banner">
                {t('textBanner3.label')}
                </div>
                  <a className="btnRefPurple" href='https://emilan.co/' target='_blank' rel='noopener noreferrer' >open enrollment?</a>
                </div>
              </SwiperSlide> */}
          {/* <SwiperSlide>
                <div className="banner banner4">
                <div className="bub1"></div>
                <div className="bub2"></div>
                <div className="bub3"></div>
                <div className="bub4"></div>
                <div className="bub5"></div>
                <div className="bub6"></div>
                <div className="bub7"></div>
                <div className="bub8"></div>
                <div className="text_banner">
                  {t('textBanner4_1.label')}<span>{t('textBanner4_2.label')}</span>
                </div>
                  <a className="btnRefPurple" href='https://emilan.co/' target='_blank' rel='noopener noreferrer' >{t('butonBanner4.label')}</a>
                </div>
              </SwiperSlide> */}
          {/* <SwiperSlide>
                <div className={localStorage.getItem('lng') === 'es' ? "banner banner5":"banner banner5 banner5IN" }>
                <p className={localStorage.getItem('lng') === 'es' ?"text_banner":"text_banner in" }>{t('textBanner5_1.label')}<span>{t('textBanner5_2.label')}</span></p>
                  <a className={localStorage.getItem('lng') === 'es' ? "btnRefPurple":"btnRefPurple in" }  href='https://emilan.co/referidos-emilan' rel='noopener noreferrer' >{t('butonBanner5.label')}</a>
                  <div className="termCondition" onClick={()=>openModal_GiftRIFA()}> {t('termCondition2.label')} {t('termCondition.label')}. <b>{t('PupLat_terms2.label')}</b></div>
                </div>
              </SwiperSlide>   */}
          {/* <SwiperSlide>
            <div className="banner banner6">
              <div className="bub1"></div>
              <div className="logo">
              </div>

              <div className="text_banner">
                {t('textBanner6_1.label')}<span>{t('textBanner6_2.label')}</span>
              </div>
              <a className="btnBanner" href='https://wa.link/9nhcxh' target='_blank' rel='noopener noreferrer' >{t('salud.label')}</a>
            </div>
          </SwiperSlide> */}
          {/* <SwiperSlide>
            <div className="banner banner7">
              <div className="bub1"></div>
              <div className="logo">
              </div>

              <div className="text_banner">
                <span>{t('textBanner7_1.label')}</span>{t('textBanner7_2.label')}
              </div>
              <a className="btnBanner" href='https://wa.link/rwxair' target='_blank' rel='noopener noreferrer' >{t('vida.label')}</a>
            </div>
          </SwiperSlide> */}
          {/* <SwiperSlide>
                <div className="banner banner_webinar">

                  <figure className='decision'></figure>
                  <div className="text_agente">
                    <p className='yell name'>{t('textBannWebinar_1.label')}</p>
                    <p className='text'>{t('textBannWebinar_2.label')}</p>
                  </div>

                  <p className='yell taller'>{t('textBannWebinar_3.label')} <span>{t('textBannWebinar_4.label')}</span> </p>
                  <div className="text_banner">
                    <p className="title">
                      {t('textBannWebinar_5.label')}
                      <span >{t('textBannWebinar_6.label')}</span>
                    </p>
                    <div className="calendar">
                      <div className="dia">
                        <i className="icon icon-calend_w"></i>
                        <div className="text">
                          <span>{t('textBannWebinar_7.label')}</span>
                          <span className="grey">{t('textBannWebinar_8.label')}</span>
                        </div>
                      </div>
                      <div className="hora">
                        <i className="icon icon-reloj_w"></i>
                        <div className="text">
                          <span className="grey">8:00 p.m</span>
                          <span>{t('textBannWebinar_9.label')}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <a className="btnBanner" href='https://emilan.co/webinar-registro' target='_blank' rel='noopener noreferrer' >{t('textBannWebinar_10.label')} <i className="icon icon-caretRight_w "></i></a>
                </div>
              </SwiperSlide>  */}
          <SwiperSlide>
            <div className="banner banner8">
              <div className="bub2"></div>
              <div className="bub3"></div>
              <div className="bub1"></div>
              <div className="logo">
              </div>

              <div className="text_banner">
                <span>{t('textBanner8_1.label')}</span>{t('textBanner8_2.label')}
              </div>

            </div>
          </SwiperSlide>
        </Swiper>
        <div className='prev'>
          <i className='icon icon-prev2'></i>
        </div>
        <div className='next'>
          <i className='icon icon-next2'></i>
        </div>
      </div>
      {/* <Modal
				className='modales-n'
				visible={visible_giftUsa}
				width='90%'
				height='80%'
				effect='fadeInUp'
				onClickAway={() => closeModal()}
			>
				<div>
					<Policies_GiftUSA />
					<a className='boton-close' onClick={() => closeModal()}>
						{t('refe_continue.label')}
					</a>
				</div>
			</Modal> */}
      {/* <Modal
				className='modales-n'
				visible={visible_giftRIFA}
				width='90%'
				height='80%'
				effect='fadeInUp'
				onClickAway={() => closeModal()}
			>
				<div>
					<Policies_GiftRIFA/>
					<a className='boton-close' onClick={() => closeModal()}>
						{t('refe_continue.label')}
					</a>
				</div>
			</Modal> */}
      <Modal
        className='modales-n'
        visible={visible_giftLatam}
        width='90%'
        height='80%'
        effect='fadeInUp'
        onClickAway={() => closeModal()}
      >
        <div>
          <Policies_GiftLATAM />
          <a className='boton-close' onClick={() => closeModal()}>
            {t('refe_continue.label')}
          </a>
        </div>
      </Modal>
    </Fragment>
  )
}

export default BannerSlider