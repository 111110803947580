
import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import NotFound from './views/404';
import LandingWebinarEncuesta from './views/landings/webinar/webinar-encuesta';
import LandingWebinarRegister from './views/landings/webinar/webinar-register';

function usePageViews() {
	let location = useLocation();
	React.useEffect(() => {
		console.log(location.pathname);
	}, [location]);
}

export default function RoutesWebinar() {
	usePageViews();
	return (
		<Switch>
			<Route exact path='/webinar-encuesta' component={LandingWebinarEncuesta} />
			<Route exact path='/webinar-registro' component={LandingWebinarRegister} />
			<Route component={NotFound} />
		</Switch>
	);
}
