import React, { useEffect } from 'react'
// import FooterServicios from '../../../components/landings/servicios/footer_servicios'
import imagen_lady from '../../../assets/Images/landings/servicios/Mujer_computador@2x.png'
import FormLandings from './form_landings'


const ServicioSuplementario = () => {

  useEffect(() => {
    localStorage.setItem('lng', 'es')
  }, [])

  return (
    <div className="servicios servicio_suplementario">
      <section className="div_1_supl">
        <div className="image_mobile"></div>
        <div className="busca_plan">
          <p>¡Nunca estás demasiado protegido! Obtén planes suplementarios para tu cuidado y el de tu familia.</p>
          <p className='par2_banner_supl'>Planes en tu área</p>
          <	FormLandings />
          <a className='btn_orange' href="tel:+19546982058">
            <i className="icon icon-headset"></i>
            <p>Habla con un agente certificado<br />
              <span>(954) 698-2058</span></p>
          </a>
        </div>
      </section>
      <section className='div_2_supl'>
        <div className="buble1"></div>
        <p className='parr_1_div2'>Comunícate con un agente certificado</p>
        <div className="options_contact">
          <a className='btn_orange_mobile' href="tel:+19546982058">
            <i className="icon icon-headset"></i>
            <p>Habla con un agente certificado<br />
              <span>(954) 698-2058</span></p>
          </a>
          <a className='btn_lightBlue' href="https://m.me/emilan.co.fb?ref=w16842446" target='_blank'>
            <i className="icon icon-messenger"></i>
            <p>Chatea en <span>Facebook</span></p>
          </a>
          <a className='btn_lightBlue' href='https://bit.ly/3JRPyWa' target='_blank' >
            <i className="icon icon_what-share"></i>
            <p>Chatea en <span>Whatsapp</span></p>
          </a>
        </div>
        <div className="contenido_div2">
          <p className='parr_2_div2'>Queremos ayudarte a protegerte, estos son nuestros <b> planes suplementarios</b> para ti</p>
          <div className="cards_suple">

            <div className="card_suple">
              <a className="image_card" href='https://m.me/emilan.co.fb?ref=w16842423' target='_blank'>
                <i className="icon icon-dental_emoji"></i>
              </a>
              <div className="title_card">Dental</div>
              <p>Cuidar de la parte de tu cuerpo que te permite mostrar tu sonrisa es muy fácil, contáctanos y disfruta de estos beneficios:</p>
              <ul>
                <li>Servicios básicos, mayores y preventivos para tu cuidado dental.</li>
                <li>Cuenta con una cobertura inmediata que puedes renovar anualmente sin ningún costo extra.</li>
                <li>Costos más bajos en algunos servicios*.</li>
                <li>Puedes cancelar en cualquier momento**.</li>
              </ul>
              <a className='btn_card' href='https://m.me/emilan.co.fb?ref=w16842423' target='_blank' >Quiero más información</a>
            </div>

            <div className="card_suple">
              <a className="image_card" href='https://m.me/emilan.co.fb?ref=w16842427' target='_blank'>
                <i className="icon icon-vision_emoji"></i>
              </a>
              <div className="title_card">Visión</div>
              <p>Disfruta de ver crecer a tu familia, cuida tu visión y aprovecha estos beneficios:</p>
              <ul>
                <li>Servicios sin período de espera.</li>
                <li>Descuentos en materiales para gafas y lentes de contacto.</li>
                <li>Primas que no aumentan.</li>
                <li>Monturas a costos favorables para ti y toda tu familia.</li>
              </ul>
              <a className='btn_card' href='https://m.me/emilan.co.fb?ref=w16842427' target='_blank' >Quiero más información</a>
            </div>

            <div className="card_suple">
              <a className="image_card" href='https://m.me/emilan.co.fb?ref=w16842439' target='_blank' >
                <i className="icon icon-ataud_emoji"></i>
              </a>
              <div className="title_card">Funerario</div>
              <p>Adelántate al futuro, date la tranquilidad de tener un apoyo extra en los momentos difíciles con estos beneficios:</p>
              <ul>
                <li>Traslado al país de origen a nivel nacional e internacional.</li>
                <li>Protección para amigos (no familiares) hasta los 65 años.</li>
                <li>Protección hasta de 7 personas.</li>
                <li>Descuentos en algunos estados del país.</li>
                <li>Puedes adquirirlo en cualquier parte del mundo.</li>
              </ul>
              <a className='btn_card' href='https://m.me/emilan.co.fb?ref=w16842439' target='_blank' >Quiero más información</a>
            </div>

            <div className="card_suple">
              <a className="image_card" href='https://m.me/emilan.co.fb?ref=w16842440' target='_blank'>
                <i className="icon icon-national_logo"></i>
              </a>
              <div className="title_card">National General</div>
              <p>Obtén la protección extra que podría servirte en el futuro, vive sin preocupaciones sabiendo que tienes estos beneficios:</p>
              <ul>
                <li>En tu <b>Plan Ideal</b> cuentas con cobertura de gastos médicos, muerte o desmembramiento y hospitalización.  </li>
                <li>En tu <b>Plan de Indemnización</b> tienes acceso a la atención médica, visitas al consultorio, laboratorio y radiografías, costo de cirujano y más.</li>
                <li>En tu <b>Plan de mejoramiento</b> obtienes más cobertura de gastos médicos por accidentes, indemnización hospitalaria, muerte accidental o desmembramiento, plan cáncer, infarto, ACV y Triomed</li>
              </ul>
              <a className='btn_card' href='https://m.me/emilan.co.fb?ref=w16842440' target='_blank' >Quiero más información</a>
            </div>

          </div>
        </div>
      </section>
      <section className='div_3_supl'>
        <div className="buble2"></div>
        <div className="llamar_healt">
          <div className="llamar_info">
            <h4>¿Prefieres hablar directamente con alguien? Llámanos al</h4>
          </div>
          <div className="image">
            <img src={imagen_lady} alt="imagen prefiere llamar" />
            <a className='btn_orange2' href="tel:+19546982058">
              (954) 698-2058
            </a>
          </div>
        </div>
        <p className="condiciones">
          *Los servicios con costos más bajos están sujetos a la compañía que brinde el servicio dental. **Las cancelaciones se hacen directamente con la compañía que te brinda el servicio.
        </p>
      </section>
    </div>
  )
}

export default ServicioSuplementario