import React, { useContext, useState } from 'react'
import $ from 'jquery'

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'
import Modal from 'react-awesome-modal';
import Policies from '../components/policies';
import { getinApiBlog } from '../services/wp-api'
import { Helmet } from "react-helmet";

import Image from '../assets/Images/home/handtoCell.png'
export default function Contact() {

  // eslint-disable-next-line
  const { t } = useTranslation()

  const [inputs, setInputs] = useState({
    name: '',
    mail: '',
    phone: '',
    zip:'',
    interest:'',
    message:''
  })
  const [error, setError] = useState(false);
  const [sended, setSended] = useState(false);

  const setInput = e => {
    $('body').find('.inp-' + e.target.name+'.invalide').removeClass('invalide')
    setError(false)
    setInputs({ ...inputs, [e.target.name]: e.target.value })
    console.log(e.target.name + ':::' + e.target.value);
  }

  const submitInputs = async () => {
    // setSended(true)
    let validations = 0;
    if ((inputs.name).trim() === '') {
      $('.inp-name').addClass('invalide')
      validations = 1;
    }
    if ((inputs.mail).trim() === '') {
      $('.inp-email').addClass('invalide')
      validations = 1;
    }
    if ((inputs.phone).trim() === '') {
      $('.inp-phone').addClass('invalide')
      validations = 1;
    }
    // if ((inputs.zip).trim() === '') {
    //   $('.inp-zip').addClass('invalide')
    //   validations = 1;
    // }
    if ((inputs.interest).trim() === '') {
      $('.inp-interest').addClass('invalide')
      validations = 1;
    }
    // if ((inputs.message).trim() === '') {
    //   $('.inp-message').addClass('invalide')
    //   validations = 1;
    // }
    if (!terms){
      $('.terminos .checkmark').addClass('invalide-all')
      validations = 1;
    }

    if (validations) {
      setError(true)
      return
    }
    $('.home-form').attr('style' , 'opacity:0.5')
    await getinApiBlog('postHomeForm', JSON.stringify(inputs) ).then((res) => {
      if (res === 200) {
        $('.home-form').attr('style', 'opacity:1')
        setSended(true)
      } else {
        setError(true)
      }
    }).catch((err) => console.log(err));
  // console.log(inputs);
  }


  const [visible, setvisible] = useState(false)
  const [terms, setTerms] = useState(false)
  const openModal = () => {
    $('.terminos .checkmark').removeClass('invalide-all')
    setvisible(true)
    setTerms(!terms)
  }

  const closeModal = () => {
    setvisible(false)
  }

  return (
    <div>
      <Helmet>
        <title>Formulario de contacto</title>
        <meta name="keywords" content="emilan, Seguros, Vida, Salud, Suplementarios, Familia, Confiabilidad, Hogar, Respaldo, Pólizas." />
        <meta name="description" content="Soportados en los mejores aliados de seguros de los Estados Unidos buscamos llegar a todos los hogares prevención en temas relacionados con vida, salud y suplementarios. Contamos con la experiencia y el respaldo de grandes empresas que garantizan la total confiabilidad en nuestros servicios. " />
      </Helmet>
      <div className="home_web view">
        <div className="vi-form">
          <div className="img-campania">
            <img src={Image} 
              title="Cotización de seguros gratis"
              alt="Con nuestros formularios podrás cotizar en unos cuantos pasos tu seguro deseado: vida y salud. En la palma de tu mano tienes la posibilidad de agendar una cita con nuestros asesores sin ningún compromiso y gozar de los grandes beneficios que tenemos para ti y toda tu familia"
            />
          </div>
          <div className="form-bkp" data-aos="fade">
            <h3 className='titleForm1' data-aos="fade" >¡Nosotros te llamamos!</h3>
            <h3 className='titleForm2' data-aos="fade" >Déjanos tus datos para contactarte y guiarte</h3>
            { sended ? 
              <div className="chatelo" >
                <div className="swal2-icon swal2-success swal2-icon-show" style={{ Display: 'flex' }}><div className="swal2-success-circular-line-left" style={{ backgroundColor: 'rgb(255, 255, 255)' }}></div>
                  <span className="swal2-success-line-tip"></span> <span className="swal2-success-line-long"></span>
                  <div className="swal2-success-ring"></div> <div className="swal2-success-fix" style={{ backgroundColor: 'rgb(255, 255, 255)' }}></div>
                  <div className="swal2-success-circular-line-right" style={{ backgroundColor: 'rgb(255, 255, 255)' }}></div>
                </div>
                <h3 data-aos="fade" >{t('thanks1.label')}</h3>
                <h2 data-aos="fade" >{t('thanks2.label')}</h2>
              </div>
              
              :
              <div className="home-form" data-aos="fade">
                <div className="form-contact" id="formcontacto">
                  <div className="two-columns">
                    <div className="form-group">
                      <label>{t('iniform1.label')}</label>
                      <input className="input input-name inp-name" type="text" name="name" id="" onChange={setInput} />
                    </div>
                  </div>
                  <div className="two-columns">
                    <div className="form-group ">
                      <label>E-mail</label>
                      <input className="input input-mail inp-email" type="mail" name="mail" id="" onChange={setInput} />
                    </div>
                    <div className="form-group postal">
                      <label>{t('iniform3.label')}</label>
                      <input className="input input-mail inp-phone" type="mail" name="phone" id="" onChange={setInput} />
                    </div>
                  </div>
                  <div className="two-columns">
                    <div className="form-group ">
                      <label>{t('iniform4.label')}</label>
                      <input className="input input-mail inp-zip" type="mail" name="zip" id="" onChange={setInput} />
                    </div>
                    <div className="form-group postal2">
                      <label>{t('iniform5.label')}</label>
                      <select className="input input-phone inp-interest" name="interest" id="" onChange={setInput}  >
                        <option value="" hidden></option>
                        <option value="salud">{t('salud.label')}</option>
                        <option value="vida">{t('vida.label')}</option>
                        <option value="suplementario">{t('suple.label')}</option>
                      </select>
                    </div>
                  </div>
                  <div className="bottom-div">
                    <label>{t('iniform6.label')}</label>
                    <textarea className="input input-pregs inp-message" name="message" id="" onChange={setInput} ></textarea>
                  </div>
                  <div className="terms-home">
                    <label className='checkbox terminos' htmlFor="acepto">
                      <input id='acepto' type='checkbox' name='terms' defaultValue={terms} onClick={() => openModal()} />
                      <span className="checkmark"></span>
                      <p>
                        {t('policies.label')}
                      </p>
                    </label>
                  </div>
                  <button className="button btn-submit" type="submit" onClick={submitInputs}> {t('iniform7.label')} </button>
                </div>
              </div>
            }   
         </div>
        </div>
      </div>
      <Modal className="modales-n" visible={visible} width="90%" height="80%" effect="fadeInUp" onClickAway={() => closeModal()}>
        <div>
          <Policies />
          <a className="boton-close" onClick={() => closeModal()}>{t('iniform8.label')}</a>
        </div>
      </Modal>
    </div>
  )
} 