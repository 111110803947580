import React, { useState } from 'react'
import $ from 'jquery'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'
import Aliates from '../components/aliates'
import { Helmet } from "react-helmet";


export default function Vida() {
  const [aliados, setAliados] = useState([
    'assets/Images/aliates/Vida/AMERICAN-GENERAL.jpg',
    'assets/Images/aliates/Vida/AMERICAN-NATIONAL.jpg',
    'assets/Images/aliates/Vida/FORESTERS.jpg',
    'assets/Images/aliates/Vida/JOHN-HANCOCK.jpg',
    'assets/Images/aliates/Vida/MUTUAL-OF-OMAHA.jpg',
    'assets/Images/aliates/Vida/NATIONAL-LIFE.jpg',
    'assets/Images/aliates/Vida/TRANSAMERICA.jpg'
  ])

  // eslint-disable-next-line
  const { t } = useTranslation()

  return (
    <div>
      <Helmet>
        <title>Protección de Vida</title>
        <meta name="keywords" content="Vida, Seguro, Protección, Enfermedad, Lesión, Muerte, Beneficio, Familia." />
        <meta name="description" content="No es necesario que muera, para hacer uso de su seguro de vida. Este seguro le permite obtener acceso a la totalidad de su beneficio por muerte o de enfermedad termina o una parte de su beneficio en vida, si sufre una crónica o crítica, o bien una lesión crítica." />
      </Helmet>
      <div className="vida view1" data-aos="fade">
        <div className="banner" data-aos="fade">
          <img
            srcSet="assets/Images/vida-bkp.png 769w, assets/Images/vida-bkp-desk.png 2000w"
            title="Seguros de Vida en Estados Unidos"
            alt="Planes de vida a bajo costo en los Estados Unidos, cuidado personal y de toda la familia. Somos una empresa de seguros comprometida con el bienestar de todos."
          />
          <span className="Title animate__animated animate__fade">
            {t('vida1.label')}
          </span>
        </div>
        <div className="view-content">
          <h2 data-aos="fade-down">{t('vida2.label')}</h2>
          <div className="first-paragraph">
            <div className="lateral-text">
              <p data-aos="fade-up">
                {t('vida3.label')}
              </p>
              <p data-aos="fade-up">
                {t('vida4.label')}
              </p>
            </div>
            <img className="lateral-img" data-aos="fade-up" src="assets/Images/family-vida.png" alt='familia' />
          </div>
          <div className="icons-div div-first">
            <div className="Box-icon" data-aos="fade-down-right">
              <i className="icon icon_poliza"></i>
              <span>{t('vida5.label')}</span>
            </div>
            <div className="Box-icon" data-aos="fade-down">
              <i className="icon icon_house"></i>
              <span>{t('vida6.label')}</span>
            </div>
            <div className="Box-icon" data-aos="fade-down-left">
              <i className="icon icon_oldmans"></i>
              <span>{t('vida7.label')}</span>
            </div>
          </div>
          <div className="icons-div div-second">
            <div className="Box-icon" data-aos="fade-up-right">
              <i className="icon icon_diner"></i>
              <span>{t('vida8.label')}</span>
            </div>
            <div className="Box-icon" data-aos="fade-up">
              <i className="icon icon_study"></i>
              <span>{t('vida9.label')}</span>
            </div>
            <div className="Box-icon" data-aos="fade-up-left">
              <i className="icon icon_shild"></i>
              <span>{t('vida10.label')}</span>
            </div>
          </div>
          <div className="box-campania">
            <div className="bottom-campania">
              <div className="title-campania">
                <h4 data-aos="flip-left">{t('vida11.label')}</h4>
                <a data-aos="fade-down" href="https://wa.link/rwxair" target="_blank" rel='noreferrer noopener'>{t('aplicar.label')}</a>
              </div>
              <div className="img-campania" data-aos="fade">
                <img data-aos="fade" src="assets/Images/campania-vida.png" alt='campaña vida' />
              </div>
            </div>
          </div>
          <div className="lateral-img-desk-box" data-aos="fade-down-left">
            <img className="lateral-img-desk" src="assets/Images/family-vida-desk.png"
              title="Seguro de vida a bajo costo"
              alt="Contamos con la mejor tecnología y los mejores aliados del país para brindarte atención y servicios de calidad: Pólizas a término, Protección de Hipoteca, Gasto final, Suplemento de retiro y Planeación universitaria. Todo a un precio bajo en los Estados Unidos."
            />
          </div>
        </div>
      </div>
      <Aliates contenido={aliados} />
    </div>
  )
}