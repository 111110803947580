import React, { useState, useContext, useEffect } from 'react'
import $, { post } from 'jquery'
import { Link } from 'react-router-dom'

// Services 
import { getinApiBlog } from '../services/wp-api'
import HTML2React from 'html2react'
import { useHistory } from "react-router-dom";
import moment from 'moment'
import mojs from '@mojs/core';
import { Helmet } from "react-helmet";

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'

const Items_perpage = 5;


export default function Blog() {

  let history = useHistory();

  // eslint-disable-next-line
  const { t } = useTranslation()

  // @ts-ignore
  let [posts, setPosts] = useState([])
  let [go, setGo] = useState(false)
  let [ready, setReady] = useState(false)

  let [items, setItems] = useState([...posts].splice(0 , Items_perpage))
  let [pag, setPages] = useState(0)

  const nextPage =()=>{
    const totalPost = posts.length
    const nextP = pag + 1
    const priIndex = nextP * Items_perpage
    if(priIndex === totalPost) return;
    setPages(nextP)
    setItems([...posts].splice(priIndex , Items_perpage))
  }

  const prevPage =()=>{
    const prevP = pag - 1
    if(prevP < 0) return;
    const priIndex = prevP * Items_perpage
    setItems([...posts].splice(priIndex , Items_perpage))
    setPages(prevP)
  }



  useEffect(() => {
    getPost();
  }, [window.localStorage.getItem('lng')])

  useEffect(() => {
   loadAllMedias()
  }, [go])

  useEffect(() => {
   setItems([...posts].splice(0 , Items_perpage))
  }, [posts])

  const getPost = async () => {
    var posted = [];
    var Base = 'getAll'
    if (window.localStorage.getItem('lng') === 'en') {
      Base = 'getAllEn'
    }
    await getinApiBlog(Base).then(async (res) => {
        res.forEach( async (post) => {
          // console.log(post);
          var dateString = post.date;
          var dateObj = new Date(dateString);
          var momentObj = moment(dateObj);
          var momentString = momentObj.format('LL');
      
          let pst = {
            id: post.id,
            date: momentString,
            likes: post.meta_box.likes,
            dislikes: post.meta_box.dislikes,
            title: post.title.rendered,
            slug: post.slug,
            content: post.content.rendered,
            excerpt: post.excerpt.rendered,
            featured_media: post.featured_media,
            media: [],
            liked: false,
            disliked:false
          }
          posted.push(pst);
        });
     }).catch((err) => console.log(err))
     setPosts(posted);
     setGo(true);
  }


  const addMediatoPost = async (id, ready) => {
    let local_posts = [...posts];
    local_posts.filter( async (data, i) => {
      if (data.id === id) {
        if (data.featured_media !== 0) {
          await getinApiBlog('getMedia', data.featured_media).then((res) => {
            data.media.push(res.source_url);
            if (ready) {
              setReady(true)
            }
            return false
          }).catch((err) => console.log(err));
        }
      }
      return false
    });
    setPosts(local_posts)
    return false 
  }

  const loadAllMedias = async () => {
    // console.log(posts);
    for (let index = 0; index < posts.length; index++) {
      if ((index + 1) === posts.length) {
        await addMediatoPost(posts[index]['id'], true);
      }else{
        await addMediatoPost(posts[index]['id'], false);
      }
    }
  }

  const gotoPost = (post) => {
    history.push('/' + post.id +'='+ post.slug)
  }

  const aniamteLike = (post) => {

        function extend(a, b) {
          for (var key in b) {
            if (b.hasOwnProperty(key)) {
              a[key] = b[key];
            }
          }
          return a;
        }

        function Animocon(el, options) {
          this.el = el;
          this.options = extend({}, this.options);
          extend(this.options, options);

          this.checked = false;

          this.timeline = new mojs.Timeline();

          for (var i = 0, len = this.options.tweens.length; i < len; ++i) {
            this.timeline.add(this.options.tweens[i]);
          }

          var self = this;
          if (!post.liked) {
            self.options.onCheck();
            self.timeline.replay();
          }else{
            self.options.onUnCheck ();
          }
          self.checked = !self.checked;
        
        }

        Animocon.prototype.options = {
          tweens: [
            new mojs.Burst({})
          ],
          onCheck: function () { return false; },
          onUnCheck: function () { return false; }
        };

          /* Icon 14 */
          var el14 = document.querySelector('button.icobutton.icon14.heart-like-'+ post.id),
            el14span = el14.querySelector('span'),
            el14counter = el14.querySelector('span.icobutton__text');

          new Animocon(el14, {
            tweens: [
              // ring animation
              new mojs.Shape({
                parent: el14,
                duration: 750,
                type: 'circle',
                radius: { 0: 20 },
                fill: 'transparent',
                stroke: '#FB6300',
                strokeWidth: { 35: 0 },
                opacity: 0.2,
                x: -10,
                top: '45%',
                easing: mojs.easing.bezier(0, 1, 0.5, 1)
              }),
              new mojs.Shape({
                parent: el14,
                duration: 500,
                delay: 100,
                type: 'circle',
                radius: { 0: 10 },
                fill: 'transparent',
                stroke: '#FB6300',
                strokeWidth: { 5: 0 },
                opacity: 0.2,
                x: 11,
                y: -13,
                easing: mojs.easing.sin.out
              }),
              new mojs.Shape({
                parent: el14,
                duration: 500,
                delay: 180,
                type: 'circle',
                radius: { 0: 5 },
                fill: 'transparent',
                stroke: '#FB6300',
                strokeWidth: { 5: 0 },
                opacity: 0.5,
                x: -17,
                y: -27,
                isRunLess: true,
                easing: mojs.easing.sin.out
              }),
              new mojs.Shape({
                parent: el14,
                duration: 800,
                delay: 240,
                type: 'circle',
                radius: { 0: 10 },
                fill: 'transparent',
                stroke: '#FB6300',
                strokeWidth: { 5: 0 },
                opacity: 0.3,
                x: -40,
                y: 10,
                easing: mojs.easing.sin.out
              }),
              new mojs.Shape({
                parent: el14,
                duration: 800,
                delay: 240,
                type: 'circle',
                radius: { 0: 10 },
                fill: 'transparent',
                stroke: '#FB6300',
                strokeWidth: { 5: 0 },
                opacity: 0.4,
                x: 29,
                y: -12,
                easing: mojs.easing.sin.out
              }),
              new mojs.Shape({
                parent: el14,
                duration: 1000,
                delay: 300,
                type: 'circle',
                radius: { 0: 8 },
                fill: 'transparent',
                stroke: '#FB6300',
                strokeWidth: { 5: 0 },
                opacity: 0.2,
                x: -3,
                y: -41,
                easing: mojs.easing.sin.out
              }),
              new mojs.Shape({
                parent: el14,
                duration: 600,
                delay: 330,
                type: 'circle',
                radius: { 0: 12 },
                fill: 'transparent',
                stroke: '#FB6300',
                strokeWidth: { 5: 0 },
                opacity: 0.4,
                x: -31,
                y: -32,
                easing: mojs.easing.sin.out
              }),
              // icon scale animation
              new mojs.Tween({
                duration: 1200,
                easing: mojs.easing.ease.out,
                onUpdate: function (progress) {
                  if (progress > 0.3) {
                    var elasticOutProgress = mojs.easing.elastic.out(1.43 * progress - 0.43);
                    el14span.style.WebkitMaskImage = '/assets/Images/like.svg';
                    el14span.style.maskImage = '/assets/Images/like.svg';
                    el14span.style.WebkitTransform = el14span.style.transform = 'scale3d(' + elasticOutProgress + ',' + elasticOutProgress + ',1)';
                  }
                  else {
                    el14span.style.WebkitTransform = el14span.style.transform = 'scale3d(0,0,1)';
                  }
                }
              })
            ],
            onCheck: function () {
              el14span.style.backgroundColor = '#FB6300';
              el14counter.innerHTML = Number(el14counter.innerHTML) + 1;
            },
            onUnCheck: function () {
              el14span.style.backgroundColor = '#C0C1C3';
              var current = Number(el14counter.innerHTML);
              el14counter.innerHTML = current > 1 ? Number(el14counter.innerHTML) - 1 : '';
            }
          });
  }

  const aniamteDisLike = (post) => {

        function extend(a, b) {
          for (var key in b) {
            if (b.hasOwnProperty(key)) {
              a[key] = b[key];
            }
          }
          return a;
        }

        function Animocon(el, options) {
          this.el = el;
          this.options = extend({}, this.options);
          extend(this.options, options);

          this.checked = false;

          this.timeline = new mojs.Timeline();

          for (var i = 0, len = this.options.tweens.length; i < len; ++i) {
            this.timeline.add(this.options.tweens[i]);
          }

          var self = this;
          if (!post.disliked) {
            self.options.onCheck();
            self.timeline.replay();
          }else{
            self.options.onUnCheck ();
          }
          self.checked = !self.checked;
        
        }

        Animocon.prototype.options = {
          tweens: [
            new mojs.Burst({})
          ],
          onCheck: function () { return false; },
          onUnCheck: function () { return false; }
        };

        /* Icon 12 */
				var el12 = document.querySelector('button.icobutton.icon12.heart-dislike-'+ post.id), 
						el12span = el12.querySelector('span'),
						el12counter = el12.querySelector('span.icobutton__text');
				var opacityCurve12 = mojs.easing.path('M0,100 L20,100 L20,1 L100,1');
				var translationCurve12 = mojs.easing.path('M0,100h20V0c0,0,0.2,101,80,101');
				new Animocon(el12, {
					tweens: [
						// burst animation
						new mojs.Burst({
							parent: el12,
							count: 2,
							radius: { 10: 90 },
							angle: 92,
							top: '90%',
							children: {
								shape: 'line',
								fill: '#C0C1C3',
								scale: 1,
								radius: { 40: 0 },
								stroke: '#C0C1C3',
								strokeWidth: { 4: 1 },
								strokeLinecap: 'round',
								opacity: 0.5,
								duration: 500,
								delay: 200,
								easing: mojs.easing.bezier(0.1, 1, 0.3, 1)
							}
						}),
						// burst animation
						new mojs.Burst({
							parent: el12,
							count: 3,
							radius: { 10: 40 },
							angle: 182,
							top: '90%',
							children: {
								shape: 'line',
								fill: '#C0C1C3',
								opacity: 0.5,
								scale: 1,
								radius: { 10: 0 },
								stroke: '#C0C1C3',
								strokeWidth: { 4: 1 },
								strokeLinecap: 'round',
								duration: 600,
								delay: 200,
								easing: mojs.easing.bezier(0.1, 1, 0.3, 1)
							}
						}),
						// ring animation
						new mojs.Shape({
							parent: el12,
							radius: { 40: 0 },
							radiusY: { 20: 0 },
							fill: '#C0C1C3',
							stroke: '#C0C1C3',
							strokeWidth: 1,
							opacity: 0.3,
							top: '90%',
							duration: 400,
							delay: 100,
							easing: 'bounce.out'
						}),
						// icon scale animation
						new mojs.Tween({
							duration: 500,
							easing: mojs.easing.bounce.out,
							onUpdate: function (progress) {
								var translationProgress = translationCurve12(progress);
								el12span.style.WebkitTransform = el12span.style.transform = 'translate3d(0,' + -350 * translationProgress + '%,0)';

								var colorProgress = opacityCurve12(progress);
								el12span.style.backgroundColor = colorProgress ? '#FB6300' : '#C0C1C3';
							}
						})
					],
					onCheck: function () {
						el12span.style.backgroundColor = '#FB6300';
						el12counter.innerHTML = Number(el12counter.innerHTML) + 1;
					},
					onUnCheck: function () {
						el12counter.innerHTML = Number(el12counter.innerHTML) - 1;
						el12span.style.backgroundColor = '#C0C1C3';
					}
				});
	

         
  }

  const likedChange = async (post) => {
    aniamteLike(post)
    var likes
    var liked
    if (post.liked) {
      post.likes != '' ? likes = parseInt(post.likes) - 1 : likes = 0;
      liked = false;
    }else{ 
      post.likes != '' ? likes = parseInt(post.likes) + 1 : likes = 1;
      liked = true;
    }
    await getinApiBlog('postBlog', '/' + post.id + `?meta_box={"likes":${likes}}`).then((res) => {
      let local_posts = [...posts];
      local_posts.filter(async (data, i) => {
        if (data.id === post.id) {
          data.liked = liked
          data.likes = likes
        }
      });
      setPosts(local_posts)
    }).catch((err) => console.log(err));
  }

  const dislikedChange = async (post) => {
    aniamteDisLike(post)
    var dislikes
    var disliked
    if (post.disliked) {
      post.dislikes != '' ? dislikes = parseInt(post.dislikes) - 1 : dislikes = 0;
      disliked = false;
    } else {
      post.dislikes != '' ? dislikes = parseInt(post.dislikes) + 1 : dislikes = 1;
      disliked = true;
    }
    await getinApiBlog('postBlog', '/' + post.id + `?meta_box={"dislikes":${dislikes}}`).then((res) => {
      let local_posts = [...posts];
      local_posts.filter(async (data, i) => {
        if (data.id === post.id) {
          data.disliked = disliked
          data.dislikes = dislikes
        }
      });
      setPosts(local_posts)
    }).catch((err) => console.log(err));
  }

  return (
    <div>
      <Helmet>
        <title>Blog</title>
        <meta name="keywords" content="Novedades, Noticias, Artículos, Vida, Salud, Suplementarios, emilan, Familia." />
        <meta name="description" content="Entérate de noticias y temas de relevancia personal y común. Un espacio para instruirte y cuidar no solo de ti sino de toda tu familia. Artículos de interés para todo tipo de público con relación a los seguros de Vida, Salud, Suplementarios. " />
      </Helmet>
      <div className="blog_web view">
        <div className="blog_banner">
          <span className="Title">
            <h1 className="animate__animated animate__flipInX">Blog</h1>
          </span>
        </div>
        <div className="hover01 column">
          {(ready) && items.map((post, index) =>
            <div className="big-section" data-aos="fade-up" key={'big-section-num-'+post.id}>
              <figure>
                <img src={post.media[0]} />
                {(post.media.length > 0) ? 
                    <div className="figure-bkp" style={{ backgroundImage: `url(${post.media[0]})` }}></div>
                  : 
                    <div className="figure-bkp" style={{ backgroundImage: `url(assets/Images/blog1.png)` }}></div>
                }
              </figure>
              <div className="overlay"></div>
              <span className="tex-later">
                <i>{post.date}</i>
                <h2>{post.title}</h2>
                {HTML2React(post.excerpt)}
                <a className="btn-more" onClick={() => gotoPost(post)}>{t('leer.label')}</a>
                <section>
                  <div className="butons-likes">
                    <div className="like">
                      <button className={`icobutton icobutton--heart icon14 heart-like-${post.id}`} onClick={() => likedChange(post)}>
                        <span className="icon icon_like"></span>
                        <span className="icobutton__text icobutton__text--side">{(post.likes != '') ? post.likes : '0'}</span>
                      </button>
                    </div>
                    <span className="dislike">
                      <button className={`icobutton icobutton--heart icon12 heart-dislike-${post.id}`}  onClick={() => dislikedChange(post)}>
                        <span className="icon icon_dislike"></span>
                        <span className="icobutton__text icobutton__text--side">{( post.dislikes != '' ) ? post.dislikes : '0' }</span>
                      </button>
                    </span>
                  </div>
                </section>
              </span>
            </div>
          )}
          </div>
          <div className="butons_pagination">
            <div className="button_page" onClick={()=>prevPage()}>Anterior</div>
            <div className="current_p">{pag + 1}</div>
            <div className="button_page" onClick={()=>nextPage()}>Siguiente</div>
          </div>
      </div>
    </div>
  )
}