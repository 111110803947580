import React, { useEffect, useState } from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';

// import Swiper core and required modules
import SwiperCore, { Pagination , Navigation } from 'swiper';



// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

export default function Companies({ contenido, setOptions ,suplementario }) {

	SwiperCore.use([Pagination]);
	SwiperCore.use([Navigation]);
	
	// eslint-disable-next-line
	const { t } = useTranslation();

	const [perpage, setperpage] = useState(4);

	const setLogo = (index) => {
		setOptions(index);
	};

	return (
		<div className='companies'>
			<Swiper
				navigation={{
					// Both prevEl & nextEl are null at render so this does not work
					prevEl: '.prev',
					nextEl: '.next',
				}}
				pagination={true}
				slidesPerView={1}
				spaceBetween={10}
				freeMode={false}
				breakpoints={{
					320: {
						slidesPerView: 2,
					},
					640: {
						slidesPerView: 3,
					},
					768: {
						slidesPerView: 4,
					},
					1024: {
						slidesPerView: perpage,
						spaceBetween: 10,
					},
					1450: {
						slidesPerView: perpage,
						spaceBetween: 18,
					},
				}}
			>
			
				{contenido.map((slide, index) => (
					<SwiperSlide key={'slide-index' + index} onClick={() => setLogo(slide)}>
						<img src={slide} alt='company' />
					</SwiperSlide>
				))}
				</Swiper>
				<div className="prev" ><i className="icon icon-prev"></i></div>
				<div className="next" ><i className="icon icon-next"></i></div>
		</div>
	);
}
