import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import $ from 'jquery';
import AOS from 'aos';
// Trasnlation
import '../../../i18n';
import { useTranslation } from 'react-i18next';

import robot from '../../../assets/Images/home/roboEmi.png'

// import Promo1 from './promo1';
// import Policies_Gift from './policies_gift';
// import Modal from 'react-awesome-modal';
// import BannerSlider from './BannerSlider';

export default function HeadWebinar() {
	const { t, i18n } = useTranslation();
	const [url, setUrl] = useState(null);

	const changeLanguage = (lang) => {
		i18n.changeLanguage(lang);
		$('.bt').removeClass('active');
		$('.' + lang).addClass('active');
    $('.botones2').addClass('d-none');
		localStorage.setItem('lng', lang);
	};

	let location = useLocation();
	const { pathname } = location;
	const splitLocation = pathname.split('/');


	const ToogleLang = function(){
    console.log('clasee');
	  $('.botones2').toggleClass('d-none');
	};

	useEffect(() => {
		$('.terminos-Card').on('click', '.button-continue', function () {
			$('.terminos-Card').attr('style', 'display: none;');
		});
	}, []);
	return (
		<div>
			<div className='header'>
				<a className='source-logo header_logo' href='/'>
					<img src='assets/Images/landings/webinar/logo_emilan_azul.svg' alt='Logo Emilan' />
				</a>
        <div className='langs2 '>
        {	localStorage.getItem('lng') === 'es' ? 
          <div className="idioma1 idioma" onClick={ToogleLang}>
            <i className='icon icon-es'></i>
            <span>ES
              <i className="icon icon-caret_down"></i>
            </span>
          </div> : 
          <div className="idioma2 idioma " onClick={ToogleLang}>
            <i className='icon icon-en'></i>
            <span>EN
              <i className="icon icon-caret_down"></i>
            </span>
          </div>	}
        {	localStorage.getItem('lng') === 'es' ? 
          <div className='botones2 d-none'>
            <button className="bt es" type='button' onClick={() => changeLanguage('es')}>
              <i className='icon icon-es'></i>
              <span>ESPAÑOL</span>
            </button>
            <button className="bt en" type='button' onClick={() => changeLanguage('en')}>
              <i className='icon icon-en'></i>
              <span>INGLES</span>
            </button>
          </div> : 
          <div className='botones2 d-none'>
            <button className="bt es" type='button' onClick={() => changeLanguage('es')}>
              <i className='icon icon-es'></i>
              <span>SPANISH</span>
            </button>
            <button className="bt en" type='button' onClick={() => changeLanguage('en')}>
              <i className='icon icon-en'></i>
              <span>ENGLISH</span>
            </button>
          </div>	}
        </div>
				<a	className='bubble_what'	href='https://api.whatsapp.com/send?phone=17868090282&text=Hola Emi, te hablo desde la pagina web para asesoria gratis'	>
					<i className='icon icon_what-share'></i>
				</a>
				{/* url !== '/salud-form' && <Promo1 openModal_Gift={openModal_Gift} /> */}
			</div>

			<div className='header-desk'>
				<Link to='/' className='source-logo header_logo' href='/'>
					<img src='assets/Images/landings/webinar/logo_emilan_azul.svg' alt='Logo Emilan' />
				</Link>
				<a className='bubble_what' href='https://api.whatsapp.com/send?phone=17868090282&text=Hola Emi, te hablo desde la pagina web para asesoria gratis'	>
					<i className='icon icon_what-share'></i>
				</a>
				<a	className='robo_chat' target='_blank' rel='noreferrer'	href='https://emilan.co/salud-form'	>
					<div className='div_robot'>
            <div className="globo">
              <p className="text_robot">{t('botchat.label')}</p>
            </div>
            <div className="robo">
              <img src={robot}alt='imagen de robot de chat de ayuda'className="img_robot"></img>
            </div>
          </div>
				</a>

				<div className='langs '>
					<div className='botones'>
						<button
							className={
								localStorage.getItem('lng') === 'es' ? 'bt es active' : 'bt es'
							}
							onClick={() => changeLanguage('es')}
							type='button'
						>
							<div className='flag flag-es'></div>
							<span>{t('es.label')}</span>
						</button>
						<button
							className={
								localStorage.getItem('lng') === 'en' ? 'bt en active' : 'bt en'
							}
							onClick={() => changeLanguage('en')}
							type='button'
						>
							<div className='flag flag-en'></div>
							<span>{t('en.label')}</span>
						</button>
					</div>
				</div>

			</div>
		</div>
	);
}
