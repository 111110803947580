import React from "react";
import { Link } from "react-router-dom";
import "../assets/css/404.css";

const NotFound = () => (
  <div className="step404 img404">
    <div className="flx error">
      <h1 className="titulo">ERROR !</h1>
      <h3 className="subtitulo">PAGE NOT FOUND</h3>
      <Link to="/">
        <button className="boton btn-2" type="submit">
          Back to homepage
        </button>
      </Link>
    </div>
  </div>
);

export default NotFound;
