
import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import NotFound from './views/404';
import ServicioSalud from './views/landings/servicios/servicio_salud';
import ServicioVida from './views/landings/servicios/servicio_vida';
import ServicioSuplementario from './views/landings/servicios/servicio_suplementario';
import ServicioMedicare from './views/landings/servicios/servicio_medicare';
import ServicioTaxTeam from './views/landings/servicios/servicio_taxteam';
import LandingAgentes from './views/landings/agentes/lan_agentes';

function usePageViews() {
	let location = useLocation();
	React.useEffect(() => {
		console.log(location.pathname);
	}, [location]);
}

export default function RoutesLandings() {
	usePageViews();
	return (
		<Switch>
			<Route exact path='/landings_es/salud'component={ServicioSalud} />
			<Route exact path='/landings_es/vida' component={ServicioVida} />
			<Route exact path='/landings_es/suplementarios' component={ServicioSuplementario} />
			<Route  path='/landings_es/reparacion_credito' component={ServicioTaxTeam} />
			<Route exact path='/landings_es/medicare' component={ServicioMedicare} />
			<Route exact path='/landings_agentes' component={LandingAgentes} />
			<Route component={NotFound} />
		</Switch>
	);
}
