import React, { useState, useContext } from 'react'
import $ from 'jquery'

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'
import Aliates from '../components/aliates'
import { Helmet } from "react-helmet";

export default function Salud() {

  const [aliados, setAliados] = useState([
    'assets/Images/aliates/Salud/AMBETTER.jpg',
    'assets/Images/aliates/Salud/AMERIHEALTH.jpg',
    'assets/Images/aliates/Salud/BLUECROSS.jpg',
    'assets/Images/aliates/Salud/BRIGHT-HEALTH.jpg',
    'assets/Images/aliates/Salud/KAISER.jpg',
    'assets/Images/aliates/Salud/MOLINA.jpg',
    'assets/Images/aliates/Salud/OSCAR.jpg',
  ])

  // eslint-disable-next-line
  const { t } = useTranslation()

  return (
    <div>
      <Helmet>
        <title>Protección de Salud</title>
        <meta name="keywords" content="Salud, Mercado de Salud, Protección, Plan, Indemnización, Subsidio, Familia." />
        <meta name="description" content="Si tus ingresos u otras condiciones no te permiten acceder al subsidio del gobierno, nosotros te ofrecemos planes que te pueden costar hasta 70% menos que un plan normal y lo mejor de todo es que te cubre hasta durante todo el año." />
      </Helmet>
      <div className="salud view3" data-aos="fade">
        <div className="banner" data-aos="fade">
            <img
              srcSet="assets/Images/salud-bkp.png 769w, assets/Images/salud-bkp-desk.png 2000w"
              title="Protección de Salud a bajo costo"
              alt="Si tus ingresos u otras condiciones no te permiten acceder al subsidio del gobierno, nosotros te ofrecemos planes que te pueden costar hasta 70% menos que un plan normal y lo mejor de todo es que te cubre hasta durante todo el año. (Obamacare)."
            />
            <span className="Title animate__animated animate__fade">
             {t('salud1.label')}
            </span>
          </div>
          <div className="view-content"> 
          <h2 data-aos="fade-down">{t('salud2.label')}</h2>
            <div className="first-paragraph">
              <div className="lateral-text">
              <p data-aos="fade-up">
                {t('salud3.label')}
                </p>
              <p data-aos="fade-up">
                {t('salud4.label')}
                </p>
              </div>
              <img data-aos="fade-left" className="lateral-img" src="assets/Images/doc.png" 
                 title="Ayuda con lo Inesperado a un precio especial"
                 alt="Buscamos lo mejor para ti por ello te brindamos: Ayuda con lo Inesperado, Cáncer, Corazón, Accidente Cerebrovascular, Indemnización hospitalaria, Gastos médicos por accidentes, Indemnización por muerte accidental o desmembramiento." 
              />
            </div>
            <div className="icons-div">
              <div className="Box-icon" data-aos="fade-right">
                <i className="icon icon_cart"></i>
                <span>{t('salud5.label')}</span>
              </div>
              <div className="Box-icon" data-aos="fade-up"> 
                <i className="icon icon_calendar"></i>
                <span>{t('salud6.label')}</span>
              </div>
              <div className="Box-icon" data-aos="fade-left">
                <i className="icon icon_star"></i>
                <span>{t('salud7.label')}</span>
              </div>
            </div>
            <div className="box-campania">
             <h3 data-aos="fade-right">{t('salud8.label')}</h3>
             <ul className="top-campania">
                <li data-aos="fade-right">{t('salud9.label')}</li>
                <li data-aos="fade-right">{t('salud10.label')}</li>
                <li data-aos="fade-right">{t('salud11.label')}</li>
                <li data-aos="fade-right">{t('salud12.label')}</li>
             </ul>
             <div className="bottom-campania">
              <div className="title-campania">
                <h4 data-aos="flip-left">{t('salud13.label')}</h4>
                <a href="https://emilan.co/salud-form/" target="_blank" data-aos="fade-down">{t('aplicar.label')}</a>
              </div>
              <div className="img-campania" data-aos="fade">
                <img src="assets/Images/campania-salud.png" />
              </div>
             </div>
            </div>
            <div className="lateral-img-desk-box">
              <img className="lateral-img-desk" src="assets/Images/doc-desk.png"
                 title="Ayuda con lo Inesperado a un precio especial"
                 alt="Buscamos lo mejor para ti por ello te brindamos: Ayuda con lo Inesperado, Cáncer, Corazón, Accidente Cerebrovascular, Indemnización hospitalaria, Gastos médicos por accidentes, Indemnización por muerte accidental o desmembramiento."
              />
            </div>
          </div>
          <Aliates contenido={aliados}/>
      </div>
    </div>
  )
}