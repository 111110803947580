import React, { useState, useContext, useEffect } from 'react'
import $ from 'jquery'

// Trasnlation 
import '../i18n'
import { useTranslation } from 'react-i18next'

import { Helmet } from "react-helmet";

//Components
import Policies from '../components/policies';

//footer
import Footer2 from '../components/footer2'

export default function PoliciesView() {

  // eslint-disable-next-line
  const { t } = useTranslation()

  return (
    <div className='PoliciesView'>
      <Helmet>
        <title>Inicio</title>
        <meta name="keywords" content="emilan, Seguros, Vida, Salud, Suplementarios, Familia, Confiabilidad, Hogar, Respaldo, Pólizas." />
        <meta name="description" content="Soportados en los mejores aliados de seguros de los Estados Unidos buscamos llegar a todos los hogares prevención en temas relacionados con vida, salud y suplementarios. Contamos con la experiencia y el respaldo de grandes empresas que garantizan la total confiabilidad en nuestros servicios. " />
      </Helmet>
      <Policies/>
      <Footer2/>
    </div>
  )
} 