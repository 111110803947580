import React, { useEffect } from 'react'
import imagen_lady from '../../../assets/Images/landings/servicios/she-carrer.png'
import FormLandings from './form_landings'


const ServicioTaxTeam= () => {
  
  useEffect(() => {
    localStorage.setItem('lng', 'es')
  }, [])

  return (
  <div className="servicios servicio_taxteam">
    <section className="div_1"> 
    <div className="image_mobile"></div>
      <div className="busca_plan">
      <p>Que el pasado financiero no afecte tu futuro <i className="icon icon-ingresos"/>. <br />
      Repara tu crédito y haz realidad tus sueños <i className="icon icon-man"/> .</p>
      </div>
      <div className="options_contact">
        <a className='btn_orange' href="tel:+18882264180" >
          <i className="icon icon-headset"></i>
          <p>Habla con un experto <br /> <span>(888) 226-4180</span></p>
        </a>
        <p className='p_btns'>Escribenos también por chat</p>
        <div className="bnts_chat">
          <a className='btn_lightBlue' href='https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2F100907558856439%2F%3Fref%3Dw17077647%26messaging_source%3Dsource%253Apages%253Amessage_shortlink%26source_id%3D1441792' target='_blank'>
            <i className="icon icon-messenger"></i>
            <p>Chatea en <span>Facebook</span></p>
          </a>
          <a className='btn_lightBlue' href='https://bit.ly/E_Credit-Repair' target='_blank'>
            <i className="icon icon_what-share"></i>
            <p>Chatea en <span>Whatsapp</span></p>
          </a>
        </div>
      </div>
    </section>
    <section className='div_2'>
     <div className="buble1"></div>
     <div className="contenido_div2">
      <h2>¿Qué es el crédito?</h2>
      <p className='p_dv2'>El crédito implica recibir algo con la promesa de pagarlo después.</p>
      <p className="p2_dv2">Tu puntaje de crédito es tu <span>reputación financiera</span> completa consolidada en un número entre 300 y 850.</p>
     </div>
    </section>
    <section className='div_3'>
      <div className="contenido_div3">
        <h2>Beneficios de reparar tu crédito</h2>
        <ul>
          <li>
            <i className="icon icon-manos_abiertas "></i>
            <p>Te ayudamos a obtener una historia crediticia limpia.</p>
          </li>
          <li>
            <i className="icon icon-lupa_emoji "></i>
            <p>Identificamos las disputas por ti y nos encargamos de que las corrijan.</p>
          </li>
          <li>
            <i className="icon icon-experto "></i>
            <p>Te brindamos la ayuda personalizada de un experto.</p>
          </li>
          <li>
            <i className="icon icon-grafica_emoji"></i>
            <p>Te aconsejamos sobre tu vida crediticia y financiera.</p>
          </li>
          <li>
            <i className="icon icon-docs_emoji"></i>
            <p>Desglosamos tu puntaje de crédito.</p>
          </li>
          <li>
            <i className="icon icon-mano_ok"></i>
            <p> Logramos que tus aplicaciones y reportes de crédito reflejen tu salud financiera.</p>
          </li>
        </ul>
      </div>
    </section>
    <section className='div_5'>
      <h2>3 pasos para reparar tu crédito</h2>
      <div className="contenido_div5">
        <div className="paso paso1">
          <lord-icon
            src="https://cdn.lordicon.com/mgmiqlge.json"
            trigger="loop-on-hover"
            colors="primary:#00e5ba,secondary:#000540,tertiary:#000540,quaternary:#ebe6ef"
            style={{width:'250px', height:'220px'}}>
          </lord-icon>
          <div className="">
            <div className="num_paso"><span>1</span></div>
            <p className='p_paso'>Agenda un análisis rápido y <span>gratuito</span> con un miembro de nuestro equipo.</p>
          </div>
        </div>
        <div className="paso paso2">
          <lord-icon
            src="https://cdn.lordicon.com/pxecqsgb.json"
            trigger="hover"
            colors="primary:#00e5ba,secondary:#ebe6ef"
            style={{width:'250px', height:'220px'}}>
          </lord-icon>
          <div className="">
            <div className="num_paso"><span>2</span></div>
            <p className='p_paso'>Revisaremos tus reportes de crédito actuales.</p>
          </div>
        </div>
        <div className="paso paso3">
          <lord-icon
            src="https://cdn.lordicon.com/surjmvno.json"
            trigger="hover"
            delay="1500"
            colors="primary:#00e5ba,secondary:#ebe6ef"
            style={{width:'250px', height:'220px'}}>
          </lord-icon>
          <div className="">
            <div className="num_paso"><span>3</span></div>
            <p className='p_paso'>Iniciamos las disputas por tu futuro financiero para arreglar tu crédito.</p>
          </div>
        </div>
      </div>
    </section>
    <section className='div_4'>
      <div className="buble2"></div>
      <h2>Repara ya tu futuro financiero</h2>
      <div className="llamar_healt">
        <div className="llamar_info">
          <h4>Hagamos realidad tus sueños. <span>¡Llámanos!</span></h4>
          <a className='btn_orange2' href="tel:+18882264180">
          (888) 226-4180
          </a>
        </div>
        <div className="image">
          <img src={imagen_lady} alt="imagen prefiere llamar" />
        </div>
      </div>
    </section>

  </div>
  )
}

export default ServicioTaxTeam