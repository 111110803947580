// Trasnlation
import './../i18n';
import { useTranslation } from 'react-i18next';

/*Modal de carga en Healt-calc_mobile */
export default function ModalLoader2(){

  const { t } = useTranslation();


  const location = () => {
		window.location = 'https://emilan.co';
	};

  return(
    <div className='modal2'>
      <div className='gift-card animate__animated animate__fadeIn'>
        <h3>{t('refe_thanks.label')}</h3>
        <div>
          <div className='flex'>
            <p>{t('refe_rememberCard.label')}</p>
            <button className='button' onClick={location}>
              {t('land2_out.label')}
            </button>
          </div>
          <img src='assets/Images/Bono-regalo-01.png' alt='card' />
        </div>
      </div>
    </div>
  )
}