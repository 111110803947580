import React, { useEffect } from 'react'
import $ from 'jquery'
// import FooterServicios from '../../../components/landings/servicios/footer_servicios'
import imagen_lady from '../../../assets/Images/landings/servicios/Lang_salud-lady-working.png'
import FormLandings from './form_landings'

const ServicioVida = () => {

  useEffect(() => {
    localStorage.setItem('lng', 'es')
  }, [])

  return (
    <div className="servicios servicio_vida">
      <section className="div_1_vida">
        <div className="image_mobile"></div>
        <div className="busca_plan">
          <p>Protege tu vida y el futuro de los que amas <i className="icon icon-family_emoji" />.<b>Adquiere ahora mismo tu seguro de vida.</b></p>
          <p className='par2_banner_vida'>Encuentra la mejor protección en tu zona</p>
          <	FormLandings />

        </div>
      </section>
      <section className='div_2_vida'>
        <div className="buble1"></div>
        <p className='parr_1_div2'>Comunícate con un agente certificado</p>
        <div className="options_contact">
          <a className='btn_orange' href="tel:+19546982058">
            <i className="icon icon-headset"></i>
            <p>Llama al <br />
              <span>(954) 698-2058</span></p>
          </a>
          <a className='btn_orange_mobile' href="tel:+19546982058">
            <i className="icon icon-headset"></i>
            <p>Habla con un agente certificado<br />
              <span>(954) 698-2058</span></p>
          </a>
          <a className='btn_lightBlue' href="https://m.me/emilan.co.fb?ref=w16842417" target='_blank'>
            <i className="icon icon-messenger"></i>
            <p>Chatea en <span>Facebook</span></p>
          </a>
          <a className='btn_lightBlue' href='https://bit.ly/3JUrWjC' target='_blank' >
            <i className="icon icon_what-share"></i>
            <p>Chatea en <span>Whatsapp</span></p>
          </a>
        </div>
        <div className="contenido_div2">
          <h4>Con tu póliza de vida tienes estos beneficios</h4>
          <a className="girl_vida" href='https://m.me/emilan.co.fb?ref=w16842417' target='_blank'></a>
          <ul>
            <li>
              <i className="icon icon-cama_emoji "></i>
              <p>Beneficios en vida por enfermedad terminal.</p>
            </li>
            <li>
              <i className="icon icon-hospital_emoji"></i>
              <p>Beneficios en vida por enfermedad crónica.</p>
            </li>
            <li>
              <i className="icon icon-ambulancia_emoji "></i>
              <p>Beneficios en vida por enfermedad crítica.</p>
            </li>
            <li>
              <i className="icon icon-ataud_emoji"></i>
              <p>Beneficio por muerte o fallecimiento.</p>
            </li>
          </ul>
        </div>
      </section>
      <section className='div_3_vida'>
        <div className="contenido_div3">
          <a className="family_vida" href='https://m.me/emilan.co.fb?ref=w16842417' target='_blank'></a>
          <h4>Puedes usar tus <b>beneficios en Vida</b> para...</h4>
          <ul>
            <li>
              <i className="icon icon-casa_emoji"></i>
              <p>Gastos del hogar / Modificaciones.</p>
            </li>
            <li>
              <i className="icon icon-doctora_emoji "></i>
              <p>Cuidado de enfermería en el hogar.</p>
            </li>
            <li>
              <i className="icon icon-abuelos_emoji "></i>
              <p>Centro de cuidado para adultos.</p>
            </li>
            <li>
              <i className="icon icon-cash_emoji"></i>
              <p>Gastos para calidad de vida.</p>
            </li>
            <li>
              <i className="icon icon-avion_emoji"></i>
              <p>Vacaciones de ensueño tras diagnóstico de enfermedad terminal.</p>
            </li>
          </ul>
        </div>
      </section>
      <section className='div_4_vida'>
        <div className="buble2"></div>
        <div className="llamar_healt">
          <div className="llamar_info">
            <h4>¿Prefieres hablar directamente con alguien? Llámanos al</h4>
            <a className='btn_orange2' href="tel:+19546982058" >
              (954) 698-2058
            </a>
          </div>
          <div className="image">
            <img src={imagen_lady} alt="imagen prefiere llamar" />
          </div>
        </div>
      </section>

    </div>
  )
}

export default ServicioVida