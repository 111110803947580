import React, { useState, useEffect, useContext } from 'react'
import { Context } from '../services/store'
import moment from 'moment';

// Trasnlation 
import './../i18n'
import { useTranslation } from 'react-i18next'
import Modal from 'react-awesome-modal';
import Policies from '../components/policies';

// Services
import { getinApi } from '../services/api'
import { getinApiBlog } from '../services/wp-api'
import Select from 'react-select'
import { getinIXN } from '../services/ixn'
import $ from 'jquery'
import { Helmet } from "react-helmet";

// Components 
import Slider from "rc-slider/lib/Slider";
import "rc-slider/assets/index.css";

// File Conditions
import Months_es from "../assets/files/months_es.json";
import Months_en from "../assets/files/months_en.json";

export default function Calculator() {

  // eslint-disable-next-line
  const { t } = useTranslation()
  // @ts-ignore

  useEffect(() => {
    getStates()
    if (localStorage.getItem('lng') === 'es') {
      setMonths(Months_es)
    } else {
      setMonths(Months_en)
    }
  }, [])


  const [sended, setSended] = useState(false);
  const [visible, setvisible] = useState(false)
  const [terms, setTerms] = useState(false)
  const openModal = () => {
    $('.terminos .checkmark').removeClass('invalide-all')
    setvisible(true)
    setTerms(!terms)
  }

  const closeModal = () => {
    setvisible(false)
  }

  const [inputs, setInputs] = useState({
    name: '',
    phone: '',
    state: '',
    city_id: '',
    zip: 0,
    genre:'',
    dbd: '',
    mbd: '',
    ydb: 0,
    smoker: 0,
    income: 0,
    birthday:''
  })

  const [error, setError] = useState(false);
  
  let [states, setStates] = useState([])
  let [cities, setCities] = useState([])
  let [selectcity, setSelCity] = useState()
  let [selectstate, setSelState] = useState()
  let [selectMonth, setSelMonth] = useState()
  let [selectDay, setSelDay] = useState()
  let [days, setDays] = useState([])
  let [months, setMonths] = useState([])


  const setSelectState = e => {
    $('body').find('.invalide').removeClass('invalide')
    // setError(false)
    setSelState(e)
    setSelCity(null)
    getCities(e.value)
    setInputs({ ...inputs, state: e.value, city_id: '' })
  }

  const setSelectCity = e => {
    $('body').find('.invalide').removeClass('invalide')
    // setError(false)
    setSelCity(e)
    setInputs({ ...inputs, city_id: e.value })
  }

  const setSelectMonth = e => {
    $('body').find('.invalide').removeClass('invalide')
    // setError(false)
    let day_s = []
    let daym = 32;
    if (e.value === '02') {
      daym = 29;
    }
    for (let day = 1; day < daym; day++) {
      if (day < 10) {
        day_s.push({ value: '0' + day, label: day })
      } else {
        day_s.push({ value: day, label: day })
      }
    }
    setSelMonth(e)
    setDays(day_s)
    setInputs({ ...inputs, mbd: e.value })
  }

  const setSelectDay = e => {
    $('body').find('.invalide').removeClass('invalide')
    // setError(false)
    setSelDay(e)
    setInputs({ ...inputs, dbd: e.value })
  }

  const getStates = async () => {
    await getinApi('get', 'states')
      .then((res) => {
        let states = [];
        res.states.forEach(state => {
          let stat = {
            value: state.state_code,
            label: state.state
          }
          states.push(stat);
        });
        setStates(states)
      })
      .catch((err) => console.log(err))
  }
  const getCities = async (id_state) => {
    await getinApi('get', `states/${id_state}/cities`)
      .then((res) => {
        let cities = [];
        res.cities.forEach(city => {
          let cit = {
            value: city.id,
            label: city.name
          }
          cities.push(cit);
        });
        setCities(cities)
      })
      .catch((err) => console.log(err))
  }

  const setInput = e => {
    $('body').find('.invalide').removeClass('invalide')
    $('body').find('.invalide-all').removeClass('invalide-all')
    // setError(false)
    setInputs({ ...inputs, [e.target.name]: e.target.value })
  }

  const submitInputs = async () => {
    let validations = 0;
    if ((inputs.name).trim() === '') {
      $('.inp-nombre').addClass('invalide')
      validations = 1;
    }
    if ((inputs.phone).trim() === '') {
      $('.inp-phone').addClass('invalide')
      validations = 1;
    }
    if ((String(inputs.zip)).trim() === '' || (inputs.zip).length < 4 || isNaN(inputs.zip) || (inputs.zip).length === 0 || inputs.zip === 0) {
      $('.inp-zip').addClass('invalide')
      validations = 1;
    }
    if ((String(inputs.city_id)).trim() === '') {
      $('.inp-city').addClass('invalide')
      validations = 1;
    }
    if ((String(inputs.state)).trim() === '') {
      $('.inp-state').addClass('invalide')
      validations = 1;
    }
    if ((String(inputs.dbd)).trim() === '') {
      $('#dia').addClass('invalide')
      validations = 1;
    }
    if ((String(inputs.mbd)).trim() === '') {
      $('#mes').addClass('invalide')
      validations = 1;
    }
    if ((inputs.ydb) === 0 || (inputs.ydb).length > 4 || (inputs.ydb).length < 4 || isNaN(inputs.ydb) || (inputs.ydb).length === 0) {
      $('#year').addClass('invalide')
      validations = 1;
    }
    if (inputs.income === 0 || isNaN(inputs.income) || (inputs.income).trim() === '' || (inputs.income).length === 0) {
      $('.inp-income').addClass('invalide')
      validations = 1;
    }
    if (!inputs.smoker) {
      $('.sino .checkmark').addClass('invalide-all')
      validations = 1;
    }
    if (!inputs.genre) {
      $('.inp-genre .checkmark').addClass('invalide-all')
      validations = 1;
    }
    if (!terms) {
      $('.terminos .checkmark').addClass('invalide-all')
      validations = 1;
    }

    if (validations) {
      // setError(true)
      return
    }

    $('.form-contact').attr('style', 'opacity:0.5')

    let birthday = { birthday: inputs.ydb + '-' + inputs.mbd + '-' + inputs.dbd }
    setInputs({ ...inputs, 'birthday': birthday })
    // dispathInputs(inputs)
    goCalculator()
    $('.form-contact').attr('style', 'opacity:1')
  }

  // const dispathInputs = (inputs) => {
  //   Object.keys(inputs).map(function (key) {
  //     return dispatch({ type: 'ADD_INPUT', payload: { [key]: inputs[key] } });
  //   });
  //   // console.log(state.inputs);
  // }

  /// Calculator 

  let [carrier, setCarrier] = useState([])
  let [termRecomeded, setTermRecomeded] = useState(0)
  let [coverRecomeded, setCoverRecomeded] = useState(0)
  let [quotes, setQuotes] = useState([])
  let [value_term, setValue] = useState(5)
  let [selected, setSelected] = useState(1)
  let [termopt, setTermopt] = useState({opt:null, term:'' , 'type':'', 'value':0})

  const [coverage, setCoverage] = useState({
    price: '',
    term: '',
    coverage: 'Standart',
    genre: 0,
    period: '',
    plan_id: 1,
    face_amt: 0
  })

  const [quote, setQuote] = useState({
    "state": "UT",
    "gender": "Male",
    "current_age": 30,
    "nearest_age": 31,
    "tobacco": false,
    "face_amount": value_term * 100000,
    "product_types": null,
    "health_categories": ["Standard"],
    "carrier_ids": [1594]
  })

  const setInputs2 = e => {
    if(value_term !== e){
      setValue(e)
      setQuote({ ...quote, face_amount: e * 100000 })
    }
  }

  const ranges = { 2: "20K", 2.5: "", 3: "", 5: "", 7.5: "", 10: "", 13: "", 15: "", 17: "", 20: "2M" }

  // eslint-disable-next-line
  const goCalculator = e => {
    getCarrier() 
  }
  
  // eslint-disable-next-line
  useEffect(() => { getQuote() }, [carrier]);
  // eslint-disable-next-line
  useEffect(() => { getQuote(); setTermopt({ opt: null, term: '', 'type': '', 'value': 0 }) }, [value_term]);

  const getCarrier = async () => {
    await getinIXN('get_carrier')
      .then((res) => {
        let terms = []
        res.forEach(term => {
          terms.push(term.product_type)
        });
        var years = moment().diff(inputs.ydb + '-' + inputs.mbd + '-' + inputs.dbd, 'years', true)+'';
        // var years = moment().diff('1989-04-15', 'years', true) + '';
        var splyear = years.split('.')
        var age = splyear[0] - 0;
        if (age > 50 ) {
          setTermRecomeded(20)
        }else{
          setTermRecomeded(30)
        }
        var neare = age - 0;
        if ((splyear[1].substring(0, 1)) - 0 > 6) {
          neare = age - 0 + 1
        }
        let recomended = ((inputs.income / 100000) * 15);
        // console.log(recomended);
        setValue(recomended);
        setCoverRecomeded(recomended);
        setQuote({ ...quote, state: inputs.state, product_types: terms, current_age: age - 0, nearest_age: neare - 0, gender: inputs.genre, tobacco: inputs.smoker, face_amount: recomended * 100000 })
        setCarrier(res)
      })
      .catch((err) => console.log(err))
  }

  const getQuote = async () => {
    if (quote.product_types){
      await getinIXN('get_quotes', quote)
        .then((res) => {
          setQuotes(res)
          $('.form_content').addClass('active animate__animated animate__bounceIn')
          $('.price_list .item').addClass('animate__animated animate__bounceIn')
          setTimeout(() => {
            $('.price_list .item').removeClass('animate__animated animate__bounceIn')
          }, 455)
        })
        .catch((err) => console.log(err))
    }
  }

  const setSel = async (index, term) => {
    if ((index+1) === selected ){
      if (termopt.opt){
        window.analytics.track('Term Selected', {
          term: coverage.term,
          attempt_id: Date.now()
        });
        // dispathInputs({ 'coverage': coverage })
        setSelected(index + 1)
        $('.prices').attr('style','opacity:0.4');
        await getinApiBlog('postHomeForm2', JSON.stringify({ terms: termopt , inputs: inputs  })).then((res) => {
          if (res === 200) {
            setSended(true)
          } else {
            setError(true)
          }
        }).catch((err) => console.log(err));
      }else{
        alert(`Error: Selecciona Uno`)
      }
    }else{
      setSelected(index + 1)
      $('.pricebox').removeClass('active')
      $('.pricebox:nth-child(' + (index + 1) +')').addClass('active')
      setTermopt({ opt: null, term: '', 'type': '', 'value': 0 })
    }
  }

  const setSelTerm = (opt , term , type, value, index ) => {
    if((index + 1) === selected) setTermopt({ opt: opt, term: term, 'type': type, 'value': value})
    var arrayDeCadenas = term.split(' ');
    if ((index + 1) === selected) setCoverage({ ...coverage, term: arrayDeCadenas[0], 'period': type, 'price': value, 'face_amt': (value_term * 100)})
  }

  const flexFont = () => {
    var divs = $(".flexFont");
    for (var i = 0; i < divs.length; i++) {
      var relFontsize = divs[i].offsetWidth * 0.27
      divs[i].style.fontSize = relFontsize + 'px'
    }
  }
  const flexFontM = () => {
    var divs = $(".flexFontM")
    for (var i = 0; i < divs.length; i++) {
      var relFontsize = divs[i].offsetWidth * 0.25
      divs[i].style.fontSize = relFontsize + 'px'
    }
  }

  window.onresize = function (event) {
    flexFont()
    flexFontM()
  }
  
  return (
    <div className="content_box calculator view">
      <Helmet>
        <title>Cotizador</title>
        <meta name="keywords" content="Cotizador, Vida, Bajo costo, Planes, Aseguranza, Seguros, Estados Unidos, emilan." />
        <meta name="description" content="Sabemos lo valioso que es tu tiempo, por ello, hemos diseñado una serie de preguntas que en cuestión de minutos te ayudarán a elegir el plan de vida que más se adapte a tus necesidades. Contamos con los mejores precios en aseguranza, bajos costos, prevención en todo momento. " />
      </Helmet>
      <div className="form-bkp">
        <div className="bkp-1"></div>
        <div className="bkp-2"></div>
        <div className="bkp-3"></div>
        <div className="bkp-4"></div>
        <h3 className="cotiza-title">{t('calculate.label')}</h3>
        <div className="mostrar-girl">
          <img src="assets/Images/mostrar.png"
            title="Cotizador emilan, seguros a bajo costo"
            alt="Contamos con lo ultimo en tecnología llevándote a vivir una experiencia única con nuestro cotizador de vida, en cuestión de minutos tendrás un aproximado del valor de tu plan. Olvídate de los mitos y atrévete a proteger tu vida y la de los que mas quieres."
          />
        </div>
        {sended ?
          <div className="calculator-form">
            <div className="chatelo" >
              <div className="swal2-icon swal2-success swal2-icon-show" style={{ Display: 'flex' }}><div className="swal2-success-circular-line-left" style={{ backgroundColor: 'rgb(255, 255, 255)' }}></div>
                <span className="swal2-success-line-tip"></span> <span className="swal2-success-line-long"></span>
                <div className="swal2-success-ring"></div> <div className="swal2-success-fix" style={{ backgroundColor: 'rgb(255, 255, 255)' }}></div>
                <div className="swal2-success-circular-line-right" style={{ backgroundColor: 'rgb(255, 255, 255)' }}></div>
              </div>
              <h3 data-aos="fade" >{t('thanks1.label')}</h3>
              <h2 data-aos="fade" >{t('thanks2.label')}</h2>
            </div>
          </div>
          :
          <div className="calculator-form">
            <div className="form-contact" id="formcontacto">
              <div className="form-group">
                <label>{t('name.label')}</label>
                <input className='input inp-nombre' type='text' name='name' onChange={setInput} />
              </div>
              <div className="two-columns">
                <div className="form-group">
                  <label>{t('phone.label')}</label>
                  <input className=' input inp-phone' type='tel' name='phone' onChange={setInput} />
                </div>
                <div className="form-group postalstate">
                  <label>{t('state.label')}</label>
                  <Select className='inp-ciudad slect-tag inp-state' id="inp-state" classNamePrefix="react-select" options={states} value={selectstate} placeholder={t('state.label')} onChange={setSelectState} />
                </div>
              </div>
              <div className="two-columns">
                <div className="form-group">
                  <label>{t('city.label')}</label>
                  <Select className='inp-ciudad slect-tag inp-city' id="inp-ciudad" classNamePrefix="react-select" options={cities} value={selectcity} placeholder={t('city.label')} onChange={setSelectCity} />
                </div>
                <div className="form-group postal">
                  <label>{t('zip.label')}</label>
                  <input className='input inp-zip' type='text' name='zip'  onChange={setInput} />
                </div>
              </div>
              <div className="form-group dta-bird">
                <label>{t('born.label')}</label>
                <div className="flx select-bday">
                  <Select className='slect-tag mes postalmont' id="mes" classNamePrefix="react-select" options={months} value={selectMonth} onChange={setSelectMonth} placeholder={t('mes.label')} />
                  <Select className='slect-tag dia postalmont' id="dia" classNamePrefix="react-select" options={days} value={selectDay} onChange={setSelectDay} placeholder={t('dia.label')} />
                  <input className='input year postalmont' id='year' type="text" onChange={setInput} name='ydb' placeholder={t('año.label')} />
                </div>
              </div>
              <div className="two-columns col-last">
                <div className="form-group fomas">
                  <label>{t('smoke.label')}</label>
                  <div className="form-st3 sino">
                    <label className='checkbox label genero' htmlFor="si">
                      <input id='si' type='radio' name='smoker' value='1' onChange={setInput} />
                      <span className="checkmark"></span>{t('yes.label')}
                    </label>
                    <label className='checkbox label genero' htmlFor="no">
                      <input id='no' type="radio" name='smoker' value='0' onChange={setInput} />
                      <span className="checkmark"></span>{t('no.label')}
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <label>{t('income.label')}</label>
                  <input className='input inp-income' type="text" name='income' onChange={setInput} />
                </div>
              </div>
              <div className="form-group genre-group">
                <label>{t('genre.label')}</label>
                <div className='flx form-st3 form_ inp-genre'>
                  <label className='checkbox genero' htmlFor="hombre">
                    <input id='hombre' type='radio' name='genre' defaultValue='Male' onChange={setInput} />
                    <span className="checkmark"></span>
                    {t('man.label')}
                  </label>
                  <label className='checkbox genero' htmlFor="mujer">
                    <input id='mujer' type="radio" name='genre' defaultValue='Female' onChange={setInput} />
                    <span className="checkmark"></span>
                    {t('woman.label')}
                  </label>
                </div>
              </div>
              <div className="terms-calculator">
                <label className='checkbox terminos' htmlFor="acepto">
                  <input id='acepto' type='checkbox' name='terms' defaultValue={terms} onClick={() => openModal()} />
                  <span className="checkmark"></span>
                  <p>
                    {t('policies.label')}
                  </p>
                </label>
              </div>
              <button className='btn-submit' onClick={submitInputs} type='submit' value='continuar'>{t('cotizar.label')}</button>

            </div>
            <div className="form_content">
              <h3>{t('terecomendaunaa.label')} <span>{termRecomeded}</span>{t('terecomendaunab.label')} </h3>
              <p>{t('pofavselec.label')}</p>
              <form>
                <h2>{t('cobertura.label')}</h2>
                <div className="slider-wrapper">
                  <label>${value_term * 100},000</label>
                  <span>{t('recomen.label')}</span>
                  <Slider min={2} max={20} defaultValue={value_term} value={value_term} marks={ranges} step={null} onChange={setInputs2} />
                </div>
              </form>
              <h2 className="terms">{t('termaño.label')}</h2>
            </div>
            <div className="prices">
              {quotes.length > 0 && quotes.map((quote, index) =>
                (index < 4 && index != 2) ?
                  <div key={index + 'quotes'} className={((index + 1) === selected) ? 'pricebox active animate__animated animate__bounceIn' : 'pricebox'} >
                    {quote.product_type.split(" ").map(function (char, index) {
                    
                      if (index === 0) {
                        return <h2 className="header" key={char + index}> {char} {t('años.label')}</h2>;
                      }
                    })}
                    <div className='price_list'>
                      <div className={`${((termopt.opt + 1) === 1) ? 'active item' : 'item'} ${(termopt.opt === 1 && termopt.term === quote.product_type) ? 'active' : ''}`} onClick={() => setSelTerm(1, quote.product_type, 'monthly', quote.premium_monthly, index)} >
                        <div className="circle"><i></i></div>
                        <span>$</span>
                          {((quote.premium_monthly+"").indexOf(".") != -1 ) ?
                            (quote.premium_monthly + "").split(".").map(function (cost, index) {
                              if (index === 0) {
                                return cost;
                              }
                            }) : <div> { quote.premium_monthly }</div>
                        }
                        <div className="type flexFontS" >{t('mes.label')}</div>
                      </div>
                      <div className={(termopt.opt === 2 && termopt.term === quote.product_type) ? 'item active' : 'item'} onClick={() => setSelTerm(2, quote.product_type, 'quarterly', quote.premium_quarterly, index)}>
                        <div className="circle"><i></i></div>
                        <span>$</span>
                        {((quote.premium_quarterly + "").indexOf(".") != -1) ?
                          (quote.premium_quarterly + "").split(".").map(function (cost, index) {
                            if (index === 0) {
                              return cost;
                            }
                          }) : <div> {quote.premium_quarterly}</div>
                        }
                        <div className="type">{t('trim.label')}</div>
                      </div>
                      <div className={(termopt.opt === 3 && termopt.term === quote.product_type) ? 'item active' : 'item'} onClick={() => setSelTerm(3, quote.product_type, 'semi_annual', quote.premium_semi_annual, index)}>
                        <div className="circle"><i></i></div>
                        <span>$</span>
                        {((quote.premium_semi_annual + "").indexOf(".") != -1) ?
                          (quote.premium_semi_annual + "").split(".").map(function (cost, index) {
                            if (index === 0) {
                              return cost;
                            }
                          }) : <div> {quote.premium_semi_annual}</div>
                        }
                        <div className="type">{t('sem.label')}</div>
                      </div>
                      <div className={(termopt.opt === 4 && termopt.term === quote.product_type) ? 'item active' : 'item'} onClick={() => setSelTerm(4, quote.product_type, 'annual', quote.premium_annual, index)}>
                        <div className="circle"><i></i></div>
                        <span>$</span>
                        {((quote.premium_annual + "").indexOf(".") != -1) ?
                          (quote.premium_annual + "").split(".").map(function (cost, index) {
                            if (index === 0) {
                              return cost;
                            }
                          }) : <div> {quote.premium_annual}</div>
                        }
                        <div className="type">{t('año.label')}</div>
                      </div>
                    </div>
                    <div className="btn_price" onClick={() => setSel(index)}>{((index + 1) === selected) ? t('aplic.label') : t('verm.label')}</div>
                  </div> : ''
              )}
            </div>
          </div>
        }
      </div>
      <Modal className="modales-n" visible={visible} width="90%" height="80%" effect="fadeInUp" onClickAway={() => closeModal()}>
        <div>
          <Policies />
          <a className="boton-close" onClick={() => closeModal()}>Continuar</a>
        </div>
      </Modal>
    </div>
  )
}


