import React, { useEffect } from 'react'
import imagen_lady from '../../../assets/Images/landings/servicios/Lang_salud-lady-working.png'
import logo_medicare from '../../../assets/Images/landings/servicios/Medicare-Logo.png'
import FormLandings from './form_landings'

const ServicioMedicare = () => {

  useEffect(() => {
    localStorage.setItem('lng', 'es')
  }, [])

  return (
    <div className="servicios servicio_medicare">
      <section className="div_1_medi">
        <div className="image_mobile"></div>
        <div className="busca_plan">
          <div className="logo_m">
            <img src={logo_medicare} alt="medicare_logo" />
          </div>
          <p>Este es el mejor momento para buscar el <b>plan de medicare</b> perfecto para ti, queremos ayudarte a que continúes protegido para disfrutar de tu salud con los que amas <i className="icon icon-family_emoji" />.</p>
          <p className='par2_banner_medi'>Planes en tu área</p>
          <	FormLandings />
        </div>
      </section>
      <section className='div_2_medi'>
        <div className="buble1"></div>
        <p className='parr_1_div2'>Comunícate con un agente certificado</p>
        <div className="options_contact">
          <a className='btn_orange' href="tel:+19546982058">
            <i className="icon icon-headset"></i>
            <p>Llama al <br />
              <span>(954) 698-2058</span></p>
          </a>
          <a className='btn_orange_mobile' href="tel:+19546982058">
            <i className="icon icon-headset"></i>
            <p>Habla con un agente certificado<br />
              <span>(954) 698-2058</span></p>
          </a>
          <a className='btn_lightBlue' href="https://m.me/emilan.co.fb?ref=w16836924" target='_blank'>
            <i className="icon icon-messenger"></i>
            <p>Chatea en <span>Facebook</span></p>
          </a>
          <a className='btn_lightBlue' href='https://bit.ly/emilanmedicare1' target='_blank' >
            <i className="icon icon_what-share"></i>
            <p>Chatea en <span>Whatsapp</span></p>
          </a>
        </div>
        <div className="contenido_div2">
          <h4>Medicare <span>tiene varias opciones, escoge la que mejor vaya contigo:</span></h4>
          <div className="cards_medicare">

            <div className="card_parte">
              <div className="title_card_parte">
                <h5>Parte A</h5>
              </div>
              <div className="body_card_parte">
                <p>Aquí tienes cobertura de:</p>
                <ul>
                  <li>
                    <i className="icon icon-hospital_emoji"></i>
                    <p>Tus cuidados paliativos, hospitalarios y servicios de enfermería especializada.</p>
                  </li>
                  <li>
                    <i className="icon icon-cash_emoji"></i>
                    <p>Con primas desde 0*, deducibles y copagos sólo en caso de usar estos servicios.</p>
                  </li>
                </ul>
                <a className="btn_card_parte" href='https://m.me/emilan.co.fb?ref=w16836924' target='_blank' >Quiero saber más</a>
              </div>
            </div>

            <div className="card_parte">
              <div className="title_card_parte">
                <h5>Parte B</h5>
              </div>
              <div className="body_card_parte">
                <p>Te cubre:</p>
                <ul>
                  <li>
                    <i className="icon icon-doctora_emoji"></i>
                    <p>Visitas al médico.</p>
                  </li>
                  <li>
                    <i className="icon icon-examen_emoji"></i>
                    <p>Pruebas de diagnóstico.</p>
                  </li>
                  <li>
                    <i className="icon icon-corazon_emoji"></i>
                    <p>Cuidados preventivos.</p>
                  </li>
                  <li>
                    <i className="icon icon-ambulancia_emoji"></i>
                    <p>Urgencias, entre otros.</p>
                  </li>
                  <li>
                    <i className="icon icon-cash_emoji"></i>
                    <p>Con una cobertura del 80% de tus gastos médicos.</p>
                  </li>
                </ul>
                <a className="btn_card_parte" href='https://m.me/emilan.co.fb?ref=w16836924' target='_blank' >Quiero saber más</a>
              </div>
            </div>

            <div className="card_parte">
              <div className="title_card_parte">
                <h5>Parte C</h5>
              </div>
              <div className="body_card_parte">
                <p>Pensando en los mejores beneficios para ti, esta opción combina la Parte A, B y D, brindándote:</p>
                <ul>
                  <li>
                    <i className="icon icon-doctora_emoji"></i>
                    <p>Una cobertura completa de tus gastos médicos y hospitalarios.  </p>
                  </li>
                  <li>
                    <i className="icon icon-medicamento_emoji"></i>
                    <p>Medicamentos recetados y OTC, entre muchos otros.</p>
                  </li>
                  <li>
                    <i className="icon icon-cash_emoji"></i>
                    <p>Con primas desde 0*, sin deducibles y un máximo de bolsillo anual.</p>
                  </li>
                </ul>
                <a className="btn_card_parte" href='https://m.me/emilan.co.fb?ref=w16836924' target='_blank' >Quiero saber más</a>
              </div>
            </div>

            <div className="card_parte">
              <div className="title_card_parte">
                <h5>Parte D</h5>
              </div>
              <div className="body_card_parte">
                <p>Te cubre:</p>
                <ul>
                  <li>
                    <i className="icon icon-medicamento_emoji"></i>
                    <p>Los medicamentos recetados**.</p>
                  </li>
                  <li>
                    <i className="icon icon-jeringa_emoji"></i>
                    <p>Te ofrece una red de farmacias en las que conseguirás tu medicamento al precio más favorable.</p>
                  </li>
                </ul>
                <a className="btn_card_parte" href='https://m.me/emilan.co.fb?ref=w16836924' target='_blank' >Quiero saber más</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='div_3_medi'>
        <div className="buble2"></div>
        <div className="llamar_healt">
          <div className="llamar_info">
            <h4>¿Prefieres hablar directamente con alguien? Llámanos al</h4>
            <a className='btn_orange2' href="tel:+19546982058">
              (954) 698-2058
            </a>
          </div>
          <div className="image">
            <img src={imagen_lady} alt="imagen prefiere llamar" />
          </div>
        </div>
        <p className="condiciones">
          *Los costos de primas, deducibles, máximo de bolsillo, copagos y coaseguros varían según el plan al que estés aplicando **La lista de medicamentos recetados puede variar en cada plan dependiendo del formulario de tu estado.
        </p>
      </section>
    </div>
  )
}

export default ServicioMedicare